import { SET_STRIPE_INSTANCE, type StripeAction, type StripeInstance } from './StripeActions';

export interface StripeState {
  stripeInstance: StripeInstance;
}

const initialState = {
  stripeInstance: null,
};

const StripeReducer = (state: StripeState = initialState, action: StripeAction) => {
  switch (action.type) {
    case SET_STRIPE_INSTANCE:
      return {
        ...state,
        stripeInstance: action.stripeInstance,
      };

    default:
      return state;
  }
};

export default StripeReducer;
