import type { ChangeEvent } from 'react';
import React from 'react';
import { Box, Button } from '@popmenu/common-ui';
import type { ClassNameMap } from '@material-ui/core/styles/withStyles';
// @ts-expect-error Internal file is not TypeScript: TODO update to typescript
import TextField from '../../../admin/shared/forms/TextField';

interface CodeInputFormProps {
  classes: ClassNameMap<'underline' | 'errorInfoMessagesContainer' | 'feeDisclaimer' | 'minimumBox' | 'noPadding' | 'offerTextBox' | 'qualifyingItem' | 'qualifyingItemsDetail' | 'qualifyingItemsNotice' | 'selectedOfferCardChipContainer'>
  title: string;
  buttonText: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onApply: () => void;
  onFocus: () => void;
}

const CodeInputForm = ({
  classes,
  title,
  buttonText,
  value,
  onChange,
  onApply,
  onFocus,
}: CodeInputFormProps) => (
  <Box display="flex" width="100%">
    <TextField
      id="pm-offer-code-text-input"
      className={classes.offerTextBox}
      fullWidth
      variant="outlined"
      title={title}
      value={value}
      onFocus={onFocus}
      onChange={onChange}
      InputProps={{
        'data-cy': 'redeem_code_input',
      }}
      size="small"
    />
    <Button
      color="primary"
      disabled={!value}
      onClick={onApply}
      variant="contained"
    >
      {buttonText}
    </Button>
  </Box>
);

export default CodeInputForm;
