import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getParam } from '../utils/urls';
import AnnouncementBanner from './announcements/AnnouncementBanner';
import RootAnnouncement from './shared/RootAnnouncement/RootAnnouncement';
import DraftBanner from './draft_banner/DraftBanner';

const BannerContainer = ({ draftMode, restaurant, showAnnouncements }) => {
  const [customPageDraft, setCustomPageDraft] = useState(false);
  const [dismissedDraftBanner, setDismissedDraftBanner] = useState(false);

  const currentRestaurantBanner = restaurant.featuredAnnouncementsToday ? <AnnouncementBanner restaurant={restaurant} /> : <RootAnnouncement restaurant={restaurant} />;
  const bannerReady = useCallback((param) => {
    setCustomPageDraft(param);
  }, [setCustomPageDraft]);

  useEffect(() => {
    bannerReady(getParam('custom_page_draft'));
  }, [bannerReady]);

  const showDraftBanner = customPageDraft && !dismissedDraftBanner;

  let customDraftBannerMessage;

  if (!restaurant.theme.customCssEnabled) {
    customDraftBannerMessage = {
      id: 'custom_page.draft_preview_no_custom_css',
      message: 'Preview of draft content: Custom CSS Disabled',
    };
  } else if (!draftMode) {
    customDraftBannerMessage = {
      id: 'custom_page.draft_preview_invalid',
      message: 'You are viewing the live version of this page',
    };
  }

  return (
    <React.Fragment>
      {showDraftBanner && (
        <DraftBanner customMessage={customDraftBannerMessage} setDismissedDraftBanner={() => setDismissedDraftBanner(true)} />
      )}
      {!showDraftBanner && showAnnouncements && currentRestaurantBanner}
    </React.Fragment>
  );
};

BannerContainer.propTypes = {
  restaurant: PropTypes.shape({
    id: PropTypes.number,
    theme: PropTypes.shape({
      announcementBgColor: PropTypes.string,
      announcementBorderColor: PropTypes.string,
    }),
  }).isRequired,
  showAnnouncements: PropTypes.bool.isRequired,
};

export default BannerContainer;
