import React, { useState, useMemo, useEffect } from 'react';

import type { ScrollObserverRef } from '~/utils/useScrollObserver';
import { buildResponsiveImgProps } from '../utils/responsiveImages';
import { usePopmenuConfig } from '../utils/withPopmenuConfig';
import { useRestaurant } from '../utils/withRestaurant';

import { classNames, makeStyles } from '../utils/withStyles';
import { usePageLoadStageFinished } from '../utils/postponed';

import { visualRegressionsMode } from '../utils/visualRegressionsMode';
import { useLazyScrollObserver } from '../utils/useLazyScrollObserver';

const useTransitionStyles = makeStyles({
  img: {
    transition: '0.6s filter linear',
  },
});

type ImgProps = Omit<
  React.ImgHTMLAttributes<HTMLImageElement>,
  // these properties have different types in CustomImgProps and React.ImgHTMLAttributes
  'alt' | 'onLoad' | 'src'
>;

interface ScrolledCustomImgProps extends ImgProps {
  alt?: string | null;
  desktopDownscale?: number;
  index?: number;
  onLoad?: ((index: number, img: HTMLImageElement) => void) | null;
  priority?: 'primary' | 'secondary';
  size?: 'sm' | 'lg';
  src?: string | null;
  usePreviewAsPlaceholder?: boolean;
  waitForScrolled?: boolean;
}

interface CustomImgProps extends ScrolledCustomImgProps {
  cloudflareBaseUrl: string;
  restaurantName?: string | null;
  loadHiFiImage: boolean;
  scrollObserverRef: ScrollObserverRef<HTMLImageElement>;
}

const CustomImg = ({
  alt = null,
  className,
  cloudflareBaseUrl,
  desktopDownscale = 2,
  index = -1,
  loadHiFiImage,
  onLoad = null,
  priority = 'secondary',
  restaurantName = null,
  scrollObserverRef,
  size = 'lg',
  src = null,
  style = {},
  usePreviewAsPlaceholder = false,
  waitForScrolled = true,
  width = undefined,
  height = undefined,
  ...imgProps
}: CustomImgProps) => {
  const placeholderQuality = priority === 'primary' ? 'high' : 'low';
  const showPlaceholder = !loadHiFiImage && waitForScrolled && !visualRegressionsMode;
  const [shouldApplyBlur, setShouldApplyBlur] = useState(showPlaceholder);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [shouldUseLowerSizeInitially, setShouldUseLowerSizeInitially] = useState(usePreviewAsPlaceholder);

  const transitionClasses = useTransitionStyles();

  useEffect(() => {
    setIsFirstRender(false);
    const img = scrollObserverRef.current;

    if (img && !showPlaceholder) {
      const onImgLoad = () => {
        // A straightforward way <img onLoad={onLoad} ... /> doesn't work with browser cache.
        // The cast is safe because this only gets called when `img` is defined.
        if (onLoad) onLoad(index, img);
        setShouldApplyBlur(false);
        setShouldUseLowerSizeInitially(false);
      };

      if (img.complete) onImgLoad();
      else img.onload = onImgLoad;
    }
  }, [scrollObserverRef, onLoad, usePreviewAsPlaceholder, showPlaceholder, index, src, size]);

  const { legacyBrowsersFallbackSrc, sources = [] } = useMemo(() => {
    let imgOptions = {
      desktopDownscale,
      downscale: 1,
      quality: 60,
      size,
    };

    // |usePreviewAsPlaceholder| is useful when there is an already loaded preview with size="sm".
    // This small preview will be used as a low-fi placeholder to show the image faster.
    // Should only be used when size="lg"
    if (shouldUseLowerSizeInitially) {
      imgOptions.size = 'sm';
    } else if (showPlaceholder) {
      imgOptions = {
        desktopDownscale,
        downscale: placeholderQuality === 'low' ? 6 : 1,
        quality: placeholderQuality === 'low' ? 5 : 20,
        size,
      };
    }

    return buildResponsiveImgProps(cloudflareBaseUrl, src, imgOptions);
  }, [cloudflareBaseUrl, shouldUseLowerSizeInitially, desktopDownscale, src, showPlaceholder, placeholderQuality, size]);

  if (!src) {
    return null;
  }

  return (
    <picture>
      {sources.map((source, i) => (
        <source
          key={i}
          srcSet={source.srcs.map((srcSetItem, dpiIndex) => `${srcSetItem} ${dpiIndex + 1}x`).join(', ')}
          media={`(min-width: ${i === 0 ? 0 : sources[i - 1]?.width}px)`}
        />
      )).reverse()}
      <img
        {...imgProps}
        data-cy={`custom_img_${(showPlaceholder || shouldUseLowerSizeInitially) ? 'placeholder' : 'hi_fi'}`}
        className={classNames(
          className,
          {
            'pm-low-fi-placeholder': placeholderQuality === 'low' && shouldApplyBlur,
            [transitionClasses.img]: waitForScrolled && !isFirstRender,
          },
        )}
        alt={(alt || restaurantName) ?? undefined}
        ref={scrollObserverRef}
        src={legacyBrowsersFallbackSrc}
        style={style}
        width={width}
        height={height}
      />
    </picture>
  );
};

// eslint-disable-next-line react/require-default-props -- passed forward as a whole
const ScrolledCustomImg = (props: ScrolledCustomImgProps) => {
  const { cloudflareUrl } = usePopmenuConfig();
  const restaurantName = useRestaurant()?.name;
  const { scrolledTo, scrollObserverRef } = useLazyScrollObserver<HTMLImageElement>();

  const firstSessionLoaded = usePageLoadStageFinished('firstSessionLoaded');

  // During the early stages of the first session, we rush to load other assets besides the 'primary' hi-fi images.
  // 'primary' lo-fi placeholders look decent enough so we can postpone hi-fi images until other assets arrive.
  // On later visits, after the first session is loaded we can afford all hi-fi images since everything is cached.
  const shouldSkipHiFiImage = !firstSessionLoaded && props.priority === 'primary';

  return (
    <CustomImg
      {...props}
      cloudflareBaseUrl={cloudflareUrl}
      loadHiFiImage={scrolledTo && !shouldSkipHiFiImage}
      restaurantName={restaurantName}
      scrollObserverRef={scrollObserverRef}
    />
  );
};

export default ScrolledCustomImg;
