
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "navBilling": {
      "id": "nav.billing",
      "defaultMessage": "Billing"
    },
    "navClientAccessibility": {
      "id": "nav.client_accessibility",
      "defaultMessage": "Website Accessibility"
    },
    "navClientTerms": {
      "id": "nav.client_terms",
      "defaultMessage": "Client Terms of Service"
    },
    "navGiveawayTerms": {
      "id": "nav.giveaway_terms",
      "defaultMessage": "Giveaway Terms"
    },
    "navGoogleMyBusiness": {
      "id": "nav.google_my_business",
      "defaultMessage": "Google Business Profile"
    },
    "navLocations": {
      "id": "nav.locations",
      "defaultMessage": "Locations"
    },
    "navMenu": {
      "id": "nav.menu",
      "defaultMessage": "Menus"
    },
    "navPops": {
      "id": "nav.pops",
      "defaultMessage": "My Pops"
    },
    "navPrivacy": {
      "id": "nav.privacy",
      "defaultMessage": "Privacy Policy"
    },
    "navCcpa": {
      "id": "nav.ccpa",
      "defaultMessage": "Do Not Sell My Personal Information"
    },
    "navProfile": {
      "id": "nav.profile",
      "defaultMessage": "Profile"
    },
    "navRestaurants": {
      "id": "nav.restaurants",
      "defaultMessage": "Restaurants"
    },
    "navRootBillingPlans": {
      "id": "nav.root_billing_plans",
      "defaultMessage": "Root Products"
    },
    "navRootBillingCoupons": {
      "id": "nav.root_billing_coupons",
      "defaultMessage": "Root Coupons"
    },
    "navSettings": {
      "id": "nav.settings",
      "defaultMessage": "Settings"
    },
    "navSignIn": {
      "id": "nav.sign_in",
      "defaultMessage": "Sign In"
    },
    "navSignOut": {
      "id": "nav.sign_out",
      "defaultMessage": "Sign Out"
    },
    "navWebAccessibility": {
      "id": "nav.web_accessibility",
      "defaultMessage": "Website Accessibility"
    },
    "navWelcome": {
      "id": "nav.welcome",
      "defaultMessage": "Welcome, {name}!"
    }
  }
);
export default defaultMessages;

