/* eslint-disable no-param-reassign */
import React from 'react';
import { injectResponsiveImgs } from '../../utils/responsiveImages';
import { injectScriptFlag } from '../../utils/dom';

import { useAccessibleHeadingLevel } from './useAccessibleHeadingLevel';

const openingTagsRegex = /<h([1-6])[^<]*>/g;
const closingTagsRegex = /<\/h([1-6])[^<]*>/g;
const classNameRegex = /class="([\w|\s|-]+)"/g;

const openingHTMLCommentTag = '<!--';
const closingHTMLCommentTag = '-->';
function guardHTMLComments(html: string) {
  // Find the index of the last occurrence of the opening and closing comment tags
  const lastOpeningTagIndex = html.lastIndexOf(openingHTMLCommentTag);
  const lastClosingTagIndex = html.lastIndexOf(closingHTMLCommentTag);

  // Check if there is an opening tag without a corresponding closing tag after it
  if (lastOpeningTagIndex > lastClosingTagIndex) {
    // If so, append a closing comment tag to the end of the HTML content
    return `${html} ${closingHTMLCommentTag}`;
  }

  // If the HTML content is properly enclosed or does not need enclosing, return it unchanged
  return html;
}

type CustomHTMLProps = {
  children: (html: string) => JSX.Element | null;
  cloudflareBaseUrl: string,
  contentRef: React.RefObject<HTMLDivElement>,
  html: string;
  id: string,
}

export const CustomHTML = ({ children, html, cloudflareBaseUrl, id, contentRef }: CustomHTMLProps) => {
  openingTagsRegex.lastIndex = 0;
  const hasHeadings = openingTagsRegex.test(html);
  const headingLevel = useAccessibleHeadingLevel(contentRef, !hasHeadings);

  const convertedHTML = React.useMemo(() => {
    let result: string = injectResponsiveImgs(cloudflareBaseUrl, html, { size: 'lg' });
    result = injectScriptFlag(result, id);
    result = fixHeadingsOrder(result, headingLevel);
    return guardHTMLComments(result);
  }, [cloudflareBaseUrl, headingLevel, html, id]);
  return children(convertedHTML);
};

export const tokenizeByHeadingTags = (html: string) => {
  const tokens = tokenizeByRegex(html, openingTagsRegex, false);

  return tokens.map(token => tokenizeByRegex(token, closingTagsRegex, true)).flat();
};

function tokenizeByRegex(source: string, regex: RegExp, shouldIncludeMatch: boolean) {
  const tokens = [];

  let currentIndex = 0;

  regex.lastIndex = 0;

  [...source.matchAll(regex)].forEach((match) => {
    const index = (match.index ?? 0) + (shouldIncludeMatch ? match[0].length : 0);
    if (index === currentIndex) return;
    const token = source.slice(currentIndex, index);
    tokens.push(token);
    currentIndex = index;
  });

  if (currentIndex < source.length) {
    const token = source.slice(currentIndex);
    tokens.push(token);
  }

  regex.lastIndex = 0;

  return tokens;
}

// html must be in form of <h\d ... > ... </h\d>
function fixHeadingLevelForSingleHtmlToken(html: string, newLevel: number, oldLevel: number) {
  classNameRegex.lastIndex = 0;
  const [firstTag, ...restHtml] = html.split('>');
  if (!firstTag) return html;

  const tokens = tokenizeByRegex(firstTag, classNameRegex, false);
  let convertedHtml = html;
  const classNameExists = tokens.length > 1;
  if (classNameExists && tokens[1]) {
    const subtokens = tokens[1].split('"');
    subtokens[1] = `${subtokens[1]} pm-h${oldLevel} pm-AH`;
    convertedHtml = `${tokens[0] + subtokens.join('"')}>${restHtml.join('>')}`;
  } else {
    convertedHtml = html.replace(`<h${oldLevel}`, `<h${oldLevel} class="pm-h${oldLevel} pm-AH"`);
  }

  return convertedHtml.replace(`<h${oldLevel}`, `<h${newLevel}`).replace(`</h${oldLevel}`, `</h${newLevel}`);
}

function fixHeadingsOrder(html: string, baseHeadingLevel: number) {
  type HeadingInnerHTMLInfo = {
    html: string, isValidHeading: boolean, oldLevel: number, newLevel?: number
  };
  const tokens: HeadingInnerHTMLInfo[] = tokenizeByHeadingTags(html).map((token) => {
    openingTagsRegex.lastIndex = 0;
    closingTagsRegex.lastIndex = 0;
    const openingTagExecResult = openingTagsRegex.exec(token);
    const openingTagLevel = openingTagExecResult?.[1];
    if (!openingTagLevel) {
      return { html: token, isValidHeading: false, oldLevel: NaN };
    }
    const closingTagLevel = closingTagsRegex.exec(token)?.[1];
    openingTagsRegex.lastIndex = 0;
    closingTagsRegex.lastIndex = 0;
    const isValidHeading = ['1', '2', '3', '4', '5', '6'].includes(openingTagLevel) && openingTagLevel === closingTagLevel;
    return {
      html: token,
      isValidHeading,
      oldLevel: Number(openingTagLevel),
    };
  });

  let firstLevel = -1;
  let previousLevel = firstLevel;

  tokens.forEach((token) => {
    if (token.isValidHeading) {
      token.newLevel = token.oldLevel;

      if (firstLevel === -1) {
        firstLevel = token.newLevel;
        previousLevel = firstLevel;
      }
      if (token.newLevel < firstLevel) {
        token.newLevel = firstLevel;
      }
      if (token.newLevel > previousLevel + 1) {
        token.newLevel = previousLevel + 1;
      }

      previousLevel = token.newLevel;
    }
  });

  return tokens.map((token) => {
    if (token.isValidHeading && token.newLevel) {
      let newLevel = token.newLevel - firstLevel + baseHeadingLevel;
      if (newLevel > 6) newLevel = 6;
      return fixHeadingLevelForSingleHtmlToken(token.html, newLevel, token.oldLevel);
    }
    return token.html;
  }).join('');
}
