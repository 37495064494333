import type { Dispatch } from 'redux';
import type { CartType, FulfillmentType } from '~/utils/types';
import type { DeliveryLocation as GqlDeliveryLocation } from '~/libs/gql/types';

export const RESET_MENU_ITEM_CART_DATA = 'RESET_MENU_ITEM_CART_DATA';
export const SET_MENU_ITEM_CART_ADDRESS = 'SET_MENU_ITEM_CART_ADDRESS';
export const SET_MENU_ITEM_CART_BYPASS_PROMPT = 'SET_MENU_ITEM_CART_BYPASS_PROMPT';
export const SET_MENU_ITEM_CART_CHECKOUT_PENDING = 'SET_MENU_ITEM_CART_CHECKOUT_PENDING';
export const SET_MENU_ITEM_CART_IS_SCHEDULED = 'SET_MENU_ITEM_CART_IS_SCHEDULED';
export const SET_MENU_ITEM_CART_LOCATION_ID = 'SET_MENU_ITEM_CART_LOCATION_ID';
export const SET_MENU_ITEM_CART_SCHEDULED_AT = 'SET_MENU_ITEM_CART_SCHEDULED_AT';
export const SET_MENU_ITEM_CART_TYPE = 'SET_MENU_ITEM_CART_TYPE';
export const SET_EDIT_MENU_ITEM_CART_LOCATION_MODAL_ENABLED = 'SET_EDIT_MENU_ITEM_CART_LOCATION_MODAL_ENABLED';
export const SET_MENU_ITEM_CART_SPECIAL_INSTRUCTIONS_MAX_LENGTH = 'SET_MENU_ITEM_CART_SPECIAL_INSTRUCTIONS_MAX_LENGTH';
export const SET_MENU_ITEM_CART_FULFILLMENT_TYPE = 'SET_MENU_ITEM_CART_FULFILLMENT_TYPE';
export const SET_MENU_ITEM_CART_DELIVERY_ADDRESS = 'SET_MENU_ITEM_CART_DELIVERY_ADDRESS';
export const SET_MENU_ITEM_CART_DELIVERY_ADDRESS_EXTRA = 'SET_MENU_ITEM_CART_DELIVERY_ADDRESS_EXTRA';
export const SET_MENU_ITEM_CART_DELIVERY_ADDRESS_ERROR = 'SET_MENU_ITEM_CART_DELIVERY_ADDRESS_ERROR';
export const SET_MENU_ITEM_CART_DELIVERY_VERIFIED_ADDRESS = 'SET_MENU_ITEM_CART_DELIVERY_VERIFIED_ADDRESS';
export const SET_MENU_ITEM_CART_DELIVERY_LOCATIONS = 'SET_MENU_ITEM_CART_DELIVERY_LOCATIONS';
export const SET_MENU_ITEM_CART_DELIVERY_PENDING_VERIFIED_ADDRESS = 'SET_MENU_ITEM_CART_DELIVERY_PENDING_VERIFIED_ADDRESS';
export const SET_MENU_ITEM_CART_DELIVERY_PENDING_LOCATIONS = 'SET_MENU_ITEM_CART_DELIVERY_PENDING_LOCATIONS';
export const SET_MENU_ITEM_CART_ID = 'SET_MENU_ITEM_CART_ID';
export const SET_MENU_ITEM_CART_NEEDS_UPDATE = 'SET_MENU_ITEM_CART_NEEDS_UPDATE';
export const SET_SELECTED_MENU_ITEM = 'SET_SELECTED_MENU_ITEM';
export const SET_SELECTED_SECTION_INDEX = 'SET_SELECTED_SECTION_INDEX';
export const SET_SELECTED_SECTION_OFFSET = 'SET_SELECTED_SECTION_OFFSET';
export const SET_FOCUS_FIRST_SECTION_ITEM_EFFECT = 'SET_FOCUS_FIRST_SECTION_ITEM_EFFECT';
export const SET_SELECTED_SECTION_SLUG = 'SET_SELECTED_SECTION_SLUG';
export const OPEN_INVALID_CART_MODAL = 'OPEN_INVALID_CART_MODAL';
export const CLOSE_INVALID_CART_MODAL = 'CLOSE_INVALID_CART_MODAL';
export const SET_DINE_IN_GUEST_INPUT_VERIFIED = 'SET_DINE_IN_GUEST_INPUT_VERIFIED';
export const SET_SHOULD_PRELOAD_SECTIONS = 'SET_SHOULD_PRELOAD_SECTIONS';

// Note: these types may need to be updated as callers are converted to TypeScript.
export interface VerifiedAddress {
  city: Optional<string>;
  formattedAddress: Optional<string>;
  postalCode: Optional<string>;
  state: Optional<string>;
  streetAddress: Optional<string>;
}

export interface MenuItem {
  menuId: Optional<number>;
  menuSectionId: Optional<number>;
}

// TODO: Maybe change callers so we can use the GQL type directly
type DeliveryLocation = Omit<GqlDeliveryLocation, '__typename'>;

export type MenuItemCartAction =
  { type: typeof RESET_MENU_ITEM_CART_DATA } |
  { type: typeof SET_MENU_ITEM_CART_ADDRESS, menuItemCartAddress: Optional<string> } |
  { type: typeof SET_MENU_ITEM_CART_BYPASS_PROMPT, bypassPrompt: boolean } |
  { type: typeof SET_MENU_ITEM_CART_CHECKOUT_PENDING, checkoutPending: boolean } |
  { type: typeof SET_MENU_ITEM_CART_IS_SCHEDULED, menuItemCartIsScheduled: boolean } |
  { type: typeof SET_MENU_ITEM_CART_LOCATION_ID, menuItemCartLocationId: Optional<number> } |
  { type: typeof SET_MENU_ITEM_CART_SCHEDULED_AT, menuItemCartScheduledAt: unknown } |
  { type: typeof SET_MENU_ITEM_CART_TYPE, menuItemCartType: Optional<CartType> } |
  { type: typeof SET_EDIT_MENU_ITEM_CART_LOCATION_MODAL_ENABLED, editMenuItemCartLocationModalEnabled: boolean } |
  { type: typeof SET_MENU_ITEM_CART_SPECIAL_INSTRUCTIONS_MAX_LENGTH, menuItemCartSpecialInstructionsMaxLength: Optional<number> } |
  { type: typeof SET_MENU_ITEM_CART_FULFILLMENT_TYPE, menuItemCartFulfillmentType: Optional<FulfillmentType> } |
  { type: typeof SET_MENU_ITEM_CART_DELIVERY_ADDRESS, menuItemCartDeliveryAddress: Optional<string> } |
  { type: typeof SET_MENU_ITEM_CART_DELIVERY_ADDRESS_EXTRA, menuItemCartDeliveryAddressExtra: Optional<string> } |
  { type: typeof SET_MENU_ITEM_CART_DELIVERY_ADDRESS_ERROR, menuItemCartDeliveryAddressError: Optional<string> } |
  { type: typeof SET_MENU_ITEM_CART_DELIVERY_VERIFIED_ADDRESS, menuItemCartDeliveryVerifiedAddress: Optional<VerifiedAddress> } |
  { type: typeof SET_MENU_ITEM_CART_DELIVERY_LOCATIONS, menuItemCartDeliveryLocations: DeliveryLocation[] } |
  { type: typeof SET_MENU_ITEM_CART_DELIVERY_PENDING_VERIFIED_ADDRESS, menuItemCartDeliveryPendingVerifiedAddress: Optional<VerifiedAddress> } |
  { type: typeof SET_MENU_ITEM_CART_DELIVERY_PENDING_LOCATIONS, menuItemCartDeliveryPendingLocations: DeliveryLocation[] } |
  { type: typeof SET_MENU_ITEM_CART_ID, menuItemCartId: Optional<number> } |
  { type: typeof SET_MENU_ITEM_CART_NEEDS_UPDATE, updateNeeded: boolean } |
  { type: typeof SET_SELECTED_MENU_ITEM, selectedMenuItem: Optional<MenuItem> } |
  { type: typeof SET_SELECTED_SECTION_INDEX, selectedSectionIndex: number } |
  { type: typeof SET_SELECTED_SECTION_OFFSET, selectedSectionOffset: number } |
  { type: typeof SET_SELECTED_SECTION_SLUG, selectedSectionSlug: Optional<string> } |
  { type: typeof OPEN_INVALID_CART_MODAL } |
  { type: typeof CLOSE_INVALID_CART_MODAL } |
  { type: typeof SET_DINE_IN_GUEST_INPUT_VERIFIED, isVerified: boolean } |
  { type: typeof SET_SHOULD_PRELOAD_SECTIONS, shouldPreloadSections: boolean } |
  { type: typeof SET_FOCUS_FIRST_SECTION_ITEM_EFFECT, status: 'active' | 'standby' }

// TODO: make this an alias for the argument type instead. Currently needed to decrease diff.
type DispatchMIC = (dispatch: Dispatch<MenuItemCartAction>) => void;

export function setMenuItemCartBypassPrompt(bypassPrompt: boolean): DispatchMIC {
  return (dispatch) => {
    dispatch({ bypassPrompt, type: SET_MENU_ITEM_CART_BYPASS_PROMPT });
  };
}

export function setMenuItemCartCheckoutPending(checkoutPending: boolean): DispatchMIC {
  return (dispatch) => {
    dispatch({ checkoutPending, type: SET_MENU_ITEM_CART_CHECKOUT_PENDING });
  };
}

export function setMenuItemCartIsScheduled(menuItemCartIsScheduled: boolean): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartIsScheduled, type: SET_MENU_ITEM_CART_IS_SCHEDULED });
  };
}

export function setMenuItemCartLocationId(menuItemCartLocationId: Optional<number>): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartLocationId, type: SET_MENU_ITEM_CART_LOCATION_ID });
  };
}

export function setMenuItemCartScheduledAt(menuItemCartScheduledAt: unknown): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartScheduledAt, type: SET_MENU_ITEM_CART_SCHEDULED_AT });
  };
}

export function setMenuItemCartType(menuItemCartType: Optional<CartType>): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartType, type: SET_MENU_ITEM_CART_TYPE });
  };
}

export function setMenuItemCartSpecialInstructionsMaxLength(menuItemCartSpecialInstructionsMaxLength: Optional<number>): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartSpecialInstructionsMaxLength, type: SET_MENU_ITEM_CART_SPECIAL_INSTRUCTIONS_MAX_LENGTH });
  };
}

export function setEditMenuItemCartLocationModalEnabled(editMenuItemCartLocationModalEnabled: boolean): DispatchMIC {
  return (dispatch) => {
    dispatch({ editMenuItemCartLocationModalEnabled, type: SET_EDIT_MENU_ITEM_CART_LOCATION_MODAL_ENABLED });
  };
}

export function setMenuItemCartFulfillmentType(menuItemCartFulfillmentType: Optional<FulfillmentType>): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartFulfillmentType, type: SET_MENU_ITEM_CART_FULFILLMENT_TYPE });
  };
}

export function setMenuItemCartDeliveryAddress(menuItemCartDeliveryAddress: Optional<string>): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartDeliveryAddress, type: SET_MENU_ITEM_CART_DELIVERY_ADDRESS });
  };
}

export function setMenuItemCartDeliveryAddressExtra(menuItemCartDeliveryAddressExtra: Optional<string>): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartDeliveryAddressExtra, type: SET_MENU_ITEM_CART_DELIVERY_ADDRESS_EXTRA });
  };
}

export function setMenuItemCartDeliveryAddressError(menuItemCartDeliveryAddressError: Optional<string>): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartDeliveryAddressError, type: SET_MENU_ITEM_CART_DELIVERY_ADDRESS_ERROR });
  };
}

export function setMenuItemCartDeliveryVerifiedAddress(menuItemCartDeliveryVerifiedAddress: Optional<VerifiedAddress>): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartDeliveryVerifiedAddress, type: SET_MENU_ITEM_CART_DELIVERY_VERIFIED_ADDRESS });
  };
}

export function setMenuItemCartDeliveryLocations(menuItemCartDeliveryLocations: DeliveryLocation[]): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartDeliveryLocations, type: SET_MENU_ITEM_CART_DELIVERY_LOCATIONS });
  };
}

export function setMenuItemCartDeliveryPendingLocations(menuItemCartDeliveryPendingLocations: DeliveryLocation[]): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartDeliveryPendingLocations, type: SET_MENU_ITEM_CART_DELIVERY_PENDING_LOCATIONS });
  };
}

export function setMenuItemCartDeliveryPendingVerifiedAddress(menuItemCartDeliveryPendingVerifiedAddress: Optional<VerifiedAddress>): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartDeliveryPendingVerifiedAddress, type: SET_MENU_ITEM_CART_DELIVERY_PENDING_VERIFIED_ADDRESS });
  };
}

export function setMenuItemCartId(menuItemCartId: Optional<number>): DispatchMIC {
  return (dispatch) => {
    dispatch({ menuItemCartId, type: SET_MENU_ITEM_CART_ID });
  };
}

export function resetMenuItemCartData(): DispatchMIC {
  return (dispatch) => {
    dispatch({ type: RESET_MENU_ITEM_CART_DATA });
  };
}

export function setMenuItemCartNeedsUpdate(updateNeeded: boolean): DispatchMIC {
  return (dispatch) => {
    dispatch({ type: SET_MENU_ITEM_CART_NEEDS_UPDATE, updateNeeded });
  };
}

export function setSelectedMenuItem(selectedMenuItem: Optional<MenuItem>): DispatchMIC {
  return (dispatch) => {
    dispatch({ selectedMenuItem, type: SET_SELECTED_MENU_ITEM });
  };
}

export function setSelectedSectionIndex(selectedSectionIndex: number): DispatchMIC {
  return (dispatch) => {
    dispatch({ selectedSectionIndex, type: SET_SELECTED_SECTION_INDEX });
  };
}

export function setSelectedSectionOffset(selectedSectionOffset: number): DispatchMIC {
  return (dispatch) => {
    dispatch({ selectedSectionOffset, type: SET_SELECTED_SECTION_OFFSET });
  };
}

export function setFocusFirstSectionItemEffect(status: 'active' | 'standby'): DispatchMIC {
  return (dispatch) => {
    dispatch({ status, type: SET_FOCUS_FIRST_SECTION_ITEM_EFFECT });
  };
}

export function setSelectedSectionSlug(selectedSectionSlug: Optional<string>): DispatchMIC {
  return (dispatch) => {
    dispatch({ selectedSectionSlug, type: SET_SELECTED_SECTION_SLUG });
  };
}

export function openInvalidCartModal(): DispatchMIC {
  return (dispatch) => {
    dispatch({ type: OPEN_INVALID_CART_MODAL });
  };
}

export function closeInvalidCartModal(): DispatchMIC {
  return (dispatch) => {
    dispatch({ type: CLOSE_INVALID_CART_MODAL });
  };
}

export function setDineInGuestInputVerified(isVerified: boolean): DispatchMIC {
  return (dispatch) => {
    dispatch({ isVerified, type: SET_DINE_IN_GUEST_INPUT_VERIFIED });
  };
}

export function setShouldPreloadSections(shouldPreloadSections: boolean): DispatchMIC {
  return (dispatch) => {
    dispatch({ shouldPreloadSections, type: SET_SHOULD_PRELOAD_SECTIONS });
  };
}
