export const getLocale = () => {
  const defaultLang = 'en-US';

  if (typeof window === 'undefined') {
    return defaultLang;
  }

  if (navigator?.language) {
    return navigator.language;
  } else if (navigator?.languages?.[0]) {
    return navigator.languages[0];
  }
  return defaultLang;
};
