
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "consumerSearchBarPlaceholder": {
      "id": "consumer.search_bar_placeholder",
      "defaultMessage": "Search to find a nearby location"
    },
    "consumerOrderingPaymentMethod": {
      "id": 'consumer.ordering.payment_method',
      "defaultMessage": "Payment Method"
    },
  }
);
export default defaultMessages;

