let apolloClient: PopmenuApolloClient | null = null;
let resolveApolloClientPromise: ((client: PopmenuApolloClient) => void) = () => undefined;
let apolloClientPromise = new Promise<PopmenuApolloClient>((resolve) => {
  resolveApolloClientPromise = resolve;
});

// TODO: create an eslint rule that prevent using it ouside the if condition
// if (isSSR) {
//   getServerSideApolloClient();
// }
export function getServerSideApolloClient() {
  if (!apolloClient) {
    throw new Error('Apollo client was not set before calling getServerSideApolloClient');
  }
  return apolloClient;
}

export function getApolloClientWhenCreated() {
  return apolloClientPromise;
}

// Issue with Global variables:45454-
// In a server-side rendered application, global variables do not automatically reset between renders.
// This behavior affects our `apolloClientPromise`. If this promise gets resolved in one render,
// it remains resolved in subsequent renders. This can lead to unintended reuse of the same Apollo Client
// instance across different renders, potentially causing data leakage or inconsistent application state.
// To prevent this, we need to ensure that `apolloClientPromise` is reinitialized as a new, unresolved promise
// before each server-side render. This way, each render cycle can safely resolve its own instance of the Apollo Client
// without being affected by the state of previous renders.
//
// The `resetApolloClient` function is designed to perform this reset by reinitializing `apolloClient` and
// `apolloClientPromise` before every server-side render. This ensures a fresh, independent Apollo Client setup
// for each render cycle.
function resetApolloClient() {
  apolloClient = null;
  apolloClientPromise = new Promise<PopmenuApolloClient>((resolve) => {
    resolveApolloClientPromise = resolve;
  });
}

export function setApolloClient(client: PopmenuApolloClient) {
  if (apolloClient && apolloClient !== client) {
    resetApolloClient();
  }
  apolloClient = client;
  resolveApolloClientPromise(client);
}
