import type { CheckoutPageEvent, CheckoutPageState } from '~/orderingApp/types';

export const initialState: CheckoutPageState = {
  effects: [],
  meta: {
    formSubmissionAttempted: false,
  },
};

export const checkoutPageReducer = (prevState: CheckoutPageState = initialState, event: CheckoutPageEvent) => {
  const nextState = { ...prevState, meta: { ...prevState.meta } };
  switch (event.type) {
    case 'formSubmissionAttempted':
      nextState.meta.formSubmissionAttempted = true;
      if (!nextState.effects.includes('scrollToInvalidInput')) {
        nextState.effects = [...nextState.effects, 'scrollToInvalidInput'];
      }
      break;
    case 'invalidInputScrolledIntoView':
      nextState.effects = nextState.effects.filter(effect => effect !== 'scrollToInvalidInput');
      break;
    case 'resetPaymentProcessorState':
      return initialState;
    default:
      break;
  }
  return nextState;
};
