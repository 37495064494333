import { isSSR } from './dom';

const capturedMessages = new Set<string>();
export const captureSentryMessage = (message: string) => {
  console.error(message);
  if (!isSSR && !capturedMessages.has(message)) {
    capturedMessages.add(message);
    if (window.Sentry?.captureMessage) {
      window.Sentry.captureMessage(message);
    }
  }
};
