import type { PickActionFromState, Dispatch } from '~/utils/redux';
import type { DishesState, MenuItemCart, SuggestedMenuItem } from './DishesState';

export const OPEN_MENU_ITEM_MODAL = 'OPEN_MENU_ITEM_MODAL';
export const CLOSE_MENU_ITEM_MODAL = 'CLOSE_MENU_ITEM_MODAL';
export const OPEN_ADD_TO_CART_MODAL = 'OPEN_ADD_TO_CART_MODAL';
export const CLOSE_ADD_TO_CART_MODAL = 'CLOSE_ADD_TO_CART_MODAL';
export const OPEN_EDIT_SELECTED_ITEM_MODAL = 'OPEN_EDIT_SELECTED_ITEM_MODAL';
export const CLOSE_EDIT_SELECTED_ITEM_MODAL = 'CLOSE_EDIT_SELECTED_ITEM_MODAL';
export const OPEN_EDIT_MENU_ITEM_CART_MODAL = 'OPEN_EDIT_MENU_ITEM_CART_MODAL';
export const CLOSE_EDIT_MENU_ITEM_CART_MODAL = 'CLOSE_EDIT_MENU_ITEM_CART_MODAL';
export const OPEN_EDIT_MENU_ITEM_CART_LOCATION_MODAL = 'OPEN_EDIT_MENU_ITEM_CART_LOCATION_MODAL';
export const CLOSE_EDIT_MENU_ITEM_CART_LOCATION_MODAL = 'CLOSE_EDIT_MENU_ITEM_CART_LOCATION_MODAL';
export const OPEN_EDIT_MENU_ITEM_CART_MODAL_FROM_CUSTOM_PAGE = 'OPEN_EDIT_MENU_ITEM_CART_MODAL_FROM_CUSTOM_PAGE';
export const PREVENT_EDIT_MENU_ITEM_CART_MODAL_ON_CLOSE_CALLBACK = 'PREVENT_EDIT_MENU_ITEM_CART_MODAL_ON_CLOSE_CALLBACK';
export const SET_MENU_ITEM_ID = 'SET_MENU_ITEM_ID';
export const SET_MENU_ID = 'SET_MENU_ID';
export const SET_DISCREPANCY_MENU_ITEM_IDS = 'SET_DISCREPANCY_MENU_ITEM_IDS';

export const SET_SUGGESTED_ITEMS_WITH_MODIFIERS = 'SET_SUGGESTED_ITEMS_WITH_MODIFIERS';
export const SET_ALLOW_SUGGESTED_ITEMS = 'SET_ALLOW_SUGGESTED_ITEMS';

export type DishesAction =
  CloseMenuItemModalAction |
  OpenMenuItemModalAction |
  OpenAddToCartModalAction |
  CloseAddToCartModalAction |
  OpenEditSelectedItemModalAction |
  CloseEditSelectedItemModalAction |
  OpenEditMenuItemCartModalAction |
  CloseEditMenuItemCartModalAction |
  OpenEditMenuItemCartLocationModalAction |
  CloseEditMenuItemCartLocationModalAction |
  OpenEditMenuItemCartModalFromCustomPageAction |
  PreventEditMenuItemCartModalOnCloseCallbackAction |
  SetDiscrepancyMenuItemIdsAction |
  SetMenuItemIdAction |
  SetMenuIdAction |
  SetSuggestedItemsWithModifiersAction |
  SetAllowSuggestedItemsAction;

type OpenMenuItemModalAction = PickActionFromState<DishesState, typeof OPEN_MENU_ITEM_MODAL, 'menuItemId' | 'showAddToCartButton' | 'menuEnabled' | 'scheduledAt' | 'menuOrderingUrl'>;

export function openMenuItemModal(menuItemId: number, showAddToCartButton: boolean = false, menuEnabled: boolean = true, scheduledAt: string | null = null, menuOrderingUrl: string | null = null) {
  return (dispatch: Dispatch<OpenMenuItemModalAction>) => {
    dispatch({ menuEnabled, menuItemId, menuOrderingUrl, scheduledAt, showAddToCartButton, type: OPEN_MENU_ITEM_MODAL });
  };
}

type CloseMenuItemModalAction = PickActionFromState<DishesState, typeof CLOSE_MENU_ITEM_MODAL>;
export function closeMenuItemModal() {
  return (dispatch: Dispatch<CloseMenuItemModalAction>) => {
    dispatch({ type: CLOSE_MENU_ITEM_MODAL });
  };
}

type OpenAddToCartModalAction = PickActionFromState<DishesState, typeof OPEN_ADD_TO_CART_MODAL, 'menuItemId' | 'menuId'>;
export function openAddToCartModal(menuItemId: number, menuId: number | null = null) {
  return (dispatch: Dispatch<OpenAddToCartModalAction>) => {
    dispatch({ menuId, menuItemId, type: OPEN_ADD_TO_CART_MODAL });
  };
}

type CloseAddToCartModalAction = PickActionFromState<DishesState, typeof CLOSE_ADD_TO_CART_MODAL>;
export function closeAddToCartModal() {
  return (dispatch: Dispatch<CloseAddToCartModalAction>) => {
    dispatch({ type: CLOSE_ADD_TO_CART_MODAL });
  };
}

type OpenEditSelectedItemModalAction = PickActionFromState<DishesState, typeof OPEN_EDIT_SELECTED_ITEM_MODAL, 'selectedMenuItemId' | 'onSelectedMenuItemEditComplete'>;
export function openEditSelectedItemModal(selectedMenuItemId: number, onSelectedMenuItemEditComplete: (() => void) | null = null) {
  return (dispatch: Dispatch<OpenEditSelectedItemModalAction>) => {
    dispatch({ onSelectedMenuItemEditComplete, selectedMenuItemId, type: OPEN_EDIT_SELECTED_ITEM_MODAL });
  };
}

type CloseEditSelectedItemModalAction = PickActionFromState<DishesState, typeof CLOSE_EDIT_SELECTED_ITEM_MODAL>;
export function closeEditSelectedItemModal() {
  return (dispatch: Dispatch<CloseEditSelectedItemModalAction>) => {
    dispatch({ type: CLOSE_EDIT_SELECTED_ITEM_MODAL });
  };
}

type OpenEditMenuItemCartModalAction = PickActionFromState<DishesState, typeof OPEN_EDIT_MENU_ITEM_CART_MODAL, 'editing' | 'menuItemCart' | 'onCloseCallback' | 'preserveAddToCartModal'>;
export function openEditMenuItemCartModal(menuItemCart: MenuItemCart, editing = false, onCloseCallback: (() => void) | null = null, preserveAddToCartModal: boolean = false) {
  // The editing parameter is used when accessing the modal via an edit button. If not, default is false.
  return (dispatch: Dispatch<OpenEditMenuItemCartModalAction>) => {
    dispatch({ editing, menuItemCart, onCloseCallback, preserveAddToCartModal, type: OPEN_EDIT_MENU_ITEM_CART_MODAL });
  };
}

type CloseEditMenuItemCartModalAction = PickActionFromState<DishesState, typeof CLOSE_EDIT_MENU_ITEM_CART_MODAL>;
export function closeEditMenuItemCartModal() {
  return (dispatch: Dispatch<CloseEditMenuItemCartModalAction>) => {
    dispatch({ type: CLOSE_EDIT_MENU_ITEM_CART_MODAL });
  };
}

type OpenEditMenuItemCartLocationModalAction = PickActionFromState<DishesState, typeof OPEN_EDIT_MENU_ITEM_CART_LOCATION_MODAL, 'locationId'>;
export function openEditMenuItemCartLocationModal(locationId: number) {
  return (dispatch: Dispatch<OpenEditMenuItemCartLocationModalAction>) => {
    dispatch({ locationId, type: OPEN_EDIT_MENU_ITEM_CART_LOCATION_MODAL });
  };
}

type CloseEditMenuItemCartLocationModalAction = PickActionFromState<DishesState, typeof CLOSE_EDIT_MENU_ITEM_CART_LOCATION_MODAL>;
export function closeEditMenuItemCartLocationModal() {
  return (dispatch: Dispatch<CloseEditMenuItemCartLocationModalAction>) => {
    dispatch({ type: CLOSE_EDIT_MENU_ITEM_CART_LOCATION_MODAL });
  };
}

type OpenEditMenuItemCartModalFromCustomPageAction = PickActionFromState<DishesState, typeof OPEN_EDIT_MENU_ITEM_CART_MODAL_FROM_CUSTOM_PAGE, 'menuItemId' | 'remindToTry' | 'menuId'>;
export function openEditMenuItemCartModalFromCustomPage(menuItemId: number, menuId: number | null, remindToTry: 'remind_to_try' | null = null) {
  return (dispatch: Dispatch<OpenEditMenuItemCartModalFromCustomPageAction>) => {
    dispatch({ menuId, menuItemId, remindToTry, type: OPEN_EDIT_MENU_ITEM_CART_MODAL_FROM_CUSTOM_PAGE });
  };
}

type PreventEditMenuItemCartModalOnCloseCallbackAction = PickActionFromState<DishesState, typeof PREVENT_EDIT_MENU_ITEM_CART_MODAL_ON_CLOSE_CALLBACK>;
export function preventEditMenuItemCartModalOnCloseCallback() {
  return (dispatch: Dispatch<PreventEditMenuItemCartModalOnCloseCallbackAction>) => {
    dispatch({ type: PREVENT_EDIT_MENU_ITEM_CART_MODAL_ON_CLOSE_CALLBACK });
  };
}

type SetMenuItemIdAction = PickActionFromState<DishesState, typeof SET_MENU_ITEM_ID, 'menuItemId'>;
export function setMenuItemId(menuItemId: number) {
  return (dispatch: Dispatch<SetMenuItemIdAction>) => {
    dispatch({ menuItemId, type: SET_MENU_ITEM_ID });
  };
}

type SetSuggestedItemsWithModifiersAction = PickActionFromState<DishesState, typeof SET_SUGGESTED_ITEMS_WITH_MODIFIERS, 'suggestedItemsWithModifiers'>;
export function setSuggestedItemsWithModifiers(suggestedItemsWithModifiers: SuggestedMenuItem[]) {
  return (dispatch: Dispatch<SetSuggestedItemsWithModifiersAction>) => {
    dispatch({ suggestedItemsWithModifiers, type: SET_SUGGESTED_ITEMS_WITH_MODIFIERS });
  };
}

type SetMenuIdAction = PickActionFromState<DishesState, typeof SET_MENU_ID, 'menuId'>;
export function setMenuId(menuId: number) {
  return (dispatch: Dispatch<SetMenuIdAction>) => {
    dispatch({ menuId, type: SET_MENU_ID });
  };
}

type SetDiscrepancyMenuItemIdsAction = PickActionFromState<DishesState, typeof SET_DISCREPANCY_MENU_ITEM_IDS, 'discrepancyMenuItemIds'>;
export function setDiscrepancyMenuItemIds(discrepancyMenuItemIds: number[]) {
  return (dispatch: Dispatch<SetDiscrepancyMenuItemIdsAction>) => {
    dispatch({ discrepancyMenuItemIds, type: SET_DISCREPANCY_MENU_ITEM_IDS });
  };
}

type SetAllowSuggestedItemsAction = PickActionFromState<DishesState, typeof SET_ALLOW_SUGGESTED_ITEMS, 'allowSuggestedItems'>;
export function setAllowSuggestedItems(allowSuggestedItems: boolean) {
  return (dispatch: Dispatch<SetAllowSuggestedItemsAction>) => {
    dispatch({ allowSuggestedItems, type: SET_ALLOW_SUGGESTED_ITEMS });
  };
}
