import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import ActionBarLink from './ActionBarLink';
import { openProfileModal, openProfileV2Modal, openVipV2Modal, setVipData } from '../../shared/ModalActions';
import { withCurrentSession } from '../../shared/CurrentSessionProvider';
import { classNames, withStyles } from '../../utils/withStyles';
import styles from './styles';

const ActionBar = (props) => {
  const { classes, restaurant, isUser, isPreview } = props;
  const { actionBar } = restaurant;

  if (!actionBar || !actionBar.isEnabled || actionBar.actionBarLinks.length === 0) return null;

  const modalEvents = {
    openProfileModal: props.openProfileModal,
    openProfileV2Modal: props.openProfileV2Modal,
    openVipV2Modal: props.openVipV2Modal,
    setVipData: props.setVipData,
  };

  const loggedAndSignInLink = linkType => (isUser && linkType === 'sign_in_type' && !isPreview);

  return (
    <div className={classNames(classes.actionBarOuterContainer, 'pm-action-bar')}>
      <div className={classes.actionBarContainer}>
        {actionBar.actionBarLinks.map((link) => {
          if (loggedAndSignInLink(link.linkType)) return null;

          return (
            <div key={link.id} className={classes.actionBarButton}>
              <ActionBarLink
                classes={classes}
                link={link}
                restaurant={restaurant}
                isUser={isUser}
                modalEvents={modalEvents}
                isPreview={isPreview}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

ActionBar.defaultProps = {
  isPreview: false,
};

ActionBar.propTypes = {
  classes: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
  isUser: PropTypes.bool.isRequired,
  openProfileModal: PropTypes.func.isRequired,
  openProfileV2Modal: PropTypes.func.isRequired,
  openVipV2Modal: PropTypes.func.isRequired,
  restaurant: PropTypes.object.isRequired,
  setVipData: PropTypes.func.isRequired,
};

export default compose(
  connect(state => ({
    isPreview: state.consumer.isPreview,
  }), {
    openProfileModal,
    openProfileV2Modal,
    openVipV2Modal,
    setVipData,
  }),
  withStyles(styles),
  withCurrentSession,
  mapProps(({ currentSession, ...props }) => ({
    ...props,
    isUser: !!currentSession.user,
  })),
)(ActionBar);
