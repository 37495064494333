import {
  SET_ORDERING_OFFER_CODE,
  SET_PROFILE_EVENT_TRACKING,
  SET_GIFT_CARD_CODE,
  SET_GIFT_CARD_ID,
  SET_ORDERING_EVENT_ID,
  SET_IS_PREVIEW,
  SET_SHOW_MOBILE_CART_MODAL,
  SET_ACTIVE_CART_LOCATION_SLUG,
  SET_ORDER_EDIT_MODAL_HAS_SHOWN,
  SET_ACTIVE_SECTION,
} from './ConsumerActions';

const initialState = {
  activeCartLocationSlug: null,
  activeSection: null,
  giftCardCode: null,
  giftCardId: null,
  isPreview: false,
  orderDetailsModalHasShown: false,
  orderEditModalHasShown: false,
  orderingEventId: null,
  orderingOfferCode: null,
  profileEventTracking: [],
  showMobileCartModal: false,
};

const ConsumerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDERING_OFFER_CODE:
      return {
        ...state,
        orderingOfferCode: action.orderingOfferCode,
      };
    case SET_GIFT_CARD_CODE:
      return {
        ...state,
        giftCardCode: action.giftCardCode,
      };
    case SET_GIFT_CARD_ID:
      return {
        ...state,
        giftCardId: action.giftCardId,
      };
    case SET_ORDERING_EVENT_ID:
      return {
        ...state,
        orderingEventId: action.orderingEventId,
      };
    case SET_IS_PREVIEW:
      return {
        ...state,
        isPreview: true,
      };
    case SET_SHOW_MOBILE_CART_MODAL:
      return {
        ...state,
        showMobileCartModal: action.showMobileCartModal,
      };
    case SET_ACTIVE_CART_LOCATION_SLUG:
      return {
        ...state,
        activeCartLocationSlug: action.activeCartLocationSlug,
      };
    case SET_PROFILE_EVENT_TRACKING:
      return {
        ...state,
        profileEventTracking: action.profileEventTracking,
      };
    case SET_ORDER_EDIT_MODAL_HAS_SHOWN:
      return {
        ...state,
        orderEditModalHasShown: action.orderEditModalHasShown,
      };
    case SET_ACTIVE_SECTION:
      return {
        ...state,
        activeSection: action.activeSection,
      };
    default:
      return state;
  }
};

export default ConsumerReducer;
