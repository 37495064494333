// Visual Regressions Detection is a Playwright testing tool running on Circle-CI.
// It takes screenshots of pages from the scrubbed-db and pixelmatches them with baseline snapshots.
// If a commit results in layout changes on some pages, the Visual Regressions Testing will detect it.
//
// Some Popmenu Components render differently depending on the current date.
// Other components render differently by design (e.g. Animations, Videos).
// In order to make pixelmatch work it's necessary to disable such alterations.
// This is why this file exists.
// When visualRegressionsMode is true all Popmenu components must stop changing.
// All Popmenu consumer components must support that.

// Must remain null in production!!!
export let visualRegressionsMode: string | null = null;

export function setupVisualRegressionsMode(mode: string | null, env: string) {
  visualRegressionsMode = mode;

  if (visualRegressionsMode != null) {
    console.warn(`[POPMENU] Visual Regressions Detection Mode: ${visualRegressionsMode}. Env: ${env} All content alterations are disabled. This message must never appear in production!!!`);

    if (typeof window !== 'undefined' && window.Sentry != null) {
      window.Sentry.captureMessage("Visual Regressions Mode and Sentry are running on the same page. It's likely a production environment. This is bad!!! Immediate action is required!!!");
    }
    if (env !== 'production_local') {
      console.error('[POPMENU] Visual Regressions are only working in production_local environment.');
      visualRegressionsMode = null;
    }
  }
}
