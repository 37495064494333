import { readableFontColor } from '../../../../utils/colors';

export default theme => ({
  accordion: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(1, -2),
    },

    boxShadow: 'none',
    margin: theme.spacing(1, 0),
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1, 3),
  },
  accordionExpandIcon: {
    fontSize: theme.spacing(3),
  },
  accordionSummary: {
    [theme.breakpoints.up('md')]: {
      top: theme.spacing(3.5),
    },
    backgroundColor: 'white',
    padding: theme.spacing(0, 1.5),
    position: 'sticky',
    top: theme.spacing(6),
    zIndex: 1,
  },
  addToCartButton: {
    marginBottom: theme.spacing(2),
  },
  button: {
    padding: 0,
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
    display: 'none',
    float: 'right',
    margin: theme.spacing(1, 0, 1, 1),
    paddingTop: '3px', // To match height of larger profile icon
    position: 'relative',
  },
  buttonQuantity: (props) => {
    const backgroundColor = props.cartType === 'dine_in_cart_type' ? theme.palette.red.main : theme.palette.secondary.main;
    return {
      backgroundColor,
      borderRadius: '50%',
      boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
      color: readableFontColor(theme.palette.secondary.main),
      fontSize: '0.75em',
      fontWeight: 'bold',
      height: `${theme.spacing(2)}px`,
      lineHeight: `${theme.spacing(2)}px`,
      pointerEvents: 'none',
      position: 'absolute',
      right: theme.spacing(0.25),
      textAlign: 'center',
      top: theme.spacing(0.25),
      width: `${theme.spacing(2)}px`,
    };
  },
  cartSummaryDescription: {
    color: '#595959',
  },
  codeInputBoxContainer: {
    padding: theme.spacing(2, 2, 0),
  },
  containedDivider: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  dineInButton: {
    color: 'black',
    fontSize: '1.75em',
    height: '34px',
    width: '44px',
  },
  dineInButtonContainer: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, -4),
    },
    backgroundColor: 'white',
    bottom: 0,
    boxShadow: '0px -10px 15px rgb(242, 242, 242)',
    margin: theme.spacing(0, -1),
    padding: theme.spacing(1, 2),
    position: 'sticky',
    zIndex: 2,
  },
  dineInSummaryHeader: {
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(-2, -3, 1),
      top: theme.spacing(-3),
    },
    backgroundColor: 'white',
    boxShadow: '0px 2px 15px rgb(242, 242, 242)',
    margin: theme.spacing(-2, -1, 0),
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(2),
    position: 'sticky',
    top: theme.spacing(-1),
    zIndex: 2,
  },
  dineInSummaryModal: {
    padding: '3px',
  },
  dineInSummaryWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'space-between',
  },
  discountedPriceBox: {
    '& span': {
      color: readableFontColor(theme.palette.primary.main),
      marginRight: theme.spacing(1),
      textDecoration: 'line-through',
    },
  },
  divider: {
    marginBottom: '1rem !important',
    marginTop: '1rem !important',
  },
  drawer: {
    width: '100%',
  },
  editButtons: {
    '&:last-child': {
      marginRight: 0,
    },
    color: theme.consumer.menuItemCart.summary.link.color,
    fontSize: '0.85rem',
    marginRight: theme.spacing(1),
    opacity: 0.7,
    paddingLeft: 0,
  },
  emptyCart: {
    color: '#757575',
    display: 'block',
    fontSize: '10em',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  feeDisclaimer: {
    color: theme.consumer.general.mutedText.color,
    fontSize: '0.85rem',
    textAlign: 'center',
  },
  fontBold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  freeDeliveryNotification: {
    backgroundColor: '#F5F5F5',
    fontSize: '0.8rem',
    fontWeight: theme.typography.fontWeightBold,
    padding: '5px',
    textAlign: 'center',
  },
  infoColorText: {
    color: theme.palette.info.dark,
  },
  menuItemName: {
    margin: theme.spacing(1, 0),
  },
  menuItemPrice: {
    float: 'right',
    marginLeft: theme.spacing(1),
  },
  menuItemTotal: {
    margin: theme.spacing(2, 0, 1),
  },
  minimumAmount: {
    backgroundColor: '#F5F5F5',
    width: '100%',
  },
  modalButtons: {
    borderColor: '#f4f4f4',
    bottom: 0,
    padding: theme.spacing(2),
    position: 'fixed',
    width: '100%',
  },
  newTabSubtotalContainer: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  noMargin: {
    margin: 0,
  },
  orderContainer: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    width: '100%',
  },
  subtotal: {
    marginLeft: 'auto',
  },
  summaryHeaders: {
    borderColor: '#f4f4f4',
    display: 'flex',
    justifyContent: 'space-between',
  },
  summaryTextWrapper: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  tabSubtotal: {
    fontWeight: 'bold',
  },
  tabSubtotalContainer: {
    borderBottom: 'solid',
    borderBottomColor: theme.palette.grey[300],
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
});
