
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "popsPopTypesLovedItEvent": {
      "id": "pops.pop_types.loved_it.event",
      "defaultMessage": "Attended, loved it"
    },
    "popsPopTypesLovedItNew": {
      "id": "pops.pop_types.loved_it.new",
      "defaultMessage": "Had It, Loved It!"
    },
    "popsPopTypesLovedItIcon": {
      "id": "pops.pop_types.loved_it.icon",
      "defaultMessage": "heart"
    },
    "popsPopTypesLovedItVerb": {
      "id": "pops.pop_types.loved_it.verb",
      "defaultMessage": "loved"
    },
    "popsPopTypesLovedItTooltip": {
      "id": "pops.pop_types.loved_it.tooltip",
      "defaultMessage": "Love"
    },
    "popsPopTypesWannaTryEvent": {
      "id": "pops.pop_types.wanna_try.event",
      "defaultMessage": "Remind Me To Attend"
    },
    "popsPopTypesWannaTryNew": {
      "id": "pops.pop_types.wanna_try.new",
      "defaultMessage": "Remind Me To Try This"
    },
    "popsPopTypesWannaTryIcon": {
      "id": "pops.pop_types.wanna_try.icon",
      "defaultMessage": "calendar-o"
    },
    "popsPopTypesWannaTryVerb": {
      "id": "pops.pop_types.wanna_try.verb",
      "defaultMessage": "wants to try"
    },
    "popsPopTypesWannaTryTooltip": {
      "id": "pops.pop_types.wanna_try.tooltip",
      "defaultMessage": "Remind"
    },
    "popsPopTypesLikedItEvent": {
      "id": "pops.pop_types.liked_it.event",
      "defaultMessage": "Attended, liked it"
    },
    "popsPopTypesLikedItNew": {
      "id": "pops.pop_types.liked_it.new",
      "defaultMessage": "Had It, Liked It"
    },
    "popsPopTypesLikedItIcon": {
      "id": "pops.pop_types.liked_it.icon",
      "defaultMessage": "thumbs-up"
    },
    "popsPopTypesLikedItVerb": {
      "id": "pops.pop_types.liked_it.verb",
      "defaultMessage": "liked"
    },
    "popsPopTypesLikedItTooltip": {
      "id": "pops.pop_types.liked_it.tooltip",
      "defaultMessage": "Like"
    },
    "popsPopTypesDislikedItEvent": {
      "id": "pops.pop_types.disliked_it.event",
      "defaultMessage": "Attended, didn't like it"
    },
    "popsPopTypesDislikedItNew": {
      "id": "pops.pop_types.disliked_it.new",
      "defaultMessage": "Had It, Disliked It"
    },
    "popsPopTypesDislikedItIcon": {
      "id": "pops.pop_types.disliked_it.icon",
      "defaultMessage": "thumbs-down"
    },
    "popsPopTypesDislikedItVerb": {
      "id": "pops.pop_types.disliked_it.verb",
      "defaultMessage": "disliked"
    }
  }
);
export default defaultMessages;

