import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@popmenu/admin-ui';
import { Typography } from '@popmenu/common-ui';

import formStyles from '../../assets/jss/shared/formStyles';
import { withStyles } from '../../utils/withStyles';
import { nl2br } from '../../utils/utils';

import BasicModal from './BasicModal';

class Confirm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      confirmed: false,
      show: props.show,
    };
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
  }

  closeModal() {
    this.setState({ confirmed: false, show: false });
    this.props.onClose();
  }

  openModal() {
    this.setState({ show: true });
  }

  args() {
    return {
      closeModal: this.closeModal,
      openModal: this.openModal,
    };
  }

  render() {
    const loading = this.props.loading || this.state.confirmed;
    return (
      <React.Fragment>
        {this.props.children(this.args())}
        <BasicModal
          actions={(
            <div style={{ margin: '0px 8px 8px 0px' }}>
              <Button
                disabled={loading}
                onClick={(e) => {
                  e.stopPropagation();
                  this.closeModal();
                  this.props.onCancel(this.args());
                }}
                variant="ghost"
                data-cy={'confirm_modal_cancel'}
                data-tour-id="confirm-modal-cancel"
              >
                {this.props.cancelTitle}
              </Button>
              <Button
                loading={loading}
                variant="text"
                onClick={(e) => {
                  e.stopPropagation();
                  this.setState({ confirmed: true });
                  this.props.onConfirm(this.args());
                }}
                data-cy={'confirm_modal_confirm'}
                data-tour-id="confirm-modal-confirm"
              >
                {this.props.confirmTitle}
              </Button>
            </div>
          )}
          closeModal={this.closeModal}
          show={this.state.show}
          size="sm"
          title={this.props.title}
        >
          {typeof this.props.message === 'string' && (
            <Typography gutterBottom variant="body1">{nl2br(this.props.message)}</Typography>
          )}
          {typeof this.props.message !== 'string' && (
            this.props.message
          )}
        </BasicModal>
      </React.Fragment>
    );
  }
}

Confirm.defaultProps = {
  cancelTitle: 'Cancel',
  color: 'primary',
  confirmTitle: 'Confirm',
  loading: false,
  onCancel: () => {},
  onClose: () => {},
  show: false,
  title: 'Confirm',
  variant: 'secondary',
};

Confirm.propTypes = {
  cancelTitle: PropTypes.string,
  children: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['danger', 'primary', 'secondary']),
  confirmTitle: PropTypes.string,
  loading: PropTypes.bool,
  message: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  show: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  variant: PropTypes.oneOf(['primary', 'secondary', 'tertiary', 'text', 'warning', 'error', 'success', 'info']),
};

export default withStyles(formStyles)(Confirm);
