import type { StyleRules, Theme } from '@material-ui/core/styles';

const textFieldStyles = (theme: Theme) => ({
  centerNumberInField: {
    textAlign: 'center',
  },
  dishButton: {
    fontSize: '1.25rem',
  },
  endAdornmentContainer: {
    textAlign: 'center',
    width: '40px',
  },
  stepperControls: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  textField: {
    '& textarea': {
      zIndex: 1, // Fix for empty textarea in Safari: https://github.com/mui-org/material-ui/issues/16105#issuecomment-505819557
    },
    width: '100%',
  },
  textfieldWithoutUpDownButtons: {
    '& .MuiInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
      '-moz-appearance': 'textfield',
    },
    '& .MuiOutlinedInput-input': {
      '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
        '-webkit-appearance': 'none',
      },
      '-moz-appearance': 'textfield',
    },
  },
  textFieldWithStepperOnly: {
    border: 'none',
    textAlign: 'center',
  },
} as const satisfies StyleRules);

export default textFieldStyles;
