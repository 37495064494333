export default theme => ({
  errorInfoMessagesContainer: {
    marginTop: theme.spacing(1),
  },
  feeDisclaimer: {
    color: theme?.consumer?.general?.mutedText?.color,
    fontSize: '0.85rem',
    padding: theme.spacing(1, 1.8, 0, 1.8),
  },
  minimumBox: {
    '& svg': {
      fontSize: 'x-large',
      marginRight: '5px',
      verticalAlign: 'middle',
    },
    color: '#FF0000',
    fontSize: '13px',
    marginBottom: theme.spacing(1),
  },
  noPadding: {
    padding: '0 0 0 0 !important',
  },
  offerTextBox: {
    '& .MuiOutlinedInput-root': {
      height: '100%',
    },
    marginRight: theme.spacing(2),
  },
  qualifyingItem: {
    '&:hover': {
      backgroundColor: `${theme.palette.common.white} !important`,
      opacity: 0.8,
    },
    paddingBottom: '0px',
    paddingTop: '0px',
  },
  qualifyingItemsDetail: {
    display: 'flex',
    flexDirection: 'column',
  },
  qualifyingItemsNotice: {
    '& svg': {
      color: '#FF0000',
      fontSize: 'x-large',
      marginRight: theme.spacing(0.5),
      verticalAlign: 'middle',
    },
    display: 'flex',
    textAlign: 'unset',
  },
  qualifyingItemsText: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: theme.spacing(1.75),
  },
  seeCodeDetails: {
    '&:hover': {
      backgroundColor: `${theme.palette.common.white} !important`,
      opacity: 0.8,
    },
    color: theme.palette.info.dark,
    padding: '0',
    textDecoration: 'underline',
    width: 'fit-content',
  },
  seeQualifyingItems: {
    '&:hover': {
      backgroundColor: `${theme.palette.common.white} !important`,
      opacity: 0.8,
    },
    padding: '0',
    textDecoration: 'underline',
    width: 'fit-content',
  },
  selectedCodeChipsContainer: {
    paddingTop: theme.spacing(1),
  },
  selectedCodesContainer: {
    '& .offer-chip.danger-chip': {
      '& .MuiChip-deleteIcon': {
        color: 'unset',
      },

      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },

    display: 'flex',
    gap: theme.spacing(1),
  },
  selectedOfferCardChipContainer: {
    '& .offer-chip': {
      marginRight: theme.spacing(1),
      maxWidth: '85%',
    },
    '& .offer-chip.danger-chip': {
      '& .MuiChip-deleteIcon': {
        color: 'unset',
      },

      borderColor: theme.palette.error.main,
      color: theme.palette.error.main,
    },
    display: 'flex',
    padding: theme.spacing(0.5, 0),
  },
  underline: {
    '&:hover': {
      textDecoration: 'none',
    },
    textDecoration: 'underline',
  },
});
