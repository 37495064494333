// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';
import loadable from '@loadable/component';

import Modal from './Modal';
import Loading from '../../../shared/Loading';
import retry from '../../../utils/retry';

const loadableRetry = (fn, opts) => loadable(() => retry(fn), opts || { fallback: <Loading size="lg" /> });

export const AddToCartModal = () => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'AddToCartModal' */ '../../menus/cart/AddToCartModal/AddToCartModal'))} path="dishes.showAddToCartModal" />;
export const EditSelectedItemModal = () => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'EditSelectedItemModal' */ '../../menus/cart/EditSelectedItemModal/EditSelectedItemModal'))} path="dishes.showSelectedItemModal" />;
export const FavoriteLocationModal = props => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'FavoriteLocationModal' */ '../../../shared/FavoriteLocationModal/FavoriteLocationModal'))} path="modals.showFavoriteLocationModal" {...props} />;
export const ForgotModal = () => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'ForgotModal' */ '../../../shared/ForgotModal/ForgotModal'))} path="modals.showForgotModal" />;
export const MenuItemModalContainer = () => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'MenuItemModalContainer' */ '../../menus/MenuItemModalContainer'))} path="dishes.showMenuItemModal" />;
export const ProfileModal = props => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'ProfileModal' */ '../../../shared/ProfileModal/ProfileModal'))} path="modals.showProfileModal" {...props} />;
export const ReviewSectionModal = () => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'ReviewSectionModal' */ '../../../shared/ReviewSectionModal/ReviewSectionModal'))} path="modals.showReviewSectionModal" />;
export const SocialShareModal = () => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'SocialShareModal' */ '../SocialShareModal'))} path="modals.showSocialShareModal" />;
export const TermsModal = () => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'TermsModal' */ '../../../shared/TermsModal/TermsModal'))} path="modals.showTermsModal" />;
export const EditMenuItemCartLocationModal = props => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'EditMenuItemCartLocationModal' */ '../../menus/cart/EditMenuItemCartLocationModal'))} path="dishes.showEditMenuItemCartLocationModal" {...props} />;
export const OrderDetailsModal = props => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'OrderDetailsModal' */ '../../menus/cart/OrderDetailsModal'))} path="dishes.showEditMenuItemCartModal" {...props} />;
export const VipV2Modal = () => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'VipV2Modal' */ '../../../shared/VipV2Modal/VipV2Modal'))} path="modals.showVipV2Modal" />;
export const FollowPromptV2 = () => <Modal visregNavOnly LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'FollowPromptV2Modal' */ '../FollowPromptV2/FollowPromptV2'))} path="modals.showFollowPromptV2" />;
export const ProfileV2Modal = props => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'ProfileV2Modal' */ '../../profile/ProfileV2Modal'))} path="modals.showProfileV2Modal" {...props} />;
export const UserEmailCheckPrompt = props => <Modal LoadableComponent={loadableRetry(() => import(/* webpackChunkName: 'UserEmailCheckPrompt' */ '../UserEmailCheckPrompt/UserEmailCheckPrompt'))} path="modals.showUserEmailCheckPrompt" {...props} />;
