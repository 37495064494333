import htmlEncodeLib from 'js-htmlencode';
// important to import type only, see below
import type scrollToElementLib from 'scroll-to-element';

import { asError } from './asError';

declare global {
  interface Element {
    // internal, do not use outside dom.ts!
    _pmRect?: DOMRect;
  }

  interface Window {
    // internal, do not use outside dom.ts!
    POPMENU_SCRIPT_FLAGS?: Record<string, true>;
    // internal, do not use outside dom.ts!
    execScript?: (script: string) => void;
  }
}

// This overload returns the element type corresponding to the tag name.
export function closestElement<TagName extends keyof HTMLElementTagNameMap>(el: Optional<EventTarget>, tagName: TagName): HTMLElementTagNameMap[TagName] | null;
// This overload returns the element type corresponding to the tag name.
export function closestElement<TagName extends keyof SVGElementTagNameMap>(el: Optional<EventTarget>, tagName: TagName): SVGElementTagNameMap[TagName] | null;
// The general overload for arbitrary selectors. Specify TElement explicitly if you know the selector always selects a particular element type.
export function closestElement<TElement extends Element = Element>(el: Optional<EventTarget>, selector: string): TElement | null
// The implementation signature, not exported itself
export function closestElement(el: Optional<EventTarget>, selector: string): Element | null {
  if (el instanceof Element) {
    const parentEl = el.parentElement || el.parentNode;
    if (el.matches(selector)) {
      // assumed to have the overload's return type due to the condition above
      return el;
    } else if (parentEl && parentEl !== el) {
      return closestElement(parentEl, selector);
    }
  }
  return null;
}

// This overload returns the element type corresponding to the tag name.
export function findElements<TagName extends keyof HTMLElementTagNameMap>(tagName: TagName, context?: Optional<ParentNode>): HTMLElementTagNameMap[TagName][];
// This overload returns the element type corresponding to the tag name.
export function findElements<TagName extends keyof SVGElementTagNameMap>(tagName: TagName, context?: Optional<ParentNode>): SVGElementTagNameMap[TagName][];
// The general overload for arbitrary selectors. Specify TElement explicitly if you know the selector always selects a particular element type.
export function findElements<TElement extends Element = Element>(selector: string, context?: Optional<ParentNode>): TElement[];
// The implementation signature
export function findElements<TElement extends Element = Element>(selector: string, context?: Optional<ParentNode>): TElement[] {
  // console.log('findElements');
  try {
    const elements = (context || document).querySelectorAll<TElement>(selector);
    const array: TElement[] = [];
    elements.forEach(element => array.push(element));
    return array;
  } catch (err) {
    console.warn('[POPMENU] findElements failed');
  }
  return [];
}

// This overload returns the element type corresponding to the tag name.
export function findElement<TagName extends keyof HTMLElementTagNameMap>(tagName: TagName, context?: Optional<ParentNode>): HTMLElementTagNameMap[TagName] | null;
// This overload returns the element type corresponding to the tag name.
export function findElement<TagName extends keyof SVGElementTagNameMap>(tagName: TagName, context?: Optional<ParentNode>): SVGElementTagNameMap[TagName] | null;
// The general overload for arbitrary selectors. Specify TElement explicitly if you know the selector always selects a particular element type.
export function findElement<TElement extends Element = Element>(selector: string, context?: Optional<ParentNode>): TElement | null;
// The implementation signature
export function findElement<TElement extends Element = Element>(selector: string, context?: Optional<ParentNode>): TElement | null {
  // console.log('findElement');
  try {
    return (context || document).querySelector<TElement>(selector);
  } catch (err) {
    console.warn('[POPMENU] findElement failed');
  }
  return null;
}

export const offset = (el: Optional<Element>) => {
  if (el) {
    // Try/catch because calling getBoundingClientRect on an element outside the dom raises an exception in IE
    try {
      const rect = el.getBoundingClientRect();
      return {
        bottom: rect.bottom,
        left: rect.left,
        top: rect.top,
      };
    } catch (err) {
      console.warn(`[POPMENU] offset error: ${asError(err).toString()}`);
    }
  }
  return {
    bottom: 0,
    left: 0,
    top: 0,
  };
};

export const isSSR = typeof window === 'undefined';

export const isClientSideCypress = !isSSR && !!window.Cypress;

// Be careful with SSR awareness. This variable makes sense only in browser.
// Don't use it outside of React.useEffect or similar client-only functions.
export const supportsAspectRatio = typeof CSS !== 'undefined' && CSS.supports?.('aspect-ratio: 1');

// Prevent server-side error when importing DOM-dependent library
let scrollToElement: typeof scrollToElementLib | null = null;
if (typeof window !== 'undefined') {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  scrollToElement = require('scroll-to-element') as typeof scrollToElementLib;
}

export const scrollTo = (element: Optional<Element>, customOffSet: number = 0) => {
  if (scrollToElement === null) {
    return;
  }
  if (!element) {
    return;
  }
  const navbar = findElement<HTMLElement>('#navbar');
  const navbarHeight = navbar?.offsetHeight ?? 0;
  const announcement = findElement<HTMLElement>('.pm-root-announcement');
  const announcementHeight = announcement?.offsetHeight ?? 0;
  const offsetHeight = -(navbarHeight + announcementHeight + customOffSet);
  scrollToElement(element, {
    duration: 450,
    offset: offsetHeight,
  });
};

/* eslint-disable func-names */
/* eslint-disable no-eval */
export const dangerouslyExecuteScripts = (element: Optional<Element>, scriptId: string) => {
  if (isSSR) {
    return;
  }
  if (!element) {
    return;
  }

  // All custom HTML is assigned a unique scriptId attribute via injectScriptFlag and
  // injected with a script that executes `window.POPMENU_SCRIPT_FLAGS[scriptId] = true`.
  // If the flag is set, then inline scripts were executed and should not be reprocessed.
  const executed = !!(scriptId && window.POPMENU_SCRIPT_FLAGS?.[scriptId]);

  findElements('script', element).forEach((script) => {
    try {
      // Only (re)-include scripts if they have not already been evaluated
      if (!executed) {
        console.log(`[POPMENU] Finding custom scripts for ${scriptId}`);
        if (script.src) {
          // Load external scripts
          console.log(`[POPMENU] Evaluating external script ${scriptId} (${script.src})`);
          const s = document.createElement('script');
          s.src = script.src;
          s.async = true;
          // s is inserted into the dom briefly to allow it to execute
          script.parentNode?.insertBefore(s, script);
          script.parentNode?.removeChild(s);
        } else if (script.innerHTML) {
          // Evaluate inline scripts in global context
          console.log(`[POPMENU] Evaluating inline script ${scriptId}`);
          (window.execScript || function (data: string) {
            window.eval.call(window, data);
          })(script.innerHTML);
        }
      }
    } catch (err) {
      console.warn(`[POPMENU] Error executing embedded script: ${asError(err).message}`);
    }
  });

  // Clear the flag so that the script can be reprocessed later after being unmounted
  if (scriptId && window.POPMENU_SCRIPT_FLAGS?.[scriptId]) {
    console.log(`[POPMENU] POPMENU_SCRIPT_FLAGS deleted: ${window.POPMENU_SCRIPT_FLAGS[scriptId]}`);
    // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
    delete window.POPMENU_SCRIPT_FLAGS[scriptId];
  }
};
/* eslint-enable func-names */
/* eslint-enable no-eval */

export const isMobile = () => typeof window !== 'undefined' && window.navigator &&
  (navigator.userAgent.includes('iPhone') || navigator.userAgent.includes('iPod') || navigator.userAgent.includes('iPad') || navigator.userAgent.includes('Android'));

// Doesn't seem to be ever used with null/undefined currently, can just re-export
export const htmlEncode = (value: Optional<string>) => htmlEncodeLib.htmlEncode(value || '');

export const injectScriptFlag = (html: string, scriptId: string) => {
  if (html.includes('<script')) {
    return `${html}<script type="text/javascript">window.POPMENU_SCRIPT_FLAGS = window.POPMENU_SCRIPT_FLAGS || {}; window.POPMENU_SCRIPT_FLAGS["${htmlEncode(scriptId)}"] = true;</script>`;
  }
  return html;
};

// NOTE: Consider how component state could be used to modify the element's class names rather than manipulating directly
/* eslint-disable no-param-reassign */
export const toggleClass = (el: Optional<Element>, className: string, force: boolean | null = null) => {
  if (el && className) {
    if (el.classList) {
      if (force === null) {
        el.classList.toggle(className);
      } else if (force) {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    } else {
      const classes = el.className.split(' ');
      const existingIndex = classes.indexOf(className);
      if (force === null) {
        if (existingIndex >= 0) {
          classes.splice(existingIndex, 1);
        } else {
          classes.push(className);
        }
      } else if (force) {
        if (existingIndex < 0) {
          classes.push(className);
        }
      } else if (existingIndex >= 0) {
        classes.splice(existingIndex, 1);
      }
      el.className = classes.join(' ');
    }
  }
};
/* eslint-enable no-param-reassign */
