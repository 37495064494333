import React from 'react';

import Skeleton from '@material-ui/lab/Skeleton';
import { useHistory } from 'react-router-dom';
import type { LazyScrollObserverOptions } from '../utils/useLazyScrollObserver';
import { useLazyScrollObserver } from '../utils/useLazyScrollObserver';
import { visualRegressionsMode } from '../utils/visualRegressionsMode';
import Loading from './Loading';

type Props = {
  firstHiddenSection: number,
  children: React.JSX.Element | React.JSX.Element[],
  height?: string,
  loaderVariant?: 'circular' | 'skeleton',
  lazyScrollObserverOptions?: LazyScrollObserverOptions,
};

export const earlyVisibilityOptions = {
  threshold: 0.15,
};

const DEFAULT_SCROLL_OBSERVER_OPTIONS: LazyScrollObserverOptions = {
  earlyVisibilityOptions,
};

const MultiSectionLazyContainer = ({ children, firstHiddenSection, height = '100vh', loaderVariant = 'skeleton', lazyScrollObserverOptions = DEFAULT_SCROLL_OBSERVER_OPTIONS }: Props) => {
  const childArray = Array.isArray(children) ? children : [children];

  // The skeleton is removed and the main page content is rendered before user scrolls to the skeleton by the pixels number specified in rootMargin.
  // WidgetApp uses this component and cannot have a Router so we must check if useHistory is available
  const hash = useHistory()?.location.hash ?? '';

  const isMenuItemLink = hash.includes('item=');
  const isCustomLink = hash.length > 0 && !hash.includes('#menu') && !hash.includes('#choose-location');

  const { scrolledTo, scrollObserverRef } = useLazyScrollObserver(lazyScrollObserverOptions);

  return (
    // filter is needed because the section can be nullish, though TypeScript doesn't see it.
    <React.Fragment>
      {childArray.flat().filter(section => Boolean(section)).map((section, index) => {
        if (scrolledTo || visualRegressionsMode || index < firstHiddenSection || isMenuItemLink || isCustomLink) {
          return section;
        } else if (index > firstHiddenSection) {
          return null;
        } else if (loaderVariant === 'circular') {
          return (
            <Loading
              containerRef={scrollObserverRef}
              key={index}
            />
          );
        } else {
          return (
            <Skeleton
              className="pm-mslc-skeleton"
              height={height}
              key={index}
              ref={scrollObserverRef}
              variant="rect"
            />
          );
        }
      })}
    </React.Fragment>
  );
};

export default MultiSectionLazyContainer;
