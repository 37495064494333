import React, { useContext } from 'react';
import throttle from 'lodash/throttle';

const EVENT_THROTTLE = 250;
const MOBILE_WIDTH = 960;

// Media queries with Material UI breakpoints
const mqXs = 'only screen and (max-width: 768px)';
const mqSm = 'only screen and (min-width: 768px) and (max-width: 992px)';
const mqMd = 'only screen and (min-width: 992px) and (max-width: 1440px)';
const mqLg = 'only screen and (min-width: 1440px) and (max-width: 1900px)';
const mqXl = 'only screen and (min-width: 1900px)';

interface WindowSizeState {
  innerHeight: number;
  innerWidth: number;
  isMobile: boolean;
  outerWidth: number;
  addResizeListener?: (fn: ResizeListener) => void
  removeResizeListener?: (fn: ResizeListener) => void
  triggerResize?: () => void
}

const INITIAL_MOBILE_STATE: WindowSizeState = {
  innerHeight: 768,
  innerWidth: 375,
  isMobile: true,
  outerWidth: 375,
};

const INITIAL_TABLET_STATE: WindowSizeState = {
  innerHeight: 768,
  innerWidth: 960,
  isMobile: true,
  outerWidth: 960,
};

const INITIAL_DESKTOP_STATE: WindowSizeState = {
  innerHeight: 768,
  innerWidth: 1280,
  isMobile: false,
  outerWidth: 1280,
};

interface WindowSizeProviderProps {
  children: React.ReactNode;
  desktop: boolean;
  mobile: boolean;
  tablet: boolean;
}

const initState = (props: WindowSizeProviderProps) => {
  if (props.mobile) {
    return INITIAL_MOBILE_STATE;
  } else if (props.tablet) {
    return INITIAL_TABLET_STATE;
  }
  return INITIAL_DESKTOP_STATE;
};

export const WindowSizeContext = React.createContext(INITIAL_DESKTOP_STATE);

export const useWindowSizeContext = () => useContext(WindowSizeContext);

export interface WindowSizeProps {
  windowSize: WindowSizeState;
}

export function withWindowSizeContext<TProps extends WindowSizeProps>(Component: React.ComponentType<TProps>) {
  // eslint-disable-next-line react/function-component-definition
  return function ComponentWithWindowSizeContext(props: Omit<TProps, keyof WindowSizeProps>) {
    return (
      // The cast is necessary because of https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
      <WindowSizeContext.Consumer>
        {windowSizeState => <Component {...props as TProps} windowSize={windowSizeState} />}
      </WindowSizeContext.Consumer>
    );
  };
}

export type ResizeListener = (state: WindowSizeState) => void;

/* eslint-disable @typescript-eslint/unbound-method */
class WindowSizeProvider extends React.PureComponent<WindowSizeProviderProps, WindowSizeState> {
  // should be DebouncedFunc<() => void>, but that type is not exported.
  private readonly throttledHandleResize: (() => void) & {
    cancel(): void;
  };

  private readonly resizeListeners: ResizeListener[];

  constructor(props: WindowSizeProviderProps) {
    super(props);
    this.state = initState(props);
    this.addResizeListener = this.addResizeListener.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.removeResizeListener = this.removeResizeListener.bind(this);
    this.throttledHandleResize = throttle(this.handleResize, EVENT_THROTTLE);
    this.resizeListeners = [];
    // this.renderCount = 0;
  }

  componentDidMount() {
    window.addEventListener('resize', this.throttledHandleResize);

    // Keep initial state set to breakpoints matching SSR values, to prevent rerender
    let innerWidth;
    if (!window.matchMedia) {
      return;
    }
    if (window.matchMedia(mqXl).matches) {
      innerWidth = 1920;
    } else if (window.matchMedia(mqLg).matches) {
      innerWidth = 1536;
    } else if (window.matchMedia(mqMd).matches && !this.props.desktop) {
      innerWidth = 1366;
    } else if (window.matchMedia(mqSm).matches && !this.props.tablet) {
      innerWidth = 992;
    } else if (window.matchMedia(mqXs).matches && !this.props.mobile) {
      innerWidth = 375;
    } else {
      innerWidth = window.innerWidth;
    }
    if (innerWidth !== this.state.innerWidth) {
      this.throttledHandleResize();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.throttledHandleResize);
    this.throttledHandleResize.cancel();
  }

  addResizeListener(fn: ResizeListener) {
    this.resizeListeners.push(fn);
  }

  removeResizeListener(fn: ResizeListener) {
    const index = this.resizeListeners.indexOf(fn);
    if (index > -1) {
      this.resizeListeners.splice(index, 1);
    }
  }

  handleResize() {
    console.log('[POPMENU] WindowSizeProvider.handleResize');

    const { innerHeight, innerWidth, outerWidth } = window;
    if (innerHeight !== this.state.innerHeight || innerWidth !== this.state.innerWidth) {
      const newState = {
        ...this.state,
        innerHeight,
        innerWidth,
        isMobile: innerWidth <= MOBILE_WIDTH,
        outerWidth,
      };
      this.setState(newState);

      this.resizeListeners.forEach(fn => fn(newState));
    }
  }

  render() {
    // TODO: Refactor to prevent excess rerenders
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
      ...this.state,
      addResizeListener: this.addResizeListener,
      removeResizeListener: this.removeResizeListener,
      triggerResize: this.handleResize,
    };

    // console.log(`[POPMENU] WindowSizeProvider.render ${this.renderCount += 1}`, this.state.innerWidth, this.state.outerWidth);
    return (
      <WindowSizeContext.Provider value={value}>
        {this.props.children}
      </WindowSizeContext.Provider>
    );
  }
}
/* eslint-enable @typescript-eslint/unbound-method */

export default WindowSizeProvider;
