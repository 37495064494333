import React from 'react';
import gql from 'graphql-tag';

const themeFontNameFragment = gql`
  fragment ThemeFontFamilyFragment on ThemeFont {
    __typename
    id
    customFontUrls
    googleFontId
    name
  }
`;

export const getThemeFont = (client, fontId) => {
  if (!fontId) {
    return {};
  }
  let themeFont;
  try {
    themeFont = client.readFragment({
      fragment: themeFontNameFragment,
      id: `ThemeFont:${fontId}`,
    });
  } catch (err) {
    console.warn(`[POPMENU] Error loading ThemeFontFamilyFragment fragment: ${err.toString()}`);
    return {};
  }
  return themeFont || {};
};

export const getThemeFontName = (client, fontId) => getThemeFont(client, fontId).name || `(Font #${fontId})`;

export const buildThemeStyles = (theme, selector = 'html, body') => {
  const styles = [];
  // Default font
  if (theme.defaultFont.color) {
    styles.push(`${selector} { color: ${theme.defaultFont.color}; }`);
  }
  if (theme.defaultFont.family) {
    styles.push(`${selector} { font-family: "${theme.defaultFont.family}"; }`);
  }
  // Custom section content font
  if (theme.customSectionContentFont.color) {
    styles.push(`.fr-view { color: ${theme.customSectionContentFont.color}; }`);
  }
  if (theme.customSectionContentFont.family) {
    styles.push(`.fr-view { font-family: "${theme.customSectionContentFont.family}"; }`);
  }
  // Custom section header font
  if (theme.customSectionHeaderFont.color) {
    styles.push(`.fr-view h1, .fr-view h2, .fr-view h3, .fr-view h4, .fr-view h5, .fr-view h6 { color: ${theme.customSectionHeaderFont.color}; }`);
  }
  if (theme.customSectionHeaderFont.family) {
    styles.push(`.fr-view h1, .fr-view h2, .fr-view h3, .fr-view h4, .fr-view h5, .fr-view h6 { font-family: "${theme.customSectionHeaderFont.family}"; }`);
  }
  return styles.join('\n');
};

const toFontFormat = (url) => {
  const formats = {
    eot: 'embedded-opentype',
    svg: 'svg',
    ttf: 'truetype',
    woff: 'woff',
    woff2: 'woff2',
  };
  let format = '';
  Object.keys(formats).forEach((extension) => {
    if (!format && url.endsWith(`.${extension}`)) {
      format = ` format('${formats[extension]}')`;
    }
  });
  return format;
};

export const buildFontStyleTags = (fontSelections, isDangerous = false) => {
  const getContentProps = (content) => {
    if (isDangerous) {
      return {
        dangerouslySetInnerHTML: {
          __html: content,
        },
      };
    }
    return {
      children: content,
    };
  };

  const styleTags = [];
  let googleFontIds = [];
  let customFontFaces = [];

  // Build from provided FontSelectionType GQL objects
  fontSelections.forEach((fontSelection) => {
    if (fontSelection && typeof fontSelection === 'object') {
      if (fontSelection.googleFontId) {
        googleFontIds.push(`${fontSelection.googleFontId}:400,400i,700,700i`);
      } else if (fontSelection.customFontUrls && fontSelection.customFontUrls.length > 0) {
        customFontFaces.push(`@font-face { font-family: "${fontSelection.family}"; src: ${fontSelection.customFontUrls.map(url => `url("${url}")${toFontFormat(url)}`).join(', ')}; font-display: swap; }`);
      }
    }
  });
  // Remove duplicates
  googleFontIds = googleFontIds.filter((googleFontId, index) => googleFontIds.indexOf(googleFontId) === index);
  customFontFaces = customFontFaces.filter((customFontFace, index) => customFontFaces.indexOf(customFontFace) === index);

  // Combined Google Fonts import
  if (googleFontIds.length > 0) {
    styleTags.push((
      <style
        key="pm-theme-google-fonts"
        id="pm-theme-google-fonts"
        type="text/css"
        {...getContentProps(`@import url('https://fonts.googleapis.com/css?family=${googleFontIds.join('|')}&display=swap');`)}
      />
    ));
  }

  // Separate @font-face imports
  if (customFontFaces.length > 0) {
    styleTags.push((
      <style
        key="pm-theme-custom-fonts"
        id="pm-theme-custom-fonts"
        type="text/css"
        {...getContentProps(customFontFaces.join('\n'))}
      />
    ));
  }

  return styleTags;
};

export const getThemeStyleTags = (theme) => {
  // Base theme styles (headings, paragraphs, etc)
  const styleTags = [(
    <style key="pm-theme-styles" id="pm-theme-styles" type="text/css">
      {buildThemeStyles(theme)}
    </style>
  )];

  // Custom CSS
  if (theme.customCssEnabled && theme.customCss) {
    styleTags.push((
      <style key="pm-theme-custom-css" id="pm-theme-custom-css" type="text/css">
        {theme.customCss}
      </style>
    ));
  }

  // Build Google Font @import and custom @font-faces
  const fontSelections = [];
  Object.keys(theme).forEach((attr) => {
    const fontSelection = theme[attr];
    if (attr.indexOf('Font') > -1 && fontSelection && typeof fontSelection === 'object') {
      fontSelections.push(fontSelection);
    }
  });
  return styleTags.concat(buildFontStyleTags(fontSelections));
};
