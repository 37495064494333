import {
  SET_OFFER_DETAILS,
  CLEAR_OFFER_DETAILS,
  SET_MARKETING_OFFER_DETAILS,
  CLEAR_MARKETING_OFFER_DETAILS,
  SET_WRONG_LOCATION_OFFER,
  CLEAR_WRONG_LOCATION_OFFER,
} from './OffersActions';

const initialState = {
  marketingOfferValues: {},
  offerValues: {},
  showWrongLocationOffer: false,
  wrongLocationOfferDetails: null,
};

const offers = (state = initialState, action) => {
  switch (action.type) {
    case SET_OFFER_DETAILS:
      return {
        ...state,
        offerValues: action.offerValues,
      };
    case CLEAR_OFFER_DETAILS:
      return initialState;
    case SET_MARKETING_OFFER_DETAILS:
      return {
        ...state,
        marketingOfferValues: action.offerValues,
      };
    case CLEAR_MARKETING_OFFER_DETAILS:
      return initialState;
    case SET_WRONG_LOCATION_OFFER:
      return {
        ...state,
        showWrongLocationOffer: true,
        wrongLocationOfferDetails: action.wrongLocationOfferDetails,
      };
    case CLEAR_WRONG_LOCATION_OFFER:
      return initialState;

    default:
      return state;
  }
};

export default offers;
