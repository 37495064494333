import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/file';

import useTimeout from '../utils/useTimeout';
import { visualRegressionsMode } from '../utils/visualRegressionsMode';

const Stream = ({ autoplay, cloudflareId, controls, height, id, loop, muted, onStart, poster, ...props }) => {
  const [render, setRender] = useState(false);
  const config = useMemo(() => ({
    attributes: {
      id,
      poster,
      preload: 'none',
    },
    dashVersion: '2.9.2',
    forceAudio: false,
    forceDASH: false,
    forceHSL: true,
    forceVideo: true,
    hlsOptions: {},
    hlsVersion: '0.13.1',
    tracks: [], // TODO: WebVTT
  }), [id, poster]);
  useTimeout(() => setRender(true), 1500);
  return (
    <div
      aria-hidden={props['aria-hidden']}
      className="pm-video-poster"
      style={{
        backgroundImage: `url('${poster}')`,
        backgroundPosition: 'center center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}
    >
      {render && (
        <ReactPlayer
          config={config}
          controls={controls && !visualRegressionsMode}
          height={height}
          light={false}
          loop={loop}
          muted={muted}
          onStart={onStart}
          playing={autoplay && !visualRegressionsMode}
          playsinline
          url={`https://videodelivery.net/${cloudflareId}/manifest/video.m3u8`}
          width="100%"
        />
      )}
    </div>
  );
};

Stream.defaultProps = {
  'aria-hidden': false,
  autoplay: true,
  cloudflareId: null,
  controls: false,
  height: '100%',
  id: null,
  loop: true,
  muted: true,
  onStart: undefined,
  poster: null,
};

Stream.propTypes = {
  'aria-hidden': PropTypes.bool,
  autoplay: PropTypes.bool,
  cloudflareId: PropTypes.string,
  controls: PropTypes.bool,
  height: PropTypes.string,
  id: PropTypes.string,
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  onStart: PropTypes.func,
  poster: PropTypes.string,
};

export default Stream;
