import { startTasksPendingHydration } from './postponed';

export const onHydrationFinished = (isConsumer: boolean) => () => {
  // Remove pre-rendered JSS after mount
  const jssStyles = document.getElementById('pm-jss-server');
  jssStyles?.parentElement?.removeChild(jssStyles);
  try {
    window.popmenuHydrated?.();
  } catch (e) {
    console.error(e);
  }
  setTimeout(() => {
    startTasksPendingHydration();
  }, 0);

  if (isConsumer) {
    // Why queueMicrotask? Because there are useEffects that are executed after the hydration in the same task. Setting mark now is too early.
    // Why not setTimeout? For purely aesthetic reasons in the performance profile (setTimeout is executed in the next task).
    queueMicrotask(() => {
      try {
        performance.mark('popmenu-hydration-end');
        performance.measure('popmenu-hydration', 'popmenu-hydration-start', 'popmenu-hydration-end');
        console.log('[POPMENU] Hydration finished.');
      } catch (e) {
        console.error(e);
      }
    });
  }
};
