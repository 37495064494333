import React, { createContext, useCallback, useMemo, useRef, useState } from 'react';
import { repeatUntilUserScroll } from './virtuosoScrollHelpers';

export const VirtualizedMenuContext = createContext(null);

export const VirtualizedMenuContextProvider = ({ children }) => {
  // offset is necessary to compensate for nav bar height.
  const [virtuosoOffset, setVirtuosoOffset] = useState(null);
  const virtuosoRef = useRef(null);
  const featuredSectionEnabledRef = useRef(false);
  const onVirtuosoRenderedPromise = useRef(null);
  const onVirtuosoRendered = useCallback(() => {
    onVirtuosoRenderedPromise.current?.resolve?.();
    onVirtuosoRenderedPromise.current = null;

    // Virtuoso in unpredictable when restoring scroll position on reloads.
    // Enabled scrollRestoration would scroll the page to a random place.
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  const setFeaturedSectionEnabled = useCallback((enabled) => {
    featuredSectionEnabledRef.current = enabled;
  }, []);

  const scrollToVirtuosoIndex = useCallback(async ({ index, offset }) => {
    // scrollToVirtuosoIndex must wait for the referenced element to complete rendering
    if (!virtuosoRef.current) {
      if (!onVirtuosoRenderedPromise.current) {
        let resolveFunction;
        onVirtuosoRenderedPromise.current = new Promise((resolve) => {
          resolveFunction = resolve;
        });
        onVirtuosoRenderedPromise.current.resolve = resolveFunction;
      }
      await onVirtuosoRenderedPromise.current;
    }

    if (!virtuosoRef.current) return;
    setVirtuosoOffset(offset);

    // featured section is outside virtuso. Scroll is handled separately.
    if (featuredSectionEnabledRef.current && index === 0) {
      return;
    }

    repeatUntilUserScroll(() => {
      virtuosoRef.current?.scrollToIndex({
        // WARNING!!! Do not use behavior: 'smooth' as it makes App bar glitchy
        behavior: 'auto',
        index: index - (featuredSectionEnabledRef.current ? 1 : 0),
        offset: -offset });
    });
  }, []);

  const value = useMemo(() => ({
    onVirtuosoRendered,
    scrollToVirtuosoIndex,
    setFeaturedSectionEnabled,
    virtuosoOffset,
    virtuosoRef,
  }), [scrollToVirtuosoIndex, virtuosoOffset, setFeaturedSectionEnabled, onVirtuosoRendered]);

  return (
    <VirtualizedMenuContext.Provider value={value}>
      {children}
    </VirtualizedMenuContext.Provider>
  );
};

export const withVirtualizedMenuContext = (Component) => {
  const WrappedComponent = props => (
    <VirtualizedMenuContext.Consumer>
      {context => <Component {...props} {...context} />}
    </VirtualizedMenuContext.Consumer>
  );
  return WrappedComponent;
};
