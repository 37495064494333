import React from 'react';
import { Query as ApolloQuery } from '~/lazy_apollo/client/react/components';
import type { DocumentNode, OperationVariables, QueryFunctionOptions, TypedDocumentNode } from '~/lazy_apollo/client';
import type { QueryChildren, ServerSideQuerySubtle } from '../utils/apolloOptimizations';

type FetchPolicy = 'cache-and-network' | 'cache-first' | 'network-only';

export interface QueryProps<TData = unknown, TVariables extends OperationVariables = OperationVariables>
  extends QueryFunctionOptions<TData, TVariables> {
  // Please set only one of the following: ApolloQuery or ServerSideQuerySubtle.
  // This could be written as a union, but that triggers a strange IDEA bug.
  QueryComponent: [typeof ApolloQuery<TData, TVariables>, typeof ServerSideQuerySubtle<TData, TVariables>][number];
  children: QueryChildren<TData, TVariables> | null;
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
  fetchPolicy: FetchPolicy;
  nextFetchPolicy: FetchPolicy;
  notifyOnNetworkStatusChange: boolean;
  partialRefetch: boolean;
  returnPartialData: boolean;
  ssr: boolean;
  variables: TVariables;
}

class Query<TData = unknown, TVariables extends OperationVariables = OperationVariables> extends React.PureComponent<QueryProps<TData, TVariables>> {
  // Default ApolloQuery options
  // https://www.apollographql.com/docs/react/api/@apollo/client
  // https://www.apollographql.com/docs/react/migrating/apollo-client-3-migration/
  static readonly defaultProps = {
    children: null,
    fetchPolicy: 'cache-first',
    nextFetchPolicy: 'cache-first',
    notifyOnNetworkStatusChange: true,
    partialRefetch: true,
    QueryComponent: ApolloQuery,
    returnPartialData: false,
    ssr: true,
    variables: undefined,
  };

  render() {
    const { children, QueryComponent, query, variables, ...props } = this.props;
    // Replace variables set to undefined literal with "null" so that they are still sent to the server & included in the query cache
    let cleanVariables: TVariables | undefined;
    if (variables) {
      const cleanVariablesTemp: OperationVariables = {};
      Object.keys(variables).forEach((key) => {
        if (typeof variables[key] === 'undefined') {
          cleanVariablesTemp[key] = null;
        } else {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- both sides are `any`
          cleanVariablesTemp[key] = variables[key];
        }
      });
      cleanVariables = cleanVariablesTemp as TVariables;
    }

    return (
      <QueryComponent
        query={query}
        variables={cleanVariables}
        {...props}
      >
        {(params) => {
          if (typeof children === 'function') {
            return children(params);
          }
          return null;
        }}
      </QueryComponent>
    );
  }
}

export default Query;
