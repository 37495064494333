import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { compose, mapProps } from '@shakacode/recompose';

import { findElement } from '../utils/dom';
import { withWindowSizeContext } from './WindowSizeProvider';

class PageChangeHandler extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageName: null,
    };
  }

  componentDidUpdate(prevProps) {
    // On react-router page change
    if (this.props.location && this.props.location.pathname !== prevProps.location.pathname) {
      setTimeout(() => {
        // Scroll to the top of the page as most users expect
        window.scrollTo(0, 0);

        // Focus the main content for screenreaders
        const mainEl = findElement('main');
        if (mainEl) {
          mainEl.focus();
        }

        // Announce page change via aria-live region
        let pageName;
        if (this.props.restaurant) {
          const currentCustomPage = this.props.restaurant.customPages.find((page) => {
            const slug = this.props.location.pathname.split('/', 2)[1];
            return (page.isHomePage && this.props.location.pathname === '/') || page.slug === slug;
          });
          if (currentCustomPage) {
            if (currentCustomPage.isHomePage) {
              pageName = 'Home';
            } else {
              pageName = currentCustomPage.customMetaTitle;
            }
          }
        }
        this.setState({ pageName: pageName || this.props.location.pathname });

        // Run custom event binding
        if (typeof this.props.onChange === 'function') {
          this.props.onChange();
        }

        // Trigger resize
        this.props.triggerResize();
      }, 10);
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 50);
      console.log(`[POPMENU] Navigated to ${this.props.location.pathname}`);
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.props.children}
        <div
          aria-atomic="true"
          aria-live="polite"
          className="sr-only"
          role="status"
        >
          {this.state.pageName && `Navigated to ${this.state.pageName} page.`}
        </div>
      </React.Fragment>
    );
  }
}

PageChangeHandler.defaultProps = {
  children: null,
  onChange: null,
  restaurant: null,
};

PageChangeHandler.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
  restaurant: PropTypes.shape({
    customPages: PropTypes.arrayOf(PropTypes.shape({
      customMetaTitle: PropTypes.string,
      slug: PropTypes.string,
    })),
  }),
  triggerResize: PropTypes.func.isRequired,
};

export default compose(
  withRouter,
  withWindowSizeContext,
  mapProps(({ windowSize, ...props }) => ({
    ...props,
    triggerResize: windowSize.triggerResize,
  })),
)(PageChangeHandler);
