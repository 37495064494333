import React from 'react';

const AriaElementGroup = () => (
  <div className="sr-only">
    <span id="aria-new-window-0">Opens in a new window</span>
    <span id="aria-new-window-1">Opens an external site</span>
    <span id="aria-new-window-2">Opens an external site in a new window</span>
  </div>
);

export default AriaElementGroup;
