import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { withRestaurant } from '../../utils/withRestaurant';

const Meta = (props) => {
  let { imageHeight, imageUrl, imageWidth } = props;
  if (!imageUrl && props.restaurant && props.restaurant.logoWithPaddingUrl) {
    imageUrl = props.restaurant.logoWithPaddingUrl;
    imageHeight = 300;
    imageWidth = 300;
  }

  // Canonical URL should be absolute (not relative) and never include any querystring/hash params, to prevent duplicates
  // props.canonicalUrl should include leading slash
  let canonicalUrl;
  if (props.canonicalUrl) {
    if (props.canonicalUrl.indexOf('http') === 0) {
      canonicalUrl = props.canonicalUrl;
    } else if (props.restaurant && props.restaurant.popmenuUrl) {
      canonicalUrl = `${props.restaurant.popmenuUrl}${props.canonicalUrl.split('?')[0].split('#')[0]}`;
    }
  }

  // titleTemplate: Template used for page title
  // title: Replaces "%s" in the titleTemplate
  // defaultTitle: Used in place of the titleTemplate when the title is blank
  const helmetProps = {
    title: props.title,
  };
  if (props.defaultTitle) {
    helmetProps.defaultTitle = props.defaultTitle;
  }
  if (props.titleTemplate) {
    helmetProps.titleTemplate = props.titleTemplate;
  }

  return (
    <Helmet {...helmetProps}>
      {canonicalUrl && (
        <link rel="canonical" href={canonicalUrl} />
      )}
      {props.description && (
        <meta name="description" content={props.description} />
      )}
      {props.description && (
        <meta property="og:description" content={props.description} />
      )}
      {imageUrl && (
        <meta property="og:image" content={imageUrl} />
      )}
      {imageHeight && (
        <meta property="og:image:height" content={imageHeight} />
      )}
      {imageWidth && (
        <meta property="og:image:width" content={imageWidth} />
      )}
      {props.keywords && props.keywords.length && (
        <meta name="keywords" content={typeof props.keywords === 'string' ? props.keywords : props.keywords.join(',')} />
      )}
      {props.children}
    </Helmet>
  );
};

Meta.defaultProps = {
  canonicalUrl: null,
  children: null,
  defaultTitle: undefined,
  description: null,
  imageHeight: null,
  imageUrl: null,
  imageWidth: null,
  keywords: [],
  title: undefined,
  titleTemplate: undefined,
};

Meta.propTypes = {
  canonicalUrl: PropTypes.string,
  children: PropTypes.node,
  defaultTitle: PropTypes.string,
  description: PropTypes.string,
  imageHeight: PropTypes.number,
  imageUrl: PropTypes.string,
  imageWidth: PropTypes.number,
  keywords: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  restaurant: PropTypes.shape({
    logoWithPaddingUrl: PropTypes.string,
    popmenuUrl: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  titleTemplate: PropTypes.string,
};

export default withRestaurant(Meta);
