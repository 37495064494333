// See https://react-redux.js.org/using-react-redux/usage-with-typescript#define-typed-hooks
/* eslint-disable @typescript-eslint/no-restricted-imports,custom/require-connected-props-with-connect --
   We define the replacements here */
import {
  connect as connectLib,
  useDispatch as useDispatchLib,
  useSelector as useSelectorLib,
  type TypedUseSelectorHook,
  type Connect,
} from 'react-redux';
/* eslint-enable @typescript-eslint/no-restricted-imports */

import type { Action, Dispatch, RootState } from '~/utils/initStore';

// for convenience
export type { Dispatch, RootState };

export const useDispatch: () => Dispatch<Action> = useDispatchLib;

export const useSelector: TypedUseSelectorHook<RootState> = useSelectorLib;

export type PickActionFromState<ReduxState, ReduxActionType extends string, Keys extends keyof ReduxState = never> = {
  type: ReduxActionType,
} & Pick<ReduxState, Keys>;

export const connect: Connect<RootState> = connectLib;
