import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Box } from '@popmenu/common-ui';
import classNames from 'classnames';
import { compose, mapProps } from '@shakacode/recompose';

import { useIntl } from '../../../utils/withIntl';

import styles from './styles';

import { makeStyles } from '../../../utils/withStyles';
import { withRestaurant } from '../../../utils/withRestaurant';
import { withWindowSizeContext } from '../../../shared/WindowSizeProvider';
import { useLazyAsyncFunction } from '../../../utils/useLazyAsyncFunction';

import CodeInputForm from '../CodeApplier/CodeInputForm';
import CodeApplierV2 from '../CodeApplier/CodeApplierContainerV2/CodeApplierV2.imports-loadable';
import useOfferCodeData from './useOfferCodeData';

const useStyles = makeStyles(styles);

const CodeInputBoxV2 = ({ className, restaurant, menuItemCart }) => {
  const showWrongLocationOffer = useSelector(state => state.offers.showWrongLocationOffer);
  const orderingOfferLocationDetails = useSelector(state => state.offers.wrongLocationOfferDetails);

  const t = useIntl();
  const classes = useStyles();
  const [code, setCode] = React.useState(orderingOfferLocationDetails?.code || '');

  const [onApplyOfferCode, initOnApplyOfferCode] = useLazyAsyncFunction();
  const [loadCodeApplier, setLoadCodeApplier] = React.useState(false);
  const onFocus = useCallback(() => {
    setLoadCodeApplier(true);
  }, []);

  const {
    getCodeInputTitle,
  } = useOfferCodeData(restaurant, menuItemCart);

  useEffect(() => {
    if (orderingOfferLocationDetails?.code !== '') {
      setCode(orderingOfferLocationDetails?.code);
    }

    if (menuItemCart.selectedGiftCard || menuItemCart.selectedOrderingOfferCode || showWrongLocationOffer) {
      setLoadCodeApplier(true);
    }
  }, [menuItemCart.selectedGiftCard, menuItemCart.selectedOrderingOfferCode, orderingOfferLocationDetails, showWrongLocationOffer]);

  if (!restaurant || !menuItemCart) {
    return null;
  }

  return (
    <Box className={classNames('code-input-box-container', className)}>
      <CodeInputForm
        classes={classes}
        value={code}
        onChange={e => setCode(e.target.value)}
        onApply={onApplyOfferCode}
        onFocus={onFocus}
        title={t(getCodeInputTitle())}
        buttonText={t('consumer.ordering.apply')}
      />
      {loadCodeApplier && (
      <CodeApplierV2
        classes={classes}
        menuItemCart={menuItemCart}
        restaurant={restaurant}
        code={code}
        setCode={setCode}
        initOnApplyOfferCode={initOnApplyOfferCode}
      />
      )}
    </Box>
  );
};

CodeInputBoxV2.propTypes = {
  className: PropTypes.string,
  menuItemCart: PropTypes.object,
  restaurant: PropTypes.object.isRequired,
};

CodeInputBoxV2.defaultProps = {
  className: null,
  menuItemCart: null,
};

export default compose(
  withRestaurant,
  withWindowSizeContext,
  mapProps(({ windowSize, ...props }) => ({
    ...props,
    isMobile: windowSize.isMobile,
  })),
)(CodeInputBoxV2);
