import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { useDispatch } from 'react-redux';
import { Box, Button, Icon, Typography } from '@popmenu/common-ui';

import { Edit } from '@popmenu/web-icons';
import { withIntl } from '~/utils/withIntl';
import { formatCurrency } from '~/utils/currency';
import { nl2br } from '~/utils/react';
import { classNames, withStyles } from '~/utils/withStyles';
import orderSummarySelectedItemStyles from './styles';
import { handleNotFound } from '../../../MenuItemCartHelpers';
import destroyMenuItemCartSelectedMenuItemMutation from '../../../../../../libs/gql/mutations/menu_item_carts/destroyMenuItemCartSelectedMenuItemMutation.gql';

import DestroyControl from '../../../../../../admin/shared/DestroyControl';
import CustomImg from '../../../../../../shared/CustomImg';
import DishEmptyState from '../../../../../../assets/svg/popmenu_dish_empty.svg';

const useMessages = () => {
  const intl = useIntl();

  return {
    editItemLabel: itemName => intl.formatMessage({ defaultMessage: 'Edit Item: {itemName}', id: 'ordering_summary_selected_item.edit_item_label' }, { itemName }),
  };
};

const OrderSummarySelectedItem = (props) => {
  const {
    classes,
    destroyText,
    editOnClick,
    onRemoveComplete,
    fromMenuSummary,
    isOfferApplied,
    isNestedModifiersEnabled,
    selectedMenuItem,
    showModifiersPrices,
    t,
  } = props;
  const dispatch = useDispatch();

  const editButtonClasses = classNames(
    classes.editButtons,
    fromMenuSummary ? classes.menuSummaryEditButtons : null,
  );
  const messages = useMessages();

  const isFree = selectedMenuItem.discountAmount >= selectedMenuItem.subtotal;

  return (
    <div className={isOfferApplied ? classes.selectedMenuItemRowWithOffer : classes.selectedMenuItemRow}>
      <div className={classes.selectedMenuItemRowImgContainer}>
        <div className={classes.selectedMenuItemRowImgWrapper}>
          {selectedMenuItem.menuItem.featuredPhoto ? (
            <CustomImg
              alt={selectedMenuItem.menuItemName}
              className={classes.selectedMenuItemRowImg}
              src={selectedMenuItem.menuItem.featuredPhoto.photoUrl}
              size="sm"
            />
          ) : (
            <DishEmptyState className={classes.selectedMenuItemRowImg} />
          )}
        </div>
      </div>
      <div className={classes.selectedMenuItemInfo}>
        <Typography className={classes.selectedMenuItemName}>
          {!!selectedMenuItem.subtotal && (
            <span className={classes.selectedMenuItemPrice}>
              <FormattedMessage id="consumer.ordering.summary.price_quantity" defaultMessage="{price} x{quantity}" values={{ price: formatCurrency(selectedMenuItem.price, selectedMenuItem.menuItem.currency, { showDecimals: true, showSymbol: true }), quantity: selectedMenuItem.quantity }} />
            </span>
          )}
          <span className={classes.selectedMenuItemDish}>
            {selectedMenuItem.menuItemName}
          </span>

          {/* Display nested modifier groups */}
          {isNestedModifiersEnabled ? (
            selectedMenuItem.selectedModifierGroups && (
              <Box mt={1}>
                {selectedMenuItem.selectedModifierGroups.map(selectedModifierGroup => (
                  <Box mb={2}>
                    <Typography variant="body2" className={classes.grayText}>
                      {selectedModifierGroup.menuItemModifierGroupName}
                    </Typography>
                    {selectedModifierGroup.selectedModifiers.map(selectedModifier => (
                      <React.Fragment>
                        <Typography variant="body2">
                          <FormattedMessage
                            id="consumer.ordering.summary.modifier_group"
                            defaultMessage="{showPrice, select,
                              true {{name} ({price})}
                              other {{name}}
                            }"
                            values={{
                              name: selectedModifier.modifierName,
                              price: formatCurrency(selectedModifier.modifierPrice, 'USD', { showDecimals: true, showSymbol: true }),
                              showPrice: !!selectedModifier.modifierPrice && showModifiersPrices,
                            }}
                          />
                        </Typography>

                        {/* Nested Modifier Groups */}
                        {selectedModifier.selectedModifierGroups && selectedModifier.selectedModifierGroups.map(smg => (
                          <Box ml={1}>
                            <Typography variant="body2" className={classes.grayText}>
                              {smg.menuItemModifierGroupName}
                            </Typography>

                            {smg.selectedModifiers.map(sm => (
                              <Typography variant="body2">
                                {sm.modifierName}
                              </Typography>
                            ))}
                          </Box>
                        ))}
                      </React.Fragment>

                    ))}
                  </Box>
                ))}
              </Box>
            )
          ) : (
            selectedMenuItem.summaryExtended && (
              <Typography variant="body2" className={classes.grayText}>{nl2br(selectedMenuItem.summaryExtended)}</Typography>
            )
          )}
        </Typography>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Button
              aria-label={messages.editItemLabel(selectedMenuItem.menuItemName)}
              className={editButtonClasses}
              data-cy="edit_selected_item"
              startIcon={<Icon icon={Edit} />}
              onClick={editOnClick}
              size="small"
              variant="text"
            >
              <FormattedMessage id="consumer.ordering.edit_item" defaultMessage="Edit" />
            </Button>
            <DestroyControl
              i18nKey="consumer.ordering"
              ButtonProps={{
                'aria-label': t('consumer.ordering.destroy'),
                className: editButtonClasses,
                'data-cy': 'remove_selected_item_from_cart',
                size: 'small',
                textTransform: 'none',
                variant: 'text',
              }}
              onRemoveComplete={onRemoveComplete}
              onError={err => handleNotFound(err, dispatch)}
              mutation={destroyMenuItemCartSelectedMenuItemMutation}
              size="xs"
              text={destroyText}
              variables={{
                selectedMenuItemId: selectedMenuItem.id,
              }}
              variant="text"
            />
          </Box>
          {isOfferApplied && (
            <Box
              data-cy="offer_details_chip"
              display="flex"
              alignSelf="center"
              paddingX={1}
              className={classes.offerAppliedChip}
            >
              <Typography variant="caption">
                {isFree ? (
                  <FormattedMessage id="consumer.ordering.free" defaultMessage="Free" />
                ) : (
                  <FormattedMessage id="consumer.ordering.offer_applied" defaultMessage="Offer {offer}" values={{ offer: selectedMenuItem.discountAmount > 0 ? `(-${formatCurrency(selectedMenuItem.discountAmount, selectedMenuItem.menuItem.currency, { showDecimals: true, showSymbol: true })})` : 'Applied' }} />
                )}
              </Typography>
            </Box>
          )}
        </Box>
      </div>
    </div>
  );
};

OrderSummarySelectedItem.defaultProps = {
  fromMenuSummary: false,
  isNestedModifiersEnabled: false,
  isOfferApplied: null,
  onRemoveComplete: () => { },
  showModifiersPrices: true,
};

OrderSummarySelectedItem.propTypes = {
  classes: PropTypes.object.isRequired,
  destroyText: PropTypes.string.isRequired,
  editOnClick: PropTypes.func.isRequired,
  editToolTip: PropTypes.string.isRequired,
  fromMenuSummary: PropTypes.bool,
  isNestedModifiersEnabled: PropTypes.bool,
  isOfferApplied: PropTypes.bool,
  onRemoveComplete: PropTypes.func,
  selectedMenuItem: PropTypes.object.isRequired,
  showModifiersPrices: PropTypes.bool,
};

export default compose(
  withIntl,
  withStyles(orderSummarySelectedItemStyles),
)(OrderSummarySelectedItem);
