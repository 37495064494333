import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useMutation } from '~/lazy_apollo/client';
import { handleNotFound } from '../../menus/cart/MenuItemCartHelpers';

import validateMenuItemCartSelectedMenuItems from '../../../libs/gql/mutations/menu_item_carts/validateMenuItemCartSelectedMenuItems.gql';

export const useMenuItemCartValidation = ({ menuItemCartId, navigateToCheckoutPage }) => {
  const dispatch = useDispatch();
  const [validateMenuItemCart, { loading }] = useMutation(
    validateMenuItemCartSelectedMenuItems,
    {
      onCompleted: (response) => {
        const invalidSelectedItems = response.validateMenuItemCartSelectedMenuItems.selectedMenuItems.filter(
          smi => !smi.isValid,
        );
        if (invalidSelectedItems.length === 0) {
          navigateToCheckoutPage();
        }
      },
      onError: err => handleNotFound(err, dispatch),
      variables: {
        menuItemCartId,
      },
    },
  );

  const onCheckoutClicked = useCallback(() => {
    validateMenuItemCart();
  }, [validateMenuItemCart]);

  if (!menuItemCartId) {
    return [false, () => { }];
  }

  return [loading, onCheckoutClicked];
};
