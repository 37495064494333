import { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { openFollowPromptV2 } from '../../../shared/ModalActions';
import { WindowContext } from '../../../shared/WindowProvider';

export const ScrolledModalsOpener = connect(null, { openFollowPromptV2 })((props) => {
  const [triggeredEventsOnScrolledHeader, setTriggeredEventsOnScrolledHeader] = useState(false);
  const { scrolledHeader } = useContext(WindowContext);

  useEffect(() => {
    if (scrolledHeader && !triggeredEventsOnScrolledHeader) {
      // Performance Optimization. Invoking this method will cause the browser to download the FollowPromptV2 chunk.
      // We wait for scrolledHeader to avoid downloading the chunk too early, and also because FollowPromptV2 itself doesn't
      // render anything until scrolledHeader is true.
      props.openFollowPromptV2();
      setTriggeredEventsOnScrolledHeader(true);
    }
  }, [scrolledHeader, triggeredEventsOnScrolledHeader, props]);

  return null;
});
