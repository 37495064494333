import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { connect } from 'react-redux';

import {
  closeNavModal,
  openVipV2Modal,
  setVipData,
} from './ModalActions';
import { closestElement, scrollTo, toggleClass } from '../utils/dom';
import { getParam } from '../utils/urls';
import { withCurrentSession } from './CurrentSessionProvider';
import { withIntl } from '../utils/withIntl';
import { isTabKey } from '../utils/events';

class EventListenerContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleLinkClick = this.handleLinkClick.bind(this);
  }

  componentDidMount() {
    document.body.addEventListener('click', this.handleLinkClick);
    window.addEventListener('keydown', this.handleFirstTab);
    // Open sign up modal for ?follow=show query string
    if (getParam('follow') === 'show') {
      this.props.openVipV2Modal();
    }
    if (getParam('magic_link') === 'show') {
      this.props.setVipData({ isMagicLinkPath: true });
      this.props.openVipV2Modal();
    }
    if (getParam('sign_in') === 'show') {
      this.props.setVipData({ showSignInForm: true });
      this.props.openVipV2Modal();
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.handleLinkClick);
    window.removeEventListener('keydown', this.handleFirstTab);
  }

  // On first tab keypress, add screenreader class to body for enabling focus outline
  handleFirstTab(e) {
    if (isTabKey(e)) {
      toggleClass(document.body, 'sr-interface', true);
      window.removeEventListener('keydown', this.handleFirstTab);
    }
  }

  handleLinkClick(e) {
    const { target } = e;
    if (target) {
      const closestLink = closestElement(target, 'a') || closestElement(target, 'area');
      const closestFollowLink = closestElement(target, '.pm-follow-link');
      if ((closestLink && (closestLink.href && (closestLink.href === '#follow' || closestLink.href.endsWith('/follow'))) || closestFollowLink)) {
        e.preventDefault();
        e.stopPropagation();

        if (this.props.currentSession.user) {
          // TODO: Follow restaurant if not already following (?)
        } else {
          this.props.openVipV2Modal();
        }
        return false;
      } else if (closestLink && closestLink.href && String(closestLink.href).indexOf('#') > -1) {
        let selector;
        if (closestLink.href.includes('menu=')) {
          selector = getParam('menu', closestLink.href);
        } else {
          selector = closestLink.href.split('#')[1];
        }
        try {
          const selectorTarget = document.getElementById(selector) || document.getElementById(`menu-${selector}`);
          if (selectorTarget) {
            setTimeout(() => {
              console.log(`[POPMENU] Element ID found: ${selector}`);
              scrollTo(selectorTarget);
              this.props.closeNavModal();
              selectorTarget.focus();
            }, 150);
          }
          console.log(`[POPMENU] Element ID not found: ${selector}`);
        } catch (err) {
          console.log(`[POPMENU] Invalid selector: ${selector}`);
        }
      }
    }
    return true;
  }

  render() {
    return null;
  }
}

EventListenerContainer.propTypes = {
  closeNavModal: PropTypes.func.isRequired,
  currentSession: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.number,
    }),
  }).isRequired,
  openVipV2Modal: PropTypes.func.isRequired,
  setVipData: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withIntl,
  withCurrentSession,
  connect(() => ({
  }), {
    closeNavModal,
    openVipV2Modal,
    setVipData,
  }),
)(EventListenerContainer);
