import { useState, useEffect } from 'react';
import { runAfter } from './postponed';
import type { VisibilityOptions } from './isVisible';
import { useScrollObserver } from './useScrollObserver';

// Initially we focus on rendering the page as fast as possible.
// Loading half-visible elements will slow things down.
// If an element is less than 50% visible, we render a skeleton/placeholder.
// When it's more than 50% visible, it becomes the main element on the screen so we render it.
const EARLY_INTERSECTION_OBSERVER_OPTIONS: VisibilityOptions = {
  threshold: 0.50,
};

// When the page is fully loaded the rules change.
// Since there is nothing else to do we can render the component in advance, before it appears in the viewport.
const LATE_INTERSECTION_OBSERVER_OPTIONS: VisibilityOptions = {
  // IntersectionObserver will execute its callback when its child is 200px below the vieport.
  rootMargin: '200px',
};

export type LazyScrollObserverOptions = {
  earlyVisibilityOptions?: VisibilityOptions;
  lateVisibilityOptions?: VisibilityOptions;
};

export const useLazyScrollObserver = <TElement extends Element = Element>(options: LazyScrollObserverOptions = { }) => {
  const [scrollObserverOptions, setScrollObserverOptions] = useState<VisibilityOptions>(options.earlyVisibilityOptions ?? EARLY_INTERSECTION_OBSERVER_OPTIONS);
  useEffect(() => {
    let mounted = true;
    void runAfter('firstUserInteraction', () => mounted && setScrollObserverOptions(options.lateVisibilityOptions ?? LATE_INTERSECTION_OBSERVER_OPTIONS));
    return () => {
      mounted = false;
    };
  }, [options.lateVisibilityOptions]);
  return useScrollObserver<TElement>(scrollObserverOptions);
};
