import React from 'react';
import PropTypes from 'prop-types';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { Icon, Typography } from '@popmenu/common-ui';
import { ChevronDown } from '@popmenu/web-icons';

import { nl2br } from '../../../utils/utils';
import { readableFontColor } from '../../../utils/colors';
import { themeShape, withTheme } from '../../../utils/withTheme';
import { classNames, withStyles } from '../../../utils/withStyles';
import multiColumnSectionStyles from './styles';

import CustomContent from '../../shared/CustomContent';
import CustomImg from '../../../shared/CustomImg';
import CustomLink from '../../shared/CustomLink';
import Grid from '../../../shared/Grid';
import Stream from '../../../shared/Stream';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';

const MultiColumnSection = ({ classes, multiColumnDisplayType, multiColumnImgObjectFit, multiColumnSectionCount, sections, theme }) => {
  const styles = {
    customSectionContentFont: {
      color: theme.customSectionContentFont.color,
      fontFamily: theme.customSectionContentFont.family && `"${theme.customSectionContentFont.family}"`,
    },
    customSectionHeaderFont: {
      color: theme.customSectionHeaderFont.color,
      fontFamily: theme.customSectionHeaderFont.family && `"${theme.customSectionHeaderFont.family}"`,
    },
  };

  const shouldRenderImgOverlayHeading = section => (section.columnPhotoOverlayText && section.columnPhotoUrl);
  const renderImgOverlayHeading = (section) => {
    const { columnPhotoOverlayText } = section;
    if (shouldRenderImgOverlayHeading(section)) {
      if (section.columnLinkUrl && !section.columnSecondLinkUrl) {
        return (
          <CustomLink
            aria-label={columnPhotoOverlayText}
            className={classes.imgOverlay}
            disableAjaxLoad={section.disableAjaxLink}
            url={section.columnLinkUrl}
          >
            <AH
              typography
              align="center"
              className={classNames(classes.imgOverlayText, 'pm-img-overlay-text')}
              component="h4"
              variant="h5"
            >
              {columnPhotoOverlayText}
            </AH>
          </CustomLink>
        );
      }
      return (
        <span className={classes.imgOverlay}>
          <AH
            typography
            align="center"
            className={classNames(classes.imgOverlayText, 'pm-img-overlay-text')}
            component="h4"
            variant="h5"
          >
            {columnPhotoOverlayText}
          </AH>
        </span>
      );
    }
    return null;
  };

  const renderImage = (section) => {
    if (section.columnLinkUrl && !section.columnSecondLinkUrl) {
      return (
        <CustomLink
          aria-label={section.columnPhotoOverlayText || section.columnPhotoAlt}
          url={section.columnLinkUrl}
          disableAjaxLoad={section.disableAjaxLink}
        >
          <CustomImg
            className={classes.img}
            style={{ objectFit: multiColumnImgObjectFit }}
            src={section.columnPhotoUrl}
            alt={section.columnPhotoAlt}
          />
        </CustomLink>
      );
    }
    return (
      <CustomImg
        className={classes.img}
        style={{ objectFit: multiColumnImgObjectFit }}
        src={section.columnPhotoUrl}
        alt={section.columnPhotoAlt}
      />
    );
  };

  const renderVideo = (section) => {
    if (!section.columnVideoCloudflareId) {
      return null;
    }
    return (
      <div className={classes.videoWrapper}>
        <Stream
          autoplay={false}
          cloudflareId={section.columnVideoCloudflareId}
          controls
          loop={false}
          muted={false}
          poster={section.columnVideoThumbnailUrl}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      {/* Multi Column View */}
      {multiColumnDisplayType === 'mcdt_multi_column' && (
        <Grid container spacing={2}>
          {sections.map((section, i) => (
            <Grid
              key={i}
              component="section"
              className={classNames(
                classes.section,
                section.columnLinkUrl ? classes.sectionWithLink : null,
                !section.columnPhotoUrl && (section.columnContent || section.columnContentHtml) ? classes.sectionWithoutPhoto : null,
                'pm-column-wrapper',
              )}
              id={section.elementId}
              style={{
                alignSelf: section.columnLinkUrl || section.columnSecondLinkUrl ? null : 'stretch',
                backgroundColor: section.backgroundColor,
                color: section.backgroundColor ? readableFontColor(section.backgroundColor) : null,
              }}
              item
              md={12 / (sections.length > multiColumnSectionCount ? multiColumnSectionCount : sections.length)}
              xs={12}
            >
              {section.columnPhotoUrl && (
                <div className={classNames(classes.imgWrapper, 'pm-column-img-wrapper')}>
                  {renderImgOverlayHeading(section)}
                  {renderImage(section)}
                </div>
              )}
              <AHLevelProvider>
                {section.columnVideoUrl && (
                  renderVideo(section)
                )}
                {section.columnHeading && (
                  <AH
                    typography
                    variant="h5"
                    align="center"
                    className={classNames(classes.heading, 'pm-column-heading')}
                    style={styles.customSectionHeaderFont}
                  >
                    {section.columnHeading}
                  </AH>
                )}
                {!section.isHtmlContent && section.columnContent && (
                  <Typography
                    align="center"
                    className={classNames(classes.content, 'pm-column-content')}
                    style={styles.customSectionContentFont}
                  >
                    {nl2br(section.columnContent)}
                  </Typography>
                )}
                {section.isHtmlContent && section.columnContentHtml && (
                  <div
                    className={classNames(classes.content, 'pm-column-content')}
                    style={styles.customSectionContentFont}
                  >
                    <CustomContent
                      id={`multi-column-section-${section.id}`}
                      html={section.columnContentHtml}
                    />
                  </div>
                )}
                <Grid
                  item
                  xs={12}
                  className={section.columnContent ? classNames(classes.btnWrapperWithContent, 'pm-btn-wrapper') :
                    classNames(classes.btnWrapper, 'pm-btn-wrapper')}
                >
                  {section.columnLinkText && section.columnLinkUrl && (
                    <CustomLink
                      id={section.columnLinkTargetId || null}
                      style={section.columnSecondLinkUrl ? { marginBottom: '8px' } : null}
                      type="button"
                      url={section.columnLinkUrl}
                      disableAjaxLoad={section.disableAjaxLink}
                    >
                      {section.columnLinkText}
                    </CustomLink>
                  )}
                  {section.columnSecondLinkText && section.columnSecondLinkUrl && (
                    <CustomLink
                      id={section.columnSecondLinkTargetId || null}
                      type="button"
                      url={section.columnSecondLinkUrl}
                      disableAjaxLoad={section.disableAjaxSecondLink}
                    >
                      {section.columnSecondLinkText}
                    </CustomLink>
                  )}
                </Grid>
              </AHLevelProvider>
            </Grid>
          ))}
        </Grid>
      )}
      {/* Accordion View */}
      {multiColumnDisplayType === 'mcdt_accordion' && (
        <Grid container spacing={2}>
          {sections.map((section) => {
            const elementId = `panel-${section.id}`;
            return (
              <Accordion
                key={section.id}
                className={classes.accordionPanel}
                id={section.elementId}
                style={{ backgroundColor: section.backgroundColor }}
              >
                <AccordionSummary
                  expandIcon={<Icon icon={ChevronDown} />}
                  aria-controls={`${elementId}-content`}
                  id={`${elementId}-header`}
                >
                  {section.columnHeading && (
                    <AH
                      typography
                      variant="h5"
                      className={classNames(classes.heading, 'pm-column-heading')}
                      style={styles.customSectionHeaderFont}
                    >
                      {section.columnHeading}
                    </AH>
                  )}
                </AccordionSummary>
                <AHLevelProvider>
                  <AccordionDetails>
                    <section
                      className={classNames(
                        classes.accordionSection,
                        section.columnLinkUrl ? classes.sectionWithLink : null,
                      )}
                    >
                      <Grid container spacing={4}>
                        {section.columnPhotoUrl && (
                          <Grid
                            item
                            className="pm-column-img-wrapper"
                            xs={12}
                            md={4}
                          >
                            <div className={classes.imgWrapper}>
                              {renderImgOverlayHeading(section)}
                              {renderImage(section)}
                            </div>
                          </Grid>
                        )}
                        {!section.isHtmlContent && section.columnContent && (
                          <Grid
                            item
                            className="pm-accordion-content"
                            xs={12}
                            md={section.columnPhotoUrl && section.columnLinkText ? 8 : 12}
                          >
                            <Typography
                              align="center"
                              className={classes.accordionContent}
                              style={styles.customSectionContentFont}
                            >
                              {nl2br(section.columnContent)}
                            </Typography>
                          </Grid>
                        )}
                        {section.isHtmlContent && section.columnContentHtml && (
                          <Grid
                            item
                            className="pm-accordion-content"
                            xs={12}
                            md={section.columnPhotoUrl && section.columnLinkText ? 8 : 12}
                          >
                            <div
                              className={classes.accordionContent}
                              style={styles.customSectionContentFont}
                            >
                              <CustomContent
                                id={`multi-column-section-${section.id}`}
                                html={section.columnContentHtml}
                              />
                            </div>
                          </Grid>
                        )}
                        {/* Button */}
                        <Grid
                          item
                          xs={12}
                          md={12}
                          align="center"
                          className={section.columnContent ? 'pm-accordion-btn-wrapper-with-content' : classNames(classes.btnWrapper, 'pm-btn-wrapper')}
                          style={{ margin: 'auto 0px' }}
                        >
                          {section.columnLinkText && section.columnLinkUrl && (
                            <CustomLink
                              style={{ marginRight: `${section.columnSecondLinkUrl ? '8px' : null}` }}
                              type="button"
                              url={section.columnLinkUrl}
                              disableAjaxLoad={section.disableAjaxLink}
                            >
                              {section.columnLinkText}
                            </CustomLink>
                          )}
                          {section.columnSecondLinkText && section.columnSecondLinkUrl && (
                            <CustomLink
                              type="button"
                              url={section.columnSecondLinkUrl}
                              disableAjaxLoad={section.disableAjaxSecondLink}
                            >
                              {section.columnSecondLinkText}
                            </CustomLink>
                          )}
                        </Grid>
                      </Grid>
                    </section>
                  </AccordionDetails>
                </AHLevelProvider>
              </Accordion>
            );
          })}
        </Grid>
      )}
    </React.Fragment>
  );
};

MultiColumnSection.defaultProps = {
  sections: [],
};

MultiColumnSection.propTypes = {
  classes: PropTypes.shape().isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape()),
  theme: themeShape.isRequired,
};

export default withTheme(withStyles(multiColumnSectionStyles)(MultiColumnSection));
