import type { SnackbarKey } from 'notistack';
import type { Dispatch } from '~/utils/initStore';

export const SWITCH_SNACKBAR = 'SWITCH_SNACKBAR';

export type SnackbarAction = {
  type: typeof SWITCH_SNACKBAR;
  snackbarKey: SnackbarKey;
};

type DispatchSnackbar = (dispatch: Dispatch<SnackbarAction>) => void;

export const switchSnackbar = (snackbarKey: SnackbarKey): DispatchSnackbar => (dispatch) => {
  dispatch({
    snackbarKey,
    type: SWITCH_SNACKBAR,
  });
};
