// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';
import ReactDOM from 'react-dom';

import { loadableReady } from '@loadable/component';

import ConsumerApp from '../consumer/ConsumerApp';
import { onHydrationFinished } from '../utils/onHydrationFinished';

const App = (props, railsContext, domNodeId) => {
  loadableReady(() => {
    performance.mark('popmenu-hydration-start');
    ReactDOM.hydrate(
      React.createElement(ConsumerApp, props),
      document.getElementById(domNodeId),
      onHydrationFinished(true),
    );
  });
};

export default App;
