import type { Theme, StyleRules } from '@material-ui/core';

const formStyles = (theme: Theme) => ({
  cancelButton: {},
  extraLarge: {
    height: theme.spacing(6.9),
    padding: `0px ${theme.spacing(2)}px 0px ${theme.spacing(1)}px !important`,
  },
  submitButton: {
    margin: '0 auto',
  },
  submitButtonSpinner: {
    marginRight: theme.spacing(1),
  },
  submitGroup: {
    '&:first-child': {
      marginTop: 0,
    },
    marginTop: theme.spacing(2),
    textAlign: 'center',
  },
} as const satisfies StyleRules);

export default formStyles;
