import React, { useContext } from 'react';
import type { PopmenuConfig as GqlPopmenuConfig } from '~/libs/gql/types';

export type PopmenuConfig = GqlPopmenuConfig & {
  widget: boolean;
}

/* eslint-disable @typescript-eslint/no-non-null-assertion */
export const DefaultPopmenuConfig = {
  billingMaintenanceEnabled: false,
  cloudflareUrl: null!,
  cloverSdkUrl: null!,
  deliverectStatusWebhookUrl: null!,
  env: null!,
  facebookOauthDisabled: false,
  froalaKey: null!,
  fullStoryOrg: null,
  googleAdminAnalyticsId: null,
  googleAnalyticsId: null,
  id: null!,
  isSquareApplicationCaPresent: false,
  isSquareApplicationGbPresent: false,
  launchnotesPublicKey: null,
  lighthouseMaintenanceEnabled: false,
  mapboxPublicKey: null!,
  plaidEnv: null!,
  plaidPublicKey: null!,
  popmenuAdminAppAndroidVersion: null!,
  popmenuAdminAppIosVersion: null!,
  popmenuEnv: null!,
  popmenuVersion: null!,
  recaptchaSiteKey: null!,
  rootDishTags: [],
  rootMessagePacks: [],
  signInUrl: null!,
  squareJsUrl: null!,
  stripePublishableKey: null!,
  widget: false,
};
/* eslint-enable @typescript-eslint/no-non-null-assertion */

export const PopmenuConfigContext = React.createContext<PopmenuConfig>(DefaultPopmenuConfig);

export type WithPopmenuConfigProps = {
  popmenuConfig: PopmenuConfig;
}

export function withPopmenuConfig<TProps extends WithPopmenuConfigProps>(Component: React.ComponentType<TProps>) {
  // eslint-disable-next-line react/function-component-definition
  return function ComponentWithPopmenuConfig(props: Omit<TProps, keyof WithPopmenuConfigProps>) {
    return (
      // The cast is necessary because of https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
      <PopmenuConfigContext.Consumer>
        {popmenuConfig => <Component {...props as TProps} popmenuConfig={popmenuConfig} />}
      </PopmenuConfigContext.Consumer>
    );
  };
}

export const usePopmenuConfig = () => useContext(PopmenuConfigContext);
