import {
  CLOSE_ANNOUNCEMENT_BANNER,
  CLOSE_ANNOUNCEMENT_MODAL,
  OPEN_ANNOUNCEMENT_BANNER,
} from './AnnouncementsActions';

const initialState = {
  announcementLocationId: null,
  announcementMenuId: null,
  dismissedBannerAnnouncement: false,
  dismissedModalAnnouncement: false,
};

const announcements = (state = initialState, action) => {
  switch (action.type) {
    case OPEN_ANNOUNCEMENT_BANNER:
      return {
        ...state,
        announcementLocationId: action.announcementLocationId,
        announcementMenuId: action.announcementMenuId,
      };

    case CLOSE_ANNOUNCEMENT_BANNER:
      return {
        ...state,
        announcementLocationId: null,
        announcementMenuId: null,
        dismissedBannerAnnouncement: true,
      };

    case CLOSE_ANNOUNCEMENT_MODAL:
      return {
        ...state,
        announcementLocationId: null,
        announcementMenuId: null,
        dismissedModalAnnouncement: true,
      };

    default:
      return state;
  }
};

export default announcements;
