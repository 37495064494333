import React from 'react';
import { Link, type LinkProps } from 'react-router-dom';
import { Button, type ButtonProps } from '@popmenu/common-ui';

import { useRestaurant } from '~/utils/withRestaurant';

export type CustomLinkProps = {
  children: React.ReactNode;
  disableAjaxLoad?: boolean;
  isExternal?: boolean;
  url?: Optional<string>;
} & (
  ({ type: 'button' } & Partial<ButtonProps>) |
  ({ type?: 'link' } & Partial<LinkProps>)
);

const CustomLink = (
  {
    children,
    disableAjaxLoad = false,
    isExternal = false,
    type = 'link',
    url: originalHref = null,
    ...props
  }: CustomLinkProps,
) => {
  const {
    domain: restaurantDomain,
    popmenuUrl: restaurantPopmenuUrl,
    subdomain: restaurantSubdomain,
  } = useRestaurant() ?? {};

  if (!originalHref) {
    return null;
  }

  let href = originalHref;

  // Removing leading ".." that trips up react-router
  if (href.includes('../')) {
    const lastSegment = href.split('..').pop();
    if (lastSegment) {
      href = lastSegment;
    }
  }

  // Fix link without protocol
  if (href.startsWith('www')) {
    href = `http://${href}`;
  }

  // Extract path from fully qualified local links
  if (href.startsWith('http')) {
    try {
      const localHostnames = [
        restaurantDomain,
        restaurantDomain ? `www.${restaurantDomain}` : null,
        restaurantSubdomain ? `${restaurantSubdomain}.popmenu.com` : null,
        restaurantSubdomain ? `${restaurantSubdomain}.popmenubeta.com` : null,
        restaurantSubdomain ? `${restaurantSubdomain}.popmenu.localhost` : null,
        restaurantPopmenuUrl ? restaurantPopmenuUrl.split('//')[1] : null,
      ].filter(Boolean);
      const url = new URL(href);
      if (localHostnames.includes(url.hostname)) {
        href = `${url.pathname || ''}${url.hash || ''}`;
      }
    } catch (err) {
      // Expected to fail for malformed URLs
    }
  }

  let Component: 'a' | typeof Link;
  // The cast is not exactly correct due to incompatible event handler types,
  // but works for our purposes.
  // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  const componentProps = {
    ...props,
  } as Partial<LinkProps & ButtonProps>;

  // Render react-router Link for internal links, or regular a tag
  if (href.startsWith('tel') || href.startsWith('mailto') || href.startsWith('/files/')) {
    Component = 'a';
    componentProps.href = href;
    componentProps.rel = 'noopener';
  } else if (href.startsWith('/')) {
    if (disableAjaxLoad) {
      Component = 'a';
      componentProps.href = href;
    } else {
      Component = Link;
      componentProps.to = href;
    }
  } else {
    Component = 'a';
    componentProps['aria-describedby'] = 'aria-new-window-2';
    componentProps.href = href;
    componentProps.rel = 'noopener';
    componentProps.target = isExternal ? '_blank' : '_self';
  }

  // Render button or regular a tag
  if (type === 'button') {
    return (
      <Button
        {...componentProps}
        color="primary"
        component={Component}
        // @ts-expect-error Specifying null role to fully remove attribute from element
        role={null} // eslint-disable-line jsx-a11y/aria-role
        type="button"
        variant="contained"
      >
        {children}
      </Button>
    );
  } else {
    return <Component {...componentProps}>{children}</Component>;
  }
};

export default CustomLink;
