import { compareAsc, set, parseISO, isSameDay, isDate as isDateLib, format } from 'date-fns';
import type { ApolloError } from '~/lazy_apollo/client';

import type {
  MenuItemCart,
  OrderingEvent,
  OrderingRangeable,
  Restaurant,
  RestaurantLocation,
} from '~/libs/gql/types';
import { openInvalidCartModal, type MenuItemCartAction } from '~/shared/MenuItemCartActions';
import { useSelector, type Dispatch } from '~/utils/redux';
import { formatDate, type CapitalizedWeekDay } from '~/utils/time';
import { CartType, FulfillmentType } from '~/utils/types';
import { formatNumber } from '~/utils/numbers';
import { utcToZonedTime } from 'date-fns-tz';

// Graphql Helpers

const MENU_ITEM_CART_NOT_FOUND = 'menu_item_cart.not_found';
const SELECTED_ITEM_NOT_FOUND = 'menu_item_cart/selected_menu_item.not_found';

export const handleNotFound = (err: ApolloError, dispatch: Dispatch<MenuItemCartAction>, afterDispatch?: () => void) => {
  const message = err?.graphQLErrors?.[0]?.message;
  if (message && (message === MENU_ITEM_CART_NOT_FOUND || message === SELECTED_ITEM_NOT_FOUND)) {
    dispatch(openInvalidCartModal());
    if (typeof afterDispatch === 'function') {
      afterDispatch();
    }
  }
};

// DateTime Picker Helpers

type RestaurantLocationOrLocations = MaybeArray<RestaurantLocation>;

const getLocationsArrayFromLocations = (locations: RestaurantLocationOrLocations) => {
  const locationsArray = [];
  if (Array.isArray(locations)) {
    locationsArray.push(...locations);
  } else if (locations && typeof locations.defaultPickupDueMinutes === 'number' && locations.defaultPickupDueMinutes >= 0) {
    locationsArray.push(locations);
  }
  return locationsArray;
};

// Use the library version directly once https://github.com/date-fns/date-fns/issues/3491 is fixed.
export const isDate = (value: unknown): value is Date => isDateLib(value);

type DateTimeArg = Optional<string | Date>;

export const dateTimeToDate = (dateTime: DateTimeArg, includeToday = false) => {
  if (!dateTime) {
    return '';
  }
  const dt = isDate(dateTime) ? dateTime : parseISO(dateTime);
  const dateStr = formatDate(dt, 'M/d');
  return includeToday && isSameDay(dt, new Date()) ? `${dateStr} (today)` : dateStr;
};

export const dateTimeToTime = (dateTime: DateTimeArg, location?: string) => {
  if (!dateTime) {
    return '';
  }
  const dt = isDate(dateTime) ? dateTime : parseISO(dateTime);
  if (location) {
    const timeZone = utcToZonedTime(dt, location);
    return formatDate(timeZone, 'h:mm a');
  }
  return formatDate(dt, 'h:mm a');
};

export const getAvailableHoursForDayOfWeek = (
  dayOfWeek: `day${CapitalizedWeekDay}`,
  location: Optional<RestaurantLocation>,
  fulfillmentType: FulfillmentType,
  cartType: CartType = 'default_cart_type',
) => {
  if (!location) return 'Closed';

  let dayRanges: OrderingRangeable[] = [];

  switch (fulfillmentType.concat('-', cartType)) {
    case 'pickup_fulfillment_type-catering_cart_type':
      dayRanges = location.activeCateringOrderingRanges?.filter(range => range[dayOfWeek]) || [];
      break;
    case 'pickup_fulfillment_type-default_cart_type':
      dayRanges = location.activeOrderingRanges?.filter(range => range[dayOfWeek]) || [];
      break;
    case 'delivery_fulfillment_type-catering_cart_type':
      dayRanges = location.activeCateringDeliveryRanges?.filter(range => range[dayOfWeek]) || [];
      break;
    case 'delivery_fulfillment_type-default_cart_type':
      dayRanges = location.activeDeliveryRanges?.filter(range => range[dayOfWeek]) || [];
      break;
    default:
      break;
  }
  if (dayRanges.length === 0) {
    return 'Closed';
  }
  return dayRanges.map((range) => {
    const openDT = set(new Date(), {
      hours: 0,
      milliseconds: 0,
      minutes: 0,
      seconds: range.openTime ?? undefined,
    });
    const closeDT = set(new Date(), {
      hours: 0,
      milliseconds: 0,
      minutes: 0,
      seconds: range.closeTime ?? undefined,
    });
    return `${dateTimeToTime(openDT)} - ${dateTimeToTime(closeDT)}`;
  }).join(', ');
};

const isInitialDtInSlots = (initialDT: Optional<string>, slots: string[]) => {
  if (!initialDT) return false;

  const initialISO = parseISO(initialDT).toISOString();

  if (!initialISO) return false;

  const initialTime = initialISO.match(/\d\d:\d\d/);
  const initialDate = initialISO.match(/\d\d\d\d-\d\d-\d\d/);

  if (!initialDate || !initialTime) return false;

  return slots
    .some(slot => slot.match(initialDate[0]) && slot.match(initialTime[0]));
};

const shouldUseInitialDate = (
  initialDT: Optional<string>,
  firstDT: Optional<Date>,
  slots: string[],
) => isInitialDtInSlots(initialDT, slots) && !!firstDT && !!initialDT &&
  parseISO(initialDT) > firstDT;

/*
  Returns the start time that exist in the component's list of options.
*/

export const getFirstSelectableTime = (initialDT: Optional<string>,
  locations: RestaurantLocationOrLocations,
  fulfillmentType: FulfillmentType,
  cartType: CartType = CartType.Default,
  menuItemCart: Optional<MenuItemCart> = null,
) => {
  let firstDT: Date | null = null;
  const locationsArray = getLocationsArrayFromLocations(locations);
  const minimumCateringPickupLeadTime = Math.min(...locationsArray.map(location => location.currentCateringPickupDueMinutes));
  const minimumCateringDeliveryLeadTime = Math.min(...locationsArray.map(location => location.currentCateringDeliveryDueMinutes));

  if (menuItemCart?.orderingEvent) {
    firstDT = parseISO(menuItemCart.orderingEvent.orderingEventTimeSlots[0] ?? '');
  } else if (!locationsArray || locationsArray.length === 0 || !fulfillmentType) {
    return null;
  } else {
    switch (fulfillmentType) {
      case FulfillmentType.Pickup:
        locationsArray.forEach((location) => {
          switch (cartType) {
            case CartType.Default: {
              const firstScheduledOrderTimeSlot = location?.scheduledOrderTimeSlots?.[0];
              if (firstScheduledOrderTimeSlot !== undefined) {
                if (firstDT && parseISO(firstScheduledOrderTimeSlot) < firstDT) {
                  firstDT = parseISO(firstScheduledOrderTimeSlot);
                } else if (!firstDT) {
                  firstDT = parseISO(firstScheduledOrderTimeSlot);
                }
                if (initialDT && shouldUseInitialDate(initialDT, firstDT, location.scheduledOrderTimeSlots)) {
                  firstDT = parseISO(initialDT);
                }
              }
              break;
            }
            case CartType.Catering:
              if (location.cateringOrderTimeSlots && location.cateringOrderTimeSlots.length > 0) {
                // Find first time slot that is less than minimum catering pickup lead time
                const firstAvailableTimeSlot = location.cateringOrderTimeSlots.find((slot) => {
                  const slotDT = parseISO(slot);
                  return compareAsc(slotDT, minimumCateringPickupLeadTime) === 1;
                });
                if (firstDT && firstAvailableTimeSlot && parseISO(firstAvailableTimeSlot) < firstDT) {
                  firstDT = parseISO(firstAvailableTimeSlot);
                } else if (!firstDT && firstAvailableTimeSlot) {
                  firstDT = parseISO(firstAvailableTimeSlot);
                }
              }
              break;
            default:
              break;
          }
        });
        break;
      case FulfillmentType.Delivery:
        locationsArray.forEach((location) => {
          switch (cartType) {
            case CartType.Default: {
              const firstScheduledDeliveryTimeSlot = location?.scheduledDeliveryTimeSlots?.[0];
              if (firstScheduledDeliveryTimeSlot !== undefined) {
                if (firstDT && parseISO(firstScheduledDeliveryTimeSlot) < firstDT) {
                  firstDT = parseISO(firstScheduledDeliveryTimeSlot);
                } else if (!firstDT) {
                  firstDT = parseISO(firstScheduledDeliveryTimeSlot);
                }
                if (initialDT && shouldUseInitialDate(initialDT, firstDT, location.scheduledDeliveryTimeSlots)) {
                  firstDT = parseISO(initialDT);
                }
              }
              break;
            }
            case CartType.Catering:
              if (location.cateringDeliveryTimeSlots && location.cateringDeliveryTimeSlots.length > 0) {
                // Find first time slot that is less than minimum catering delivery lead time
                const firstAvailableTimeSlot = location.cateringDeliveryTimeSlots.find((slot) => {
                  const slotDT = parseISO(slot);
                  return compareAsc(slotDT, minimumCateringDeliveryLeadTime) === 1;
                });
                if (firstDT && firstAvailableTimeSlot && parseISO(firstAvailableTimeSlot) < firstDT) {
                  firstDT = parseISO(firstAvailableTimeSlot);
                } else if (!firstDT && firstAvailableTimeSlot) {
                  firstDT = parseISO(firstAvailableTimeSlot);
                }
              }
              break;
            default:
              break;
          }
        });
        break;
      default:
        break;
    }
  }
  if (!firstDT) {
    return null;
  }
  return formatDate(firstDT, 'ISO');
};

export const getFirstSelectableDate = (
  initialDT: Optional<string>,
  locations: RestaurantLocationOrLocations,
  fulfillmentType: FulfillmentType,
  cartType: CartType = CartType.Default,
  menuItemCart: Optional<MenuItemCart> = null,
) => {
  if (!fulfillmentType) {
    return null;
  }
  const dt = getFirstSelectableTime(initialDT, locations, fulfillmentType, cartType, menuItemCart);
  if (dt) {
    const startOfDay = set(parseISO(dt), {
      hours: 0,
      milliseconds: 0,
      minutes: 0,
      seconds: 0,
    });
    return format(startOfDay, 'M/d/yyyy');
  }
  return null;
};

// END DateTime Picker Helpers

// Location Functions

// Catering or Non-Catering
export const isCartTypeValid = (location: RestaurantLocation, cartType: CartType) => (
  cartType === 'catering_cart_type' ? location.hasCateringMenu : location.hasNonCateringMenu
);

export const isPickupAvailableForAsapOrSchedule = (
  location: RestaurantLocation,
  cartType: CartType,
): boolean => {
  if (!isCartTypeValid(location, cartType)) return false;

  const { cateringOrderTimeSlots, isOrderingCateringEnabled, isOrderingAvailable, isOrderingEnabled, isOrderingScheduleEnabled, hasPickupMenu } = location;

  if (cartType === 'default_cart_type') {
    // ASAP Pickup or Scheduled Pickup
    return (!!isOrderingAvailable || !!isOrderingScheduleEnabled) && !!isOrderingEnabled && hasPickupMenu;
  }

  if (cartType === 'catering_cart_type') {
    return isOrderingCateringEnabled && cateringOrderTimeSlots?.length > 0;
  }
  return false;
};

export const isDeliveryAvailableForAsapOrSchedule = (
  location: RestaurantLocation,
  cartType: CartType,
): boolean => {
  if (!isCartTypeValid(location, cartType)) return false;

  const { cateringDeliveryTimeSlots, isDeliveryAvailable, isOrderingDeliveryEnabled, isOrderingCateringDeliveryEnabled, isOrderingScheduleEnabled, hasDeliveryMenu, scheduledDeliveryTimeSlots } = location;
  if (cartType === 'default_cart_type') {
    // ASAP Delivery or Scheduled Delivery
    return (!!isDeliveryAvailable || (!!isOrderingScheduleEnabled && scheduledDeliveryTimeSlots?.length > 0)) && isOrderingDeliveryEnabled && hasDeliveryMenu;
  }
  if (cartType === 'catering_cart_type') {
    return isOrderingCateringDeliveryEnabled && cateringDeliveryTimeSlots?.length > 0;
  }
  return false;
};

export const getOrderingLocationsFromRestaurant = (
  restaurant: Restaurant,
  locations: RestaurantLocation[],
  cartType: CartType,
): { asapLocations: RestaurantLocation[], openLocations: RestaurantLocation[], scheduleLocations: RestaurantLocation[] } => {
  if (!restaurant) {
    return { asapLocations: [], openLocations: [], scheduleLocations: [] };
  }
  const isOrderingEnabled = restaurant.featureSetting?.isOrderingEnabled || false;
  const openLocations = locations?.filter((location) => {
    const cartIsValid = isCartTypeValid(location, cartType);
    const pickupAvailable = isPickupAvailableForAsapOrSchedule(location, cartType);
    const deliveryAvailable = isDeliveryAvailableForAsapOrSchedule(location, cartType);
    return isOrderingEnabled && cartIsValid && (pickupAvailable || deliveryAvailable);
  });

  const scheduleLocations = openLocations?.filter(location => (
    location.isOrderingScheduleEnabled ||
    cartType === 'catering_cart_type'
  ));

  const asapLocations = cartType === 'catering_cart_type' ?
    [] :
    openLocations?.filter(location => (
      (location.isOrderingAvailable || location.isDeliveryAvailable) &&
      location.hasNonCateringMenu
    ));

  return { asapLocations, openLocations, scheduleLocations };
};

export const getParamsLocationId = (
  locationSlug: string,
  isScheduled: boolean,
  openLocations: RestaurantLocation[],
  asapLocations: RestaurantLocation[],
  scheduleLocations: RestaurantLocation[],
) => {
  let locationId;
  if (isScheduled === null && openLocations) {
    const location = openLocations.find(sl => sl.slug === locationSlug);
    locationId = location ? location.id : null;
  } else if (isScheduled) {
    const location = scheduleLocations?.filter(sl => sl.slug === locationSlug)[0];
    locationId = location ? location.id : null;
  } else {
    const location = asapLocations?.filter(sl => sl.slug === locationSlug)[0];
    locationId = location ? location.id : null;
  }
  return locationId;
};

export const getASAPTimeForLocation = (location: Optional<RestaurantLocation>, fulfillmentType: FulfillmentType) => {
  if (!location) {
    return 0;
  }

  if (fulfillmentType === 'delivery_fulfillment_type') {
    return location.currentDeliveryDueMinutes;
  } else {
    return location.currentPickupDueMinutes;
  }
};

const getOrderTimeOptionsHelper = (
  asapLocations: RestaurantLocation[],
  scheduleLocations: RestaurantLocation[],
  fulfillmentType: FulfillmentType,
  isOrderingEvent = false,
) => {
  const filteredAsapLocations = asapLocations?.filter(location => (fulfillmentType === 'delivery_fulfillment_type' && location.isDeliveryAvailable) || (fulfillmentType === 'pickup_fulfillment_type' && location.isOrderingAvailable));
  const filteredScheduleLocations = scheduleLocations?.filter(location => (fulfillmentType === 'delivery_fulfillment_type' && location.isOrderingDeliveryEnabled) || (fulfillmentType === 'pickup_fulfillment_type' && location.isOrderingEnabled));
  const asapAvailable = filteredAsapLocations?.length >= 1;
  const locationPickupTimes = filteredAsapLocations?.filter(location => typeof location.defaultPickupDueMinutes === 'number' && location.defaultPickupDueMinutes >= 0).map(location => getASAPTimeForLocation(location, fulfillmentType)) || [];
  let minPrepareTime;
  let maxPrepareTime;
  if (locationPickupTimes) {
    minPrepareTime = Math.min(...locationPickupTimes);
    maxPrepareTime = Math.max(...locationPickupTimes) + 15;
  }

  return {
    isAsapAvailable: asapAvailable && !isOrderingEvent,
    isScheduleAvailable: filteredScheduleLocations?.length > 0,
    maxPrepareTime,
    minPrepareTime,
  };
};

export const getOrderTimeOptions = (
  asapLocations: RestaurantLocation[],
  scheduleLocations: RestaurantLocation[],
  fulfillmentType: FulfillmentType,
  orderTimeLaterOptionCustomLabel = 'Later',
  isOrderingEvent = false,
) => {
  const {
    isAsapAvailable,
    isScheduleAvailable,
    maxPrepareTime,
    minPrepareTime,
  } = getOrderTimeOptionsHelper(asapLocations, scheduleLocations, fulfillmentType, isOrderingEvent);

  const orderTimeOptions = [];
  if (isAsapAvailable) {
    orderTimeOptions.push({
      label: `ASAP (${minPrepareTime}-${maxPrepareTime} mins)`,
      value: false,
    });
  }
  if (isScheduleAvailable) {
    orderTimeOptions.push({
      label: orderTimeLaterOptionCustomLabel,
      value: true,
    });
  }

  return orderTimeOptions;
};

export const getFulfillmentTypeOptions = (
  asapLocations: RestaurantLocation[] = [],
  scheduleLocations: RestaurantLocation[] = [],
  cartType: CartType,
  orderTypePickupOptionCustomLabel = 'Pickup',
  locationId: number | null = null,
  orderingEvent: OrderingEvent | null = null,
) => {
  const possibleLocations = [...asapLocations, ...scheduleLocations].filter(({ id }) => (!locationId || id === locationId));
  const deliveryAvailable = !orderingEvent && possibleLocations.some(location => isDeliveryAvailableForAsapOrSchedule(location, cartType));
  const pickupAvailable = possibleLocations.some(location => isPickupAvailableForAsapOrSchedule(location, cartType));

  const orderTypeOptions = [];
  if (pickupAvailable) {
    orderTypeOptions.push({
      label: orderTypePickupOptionCustomLabel,
      type: 'pickup',
      value: 'pickup_fulfillment_type',
    });
  }
  if (deliveryAvailable) {
    orderTypeOptions.push({
      label: 'Delivery',
      type: 'delivery',
      value: 'delivery_fulfillment_type',
    });
  }
  return orderTypeOptions;
};

export const canSwitchToDeliveryLocation = (
  locationId: number,
  isScheduled: boolean,
  locations: RestaurantLocation[],
  cartType: CartType = 'default_cart_type',
) => {
  if (cartType === 'default_cart_type') {
    const hasScheduledLocation = locations.some(location => location.isOrderingDeliveryEnabled && location.scheduledDeliveryTimeSlots?.length > 0 && location.id === locationId);
    const hasAsapLocation = locations.some(location => location.isDeliveryAvailable && location.id === locationId);

    return isScheduled ? hasScheduledLocation : hasAsapLocation;
  }
  if (isScheduled && cartType === 'catering_cart_type') {
    return locations.some(location => location.isOrderingCateringDeliveryEnabled && location.cateringDeliveryTimeSlots?.length > 0 && location.id === locationId);
  }

  return false;
};

// END Location Functions

// CustomPage Functions

// Returns default online ordering page URL for the given cart type
export const getOrderingPageUrl = (
  { cartType, restaurant = null }: { cartType: CartType, restaurant?: Optional<Restaurant> },
) => {
  switch (cartType) {
    case 'dine_in_cart_type':
      return '/dine-in';
    case 'catering_cart_type':
      return restaurant?.onlineOrderCateringUrl || null;
    default:
      return restaurant?.onlineOrderDefaultUrl || null;
  }
};

// END CustomPage Functions

// Subcart Functions

export const getPendingSubcart = (menuItemCart: Optional<MenuItemCart>) => (
  menuItemCart?.subcarts?.find(subcart => subcart.status === 'pending_status')
);

// END Subcart Functions

// SelectedItem Functions

export const getSelectedItems = (menuItemCart: MenuItemCart) => {
  const pendingSubcart = getPendingSubcart(menuItemCart);
  if (!menuItemCart || !menuItemCart.selectedMenuItems) {
    return [];
  }
  if (menuItemCart.cartType !== 'dine_in_cart_type') {
    return menuItemCart.selectedMenuItems?.filter(selectedMenuItem => selectedMenuItem && selectedMenuItem.uiSelectedMenuItem.isValidItemAndModifiers);
  } else if (!pendingSubcart || !pendingSubcart.selectedMenuItems) {
    return [];
  } else {
    return pendingSubcart.selectedMenuItems?.filter(selectedMenuItem => selectedMenuItem && selectedMenuItem.uiSelectedMenuItem.isValidItemAndModifiers);
  }
};

export const getSelectedItemsCount = (menuItemCart: MenuItemCart) => {
  const quantity = getSelectedItems(menuItemCart).reduce((a, b) => a + b.quantity, 0);
  if (quantity === 0) {
    return null;
  }
  if (quantity === 1) {
    return '(1 item)';
  }
  return `(${formatNumber(quantity)} items)`;
};

// END SelectedItem Functions

export const useMenuItemCartVariables = ({ restaurantId }: { restaurantId?: number }) => {
  const cartType = useSelector(state => state.menuItemCart.menuItemCartType);
  const fulfillmentType = useSelector(state => state.menuItemCart.menuItemCartFulfillmentType);
  const orderingEventId = useSelector(state => state.consumer.orderingEventId);
  const locationId = useSelector(state => state.menuItemCart.menuItemCartLocationId);
  return {
    cartRestaurantId: restaurantId,
    cartType,
    fulfillmentType,
    locationId,
    orderingEventId,
  };
};

export function getOrderAttributes(cartType?: CartType, fulfillmentType?: FulfillmentType) {
  const isCatering = cartType === 'catering_cart_type';
  const isStandard = cartType === 'default_cart_type';
  const isPickup = fulfillmentType === 'pickup_fulfillment_type';
  const isDelivery = fulfillmentType === 'delivery_fulfillment_type';

  return { isCatering, isDelivery, isPickup, isStandard };
}

/**
 * Selects a value from a given dictionary based on context.
 *
 * Using any setting within the order flow, it is often necessary to choose the setting based on certain conditions.
 * These conditions are most often cartType, fulfillmentType. For example, a location has four different types of
 * openingRange (location.activeCateringDeliveryRanges, location.activeCateringOrderingRanges, location.activeDeliveryRanges, location.activeOrderingRanges).
 * Each openingRange corresponds to a specific combination of cartType and fulfillmentType. If the cartType
 * is 'default_cart_type' and the fulfillmentType is 'pickup_fulfillment_type', then the corresponding openingRanges are
 * found in `location.activeOrderingRanges`.
 *
 * One other common consideration is whether the location has ordering enabled according to the cartType and fulfillmentType.
 * If cartType is 'default_cart_type' and fulfillmentType is 'delivery_fulfillment_type', then a given setting will likely only be
 * effective if `location.isOrderingDeliveryEnabled` is true. The function takes this into consideration and the result will be
 * undefined if the condition is not met. However, if the setting still needs to be read even though the corresponding order toggle is
 * disabled, the value is provided as forcedResult. reference return type below.
 *
 * ```
 *  const dictionary = {
 *    cateringDelivery: location.activeCateringDeliveryRanges,
 *    cateringPickup: location.activeCateringOrderingRanges,
 *    standardDelivery: location.activeDeliveryRanges,
 *    standardPickup: location.activeOrderingRanges,
 *  };
 *  const [activeRanges] = lookupValueForOrder(dictionary, { cartType, fulfillmentType, location });
 * ```
 *
 * @param dictionary - maps values to order attributes
 * @param context - requires location, cartType, fulfillmentType
 * @returns {[result, forcedResult]} - one of elements in the array will be one of the values in dictionary, the other will be undefined.
 * The return value is an array so that the result can be easily renamed.
 */
export function lookupValueForOrder<T>(
  dictionary: Record<string, T>,
  context: { location: RestaurantLocation, cartType: CartType, fulfillmentType: FulfillmentType },
): [T, undefined] | [undefined, T] {
  const { location, cartType, fulfillmentType } = context;
  const { isCatering, isDelivery, isPickup, isStandard } = getOrderAttributes(cartType, fulfillmentType);
  let result: T | undefined;
  let forcedResult: T | undefined;
  switch (true) {
    case isCatering && isPickup:
      if (location?.isOrderingCateringEnabled) {
        result = dictionary.cateringPickup;
      } else {
        forcedResult = dictionary.cateringPickup;
      }
      break;
    case isCatering && isDelivery:
      if (location?.isOrderingCateringDeliveryEnabled) {
        result = dictionary.cateringDelivery;
      } else {
        forcedResult = dictionary.cateringDelivery;
      }
      break;
    case isStandard && isPickup:
      if (location?.isOrderingEnabled) {
        result = dictionary.standardPickup;
      } else {
        forcedResult = dictionary.standardPickup;
      }
      break;
    case isStandard && isDelivery:
      if (location?.isOrderingDeliveryEnabled) {
        result = dictionary.standardDelivery;
      } else {
        forcedResult = dictionary.standardDelivery;
      }
      break;
    default:
      break;
  }

  // the cast is safe because only one of the variables is changed from undefined
  return [result, forcedResult] as [T, undefined] | [undefined, T];
}

export const getPayOnPickupAvailability = (menuItemCart: MenuItemCart) => {
  const { isOrderingCateringPayOnPickupEnabled, isOrderingPayOnPickupEnabled } = menuItemCart.location ?? {};
  const isPickupFulfillmentType = menuItemCart.fulfillmentType === 'pickup_fulfillment_type';
  const isOrderingEvent = menuItemCart.orderingEvent;
  const isPayOnPickupEnabled = menuItemCart.cartType === 'catering_cart_type' ? isOrderingCateringPayOnPickupEnabled : isOrderingPayOnPickupEnabled;
  return (isPayOnPickupEnabled && isPickupFulfillmentType && !isOrderingEvent);
};
