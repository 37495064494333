import roundHalfEven from 'round-half-even';

export const formatFullPercent = (percent: Optional<number>) => `${percent != null ? Math.round(percent * 100) : 0}%`;

export const formatNumber = (value: Optional<number>, digits?: number) => {
  if (value == null) {
    return '';
  }
  const val = digits ?
    value.toFixed(digits).replace('.00', '') :
    String(value);
  return val.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const round = (value: Optional<number>, decimals?: number) => {
  if (typeof value === 'number') {
    return roundHalfEven(value, decimals);
  }
  return null;
};

export const ordinalSuffixOf = (i: number) => {
  const j = i % 10;
  const k = i % 100;

  if (j === 1 && k !== 11) {
    return `${i}st`;
  }
  if (j === 2 && k !== 12) {
    return `${i}nd`;
  }
  if (j === 3 && k !== 13) {
    return `${i}rd`;
  }
  return `${i}th`;
};

export const toFloat = (obj: unknown) => {
  if (typeof obj === 'undefined' || obj === undefined || obj === null || obj === '') {
    return null;
  }
  if (typeof obj === 'string') {
    // parseFloat treats commas as decimals
    return parseFloat(obj.replace(/,/, ''));
  }
  if (typeof obj === 'number') {
    return obj;
  }
  return parseFloat(String(obj));
};

export const toInt = (obj: unknown) => {
  if (typeof obj === 'undefined' || obj === undefined || obj === null || obj === '') {
    return null;
  }
  return parseInt(String(obj), 10);
};
