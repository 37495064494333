import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { compose } from '@shakacode/recompose';
import { Icon, Typography } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';
import { X as XIcon } from '@popmenu/web-icons';
import { withStyles } from '../../utils/withStyles';

import styles from './styles';

const DraftBanner = ({ classes, customMessage, setDismissedDraftBanner }) => (
  <div
    className={classes.draftBannerContainer}
  >
    <Typography className={classes.draftBannerText}>
      <FormattedMessage
        id={customMessage ? customMessage.id : 'custom_page.draft_preview'}
        defaultMessage={customMessage ? customMessage.message : 'Preview of draft content'}
      />
    </Typography>

    <IconButton
      aria-label="Dismiss Draft Banner"
      onClick={() => setDismissedDraftBanner()}
      className={classes.draftBannerDismissButton}
    >
      <Icon icon={XIcon} />
    </IconButton>
  </div>
);

DraftBanner.defaultProps = {
  customMessage: undefined,
};

DraftBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  customMessage: PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string,
  }),
  setDismissedDraftBanner: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
)(DraftBanner);
