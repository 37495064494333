import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Box, ButtonBase, Icon } from '@popmenu/common-ui';
import { Profile } from '@popmenu/web-icons';
import { useIntl } from 'react-intl';
import styles from './styles';

const useStyles = makeStyles(styles);
const useMessages = (props) => {
  const { notificationCount } = props;
  const intl = useIntl();
  return {
    label: intl.formatMessage({ defaultMessage: `profile - {notificationCount, plural,
      =0 {}
      one {# notification}
      other {# notifications}
  }`,
    id: 'nav-avatar-notifications' }, { notificationCount }),
  };
};

const ProfileAvatar = ({ user, onClick, notificationCount }) => {
  const classes = useStyles();
  const messages = useMessages({ notificationCount });
  const { avatarUrl, displayName } = user || {};
  return (avatarUrl ? (
    <Box width={26} height={26}>
      <ButtonBase onClick={onClick} aria-label={messages.label}>
        <Avatar
          alt={displayName}
          src={avatarUrl}
          className={classes.avatar}
        />
      </ButtonBase>
    </Box>
  ) : (
    <ButtonBase onClick={onClick} aria-label={messages.label}>
      <Icon
        icon={Profile}
        size="extra-large"
      />
    </ButtonBase>
  ));
};

ProfileAvatar.propTypes = {
  notificationCount: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  user: PropTypes.object,
};

ProfileAvatar.defaultProps = {
  notificationCount: 0,
  user: null,
};

export default ProfileAvatar;
