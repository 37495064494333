import React from 'react';
import { Box, Typography } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';

import { lookupValueForOrder } from '../menus/cart/MenuItemCartHelpers';
import { formatCurrency } from '../../utils/currency';

export const getCartMinSubtotal = ({ menuItemCart }) => {
  const { location, cartType, fulfillmentType, orderingEvent } = menuItemCart;
  let minAmount;
  if (orderingEvent) {
    minAmount = orderingEvent.minOrdersCount;
  } else {
    const minAmountDictionary = {
      cateringDelivery: location.cateringDeliveryMinMenuItemCartSubtotal,
      cateringPickup: location.cateringPickupMinMenuItemCartSubtotal,
      standardDelivery: location.orderingDeliveryMinMenuItemCartSubtotal,
      standardPickup: location.orderingPickupMinMenuItemCartSubtotal,
    };
    const [result] = lookupValueForOrder(minAmountDictionary, { cartType, fulfillmentType, location });
    minAmount = result;
  }
  return minAmount;
};

export const MinimumSubtotalMessage = (props) => {
  const { menuItemCart, className } = props;
  const minAmount = getCartMinSubtotal({ menuItemCart });

  if (!(minAmount > 0)) return null;

  return (
    <Box
      className={className}
      paddingLeft={2}
    >
      <Typography variant="body2">
        <FormattedMessage
          id="consumer.ordering.cart_min_subtotal"
          defaultMessage="Minimum order amount of {minAmount}"
          values={{ minAmount: formatCurrency(minAmount, menuItemCart.location.currency, { showDecimals: true, showSymbol: true }) }}
        />
      </Typography>
    </Box>
  );
};
