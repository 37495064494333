import React, { useEffect } from 'react';
import type { MenuItem } from '~/libs/gql/types';
import type { RequiredChildren } from '~/utils/react';

export let updateItemsImpl: ((items: MenuItem[]) => void) | null = null;

type GetItemContextValue = (id: number) => MenuItem | undefined;

export const GetItemContext = React.createContext<GetItemContextValue | undefined>(undefined);

// SUBTLE: performance optimization. The separate context is preferable to Apollo Cache, because Apollo Cache is expensive.
// We could just use |window.POPMENU_CLIENT.writeQuery| to save the items data. But every Apollo Call is expensive and should be avoided to keep good LH scores.
export const MenuItemsQueryCacheProvider = ({ children }: RequiredChildren) => {
  const [itemsDictionary, setItemsDictionary] = React.useState<Record<number, MenuItem>>({});
  const updateItems = React.useCallback((items: MenuItem[]) => {
    setItemsDictionary((oldItems) => {
      const newItems = { ...oldItems };
      items.forEach((item) => {
        newItems[item.id] = item;
      });
      return newItems;
    });
  }, []);
  const getItem = React.useCallback((id: number) => itemsDictionary[id], [itemsDictionary]);

  useEffect(() => {
    updateItemsImpl = updateItems;
    return () => {
      updateItemsImpl = null;
    };
  }, [updateItems]);

  return (
    <GetItemContext.Provider value={getItem}>
      {children}
    </GetItemContext.Provider>
  );
};

export const updateMenuItems = (items: MenuItem[]) => {
  if (updateItemsImpl) updateItemsImpl(items);
};
