import { useRestaurant } from './withRestaurant';

/**
 * Options for the useRestaurantContext hook.
 * @typedef {Object} HookOptions
 * @property {string[] | undefined} keys - when provided, the hook returns only a partial restaurant object of the given keys.
 */

/**
 * Returns the value of the current restaurant from context.
 * Not currently @deprecated because we aren't ready to get rid of it everywhere and don't want to just add TODOs.
 * But still try to avoid using this if possible.
 * More information and approach to fix calls in
 * https://www.notion.so/popmenu/What-the-Fragment-324c55dbe7a44e2baefbb80a734750da#7f8f520565ff4901a3e2fff8d194c763
 * @param {HookOptions} options configuration object for the hook. ({@link HookOptions})
 * @returns {Restaurant} restaurant - typed by AdminRestaurantFragment.gql
 */
export const useRestaurantContext = ({ keys } = {}) => {
  const restaurantContext = useRestaurant();
  let restaurant = restaurantContext;
  if (keys) {
    restaurant = keys.reduce((partialRestaurant, key) => ({ ...partialRestaurant, [key]: restaurant[key] }), {});
  }

  return restaurant;
};
