import React from 'react';
import PropTypes from 'prop-types';
import throttle from 'lodash/throttle';

import { offset } from '../utils/dom';

const EVENT_THROTTLE = 100;

const INITIAL_WINDOW_STATE = {
  maxNavbarHeight: 100,
  navbarHeight: 100,
  navbarHeightWithOffset: 100,
  scrolled: false,
  scrolledHeader: false,
  scrollTop: 0,
};

export const WindowContext = React.createContext({
  ...INITIAL_WINDOW_STATE,
});

export function withWindowContext(Component) {
  // eslint-disable-next-line react/function-component-definition
  return function ComponentWithWindowContext(props) {
    return (
      <WindowContext.Consumer>
        {windowState => <Component {...props} window={windowState} />}
      </WindowContext.Consumer>
    );
  };
}

class WindowProvider extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_WINDOW_STATE,
      maxNavbarHeight: props.initialNavbarHeight,
      navbarHeight: props.initialNavbarHeight,
      navbarHeightWithOffset: props.initialNavbarHeight,
      scrolledHeader: !props.isHeaderEnabled,
    };
    this.addScrollListener = this.addScrollListener.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.removeScrollListener = this.removeScrollListener.bind(this);
    this.throttledHandleScroll = throttle(this.handleScroll, EVENT_THROTTLE);
    this.scrollListeners = [];
  }

  componentDidMount() {
    if (!this.props.shouldPauseScrollListener) {
      document.addEventListener('scroll', this.throttledHandleScroll);
    }
    this.handleScroll();

    // Most scroll logic is short circuited until the user has scrolled at all
    // However, we still trigger after a second in case there is content above the fold
    setTimeout(() => { this.setState({ scrolled: true }); }, 500);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.throttledHandleScroll);
    this.throttledHandleScroll.cancel();
  }

  addScrollListener(fn) {
    if (this.props.shouldPauseScrollListener && this.scrollListeners.length === 0) {
      console.log('[POPMENU] Resume scroll listener');
      document.addEventListener('scroll', this.throttledHandleScroll, { passive: true });
    }

    // If the window was scrolled there is a possibility of data race as the listening component
    // may miss the event.
    if (this.state.scrolled) {
      fn(this.state);
    }
    this.scrollListeners.push(fn);
  }

  removeScrollListener(fn) {
    const index = this.scrollListeners.indexOf(fn);
    if (index > -1) {
      this.scrollListeners.splice(index, 1);
      if (this.props.shouldPauseScrollListener && this.scrollListeners.length === 0) {
        console.log('[POPMENU] Pause scroll listener');
        document.removeEventListener('scroll', this.throttledHandleScroll);
      }
    }
  }

  handleScroll() {
    // console.log(`[POPMENU] WindowProvider.handleScroll (${this.scrollListeners.length} listeners)`);
    const header = document.getElementById('page-header');
    const headerHeight = header ? (offset(header).bottom - (this.state.scrolledHeader ? 100 : 0)) : 0;
    const navbar = document.getElementById('navbar');
    const navbarHeight = (navbar && navbar.offsetHeight) || 0;
    const navbarHeightWithOffset = navbarHeight + ((navbar && navbar.offsetTop) || 0);
    const scrollTop = document.documentElement.scrollTop || document.body.scrollTop || 0;
    this.setState((prevState) => {
      const newState = {
        maxNavbarHeight: Math.max(navbarHeight, prevState.maxNavbarHeight),
        navbarHeight,
        navbarHeightWithOffset,
        scrolled: prevState.scrolled || (scrollTop >= (window.innerHeight / 8)),
        scrolledHeader: !header || scrollTop >= headerHeight,
        scrollTop,
      };
      this.scrollListeners.forEach(fn => fn(newState));

      return newState;
    });
  }

  render() {
    // TODO: Refactor to prevent excess rerenders
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    const value = {
      ...this.state,
      /*
        isMobile: false,
        maxNavbarHeight: 100,
        navbarHeight: 100,
        navbarHeightWithOffset: 100,
        scrolled: false,
        scrolledHeader: false,
        scrollTop: 0,
      */
      addScrollListener: this.addScrollListener,
      removeScrollListener: this.removeScrollListener,
    };
    return (
      <WindowContext.Provider value={value}>
        {this.props.children}
      </WindowContext.Provider>
    );
  }
}

WindowProvider.defaultProps = {
  initialNavbarHeight: INITIAL_WINDOW_STATE.navbarHeight,
  isHeaderEnabled: true,
  shouldPauseScrollListener: false,
};

WindowProvider.propTypes = {
  children: PropTypes.node.isRequired,
  initialNavbarHeight: PropTypes.number,
  isHeaderEnabled: PropTypes.bool,
  shouldPauseScrollListener: PropTypes.bool,
};

export default WindowProvider;
