import type { Stripe } from '@stripe/stripe-js';
import type { Dispatch } from '~/utils/initStore';

export const SET_STRIPE_INSTANCE = 'SET_STRIPE_INSTANCE';

export type StripeInstance = Promise<Stripe | null> | Stripe | null;

export type StripeAction = {
  stripeInstance: StripeInstance;
  type: typeof SET_STRIPE_INSTANCE;
};

export function setStripeInstance(stripeInstance: StripeInstance) {
  return (dispatch: Dispatch<StripeAction>) => {
    dispatch({
      stripeInstance,
      type: SET_STRIPE_INSTANCE,
    });
  };
}
