import React, { useContext } from 'react';
import { DraftModeContext } from './initContext';

export const useDraftMode = () => {
  const draftModeContext = useContext(DraftModeContext);
  return draftModeContext.draftMode;
};

export function withDraftMode(Component) {
  // eslint-disable-next-line react/function-component-definition
  return function ComponentWithDraftMode(props) {
    return (
      <DraftModeContext.Consumer>
        {draftModeContext => <Component {...props} draftMode={draftModeContext.draftMode} />}
      </DraftModeContext.Consumer>
    );
  };
}
