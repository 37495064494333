import React from 'react';
import type { Attributes, ReactElement } from 'react';

// TODO: add overloads for cloneElement overloads if necessary when converting callers to TypeScript
export const addPropsToChild = <P, >(child: ReactElement<P>, props?: Partial<P> & Attributes) => {
  if (Array.isArray(child)) {
    throw new Error('addPropsToChild expects a single child');
  }
  return React.cloneElement(child, props);
};

// Convert linebreaks to <br> tags and insert &nbsp; between last two words to prevent orphans
export const nl2br = (content: Optional<string>) => {
  const lines = (content || '').split('\n');
  return lines.map((line, i) => {
    const words = line.split(' ');
    let finalWords: React.ReactNode | null;
    let joinedWords: string;
    if (words.length > 3) {
      finalWords = (
        <React.Fragment>
          {words.slice(-2, -1)[0]}&nbsp;{words.slice(-1)[0]}
        </React.Fragment>
      );
      joinedWords = `${words.slice(0, -2).join(' ')} `;
    } else {
      finalWords = null;
      joinedWords = line;
    }
    const br = i < lines.length - 1 ? <br /> : null;
    return (
      <span key={i}>{joinedWords}{finalWords}{br}</span>
    );
  });
};

// Basic props type for those components which have required children and no other props
export interface RequiredChildren {
  children: React.ReactNode;
}

// TODO: sc-49236 Can be removed after upgrading to React 18 which fixes the definition
// A replacement for React.{FC,FunctionComponent} until React 18. See https://www.totaltypescript.com/you-can-stop-hating-react-fc
export type FunctionComponent<TProps> = (props: TProps) => ReactElement | null;
