import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages({
  "reservationsRequestedReservationDate": {
    "id": "reservations.requested_reservation_date",
    "defaultMessage": "Requested Reservation Date",
  },
  "reservationsReservationDate": {
    "id": "reservations.reservation_date",
    "defaultMessage": "Reservation Date",
  },
  "reservationsRequestedReservationPartySize": {
    "id": "reservations.requested_reservation_party_size",
    "defaultMessage": "Requested Reservation Party Size"
  },
  "reservationsPartySize": {
    "id": "reservations.party_size",
    "defaultMessage": "Party Size",
  },
  "reservationsTextNotification": {
    "id": "reservations.text_notification",
    "defaultMessage":"Notify me by text message"
  }
});
// Requested Reservation Date
export default defaultMessages;
