import { useCallback, useMemo, useState } from 'react';

// PERFORMANCE OPTIMIZATION: keep function definitions in loadable-components.
//
// This hook is useful when you want to use a function that is lazy loaded in
// one of a child components, but you want to call it from a parent component.
// If a child component is a loadable-component, the hook will allow to keep
// the definition of the function in the child component, but call it from the
// parent component.

export function useLazyAsyncFunction<Arg, Retval>() {
  type Func = (arg: Arg)=>Promise<Retval>;
  const [func, setFunc] = useState<Func>();

  const functionSetPromise = useMemo(() => {
    type Resolve = (value: Func) => void;
    let resolvePromise: Resolve | undefined;
    const promise = new Promise<Func>((resolve) => {
      resolvePromise = resolve;
    });
    return { promise, resolvePromise };
  }, []);

  const setFuncWithPromise = useCallback((f: Func) => {
    setFunc(() => f);
    functionSetPromise.resolvePromise?.(f);
  }, [functionSetPromise]);

  const fallbackFunction = useCallback(async (arg: Arg) => (await functionSetPromise.promise)(arg), [functionSetPromise]);

  return [func ?? fallbackFunction, setFuncWithPromise] as const;
}
