import { useCallback } from 'react';

const useOfferCodeData = (restaurant, menuItemCart) => {
  const location = menuItemCart?.location || {};
  const isGiftCardsEnabled = restaurant?.featureSetting?.isGiftCardsEnabled;
  const hasToastOfferSupport = location?.hasToastOfferSupport;
  const hasToastGiftCardSupport = location?.hasToastGiftCardSupport;

  const getCodeInputTitle = useCallback(() => {
    if (isGiftCardsEnabled && hasToastGiftCardSupport && hasToastOfferSupport && !location.isCloverPayment) {
      return 'consumer.ordering.gift_card_offer_code_input';
    } else if (isGiftCardsEnabled && hasToastGiftCardSupport && !location.isCloverPayment) {
      return 'consumer.ordering.gift_card_input';
    } else {
      return 'consumer.ordering.offer_code_input';
    }
  }, [hasToastGiftCardSupport, hasToastOfferSupport, isGiftCardsEnabled, location.isCloverPayment]);

  return {
    getCodeInputTitle,
    hasToastGiftCardSupport,
    hasToastOfferSupport,
    isGiftCardsEnabled,
    location,
  };
};

export default useOfferCodeData;
