
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "dollarSign": {
      "id": "generic.dollar_sign",
      "defaultMessage": "$"
    },
    "percentSign": {
      "id": "generic.percent_sign",
      "defaultMessage": "%"
    },
    "or": {
      "id": "generic.or",
      "defaultMessage": "or"
    },
    "minus": {
      "id": "generic.minus",
      "defaultMessage": "-"
    },
    "plus": {
      "id": "generic.plus",
      "defaultMessage": "+"
    },
    "colon": {
      "id": "generic.colon",
      "defaultMessage": ":"
    },
    "telephone": {
      "id": "generic.telephone",
      "defaultMessage": "telephone"
    },
    "likes": {
      "id": "generic.likes",
      "defaultMessage": "likes"
    },
    "like": {
      "id": "generic.like",
      "defaultMessage": "like"
    },
    "reviews": {
      "id": "generic.reviews",
      "defaultMessage": "reviews"
    },
    "review": {
      "id": "generic.review",
      "defaultMessage": "review"
    }
  }
);
export default defaultMessages;

