import React, { useEffect, useRef, useState } from 'react';
import type { KeyboardEvent } from 'react';
import { readableFontColor } from '../../../utils/colors';

type navItem = {
  __typename: string,
  id: number;
  isFeatured: boolean;
  linkType: string;
  name: string;
  navLinks: navItem[];
  onKeyDown?: (event: KeyboardEvent) => void;
  position: number;
  title: string;
  useRef?: (el: HTMLLIElement) => void;
}

type DropdownNavLinkProps = {
  backgroundColor: string;
  isMobile: boolean;
  linkName: string;
  navItem: navItem;
  navBarItemStyles: React.CSSProperties;
  renderLink: (navItem: navItem, dropdownLink: boolean) => React.ReactElement;
}

const DropdownNavLink = ({ backgroundColor, isMobile, linkName, navItem, navBarItemStyles, renderLink }: DropdownNavLinkProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const itemsRef = useRef<(HTMLLIElement | null)[]>([]);
  const dropdownRef = useRef<(HTMLDivElement | null)>(null);
  const shouldAutoFocusRef = useRef(false);
  const containsVIPLinks = navItem.navLinks.some(navLink => navLink.linkType === 'sign_up_type' || navLink.linkType === 'sign_in_type');

  useEffect(() => {
    itemsRef.current = itemsRef.current.filter(el => el);
    if (isExpanded && shouldAutoFocusRef.current) {
      itemsRef.current[0]?.focus();
    }

    if (shouldAutoFocusRef.current && !isExpanded) {
      dropdownRef.current?.focus();
      shouldAutoFocusRef.current = false;
    }
  }, [isExpanded]);

  const handleListItemKeyDown = (event: KeyboardEvent, index: number) => {
    const keyHandlers: Record<string, () => void> = {
      ArrowDown: () => {
        event.preventDefault();
        const nextIndex = (index + 1) % itemsRef.current.length;
        itemsRef.current[nextIndex]?.focus();
      },
      ArrowUp: () => {
        event.preventDefault();
        const prevIndex = (index - 1 + itemsRef.current.length) % itemsRef.current.length;
        itemsRef.current[prevIndex]?.focus();
      },
      Tab: () => {
        event.preventDefault();
        const nextIndex = event.shiftKey ? index - 1 : index + 1;
        const targetIndex = (nextIndex + itemsRef.current.length) % itemsRef.current.length;
        itemsRef.current[targetIndex]?.focus();
      },
    };

    if (keyHandlers[event.key]) {
      keyHandlers[event.key]?.();
    }
  };

  const joinedDropdownName = linkName.split(' ').join('-');

  return (
    <div
      id={`${joinedDropdownName}-menu-button`}
      className={`a nav-dropdown ${navItem.isFeatured ? 'pm-featured-dropdown' : ''}`}
      tabIndex={isMobile ? -1 : 0}
      style={navBarItemStyles}
      role="button"
      aria-controls={`${joinedDropdownName}-menu`}
      aria-expanded={isExpanded}
      aria-haspopup="true"
      aria-label={linkName}
      onClick={() => setIsExpanded(true)}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
      onKeyDown={(e) => {
        if (isExpanded && e.key === 'Escape') {
          e.stopPropagation();
          setIsExpanded(false);
        }
        if (e.currentTarget === e.target && (e.key === 'Space' || e.key === ' ' || e.key === 'Enter')) {
          e.preventDefault();
          shouldAutoFocusRef.current = true;
          setIsExpanded(true);
        }
      }}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          shouldAutoFocusRef.current = false;
          setIsExpanded(false);
        }
      }}
      ref={dropdownRef}
      data-testid={`${navItem.__typename}_${navItem.id}`}
    >
      <span>{linkName}</span>
      <div
        id={`${joinedDropdownName}-menu`}
        aria-labelledby={`${joinedDropdownName}-menu-button`}
        className={'nav-dropdown-links'}
        style={{
          backgroundColor,
          color: backgroundColor ? readableFontColor(backgroundColor) : undefined,
        }}
        role="menu"
        data-cy={containsVIPLinks ? 'pm-contains-vip-links' : undefined}
        tabIndex={-1}
      >
        {(isExpanded || isMobile) && navItem.navLinks?.map((navLink, index) => (
          renderLink({
            ...navLink,
            isFeatured: navItem.isFeatured,
            onKeyDown: !isMobile ? (event: KeyboardEvent) => handleListItemKeyDown(event, index) : undefined,
            useRef: !isMobile ? (el: HTMLLIElement) => { itemsRef.current[index] = el; } : undefined,
          }, true)
        ))}
      </div>
    </div>
  );
};

export default DropdownNavLink;
