import type { RestaurantLocation } from '~/libs/gql/types';
import { isSSR } from './dom';
import { asError } from './asError';
import { isValidCoordinates } from './geography';

export const getParam = (param: string, customQuery?: string) => {
  try {
    // Build fake URL from custom querystring to treat the same as QS params
    let qs = null;
    if (customQuery) {
      if (!customQuery.includes('://')) {
        qs = `https://www.popmenu.com${customQuery.startsWith('?') ? '' : '?'}${customQuery}`;
      } else {
        qs = customQuery;
      }
    }

    // Build fake URL from hash to treat the same as QS params
    let hash: Optional<string> = isSSR ? null : window.location.hash;
    if (hash) {
      // Legacy browsers do not include leading #
      if (hash.includes('#')) {
        hash = hash.split('#')[1];
      }
      hash = `https://www.popmenu.com?${hash}`;
    }

    // Prefer custom querystring > actual querystring > hash params
    let value: string | null = null;
    [qs, isSSR ? null : window.location.href, hash].forEach((url) => {
      if (value || !url) {
        return;
      }
      const key = param.replace(/[[\]]/g, '\\$&');
      const regex = new RegExp(`[?&]${key}(=([^&#]*)|&|#|$)`);
      const results = regex.exec(url);
      if (!results) {
        return;
      }
      if (!results[2]) {
        value = '';
      } else {
        value = decodeURIComponent(results[2].replace(/\+/g, ' '));
      }
    });
    return value;
  } catch (err) {
    console.log(`[POPMENU] Unhandled param error: ${asError(err).toString()}`);
  }
  return null;
};

export const removeHostName = (url: string) => {
  if (url.startsWith('www.')) {
    return url.replace('www.', '');
  }
  return url;
};

export const toQS = (obj: Record<string, Optional<string | number | boolean>>) => Object.entries(obj).map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v || '')}`).join('&');

export const toLocationMapUrl = (location: RestaurantLocation) => {
  let query;
  if (location.streetAddress && location.city) {
    const streetAddressParts = location.streetAddress.split('\n');
    query = `${streetAddressParts.join(', ')}, ${location.city},${location.state ? ` ${location.state}` : ''}${location.postalCode ? ` ${location.postalCode}` : ''}`;
  } else if (isValidCoordinates(location)) {
    query = `${location.lat},${location.lng}`;
  }
  const queryString = toQS({
    api: '1',
    query,
    query_place_id: location.googlePlaceId,
  });
  return `https://www.google.com/maps/search/?${queryString}`;
};

export const toQueryStringObject = (queryString: string): Record<string, string | undefined> => queryString.substring(1).split('&')
  .map((s) => {
    const [key, value] = s.split('=');
    const param: Record<string, string> = {};
    if (key != null) {
      param[key] = value ?? ''; // when = is absent, treat value as empty string
    }
    return param;
  })
  .reduce((previous, current) => Object.assign(previous, current));
