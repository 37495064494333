import React from 'react';
import PropTypes from 'prop-types';

class NoSsr extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
    };
  }

  componentDidMount() {
    this.setState({ render: true });
  }

  render() {
    if (this.state.render) {
      return this.props.children;
    }
    return null;
  }
}

NoSsr.defaultProps = {
  children: null,
};

NoSsr.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]),
};

export default NoSsr;
