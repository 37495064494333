import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import type { Theme } from '~/libs/gql/types';
import { MenuLayout } from '~/utils/types';

// When converting clients to TypeScript, use `PopmenuTheme` for properties with this proptype.
export const themeShape = /* @__PURE__ */ PropTypes.object;

export type PopmenuTheme = DeepPartial<Theme>;

export const ThemeContext = /* @__PURE__ */ React.createContext<PopmenuTheme>({
  customCss: null,
  defaultFont: {
    color: null,
    family: null,
  },
  defaultMenuLayout: MenuLayout.SimpleTwo,
  defaultTransitionType: 'tt_none',
  footerLogoEffect: 'fxf_white',
  headerBackgroundEffect: 'fxhbg_white',
  headerLogoAlignment: 'l_nav_r',
  headerLogoEffect: 'fxh_none',
  id: undefined,
  menuHeaderFont: {
    color: null,
    family: null,
  },
  menuItemDescriptionFont: {
    color: null,
    family: null,
  },
  menuItemNameFont: {
    case: 'none',
    color: null,
    family: null,
    weight: 'normal',
  },
  pageHeaderFont: {
    case: 'uppercase',
    color: null,
    family: null,
  },
  popmenuLogoEffect: 'pm_logo_black',
  primaryColor: null,
  secondaryColor: null,
  showCurrencySymbol: true,
  showFollowSection: true,
  showMenuItemDescription: true,
  showMenuItemDishTags: true,
  showPopularSection: false,
  showPrice: true,
});

export interface WithThemeProps {
  theme: PopmenuTheme;
}

export function withTheme<TProps extends WithThemeProps>(Component: React.ComponentType<TProps>) {
  // eslint-disable-next-line react/function-component-definition
  return function ComponentWithThemeContext(props: Omit<TProps, keyof WithThemeProps>) {
    return (
      // The cast is necessary because of https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
      <ThemeContext.Consumer>
        {(theme: PopmenuTheme) => <Component {...props as TProps} theme={theme} />}
      </ThemeContext.Consumer>
    );
  };
}

export const useTheme = () => useContext(ThemeContext);
