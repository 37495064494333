import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { Typography } from '@popmenu/common-ui';
import { FormattedMessage } from 'react-intl';
import { formatCurrency } from '../../../utils/currency';
import { lookupValueForOrder } from '../../menus/cart/MenuItemCartHelpers';

export const DeliveryFeeCoveredMessage = (props) => {
  const deliveryFeeCoveredProperties = getDeliveryFeeCoveredProperties(props);
  if (!deliveryFeeCoveredProperties || !deliveryFeeCoveredProperties.coverEnabled) return null;

  const { coverMinimum, currency } = deliveryFeeCoveredProperties;
  return (
    <Box p={1}>
      <Typography align="center">
        <FormattedMessage
          id="consumer.ordering.free_delivery_on_orders_over"
          defaultMessage="Free delivery on orders over {coverMinimum}"
          values={{ coverMinimum: formatCurrency(coverMinimum, currency) }}
        />
      </Typography>
    </Box>
  );
};
DeliveryFeeCoveredMessage.propTypes = {
  // TypeScript conversion note: should use CartType and FulfillmentType from app/javascript/utils/types.ts
  cartType: PropTypes.string.isRequired,
  fulfillmentType: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
};

export function getDeliveryFeeCoveredProperties({ location, cartType, fulfillmentType }) {
  const deliveryFeeCoveredProperties = {
    cateringDelivery: { coverEnabled: location.isOrderingCateringDeliveryCoverMinimumEnabled, coverMinimum: location.cateringDeliveryCoverMinimumAmount, currency: location.currency },
    standardDelivery: { coverEnabled: location.isOrderingDeliveryCoverMinimumEnabled, coverMinimum: location.orderingDeliveryCoverMinimumAmount, currency: location.currency },
  };
  const [result] = lookupValueForOrder(deliveryFeeCoveredProperties, { cartType, fulfillmentType, location });
  return result;
}
