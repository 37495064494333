import React from 'react';

// Respective heading AH is present inside <Header/> component, so disabling the rule is OK.
// eslint-disable-next-line regex/invalid
import { AHLevelProvider } from '../AccessibleHeading';

import {
  AddToCartModal,
  EditSelectedItemModal,
  FavoriteLocationModal,
  ForgotModal,
  MenuItemModalContainer,
  ProfileModal,
  ReviewSectionModal,
  SocialShareModal,
  VipV2Modal,
  TermsModal,
  EditMenuItemCartLocationModal,
  OrderDetailsModal,
  FollowPromptV2,
  ProfileV2Modal,
  UserEmailCheckPrompt,
} from './ConsumerModals.imports-loadable';
import { ScrolledModalsOpener } from './ScrolledModalsOpener';

class ConsumerModalContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      renderModalContainer: false,
    };
  }

  componentDidMount() {
    this.setState({ renderModalContainer: true });
  }

  render() {
    if (!this.state.renderModalContainer) {
      return null;
    }

    return (
      <AHLevelProvider>
        <AddToCartModal />
        <EditSelectedItemModal />
        <EditMenuItemCartLocationModal />
        <OrderDetailsModal />
        <ForgotModal />
        <MenuItemModalContainer />
        <ProfileModal />
        <ReviewSectionModal />
        <SocialShareModal />
        <VipV2Modal />
        <TermsModal />
        <FollowPromptV2 />
        <ProfileV2Modal />
        <UserEmailCheckPrompt />
        <ScrolledModalsOpener />
        <FavoriteLocationModal />
      </AHLevelProvider>
    );
  }
}

export default ConsumerModalContainer;
