import React, { useState, useEffect, useRef, useCallback, Fragment } from 'react';
import { Icon, Button, Box, Typography } from '@popmenu/common-ui';
import { ShoppingBag, X } from '@popmenu/web-icons';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';

import classNames from 'classnames';
import { formatCurrency } from '../../../utils/currency';
import { withIntl } from '../../../utils/withIntl';
import { useMenuItemCartValidation } from '../../shared/hooks/useMenuItemCartValidation';
import { openEditMenuItemCartModal, openEditSelectedItemModal } from '../../../shared/DishActions';
import Divider from '../../../admin/shared/forms/Divider';
import { orderingOfferApplied } from '../../online_ordering/utils';
import { OrderSummarySelectedItem } from './SubmitMenuItemCartForm/MenuItemCartForm/OrderSummarySelectedItem';
import { useWindowSizeContext } from '../../../shared/WindowSizeProvider';
import { CodeInputBoxV2 } from '../../online_ordering/CodeInputBoxV2';
import { withRestaurant } from '../../../utils/withRestaurant';
import { setMenuItemCartDeliveryAddressError } from '../../../shared/MenuItemCartActions';
import { AH, AHLevelProvider } from '../../shared/AccessibleHeading';
import { useCurrentSession } from '../../../shared/CurrentSessionProvider';
import { DeliveryFeeCoveredMessage } from '../../online_ordering/DeliveryFeeCoveredMessage/DeliveryFeeCoveredMessage';
import { MinimumSubtotalMessage } from '../../shared/MinimumSubtotalMessage';

// The below eslint error was disabled due to it triggering during a feature flag removal which is unrelated - Lee Roberts
/* eslint-disable max-lines-per-function */
const CartButtonSummary = ({ closeShowCartModal, classes, onCheckoutButtonClick, onGoToMenuButtonClick, t, isMobileOrderDetailsV2Enabled }) => {
  const { loading, pendingMenuItemCart: menuItemCart } = useCurrentSession();

  const dispatch = useDispatch();
  const { isMobile } = useWindowSizeContext();

  const { deliveryFullAddress, fulfillmentType } = menuItemCart;
  const isFeeEnabled = menuItemCart.location.isOrderingFeeEnabled || menuItemCart.location.isOrderingDineInFeeEnabled || menuItemCart.location.isOrderingDineInPlatformFeeEnabled || menuItemCart.location.isOrderingConsumerPlatformFeeEnabled || menuItemCart.location.isOrderingDeliveryPlatformFeeEnabled;

  const isWindowClient = typeof window === 'object';
  const [windowHeight, setWindowHeight] = useState(window.innerHeight);
  // Additional components that are added to the contents that will affect vertical height will need to be added and adjusted in the calculation of the selectedMenuItem contents
  const checkoutRef = useRef(null);
  const [checkoutHeight, setCheckoutHeight] = useState(0);
  const codeInputRef = useRef(null);
  const [codeInputHeight, setCodeInputHeight] = useState(0);
  const minimumOrderAmountRef = useRef(null);
  const [minimumOrderAmountHeight, setMinimumOrderAmountHeight] = useState(0);

  const [isMenuItemCartValidating, checkoutButtonClicked] = useMenuItemCartValidation({
    menuItemCartId: menuItemCart.id,
    navigateToCheckoutPage: onCheckoutButtonClick,
  });

  const onGoCheckoutPageButtonClick = useCallback(() => {
    /**
     * Validate order delivery address before process to checkout page
     */
    if (fulfillmentType === 'delivery_fulfillment_type' && !deliveryFullAddress) {
      dispatch(setMenuItemCartDeliveryAddressError('missing'));
      dispatch(openEditMenuItemCartModal(menuItemCart, false));

      return;
    }

    checkoutButtonClicked();
  }, [checkoutButtonClicked, fulfillmentType, deliveryFullAddress, dispatch, menuItemCart]);

  // Window related actions are used to account for dynamic height changes in mobile view.
  useEffect(() => {
    function setSize() {
      setWindowHeight(window.innerHeight);
    }

    if (isWindowClient) {
      window.addEventListener('resize', setSize);

      return () => window.removeEventListener('resize', setSize);
    }

    return windowHeight;
  }, [isWindowClient, windowHeight]);

  // Calculates the height of the new checkout buttons at the bottom of the component
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0].contentRect.height > 0) {
        setCheckoutHeight(entries[0].contentRect.height);
      }
    });
    const node = checkoutRef.current;
    observer.observe(node);
    return () => {
      observer.unobserve(node);
    };
  }, [checkoutHeight]);

  // Calculates the height of the new CodeInputBoxV2
  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      if (entries[0].contentRect.height > 0) {
        // Add additional 10 for padding
        setCodeInputHeight(entries[0].contentRect.height + 10);
      }
    });
    const node = codeInputRef.current;
    observer.observe(node);
    return () => {
      observer.unobserve(node);
    };
  }, [codeInputHeight]);

  // Calculates the height of the minimum order amount
  useEffect(() => {
    setMinimumOrderAmountHeight(minimumOrderAmountRef.current.clientHeight);
  }, [minimumOrderAmountHeight]);

  return (
    <React.Fragment>
      <Box>
        <Box
          className={classes.summaryHeaders}
          paddingLeft={2}
          paddingY={1}
          borderBottom={1}
        >
          <Box display="flex" alignItems="center">
            <Box
              display="flex"
              alignItems="center"
              marginRight={2}
            >
              <Icon icon={ShoppingBag} />
            </Box>
            <AH typography variant="h6" className={classes.fontBold}>
              {t(`consumer.ordering.${menuItemCart.cartType}.cart_summary_heading`)}
              <FormattedMessage
                id="consumer.ordering.cart_button.item_length"
                defaultMessage=" ({count} {item})"
                values={{ count: menuItemCart.selectedMenuItems.length, item: menuItemCart.selectedMenuItems.length === 1 ? 'item' : 'items' }}
              />
            </AH>
          </Box>
          <Button
            aria-label="close_profile"
            onClick={closeShowCartModal}
          >
            <Icon icon={X} size="extra-large" />
          </Button>
        </Box>
        <Box ref={codeInputRef}>
          <AHLevelProvider>
            {isMobile && isMobileOrderDetailsV2Enabled && (
              <Fragment>
                <CodeInputBoxV2
                  className={classes.codeInputBoxContainer}
                  menuItemCart={menuItemCart}
                />
                <Divider className={classNames(classes.divider, classes.containedDivider)} />
              </Fragment>
            )}
          </AHLevelProvider>
        </Box>
      </Box>
      <Box
        // The height will need to be manually adjusted if any additional components are added that will affect the height of the summary
        // 53 = Height of the header with the X
        // 32 = Padding of the checkout button section
        height={`calc(${windowHeight}px - 53px - ${checkoutHeight}px - 32px - ${codeInputHeight}px - ${minimumOrderAmountHeight}px)`}
        overflow="auto"
        display="flex"
        width="100%"
        data-cy="online_ordering_summary"
      >
        {menuItemCart.selectedMenuItems.length === 0 ? (
          <Box margin="auto">
            <Icon className={classes.emptyCart} icon={ShoppingBag} />
            <Typography align="center" className={classes.cartSummaryDescription}>
              <FormattedMessage id="consumer.ordering.cart_empty" defaultMessage="Your bag is empty." />
            </Typography>
            <Typography align="center" className={classes.cartSummaryDescription}>
              <FormattedMessage id="consumer.ordering.cart_empty_add_items" defaultMessage="Please add items on the menu." />
            </Typography>
          </Box>
        ) : (
          <Box className={classes.orderContainer}>
            {menuItemCart.selectedMenuItems.map(selectedMenuItem => (
              <React.Fragment key={selectedMenuItem.id}>
                <OrderSummarySelectedItem
                  isOfferApplied={menuItemCart.selectedOrderingOfferCode && orderingOfferApplied(selectedMenuItem, menuItemCart)}
                  selectedMenuItem={selectedMenuItem}
                  editOnClick={() => dispatch(openEditSelectedItemModal(selectedMenuItem.id))}
                  editToolTip={t('consumer.ordering.edit_item_tooltip')}
                  destroyText={t('consumer.ordering.remove_item')}
                  menuItemCart={menuItemCart}
                  fromMenuSummary
                />
                <Divider className={classes.divider} />
              </React.Fragment>
            ))}
          </Box>
        )}
      </Box>
      <Box ref={minimumOrderAmountRef} id="wtf">
        <MinimumSubtotalMessage menuItemCart={menuItemCart} />
      </Box>
      <Box
        className={classes.modalButtons}
        ref={checkoutRef}
      >
        <Button
          fullWidth
          color="inherit"
          onClick={onGoToMenuButtonClick}
          size="large"
          type="submit"
          variant="outlined"
        >
          <FormattedMessage id="consumer.ordering.cart_button.continue_shopping" defaultMessage="Continue Shopping" />
        </Button>
        <Button
          fullWidth
          className={classNames('gtm-checkout-button', classes.menuItemTotal)}
          data-cy="request_my_order"
          color="primary"
          loading={isMenuItemCartValidating || loading}
          disabled={menuItemCart.isSubmitted || menuItemCart.selectedMenuItems.length === 0 || isMenuItemCartValidating}
          onClick={onGoCheckoutPageButtonClick}
          size="large"
          variant="contained"
        >
          <Box>
            <FormattedMessage id="consumer.ordering.place_order" defaultMessage="Checkout" />
          </Box>
          {!!menuItemCart.subtotal && (
            <Box className={classes.subtotal}>
              {menuItemCart.discountAmount > 0 ?
                (
                  <Box className={classes.discountedPriceBox}>
                    <span>{formatCurrency(menuItemCart.subtotal, menuItemCart.location.currency, { showDecimals: true, showSymbol: true })}</span>
                    {formatCurrency((menuItemCart.subtotal - menuItemCart.discountAmount), menuItemCart.location.currency, { showDecimals: true, showSymbol: true })}
                  </Box>
                ) :
                `${formatCurrency(menuItemCart.subtotal, menuItemCart.location.currency, { showDecimals: true, showSymbol: true })}`}
            </Box>
          )}
        </Button>
        {!menuItemCart.orderingEvent && (
          <DeliveryFeeCoveredMessage location={menuItemCart.location} cartType={menuItemCart.cartType} fulfillmentType={menuItemCart.fulfillmentType} />
        )}
        {isFeeEnabled && (
          <Typography className={classes.feeDisclaimer}>
            {t('consumer.ordering.fee_disclaimer')}
          </Typography>
        )}
      </Box>
    </React.Fragment>
  );
};
/* eslint-enable max-lines-per-function */

CartButtonSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  closeShowCartModal: PropTypes.func.isRequired,
  isMobileOrderDetailsV2Enabled: PropTypes.bool,
  onGoToMenuButtonClick: PropTypes.func.isRequired,
};

CartButtonSummary.defaultProps = {
  isMobileOrderDetailsV2Enabled: false,
};

export default compose(
  withIntl,
  withRestaurant,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    isMobileOrderDetailsV2Enabled: restaurant?.featureSetting?.isMobileOrderDetailsV2Enabled,
  })),
)(CartButtonSummary);
