import type { NoResultType, Subscription, UseSSRComputationError } from '@shakacode/use-ssr-computation.runtime';
import { NoResult } from '@shakacode/use-ssr-computation.runtime';
import type { WatchQueryOptions, TypedDocumentNode, OperationVariables, DocumentNode } from '~/lazy_apollo/client';

import { captureSentryMessage } from '../utils/sentry';
import { toQueryName } from '../utils/apollo';
import { getApolloClientWhenCreated, getServerSideApolloClient } from '../utils/apolloClient';
import { isClientSideCypress, isSSR } from '../utils/dom';
import { toFirstUpperCase } from '../utils/strings';
import { jsonEqual } from '../utils/jsonEqual';

export type SSRComputationQueryOptions<QueryType, TVariables extends OperationVariables = OperationVariables> = Omit<WatchQueryOptions<TVariables, QueryType>, 'query'>;

const raiseErrorInDevelopmentEnvironment = (message: string): void => {
  if (process.env.IS_INSTRUMENTED_BUILD && !isClientSideCypress) {
    throw new Error(message);
  }
  captureSentryMessage(message);
};

export const createComputationModuleForQuery = <QueryType, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<QueryType, TVariables>,
) => {
  if (isClientSideCypress) {
    window.__CYPRESS_SSR_COMPUTATION_LOADED = true;
  }

  return {
    compute: (options: SSRComputationQueryOptions<QueryType, TVariables>): QueryType | NoResultType => {
      const { variables } = options;
      if (process.env.IS_INSTRUMENTED_BUILD) {
        const queryName = toQueryName(query);
        console.log(`[POPMENU]: executing compute function of ${queryName}Query`, { variables });
        if (isClientSideCypress && window.__CYPRESS_FAIL_SSR_COMPUTATION_QUERY === queryName) {
          throw new Error(`[Cypress] ${queryName}Query failed`);
        }
      }

      if (!isSSR) {
        return NoResult;
      }

      // TODO: remove isSSR check when we have an eslint rule that enforces that SSRComputation is not used on client side
      const result = isSSR satisfies true && getServerSideApolloClient().cache.readQuery({
        query,
        variables: options.variables,
      });

      if (isSSR && !result) {
        const errorMessage = `[POPMENU]: No result while executing ${toQueryName(query)}Query inside SSRComputation.`;
        raiseErrorInDevelopmentEnvironment(errorMessage);
      }
      return result ?? NoResult;
    },

    subscribe: (
      getCurrentResult: () => QueryType | null,
      next: (result: QueryType | null) => void,
      options: SSRComputationQueryOptions<QueryType, TVariables>,
    ): Subscription => {
      const { variables } = options;
      if (process.env.IS_INSTRUMENTED_BUILD) {
        const queryName = toQueryName(query);
        console.log(`[POPMENU]: executing subscribe function of ${queryName}Query`, { variables });
        if (isClientSideCypress && window.__CYPRESS_FAIL_SSR_COMPUTATION_QUERY === queryName) {
          throw new Error(`[Cypress] ${queryName}Query failed`);
        }
      }

      let isUnsubscribed = false;
      let watchQuerySubscription: Subscription;
      void getApolloClientWhenCreated().then((client) => {
        if (isUnsubscribed) return;
        watchQuerySubscription = client.watchQuery({
          query,
          ...options,
        }).subscribe({
          next: ({ data }) => {
            if (isUnsubscribed) return;
            const currentResult = getCurrentResult();
            if (currentResult !== data && !jsonEqual(currentResult, data)) {
              next(data);
            }
          },
        });
      });

      return {
        unsubscribe: () => {
          isUnsubscribed = true;
          watchQuerySubscription?.unsubscribe();
        },
      };
    },
  };
};

export function addUniqueId<T extends object>(obj: T): T & { uniqueId: string } {
  return {
    ...obj,
    uniqueId: JSON.stringify(obj),
  };
}

export function handleSSRComputationError(error: UseSSRComputationError): void {
  if (process.env.IS_INSTRUMENTED_BUILD) {
    throw error;
  }

  // TODO: capture the error when happen on server side as well
  captureSentryMessage(`SSR Computation Error: ${error.message}. SSRComputation File: ${error.ssrComputationFile}.`);
}

type QueryToPreload<QueryType = unknown, TVariables extends OperationVariables = OperationVariables> = {
  queryPath: string,
  query: DocumentNode | TypedDocumentNode<QueryType, TVariables>,
  variables: TVariables,
  data: QueryType,
  addToSSRComputationCache: boolean,
}
export const addPreloadedQueriesToSSRComputationCache = (queriesToPreload: QueryToPreload[], serverSideMemo: Record<string, unknown>): void => {
  queriesToPreload.forEach((queryToPreload) => {
    if (queryToPreload.addToSSRComputationCache) {
      const optionsString = JSON.stringify({ variables: queryToPreload.variables });
      const ssrComputationFileName = `${toFirstUpperCase(queryToPreload.queryPath.split('/').pop() ?? '')}.ssr_computation`;
      const ssrComputationFilePath = `app/javascript/lazy_apollo/${ssrComputationFileName}`;
      const cacheKey = `${ssrComputationFilePath}::${optionsString}`;
      // eslint-disable-next-line no-param-reassign
      serverSideMemo[cacheKey] = {
        isSubscription: true,
        result: queryToPreload.data,
      };
    }
  });
};
