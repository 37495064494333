import React from 'react';

export const WaitlistLine = () => {
  const placeholder = 'Waitlist next in line';

  return (
    <div>
      <p>{placeholder}</p>
    </div>
  );
};
