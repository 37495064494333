import type { ModalAction } from './ModalActions';

import {
  CLOSE_FORGOT_MODAL,
  CLOSE_TERMS_MODAL,
  CLOSE_NAV_MODAL,
  CLOSE_PAYMENT_ACCOUNT_MODAL,
  CLOSE_ACCOUNT_LOCATION_MODAL,
  CLOSE_DINING_OPTIONS_MODAL,
  CLOSE_NCR_SILVER_CHARGE_OPTIONS_MODAL,
  CLOSE_ORDER_TYPES_MODAL,
  CLOSE_PROFILE_MODAL,
  CLOSE_SOCIAL_SHARE_MODAL,
  CLOSE_REVENUE_CENTERS_MODAL,
  CLOSE_REVIEW_SECTION_MODAL,
  CLOSE_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL,
  OPEN_FORGOT_MODAL,
  OPEN_TERMS_MODAL,
  OPEN_NAV_MODAL,
  OPEN_PAYMENT_ACCOUNT_MODAL,
  OPEN_ACCOUNT_LOCATION_MODAL,
  OPEN_DINING_OPTIONS_MODAL,
  OPEN_NCR_SILVER_CHARGE_OPTIONS_MODAL,
  OPEN_ORDER_TYPES_MODAL,
  OPEN_PROFILE_MODAL,
  OPEN_SOCIAL_SHARE_MODAL,
  OPEN_REVENUE_CENTERS_MODAL,
  OPEN_REVIEW_SECTION_MODAL,
  OPEN_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL,
  OPEN_WEB_BUILDER_SETTINGS_MODAL,
  CLOSE_WEB_BUILDER_SETTINGS_MODAL,
  OPEN_VIP_V2_MODAL,
  CLOSE_VIP_V2_MODAL,
  OPEN_FOLLOW_PROMPT_V2,
  CLOSE_FOLLOW_PROMPT_V2,
  SET_VIP_DATA,
  OPEN_PROFILE_V2_MODAL,
  CLOSE_PROFILE_V2_MODAL,
  OPEN_USER_EMAIL_CHECK_PROMPT,
  CLOSE_USER_EMAIL_CHECK_PROMPT,
  SET_DID_SUBMIT_EMAIL_WITH_FOLLOW_PROMPT,
  OPEN_PAYMENT_PROVIDER_SELECT_MODAL,
  CLOSE_PAYMENT_PROVIDER_SELECT_MODAL,
  ENABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL,
  DISABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL,
  OPEN_FAVORITE_LOCATION_MODAL,
  CLOSE_FAVORITE_LOCATION_MODAL,
} from './ModalActions';

export interface ModalsState {
  accountId: number | null;
  accountType: string | null;
  calendarEventId: number | null;
  didSubmitEmailWithFollowPrompt: boolean;
  followPromptTrigger: string | null;
  isOnOloPage: boolean | null;
  locationId: number | null;
  menuItemId: number | null;
  setActiveProfilePage: string | null;
  showAccountLocationModal: boolean;
  showDiningOptionsModal: boolean;
  showFavoriteLocationModal: boolean;
  showFollowPromptV2: boolean;
  showForgotModal: boolean;
  showNavModal: boolean;
  showPaymentAccountModal: boolean;
  showProfileModal: boolean;
  showProfileV2Modal: boolean;
  showRevenueCentersModal: boolean;
  showReviewSectionModal: boolean;
  showSignUpModal: boolean;
  showSocialShareModal: boolean;
  showTermsModal: {
    modalType: string | null;
    showModal: boolean;
  };
  showToastAlternativePaymentTypeModal: boolean;
  showUserEmailCheckPrompt: boolean;
  showVipV2Modal: boolean;
  showWebBuilderSettingsModal: boolean;
  squareSellerAccountId: number | null;
  vipData: {
    isMagicLinkPath: boolean;
    isPopPath: boolean;
    isReviewPath: boolean;
    isWaitlistPath: boolean | undefined;
    showSignInForm: boolean;
    userEmail: string | null;
  };
  widenSidePanelSettingsModal: boolean;
}

const initialState: ModalsState = {
  accountId: null,
  accountType: null,
  calendarEventId: null,
  didSubmitEmailWithFollowPrompt: false,
  followPromptTrigger: null,
  isOnOloPage: false,
  locationId: null,
  menuItemId: null,
  setActiveProfilePage: null,
  showAccountLocationModal: false,
  showDiningOptionsModal: false,
  showFavoriteLocationModal: false,
  showFollowPromptV2: false,
  showForgotModal: false,
  showNavModal: false,
  showPaymentAccountModal: false,
  showProfileModal: false,
  showProfileV2Modal: false,
  showRevenueCentersModal: false,
  showReviewSectionModal: false,
  showSignUpModal: false,
  showSocialShareModal: false,
  showTermsModal: {
    modalType: null,
    showModal: false,
  },
  showToastAlternativePaymentTypeModal: false,
  showUserEmailCheckPrompt: false,
  showVipV2Modal: false,
  showWebBuilderSettingsModal: true,
  squareSellerAccountId: null,
  vipData: {
    isMagicLinkPath: false,
    isPopPath: false,
    isReviewPath: false,
    isWaitlistPath: false,
    showSignInForm: false,
    userEmail: null,
  },
  widenSidePanelSettingsModal: false,
};

// CAUTION: performance optimization!
// Follow prompts should be hidden initially for performance reasons (fewer bundles to download).
// But after the page is loaded they should be open by default (e.g. not getting closed when users open other modals).
const defaultState: ModalsState = {
  ...initialState,
  showFollowPromptV2: true,
};

const modals = (state = initialState, action: ModalAction) => {
  switch (action.type) {
    case OPEN_TERMS_MODAL:
      return {
        ...state,
        showTermsModal: {
          modalType: action.modalType,
          showModal: true,
        },
      };

    case CLOSE_TERMS_MODAL:
      return {
        ...state,
        showTermsModal: {
          modalType: null,
          showModal: false,
        },
      };

    case OPEN_FORGOT_MODAL:
      return {
        ...defaultState,
        showForgotModal: true,
      };

    case CLOSE_FORGOT_MODAL:
      return {
        ...defaultState,
        showForgotModal: false,
      };

    case OPEN_NAV_MODAL:
      return {
        ...state,
        showNavModal: true,
      };

    case CLOSE_NAV_MODAL:
      return {
        ...state,
        showNavModal: false,
      };

    case OPEN_PAYMENT_ACCOUNT_MODAL:
      return {
        ...state,
        showPaymentAccountModal: true,
      };

    case CLOSE_PAYMENT_ACCOUNT_MODAL:
      return {
        ...state,
        showPaymentAccountModal: false,
      };

    case OPEN_ACCOUNT_LOCATION_MODAL:
      return {
        ...state,
        accountId: action.accountId,
        accountType: action.accountType,
        showAccountLocationModal: true,
      };

    case CLOSE_ACCOUNT_LOCATION_MODAL:
      return {
        ...state,
        showAccountLocationModal: false,
      };

    case OPEN_DINING_OPTIONS_MODAL:
      return {
        ...state,
        locationId: action.locationId,
        showDiningOptionsModal: true,
      };

    case CLOSE_DINING_OPTIONS_MODAL:
      return {
        ...state,
        showDiningOptionsModal: false,
      };

    case OPEN_REVENUE_CENTERS_MODAL:
      return {
        ...state,
        locationId: action.locationId,
        showRevenueCentersModal: true,
      };

    case CLOSE_REVENUE_CENTERS_MODAL:
      return {
        ...state,
        showRevenueCentersModal: false,
      };

    case OPEN_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL:
      return {
        ...state,
        locationId: action.locationId,
        showToastAlternativePaymentTypeModal: true,
      };

    case CLOSE_TOAST_ALTERNATIVE_PAYMENT_TYPE_MODAL:
      return {
        ...state,
        showToastAlternativePaymentTypeModal: false,
      };

    case OPEN_NCR_SILVER_CHARGE_OPTIONS_MODAL:
      return {
        ...state,
        locationId: action.locationId,
        showNcrSilverChargeOptionsModal: true,
      };

    case CLOSE_NCR_SILVER_CHARGE_OPTIONS_MODAL:
      return {
        ...state,
        showNcrSilverChargeOptionsModal: false,
      };

    case OPEN_PAYMENT_PROVIDER_SELECT_MODAL:
      return {
        ...state,
        // TODO: check if it's needed
        // Before migrating to typescript, this was
        // locationId: action.locationId,
        // After migrating to typescript, it's found that the action doesn't have locationId
        locationId: undefined,
        showPaymentProviderSelectModal: true,
      };

    case CLOSE_PAYMENT_PROVIDER_SELECT_MODAL:
      return {
        ...state,
        showPaymentProviderSelectModal: false,
      };

    case OPEN_ORDER_TYPES_MODAL:
      return {
        ...state,
        cloverAccountId: action.cloverAccountId,
        showOrderTypesModal: true,
      };

    case CLOSE_ORDER_TYPES_MODAL:
      return {
        ...state,
        showOrderTypesModal: false,
      };

    case OPEN_PROFILE_MODAL:
      return {
        ...defaultState,
        showProfileModal: true,
      };

    case CLOSE_PROFILE_MODAL:
      return {
        ...defaultState,
        showProfileModal: false,
      };

    case OPEN_SOCIAL_SHARE_MODAL:
      return {
        ...defaultState,
        showSocialShareModal: true,
        socialShareMessage: action.socialShareMessage,
      };

    case CLOSE_SOCIAL_SHARE_MODAL:
      return {
        ...defaultState,
        showSocialShareModal: false,
      };

    case OPEN_REVIEW_SECTION_MODAL:
      return {
        ...defaultState,
        locationId: action.locationId,
        menuItemId: action.menuItemId,
        showReviewSectionModal: true,
      };

    case CLOSE_REVIEW_SECTION_MODAL:
      return {
        ...defaultState,
        locationId: null,
        menuItemId: null,
        showReviewSectionModal: false,
      };

    case OPEN_WEB_BUILDER_SETTINGS_MODAL:
      return {
        ...state,
        showWebBuilderSettingsModal: true,
      };

    case CLOSE_WEB_BUILDER_SETTINGS_MODAL:
      return {
        ...state,
        showWebBuilderSettingsModal: false,
      };

    case OPEN_VIP_V2_MODAL:
      return {
        ...state,
        isOnOloPage: action.isOnOloPage,
        showVipV2Modal: true,
      };

    case CLOSE_VIP_V2_MODAL:
      return {
        ...defaultState,
        isOnOloPage: false,
        showVipV2Modal: false,
      };

    case OPEN_FOLLOW_PROMPT_V2:
      return {
        ...state,
        showFollowPromptV2: true,
      };

    case CLOSE_FOLLOW_PROMPT_V2:
      return {
        ...defaultState,
        showFollowPromptV2: false,
      };

    case SET_VIP_DATA:
      return {
        ...state,
        vipData: action.vipData,
      };

    case OPEN_PROFILE_V2_MODAL:
      return {
        ...defaultState,
        setActiveProfilePage: action.setActiveProfilePage,
        showProfileV2Modal: true,
      };

    case CLOSE_PROFILE_V2_MODAL:
      return {
        ...defaultState,
        setActiveProfilePage: null,
        showProfileV2Modal: false,
      };

    case OPEN_USER_EMAIL_CHECK_PROMPT:
      return {
        ...defaultState,
        showUserEmailCheckPrompt: true,
      };

    case CLOSE_USER_EMAIL_CHECK_PROMPT:
      return {
        ...defaultState,
        showUserEmailCheckPrompt: false,
      };

    case SET_DID_SUBMIT_EMAIL_WITH_FOLLOW_PROMPT:
      return {
        ...state,
        didSubmitEmailWithFollowPrompt: action.didSubmitEmailWithFollowPrompt,
      };

    case ENABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL:
      return {
        ...state,
        widenSidePanelSettingsModal: true,
      };

    case DISABLE_WIDEN_SIDE_PANEL_SETTINGS_MODAL:
      return {
        ...state,
        widenSidePanelSettingsModal: false,
      };

    case OPEN_FAVORITE_LOCATION_MODAL:
      return {
        ...defaultState,
        showFavoriteLocationModal: true,
      };

    case CLOSE_FAVORITE_LOCATION_MODAL:
      return {
        ...defaultState,
        showFavoriteLocationModal: false,
      };

    default:
      return state;
  }
};

export default modals;
