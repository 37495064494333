import { readableFontColor } from '../../utils/colors';

export default theme => ({
  actionBarButton: (props) => {
    const backgroundColor = props?.restaurant?.actionBar?.backgroundColor || theme.palette.primary.main;
    return {
      '&:first-child': {
        flexGrow: '1',
      },
      '&:nth-child(2)': {
        borderLeft: `1px solid ${readableFontColor(backgroundColor, theme.palette.text.primary)}`,
        flexGrow: '1',
      },
      '&:nth-child(3)': {
        borderLeft: `1px solid ${readableFontColor(backgroundColor, theme.palette.text.primary)}`,
        flexGrow: '1',
      },
      '&:only-child': {
        borderRadius: theme.spacing(6),
        width: '100%',
      },
      height: '40px',
      lineHeight: '40px',
      wordBreak: 'break-word',
    };
  },
  actionBarContainer: (props) => {
    const backgroundColor = props?.restaurant?.actionBar?.backgroundColor || theme.palette.primary.main;
    return {
      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        backgroundColor,
        borderRadius: theme.spacing(6),
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        justifyContent: 'space-evenly',
        margin: '0 auto',
        textAlign: 'center',
        width: '96%',
      },
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    };
  },
  actionBarLink: (props) => {
    const backgroundColor = props?.restaurant?.actionBar?.backgroundColor || theme.palette.primary.main;
    const textColor = readableFontColor(backgroundColor, theme.palette.text.primary);
    return {
      '&:hover': {
        color: textColor,
      },
      '&:visited': {
        color: textColor,
      },
      color: textColor,
      display: 'block',
      height: '100%',
      textDecoration: 'none',
      width: '100%',
    };
  },
  actionBarOuterContainer: () => (
    {
      [theme.breakpoints.down('xs')]: {
        bottom: '8px',
        height: '44px',
        left: 0,
        position: 'sticky',
        width: '100%',
        zIndex: 999,
      },
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    }
  ),
});
