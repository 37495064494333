export const SET_ORDERING_OFFER_CODE = 'SET_ORDERING_OFFER_CODE';
export const SET_PROFILE_EVENT_TRACKING = 'SET_PROFILE_EVENT_TRACKING';
export const SET_GIFT_CARD_CODE = 'SET_GIFT_CARD_CODE';
export const SET_ORDERING_EVENT_ID = 'SET_ORDERING_EVENT_ID';
export const SET_IS_PREVIEW = 'SET_IS_PREVIEW';
export const SET_GIFT_CARD_ID = 'SET_GIFT_CARD_ID';
export const SET_SHOW_MOBILE_CART_MODAL = 'SET_SHOW_MOBILE_CART_MODAL';
export const SET_ACTIVE_CART_LOCATION_SLUG = 'SET_ACTIVE_CART_LOCATION_SLUG';
export const SET_ORDER_EDIT_MODAL_HAS_SHOWN = 'SET_ORDER_EDIT_MODAL_HAS_SHOWN';
export const SET_ACTIVE_SECTION = 'SET_ACTIVE_SECTION';

export function setOrderingOfferCode(orderingOfferCode) {
  return (dispatch) => {
    dispatch({ orderingOfferCode, type: SET_ORDERING_OFFER_CODE });
  };
}

export function setGiftCardCode(giftCardCode) {
  return (dispatch) => {
    dispatch({ giftCardCode, type: SET_GIFT_CARD_CODE });
  };
}

export function setOrderingEventId(orderingEventId) {
  return (dispatch) => {
    dispatch({ orderingEventId, type: SET_ORDERING_EVENT_ID });
  };
}
export function setIsPreview() {
  return (dispatch) => {
    dispatch({ type: SET_IS_PREVIEW });
  };
}
export function setGiftCardId(giftCardId) {
  return (dispatch) => {
    dispatch({ giftCardId, type: SET_GIFT_CARD_ID });
  };
}

export function setShowMobileCartModal(showMobileCartModal) {
  return (dispatch) => {
    dispatch({ showMobileCartModal, type: SET_SHOW_MOBILE_CART_MODAL });
  };
}

export function setActiveCartLocationSlug(activeCartLocationSlug) {
  return (dispatch) => {
    dispatch({ activeCartLocationSlug, type: SET_ACTIVE_CART_LOCATION_SLUG });
  };
}

export function setProfileEventTracking(profileEventTracking) {
  return (dispatch) => {
    dispatch({ profileEventTracking, type: SET_PROFILE_EVENT_TRACKING });
  };
}

export function setOrderEditModalHasShown(orderEditModalHasShown) {
  return (dispatch) => {
    dispatch({ orderEditModalHasShown, type: SET_ORDER_EDIT_MODAL_HAS_SHOWN });
  };
}

export function setConsumerActiveSection(activeSection) {
  return (dispatch) => {
    dispatch({ activeSection, type: SET_ACTIVE_SECTION });
  };
}
