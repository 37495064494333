import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect, withRouter } from 'react-router-dom';
import { compose, mapProps } from '@shakacode/recompose';

import { createPageView } from '~/utils/eventable';
import { ServerSideQuerySubtle } from '../../utils/apolloOptimizations';
import { withIntl } from '../../utils/withIntl';
import { withRestaurant } from '../../utils/withRestaurant';
import restaurantPageContentQuery from '../../libs/gql/queries/restaurants/restaurantPageContentQuery.gql';

import CustomPage from './CustomPage';
import ErrorContainer from '../shared/ErrorContainer';
import LoadingPage from '../shared/LoadingPage';
import Meta from '../shared/Meta';
import NotFoundContainer from '../shared/NotFoundContainer';
import Query from '../../shared/Query';
import { useDraftMode } from '../../utils/withDraftMode';

const CustomPagePage = (props) => {
  const analyticsSaved = useRef(false);
  const draftMode = useDraftMode();

  useEffect(() => {
    // Attach to snowplow page tracking
    const slug = props.location.pathname.replace(/\\|\//g, '');
    if (slug && props.restaurantId && !analyticsSaved.current) {
      createPageView([{
        data: {
          restaurantId: props.restaurantId,
          slug,
        },
        schema: 'iglu:com.popmenu/custom_page/jsonschema/1-0-1',
      }]);
      analyticsSaved.current = true;
    }
  }, [props, analyticsSaved]);

  // Handle invalid path
  if (!props.location.pathname) {
    return <NotFoundContainer />;
  }
  // Always redirect from legacy /pages URLs
  if (props.location.pathname.indexOf('/pages/') === 0) {
    return <Redirect to={props.location.pathname.replace('/pages/', '/')} />;
  }
  // Always redirect to lowercase URL
  if (props.location.pathname !== props.location.pathname.toLowerCase()) {
    return <Redirect to={props.location.pathname.toLowerCase()} />;
  }

  return (
    <Query
      QueryComponent={ServerSideQuerySubtle}
      query={restaurantPageContentQuery}
      variables={{
        draftMode,
        restaurantId: props.restaurantId,
        url: props.location.pathname,
      }}
    >
      {({ data, error, loading }) => {
        if (loading) {
          return <LoadingPage />;
        }
        // Display Not Found page if no matching custom pages/redirects were found
        if (!data || !data.restaurant || !data.restaurant.pageContent) {
          if (error) {
            // Display No Internet page is user does not have a connection
            if (typeof navigator !== 'undefined' && typeof navigator.onLine !== 'undefined' && !navigator.onLine) {
              return (
                <NotFoundContainer
                  body={props.t('consumer.offline.body')}
                  heading={props.t('consumer.offline.heading')}
                />
              );
            }
            // Display Error page if there was a server error
            return <ErrorContainer />;
          }
          return <NotFoundContainer />;
        }
        const { pageContent } = data.restaurant;

        // Render redirect
        if (pageContent.__typename === 'RestaurantCustomRedirect') {
          return <Redirect to={pageContent.redirectUrl} />;
        }
        // Render custom page
        if (pageContent.__typename.startsWith('CustomPage')) {
          return (
            <React.Fragment>
              <Meta
                canonicalUrl={pageContent.customCanonicalUrl || pageContent.url}
                description={pageContent.metaDescription}
                imageUrl={pageContent.metaImageUrl}
                keywords={pageContent.metaKeywords}
                title={pageContent.customMetaTitle}
              />
              <CustomPage {...pageContent} />
            </React.Fragment>
          );
        }
        return <NotFoundContainer />;
      }}
    </Query>
  );
};

CustomPagePage.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  restaurantId: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withRestaurant,
  mapProps(({ restaurant, ...props }) => ({
    ...props,
    restaurantId: restaurant.id,
  })),
  withRouter,
  withIntl,
)(CustomPagePage);
