import type { DocumentNode } from 'graphql';
import React from 'react';
import type { ApolloError } from '~/lazy_apollo/client';
import { Mutation as ApolloMutation, type MutationComponentOptions as ApolloMutationProps } from '~/lazy_apollo/client/react/components';

import { toQueryName } from '../utils/apollo';
import { useSnackbar } from '../utils/withSnackbar';

type MutationProps<TData, TVariables> = Omit<ApolloMutationProps<TData, TVariables>, 'refetchQueries'> & {
  refetchQueries?: DocumentNode | DocumentNode[];
};

const Mutation = <TData, TVariables>({
  awaitRefetchQueries = true,
  mutation,
  refetchQueries = [],
  ...props
}: MutationProps<TData, TVariables>) => {
  const { showSnackbarError } = useSnackbar();

  return (
    <ApolloMutation<TData, TVariables>
      // @ts-expect-error Strange type missing mutation prop (and some others) is inferred for ApolloMutation props
      mutation={mutation}
      onError={(err: ApolloError) => showSnackbarError(err)}
      awaitRefetchQueries={awaitRefetchQueries}
      refetchQueries={() => (
        // Apollo expects an array of strings that are resolved to queries based on matching query names
        (Array.isArray(refetchQueries) ? refetchQueries : [refetchQueries]).filter(Boolean).map(toQueryName)
      )}
      {...props}
    />
  );
};

export default Mutation;
