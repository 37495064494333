
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "advancedFormDefaultName": {
        "id": "advanced_forms.advancedFormDefaultName",
        "defaultMessage": "Untitled"
      },
      "advancedFormRequired": {
        "id": "advanced_forms.advancedFormRequired",
        "defaultMessage": "(Required)"
      },
      "advancedFormSubmit": {
        "id": "advanced_forms.advancedFormSubmit",
        "defaultMessage": "Submit"
      },
      "advancedFormDatePlaceholder": {
        "id": "advanced_forms.datePlaceholder",
        "defaultMessage": "select a date and time",
      },
      "advancedFormFieldFileAttachment": {
        "id": "advanced_forms.file_attachment",
        "defaultMessage": 'File Attachment'
      },
      "advancedFormFieldSelectLocation": {
        "id": "advanced_forms.select_location",
        "defaultMessage": 'Select a location'
      },
      "formsRequired": {
        "id": "forms.required",
        "defaultMessage": "Required"
      },
  }
);
export default defaultMessages;

