import React, { useEffect, useState, useRef, type Dispatch, type SetStateAction } from 'react';

import { Box } from '@popmenu/common-ui';
import { classNames, makeStyles } from '../../../utils/withStyles';
import styles from './styles';

import MoreOverflowLink from './MoreOverflowLink';

const toObjectKey = (key: number | string | symbol | null | undefined) => {
  if (typeof key === 'undefined') return 'undefined';
  if (key === null) return 'null';
  return key;
};

// WARNING: Be wary of SSR conflicts on legacy browsers when refactoring. Use useEffect to avoid SSR issues.
export const INTERSECTION_OBSERVER_SUPPORTED = (
  typeof window !== 'undefined' &&
  typeof window.IntersectionObserver !== 'undefined' &&
  typeof window.popmenuIsModernBrowser === 'function' &&
  window.popmenuIsModernBrowser()
);

type IntersectionObserverWrapperChildProps = {
  className: string,
  ['data-targetid']: string,
};

type IntersectionObserverWrapperProps = {
  backgroundColor?: string,
  children: React.ReactElement<IntersectionObserverWrapperChildProps>[],
  className?: string,
  isSplit: boolean,
  moreDropdownLinks?: (React.ReactElement | null)[][],
  navItemStyles: () => React.CSSProperties,
  setCollectiveVisibiltyMap: Dispatch<SetStateAction<Record<string, boolean>>>,
  showOverflowSection: boolean,
  vipLinks: {
    id: string,
    link: React.ReactNode,
  }[]
};

const useStyles = makeStyles(styles);

const IntersectionObserverWrapper = ({ children, className = undefined, vipLinks, ...props }: IntersectionObserverWrapperProps) => {
  const classes = useStyles();
  const navRef = useRef<HTMLUListElement | null>(null);
  const [visibilityMap, setVisibilityMap] = useState<Record<string, boolean>>({});

  const handleIntersection: IntersectionObserverCallback = (entries) => {
    const updatedEntries: Record<string, boolean> = {};
    entries.forEach((entry) => {
      if (!(entry.target instanceof HTMLElement)) return;
      const { targetid } = entry.target.dataset;
      if (!targetid) return;

      // Check if element is visible within container
      if (entry.isIntersecting) {
        updatedEntries[targetid] = true;
      } else {
        updatedEntries[targetid] = false;
      }
    });

    // Overwrite previous state values with current state
    setVisibilityMap(prev => ({
      ...prev,
      ...updatedEntries,
    }));
    props.setCollectiveVisibiltyMap(prev => ({
      ...prev,
      ...updatedEntries,
    }));
  };

  useEffect(() => {
    const lastChild = children.length === 0 ? null : children[children.length - 1];
    if (!lastChild) {
      return;
    }
    setVisibilityMap(prev => ({
      ...prev,
      [toObjectKey(lastChild.key)]: true,
    }));
    props.setCollectiveVisibiltyMap(prev => ({
      ...prev,
      [toObjectKey(lastChild.key)]: true,
    }));
  }, [children.length]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!INTERSECTION_OBSERVER_SUPPORTED) {
      return () => null;
    }
    const observer = new IntersectionObserver(handleIntersection,
      {
        root: navRef.current,
        threshold: 0.9,
      },
    );
    // We are adding observers to child elements of the container div
    // with ref as navRef. Notice that we are adding observers
    // only if we have the data attribute targetid on the child element
    Array.from(navRef.current?.children ?? []).forEach((item) => {
      if (item instanceof HTMLElement && item.dataset.targetid) {
        observer.observe(item);
      }
    });
    return () => observer.disconnect();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const childLinks = React.Children.map(children, (child: typeof children[number]) => React.cloneElement(child, {
    className: classNames(child.props.className, {
      [classes.visible]: !!visibilityMap[child.props['data-targetid']],
      [classes.inVisible]: !visibilityMap[child.props['data-targetid']],
    }),
  }));

  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-end">
      <ul
        className={classNames(className,
          props.isSplit ? classes.toolbarWrapperLogoCenter : null,
          props.isSplit || (!props.isSplit && props.showOverflowSection) ? classes.navList : null,
        )}
        ref={navRef}
        data-cy={'main_nav_links'}
        style={props.showOverflowSection && props.isSplit ? { flexDirection: 'row-reverse' } : undefined}
      >
        {props.showOverflowSection && props.isSplit ? (
          <React.Fragment>
            {
              vipLinks.map(({ id, link }) => (
                <li key={id}>
                  {link}
                </li>
              ))
            }
            <MoreOverflowLink
              className={className}
              isSplit={props.isSplit}
              moreDropdownLinks={props.moreDropdownLinks}
              navItemStyles={props.navItemStyles}
            />
            {childLinks.reverse()}
          </React.Fragment>
        ) : childLinks}
      </ul>
      {props.showOverflowSection && !props.isSplit && (
        <ul className={classNames(className, classes.nav)}>
          <MoreOverflowLink
            className={className}
            isSplit={props.isSplit}
            moreDropdownLinks={props.moreDropdownLinks}
            navItemStyles={props.navItemStyles}
          />
          {
            vipLinks.map(({ id, link }) => (
              <li key={id}>
                {link}
              </li>
            ))
          }
        </ul>
      )}
    </Box>
  );
};

export default IntersectionObserverWrapper;
