
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "sessionsAccessCode": {
      "id": "sessions.access_code",
      "defaultMessage": "Access Code"
    },
    "sessionsAccessCodeHeader": {
      "id": "sessions.access_code_header",
      "defaultMessage": "Enter Access Code"
    },
    "sessionsAccessCodeResendButton": {
      "id": "sessions.access_code_resend_button",
      "defaultMessage": "Send code again"
    },
    "sessionsAccessCodeSubheader": {
      "id": "sessions.access_code_subheader",
      "defaultMessage": "We just sent an access code to your phone number"
    },
    "sessionsForgot": {
      "id": "sessions.forgot",
      "defaultMessage": "Forgot password?"
    },
    "sessionsLogInTo": {
      "id": "sesions.log_in_to",
      "defaultMessage": "Log In to"
    },
    "sessionsSignUpTitle": {
      "id": "sessions.sign_up_title",
      "defaultMessage": "Become a VIP"
    },
    "sessionFooterText": {
      "id": "sessions.footer_text",
      "defaultMessage": "Powered By"
    },
    "sessionsForgotSend": {
      "id": "sessions.forgot_send",
      "defaultMessage": "Send reset link"
    },
    "sessionsMagicLinkHeader": {
      "id": "sessions.magic_link_header",
      "defaultMessage": "Check your email"
    },
    "sessionsMagicLinkSubheader": {
      "id": "sessions.magic_link_subheader",
      "defaultMessage": "Just sent a magic link to your email."
    },
    "sessionsMagicLinkSubheaderInfo": {
      "id": "sessions.magic_link_subheader_info",
      "defaultMessage": "The magic link will sign you in."
    },
    "sessionsNoAccount": {
      "id": "sessions.no_account",
      "defaultMessage": "Don't have an account?"
    },
    "sessionsSave": {
      "id": "sessions.save",
      "defaultMessage": "Save"
    },
    "sessionsSkipForNow": {
      "id": "sessions.skip_for_now",
      "defaultMessage": "Skip for now"
    },
    "sessionsSignUp": {
      "id": "sessions.sign_up",
      "defaultMessage": "Sign Up"
    },
    "sessionsSignUpV2Header": {
      "id": "sessions.sign_up_v2_header",
      "defaultMessage": "You're almost there..."
    },
    "sessionsBecomeAVipHeader": {
      "id": "sessions.become_a_vip_header",
      "defaultMessage": "Become a VIP"
    },
    "sessionsSignUpV2Subheader": {
      "id": "sessions.sign_up_v2_subheader",
      "defaultMessage": "A few more questions to help better communicate to you"
    },
    "sessionsBecomeAVipSubheader": {
      "id": "sessions.become_a_vip_subheader",
      "defaultMessage": "Receive updates on special events, offers, new menu items, menu reviews, and more!"
    },
    "sessionsMagicModalSubTitle": {
      "id": "sessions.magic_modal_subtitle",
      "defaultMessage": "Verify your account faster next time!"
    },
    "sessionsMagicModalSubtitleMessage": {
      "id": "sessions.magic_modal_subtitle_message",
      "defaultMessage": "(also receive offers, discounts, loyalty rewards and more, directly to your phone)"
    },
    "sessionsSignIn": {
      "id": "sessions.sign_in",
      "defaultMessage": "Sign In"
    },
    "sessionsSignInV2VerifiedTitle": {
      "id": "sessions.sign_in_v2_verified_title",
      "defaultMessage": "Verified!"
    },
    "sessionsSignInV2VerifiedSubtitle": {
      "id": "sessions.sign_in_v2_verified_subtitle",
      "defaultMessage": "You're logged into"
    },
    "sessionsSignInV2VerifiedButton": {
      "id": "sessions.sign_in_v2_verified_button",
      "defaultMessage": "Not Right Now"
    },
    "sessionsOk": {
      "id": "sessions.ok",
      "defaultMessage": "Ok"
    },
    "sessionsOmniauthFacebook": {
      "id": "sessions.omniauth.facebook",
      "defaultMessage": "Continue with Facebook"
    },
    "sessionsOmniauthFacebookType": {
      "id": "sessions.omniauth.facebook_type",
      "defaultMessage": "Continue with Facebook"
    },
    "sessionsOmniauthFacebookIcon": {
      "id": "sessions.omniauth.facebook_icon",
      "defaultMessage": "facebook"
    },
    "sessionsOmniauthFacebookConnected": {
      "id": "sessions.omniauth.facebook_connected",
      "defaultMessage": "Connected via Facebook Login"
    },
    "sessionsOmniauthGoogle": {
      "id": "sessions.omniauth.google",
      "defaultMessage": "Continue with Google"
    },
    "sessionsOmniauthGoogleType": {
      "id": "sessions.omniauth.google_type",
      "defaultMessage": "Continue with Google"
    },
    "sessionsOmniauthGoogleIcon": {
      "id": "sessions.omniauth.google_icon",
      "defaultMessage": "google"
    },
    "sessionsOmniauthGoogleConnected": {
      "id": "sessions.omniauth.google_connected",
      "defaultMessage": "Connected via Google Sign-In"
    },
    "sessionsPhoneNumber": {
      "id": "sessions.phone_number",
      "defaultMessage": "Enter Phone Number"
    }

  }
);
export default defaultMessages;

