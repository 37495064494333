import { runAfter } from './postponed';

import restaurantPageContentQuery from '../libs/gql/queries/restaurants/restaurantPageContentQuery.gql';
import menuQuery from '../libs/gql/queries/menus/consumerMenuWithoutSubsectionsQuery.gql';

let additionalStorageKeyParams = {};

let isPrefetchingComplete = false;

export const saveAdditionalStorageKeyParams = (params) => {
  additionalStorageKeyParams = params;
};

const prefetchQuery = (query, variables, queryName) => {
  if (typeof window !== 'undefined' && typeof window.POPMENU_CLIENT !== 'undefined') {
    runAfter('firstUserInteraction', () => {
      // "setTimeout" is used to call each prefetched query code in a seperate task thus not generating long task.
      setTimeout(() => {
        window.POPMENU_CLIENT.query({
          query,
          variables,
        }).then((response) => {
          // Some custom pages result in page reload, so we save prefetched queries on session storage to prevent requesting
          // prefetched queries every time the page reloads and thus, decrease the load of the prefetched queries on the server.
          const storageKey = `${queryName}_${JSON.stringify(variables)}_${JSON.stringify(additionalStorageKeyParams)}`;
          window.sessionStorage.setItem(storageKey, JSON.stringify(response.data));
        });
      }, 0);
    });
  }
};

const sortCustomPages =
  customPages => [...customPages].sort((customPageA, customPageB) => customPageB.recentVisitsCount - customPageA.recentVisitsCount);

const saveToApolloCache = (data, query, variables) => {
  window.POPMENU_CLIENT.writeQuery({
    data,
    query,
    variables,
  });
};

const extractSessionStorageData = (query, variables, queryName) => {
  if (typeof window === 'undefined') {
    return false;
  }

  const storageKey = `${queryName}_${JSON.stringify(variables)}_${JSON.stringify(additionalStorageKeyParams)}`;
  const queryData = window.sessionStorage.getItem(storageKey);
  if (!queryData) {
    return false;
  }
  // As number of custom pages that reloads on navigation are low then it is less likely that will happen often.
  // So, every prefetched query data is consumed one time from sessionStorage then deleted with all deprecated versions of it.
  saveToApolloCache(JSON.parse(queryData), query, variables);

  const queryGeneralStorageKey = `${queryName}_${JSON.stringify(variables)}`;
  Object.keys(window.sessionStorage).forEach((key) => {
    if (key.indexOf(queryGeneralStorageKey) === 0) {
      sessionStorage.removeItem(key);
    }
  });

  return true;
};

const prefetchCustomPageQueries = (draftMode, restaurantId, customPage) => {
  const restaurantContentQueryVariables = {
    draftMode,
    restaurantId,
    url: customPage.url,
  };
  const menuQueryVariables = {
    menuId: customPage.firstMenuId,
  };

  if (!extractSessionStorageData(restaurantPageContentQuery, restaurantContentQueryVariables, 'restaurantPageContentQuery')) {
    prefetchQuery(restaurantPageContentQuery, restaurantContentQueryVariables, 'restaurantPageContentQuery');
  }
  if (customPage.firstMenuId && !extractSessionStorageData(menuQuery, menuQueryVariables, 'menuQuery')) {
    prefetchQuery(menuQuery, menuQueryVariables, 'menuQuery');
  }
};

export const prefetchPopularPagesData = (draftMode, restaurantId, customPages, currentPage, prefetchedQueriesNumber) => {
  // Ensure prefetching is called only once on the first visit to the restaurant.
  if (isPrefetchingComplete) {
    return;
  }

  const restaurantCustomPages = sortCustomPages(customPages);

  for (let i = 0, prefetchedCount = 0; i < restaurantCustomPages.length && prefetchedCount < prefetchedQueriesNumber; i += 1) {
    // Some pages send a prefetch query even when they should have the data in preloaded queries like home pages.
    // So, we exclude the prefetching of the current visited page that we prefetch from.
    if (currentPage !== restaurantCustomPages[i].url) {
      prefetchedCount += 1;
      // console.log('prefetch request: ', restaurantCustomPages[i].url);
      prefetchCustomPageQueries(draftMode, restaurantId, restaurantCustomPages[i]);
    }
  }

  isPrefetchingComplete = true;
};
