import type { Theme, StyleRules } from '@material-ui/core';

const gridStyles = (theme: Theme) => ({
  container: {
    '&:last-child': {
      marginBottom: 0,
    },
    marginBottom: theme.spacing(2),
  },
  containerInline: {
    alignItems: 'center',
    flexWrap: 'wrap',
    margin: 0,
    whiteSpace: 'nowrap',
    width: '100%',
  },
  containerInlineNoWrap: {
    flexWrap: 'nowrap',
  },
} as const satisfies StyleRules);

export default gridStyles;
