import type { FormatCurrencyOptions } from 'format-currency';
import currencyFormatter from 'format-currency';

import { round } from './numbers';

const toValidCurrency = (currency: Optional<string>) => (currency || 'USD').toUpperCase();

export const currencySymbol = (currency: Optional<string>): string => {
  // Type specified to guarantee USD is a valid key
  const symbols: { USD: string, [currency: string]: string } = {
    AED: 'AED',
    AUD: '$',
    EUR: '€',
    GBP: '£',
    INR: '₹',
    KRW: '₩',
    MXN: '$',
    QAR: 'QR',
    USD: '$',
  };

  return symbols[toValidCurrency(currency)] ?? symbols.USD;
};

export const abbreviateNumber = (value: number, decimalPlaces: number = 1) => {
  if (value >= 1000000) {
    return `${(value / 1000000).toFixed(decimalPlaces)}M`;
  } else if (value >= 100000) {
    return `${(value / 1000).toFixed(0)}k`;
  } else if (value >= 10000) {
    return `${(value / 1000).toFixed(decimalPlaces)}k`;
  } else {
    return value.toString();
  }
};

export const abbreviateFormattedValue = (formattedValue: string, decimalPlaces: number = 1) => {
  const numericPart = formattedValue.replace(/[^\d.,]/g, '');
  const numbericValue = parseFloat(numericPart.replace(/,/g, ''));

  if (!(Number.isNaN(numbericValue))) {
    const abbreviatedValue = abbreviateNumber(numbericValue, decimalPlaces);

    const parts = abbreviatedValue.match(/^([\d.]+)([a-zA-Z]*)$/);
    if (parts && parts[1]) {
      const formattedAbbreviatedValue = parts[1].replace(/\B(?=(\d{3})+(?!\d))/g, ',') + (parts[2] ?? '');
      return formattedValue.replace(numericPart, formattedAbbreviatedValue);
    }
  }

  return '';
};

export const formatCurrency = (
  value: Optional<number>,
  currency?: Optional<string>,
  {
    abbreviate = false,
    decimalPlaces = 1,
    showDecimals = false,
    showSymbol = true,
  }: {
    abbreviate?: boolean;
    decimalPlaces?: number;
    showDecimals?: boolean;
    showSymbol?: boolean;
  } = {},
) => {
  if (typeof value === 'number') {
    let options: FormatCurrencyOptions;

    // Round to two decimal places
    const roundedValue = round(value, 2);
    if (roundedValue == null) {
      return null;
    }
    // TODO: why not Number.isInteger?
    const valueIsInt = roundedValue === parseInt(String(roundedValue), 10);
    const valueIsZero = roundedValue === 0;
    const defaultFractionDigits = (showDecimals || valueIsZero || !valueIsInt) ? 2 : 0;
    const validCurrency = toValidCurrency(currency);
    const symbol = showSymbol ? currencySymbol(validCurrency) : '';

    switch (validCurrency) {
      case 'AED':
        options = {
          code: 'AED',
          format: '%v %s',
          locale: 'en-US',
          maximumFractionDigits: defaultFractionDigits,
          minimumFractionDigits: defaultFractionDigits,
          symbol,
        };
        break;
      case 'AUD':
        options = {
          code: 'AUD',
          format: '%s%v',
          locale: 'en-AU',
          maximumFractionDigits: defaultFractionDigits,
          minimumFractionDigits: defaultFractionDigits,
          symbol,
        };
        break;
      case 'GBP':
        options = {
          code: 'GBP',
          format: '%s%v',
          locale: 'en-GB',
          maximumFractionDigits: defaultFractionDigits,
          minimumFractionDigits: defaultFractionDigits,
          symbol,
        };
        break;
      case 'MXN':
        options = {
          code: 'MXN',
          format: '%s%v',
          locale: 'es-MX',
          maximumFractionDigits: defaultFractionDigits,
          minimumFractionDigits: defaultFractionDigits,
          symbol,
        };
        break;
      case 'INR':
        options = {
          code: 'INR',
          format: '%s%v',
          locale: 'hi',
          maximumFractionDigits: defaultFractionDigits,
          minimumFractionDigits: defaultFractionDigits,
          symbol,
        };
        break;
      case 'KRW':
        options = {
          code: 'KRW',
          format: '%s%v',
          locale: 'ko-KR',
          maximumFractionDigits: 0,
          minimumFractionDigits: 0,
          symbol,
        };
        break;
      case 'QAR':
        options = {
          code: 'QAR',
          format: '%v %s',
          locale: 'en-US',
          maximumFractionDigits: defaultFractionDigits,
          minimumFractionDigits: defaultFractionDigits,
          symbol,
        };
        break;
      case 'USD':
      default:
        options = {
          code: 'USD',
          format: '%s%v',
          locale: 'en-US',
          maximumFractionDigits: defaultFractionDigits,
          minimumFractionDigits: defaultFractionDigits,
          symbol,
        };
        break;
    }
    let formattedValue = currencyFormatter(roundedValue, options);

    if (abbreviate) {
      formattedValue = abbreviateFormattedValue(formattedValue, decimalPlaces);
    }

    return formattedValue;
  }
  return null;
};
