export const CLOSE_UPGRADE_REQUEST_MODAL = 'CLOSE_UPGRADE_REQUEST_MODAL';
export const OPEN_UPGRADE_REQUEST_MODAL = 'OPEN_UPGRADE_REQUEST_MODAL';

export function closeUpgradeRequestModal() {
  return (dispatch) => {
    dispatch({ type: CLOSE_UPGRADE_REQUEST_MODAL });
  };
}

export function openUpgradeRequestModal(requestType) {
  return (dispatch) => {
    dispatch({
      requestType,
      type: OPEN_UPGRADE_REQUEST_MODAL,
    });
  };
}
