import { toBackgroundStyle } from '../../../utils/styles';
import { buildResponsiveCssUrls } from '../../../utils/responsiveImages';

export default theme => ({
  background: (props) => {
    // Build basic background styles
    const backgroundColor = props.backgroundColor || props.restaurant.theme.defaultCustomSectionBgColor || 'unset';
    const backgroundStyles = toBackgroundStyle({ backgroundColor, backgroundEffect: props.backgroundEffect, backgroundImageUrl: props.backgroundImageUrl });

    // Build a separate media query for each image resolution
    const backgroundImageStyles = props.backgroundImageUrl ? buildResponsiveCssUrls('background-image', props.cloudflareBaseUrl, props.backgroundImageUrl, { size: 'lg' }) : { backgroundImage: 'unset' };
    return {
      '&.pm-custom-section': {
        ...backgroundStyles,
        ...backgroundImageStyles,
        [theme.breakpoints.down('sm')]: {
          backgroundAttachment: 'scroll',
        },
        backgroundAttachment: props.backgroundImageSetting,
      },
    };
  },
  fullWidth: (props) => {
    if (props.sectionType === 'menu_section' || props.sectionType === 'online_ordering_section') {
      return {
        [theme.breakpoints.down('md')]: {
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
        padding: 0,
      };
    }
    return {
      padding: 0,
    };
  },
  sectionContainer: ({ fontColor }) => {
    if (fontColor) {
      return {
        color: fontColor,
      };
    }
    return {};
  },
});
