import { useEffect } from 'react';

export const useScrollEventListener = (callback?: () => void, intervalMs = 300) => {
  useEffect(() => {
    if (!callback) return () => null;

    window.addEventListener('scroll', callback);
    window.addEventListener('resize', callback);
    // In some cases, an element can emerge in the view not because of scrolling but because of resizing of other components.
    let timeoutId : NodeJS.Timeout | undefined;
    const intervalCallback = () => {
      callback();
      timeoutId = setTimeout(intervalCallback, intervalMs);
    };
    intervalCallback();

    return () => {
      window.removeEventListener('scroll', callback);
      window.removeEventListener('resize', callback);
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [callback, intervalMs]);
};
