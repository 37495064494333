export const orderingOfferApplied = (menuItem, menuItemCart) => {
  if (menuItemCart.selectedOrderingOfferCode.orderingOfferCode.orderingOffer.orderingOfferBxgyDetail) {
    if (menuItem.offerId) {
      return true;
    } else {
      return false;
    }
  }
  switch (menuItemCart.selectedOrderingOfferCode.offerType) {
    case 'percentage_off_offer_type':
    case 'dollar_off_offer_type':
      if (menuItemCart.subtotal >= menuItemCart.selectedOrderingOfferCode.orderingOfferCode.orderingOfferMinimumOrderAmount) {
        return true;
      }
      break;
    case 'partial_order_percentage_off_offer_type':
    case 'partial_order_dollar_off_offer_type':
      if (menuItemCart.subtotal >= menuItemCart.selectedOrderingOfferCode.orderingOfferCode.orderingOfferMinimumOrderAmount) {
        if ((menuItemCart.selectedOrderingOfferCode.orderingOfferCode.orderingOffer.menuId === menuItem.menuItem.menu.masterMenuId && menuItem.menuItem.menu.masterMenuId) ||
          (menuItemCart.selectedOrderingOfferCode.orderingOfferCode.orderingOffer.sectionId === menuItem.menuItem.section.masterSectionId && menuItem.menuItem.section.masterSectionId)) {
          return true;
        }
        if (menuItemCart.selectedOrderingOfferCode.orderingOfferCode.orderingOffer.id === menuItem.offerId) {
          return true;
        }
      }
      break;
    default:
      return false;
  }
  return false;
};

export const orderingOfferType = (orderingOfferCode) => {
  switch (orderingOfferCode.orderingOffer.offerType) {
    case 'partial_order_percentage_off_offer_type':
    case 'partial_order_dollar_off_offer_type':
      if (orderingOfferCode.orderingOffer.menuId) {
        return (`items in the ${orderingOfferCode.orderingOffer.offerAppliedTo} menu only`);
      }
      if (orderingOfferCode.orderingOffer.sectionId) {
        return (`items in the ${orderingOfferCode.orderingOffer.offerAppliedTo} only`);
      }
      if (orderingOfferCode.orderingOffer.dishId) {
        return (`${orderingOfferCode.orderingOffer.offerAppliedTo} only`);
      }
      break;
    default:
      return ('all items');
  }
  return ('all items');
};
