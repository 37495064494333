import PropTypes from 'prop-types';
import React, { memo, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { compose } from '@shakacode/recompose';
import { useSSRComputation } from '@shakacode/use-ssr-computation.macro';

import { addUniqueId } from '../../lazy_apollo/ssrComputationUtils';
import { readableFontColor } from '../../utils/colors';
import { withIntl } from '../../utils/withIntl';
import { withRestaurant } from '../../utils/withRestaurant';
import { openAnnouncementBanner } from '../../shared/AnnouncementsActions';

import AnnouncementContent from './AnnouncementContent';

// returns first inline announcement for location or all locations
const getAnnouncement = (announcements, locationId, menuId) => {
  if (locationId && menuId) {
    // display the first 'display_above_menu' enabled announcement with matching menu and location IDs
    return announcements.find(announcement => (
      announcement.displayType === 'display_above_menu' && menuId && locationId &&
      announcement.announcementLocations.some(loc => loc.locationId === locationId && loc.isEnabled)),
    );
  }
  // display the first 'display_page_section' announcement found
  return announcements.find(announcement => announcement.displayType === 'display_page_section');
};

const Announcement = (props) => {
  useEffect(() => props.openAnnouncementBanner(props.locationId, props.menuId), [props]);
  const announcementsOptions = useMemo(() => addUniqueId({ variables: { restaurantId: props.restaurant.id } }), [props.restaurant.id]);
  const data = useSSRComputation('../../lazy_apollo/RestaurantAllFeaturedAnnouncementsQuery.ssr-computation', [announcementsOptions], {});

  if (!data || !data.restaurantAllFeaturedAnnouncements) {
    return null;
  }

  const announcements = data.restaurantAllFeaturedAnnouncements;
  // NOTE: There's never a location for custom page sections, thus they will only display announcements intended
  // for all locations
  const announcement = getAnnouncement(announcements, props.locationId, props.menuId);
  if (!announcement) {
    return null;
  }

  const style = {
    backgroundColor: props.restaurant.theme.announcementBgColor,
    border: props.restaurant.theme.announcementBorderColor ? `5px solid ${props.restaurant.theme.announcementBorderColor}` : 'none',
    color: props.restaurant.theme.announcementBgColor ? readableFontColor(props.restaurant.theme.announcementBgColor) : null,
  };
  return (
    <div id="featured_announcement" className="pm-featured-announcement fr-view pm-announcement-inline" style={style}>
      <AnnouncementContent
        announcement={announcement}
        announcementFontAlignment={props.announcementFontAlignment}
      />
    </div>
  );
};

Announcement.defaultProps = {
  announcementFontAlignment: 'afa_center',
  locationId: null,
  menuId: null,
};

Announcement.propTypes = {
  announcementFontAlignment: PropTypes.string,
  locationId: PropTypes.number,
  menuId: PropTypes.number,
  openAnnouncementBanner: PropTypes.func.isRequired,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
    theme: PropTypes.shape({
      announcementBgColor: PropTypes.string,
      announcementBorderColor: PropTypes.string,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default memo(compose(
  withRestaurant,
  withIntl,
  connect(() => ({}), { openAnnouncementBanner }),
)(Announcement));
