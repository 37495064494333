export interface Coordinates {
  lat: number;
  lng: number;
}

export const isValidCoordinates = (point: unknown): point is Coordinates => (point != null && (point as Coordinates).lat != null && (point as Coordinates).lng != null);

// Calculate distance in miles (the default), kilometers, or nautical miles between two coordinates
// https://www.geodatasource.com/developers/javascript
export const calculateDistance = (point1: Coordinates, point2: Coordinates, unit: 'M' | 'K' | 'N' = 'M') => {
  if (!(isValidCoordinates(point1) && isValidCoordinates(point2))) {
    return null;
  }
  if ((point1.lat === point2.lat) && (point1.lng === point2.lng)) {
    return 0;
  }
  const radLat1 = Math.PI * point1.lat / 180;
  const radLat2 = Math.PI * point2.lat / 180;
  const theta = point1.lng - point2.lng;
  const radTheta = Math.PI * theta / 180;
  let dist = Math.sin(radLat1) * Math.sin(radLat2) + Math.cos(radLat1) * Math.cos(radLat2) * Math.cos(radTheta);
  if (dist > 1) {
    dist = 1;
  }
  dist = Math.acos(dist);
  dist = dist * 180 / Math.PI;
  dist = dist * 60 * 1.1515;
  if (unit === 'K') {
    dist *= 1.609344;
  }
  if (unit === 'N') {
    dist *= 0.8684;
  }
  return dist;
};
