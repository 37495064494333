import React, { useCallback, useEffect, useRef } from 'react';
import { compose, mapProps } from '@shakacode/recompose';
import { useDispatch } from 'react-redux';
import { useQuery } from '~/lazy_apollo/client';
import { Badge } from '@popmenu/common-ui';
import { createEvent } from '~/utils/eventable';
import ProfileAvatar from '../../profile/external/ProfileAvatar';
import { openProfileV2Modal, openVipV2Modal, setVipData } from '../../../shared/ModalActions';
import { withCurrentSession } from '../../../shared/CurrentSessionProvider';
import guestProfileUserQuery from '../../../libs/gql/queries/users/guestProfileUserQuery.gql';

const NavProfileButton = ({ isMobile, classes, user, restaurant }) => {
  const dispatch = useDispatch();
  const showUserProfile = useCallback(() => {
    dispatch(openProfileV2Modal('my_profile'));
  }, [dispatch]);

  const showUserBecomeVIPModal = useCallback(() => {
    createEvent({
      eventableType: 'BecomeVipV2',
      eventType: 'v2_nav_link',
    });
    dispatch(setVipData());
    dispatch(openVipV2Modal());
  }, [dispatch]);

  const onProfileAvatarClick = useCallback(() => {
    if (user) {
      showUserProfile();
    } else {
      showUserBecomeVIPModal();
    }
  }, [user, showUserProfile, showUserBecomeVIPModal]);

  const badgeRef = useRef();

  const { data } = useQuery(
    guestProfileUserQuery,
    {
      fetchPolicy: 'cache-and-network',
      skip: !user || !restaurant,
      variables: {
        restaurantId: restaurant?.id,
        userId: user?.id,
      },
    },
  );

  useEffect(() => {
    if (badgeRef.current) {
      const spanNode = badgeRef.current.querySelector('span');
      const ariaHidden = spanNode.getAttribute('aria-hidden');
      if (!ariaHidden) {
        spanNode.setAttribute('aria-hidden', true);
      }
    }
  });

  const orderingOfferCodes = data?.guestProfileUser?.orderingOfferCodes || [];
  const orderingOfferCodesCount = orderingOfferCodes.length;

  if (!(isMobile && restaurant.featureSetting.isMobileOrderDetailsV2Enabled)) {
    return null;
  }

  return (
    <Badge
      overlap="circular"
      color="primary"
      className={classes.profileButton}
      badgeContent={orderingOfferCodesCount || undefined}
      ref={badgeRef}
    >
      <ProfileAvatar
        user={user}
        onClick={onProfileAvatarClick}
        notificationCount={orderingOfferCodesCount}
      />
    </Badge>
  );
};

export default compose(
  withCurrentSession,
  mapProps(({ currentSession, ...props }) => ({
    ...props,
    user: currentSession.user || null,
  })),
)(NavProfileButton);
