import type { Theme } from '@material-ui/core/styles';
// In @material-ui/core v5, we should be able to import StyleRules from @material-ui/core/styles as well.
// In v4, that version has the wrong type parameter order.
import type { StyleRules } from '@material-ui/styles';

import { readableFontColor } from '../../../utils/colors';

const modalStyles = (theme: Theme) => {
  const modalPaper = {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100% !important',
      padding: 0,
      width: '100% !important',
    },
    borderRadius: '0.5rem',
    margin: '0 auto !important',
    maxWidth: theme.spacing(50),
    overflow: 'visible',
    padding: theme.spacing(2),
  };
  return {
    centeredDialogTitle: {
      '& h2': {
        alignItems: 'center',
        display: 'flex',
        fontWeight: 'bold',
      },
    },
    closeButton: {
      '& svg': {
        height: '1.5rem',
        width: '1.5rem',
      },
      position: 'absolute',
      right: theme.spacing(0.5),
      top: theme.spacing(0.5),
      zIndex: 3,
    },
    closeButtonDark: {},
    closeButtonPaper: {
      '& svg': {
        backgroundColor: 'white',
        borderRadius: '.75rem',
        height: '1.5rem',
        width: '1.5rem',
      },
      position: 'fixed',
    },
    closeButtonPaperHidden: {
      color: 'white !important',
    },
    closeButtonWrapper: {
      position: 'absolute',
      right: theme.spacing(5.5),
      top: theme.spacing(0.5),
      zIndex: 3,
    },
    dialogActions: {
      '& button': {
        '&:first-child': {
          marginLeft: 0,
        },
        marginLeft: theme.spacing(1),
      },
      margin: 0,
      padding: theme.spacing(0.5),
    },
    dialogContent: props => ({
      backgroundColor: props.backgroundColor || undefined,
      borderRadius: '0.5rem',
      color: readableFontColor(props.backgroundColor || theme.palette.surface.main),
      overflow: 'visible',
    }),
    dialogContentZeroSpacing: {
      '&:first-child': {
        padding: 0,
      },
      padding: 0,
    },
    dialogTitle: {
      '& h2': {
        fontWeight: 'bold',
      },
    },
    dialogTitleIcon: {
      marginRight: theme.spacing(1),
    },
    'modal-paper-lg': {
      ...modalPaper,
      maxWidth: modalPaper.maxWidth * 3,
    },
    'modal-paper-md': {
      ...modalPaper,
      maxWidth: modalPaper.maxWidth * 2,
    },
    'modal-paper-sm': {
      ...modalPaper,
      padding: '2px !important',
    },
    'modal-paper-xl': {
      ...modalPaper,
      maxWidth: 'none',
    },
    modalBackdrop: {
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
    modalBottomCard: {
      bottom: 0,
      left: 0,
      margin: theme.spacing(3, 0, 0, 0),
      padding: theme.spacing(1, 4),
      position: 'fixed',
      right: 0,
      zIndex: 10,
    },
    modalPaperDark: {
      backgroundColor: '#2E3042',
      color: 'white',
    },
    modalPaperFullscreen: props => ({
      backgroundColor: props.backgroundColor,
      borderRadius: 0,
      margin: '0 auto !important',
      maxWidth: '100% !important',
      minHeight: '100%',
      overflowX: 'hidden',
      width: '100% !important',
    }),
    modalPaperHidden: {
      background: 'none',
      boxShadow: 'none',
    },
    modalPaperMobileSmall: props => ({
      backgroundColor: props.backgroundColor,
      borderRadius: '8px',
      margin: '0 auto !important',
      maxWidth: '75% !important',
      // do not set overflow x to hidden. this will mess up with overflow y
      // https://stackoverflow.com/questions/18135204/setting-overflow-y-causes-overflow-x-to-change-as-well
      // overflowY is visible to show the dropdown over the modal
      overflowY: 'visible',
      width: '75% !important',
    }),
    modalPaperNoBackground: {
      // When BasicModal.backgroundColor is specified, remove the default paper
      background: 'none',
      padding: 0,
    },
    modalRoot: {
      paddingRight: '0 !important',
    },
    modalScrollBody: {
      '-webkit-overflow-scrolling': 'touch',
      overflowY: 'auto',
      padding: theme.spacing(2),
    },
    modalScrollBodyMobileSmall: {
      '-webkit-overflow-scrolling': 'touch',
      overflowY: 'visible',
      padding: theme.spacing(2),
    },
    modalScrollBodyZeroSpacing: {
      margin: 0,
      padding: 0,
    },
  } as const satisfies StyleRules<{ backgroundColor?: string }>;
};

export default modalStyles;
