import React, { useState } from 'react';
import { Icon } from '@popmenu/common-ui';
import { ArrowRight, ChevronDown } from '@popmenu/web-icons';
import PropTypes from 'prop-types';

import { isNotTabKey } from '../../utils/events';

const NavItemsDropdown = ({ linkName, navItem, navItemStyles, noBackground, renderLink, theme }) => {
  const [openDropdown, setOpenDropdown] = useState(false);
  return (
    <div
      className={`a nav-dropdown ${navItem.isFeatured ? 'pm-featured-dropdown' : ''}`}
      onClick={(e) => {
        if (isNotTabKey(e)) {
          setOpenDropdown(value => !value);
        }
      }}
      onKeyUp={() => setOpenDropdown(true)}
      role="button"
      tabIndex="0"
      aria-expanded={openDropdown}
      data-testid={`${navItem.__typename}_${navItem.id}`}
    >
      <span style={navItemStyles}>{linkName}&nbsp;{openDropdown ? <Icon icon={ChevronDown} /> : <Icon icon={ArrowRight} />}</span>
      <React.Fragment>
        { openDropdown && (
          <div
            className="nav-dropdown-links"
            style={{ backgroundColor: noBackground ? null : theme.navbarBgColor }}
          >
            {navItem.navLinks.map(navLink => renderLink(navLink, true))}
          </div>
        ) }
      </React.Fragment>
    </div>
  );
};

NavItemsDropdown.defaultProps = {
  noBackground: false,
};

NavItemsDropdown.propTypes = {
  noBackground: PropTypes.bool,
};

export default NavItemsDropdown;
