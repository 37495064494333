import React from 'react';
import { useFeatureFlags } from './featureFlagsContext';

// for using feature flags in class components
export function withFeatureFlags(Component) {
  return (props) => {
    const { isFeatureActive } = useFeatureFlags();
    return <Component {...props} isFeatureActive={isFeatureActive} />;
  };
}
