
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "profileTitle": {
      "id": "profile.nav_item_my_profile",
      "defaultMessage": "My Profile"
    },
    "profileUploadAvatarPhoto": {
      "id": "profile.avatar.upload_photo",
      "defaultMessage": "Upload Avatar Photo"
    },
    "profileNavItemFavorites": {
      "id": "profile.nav_item_favorites",
      "defaultMessage": "Favorites"
    },
    "profileNavItemGiftcard": {
      "id": "profile.nav_item_gift_card",
      "defaultMessage": "Gift Cards"
    },
    "profileNavItemMyInfo": {
      "id": "profile.nav_item_my_info",
      "defaultMessage": "My Info"
    },
    "profileNavItemOffers": {
      "id": "profile.nav_item_offers",
      "defaultMessage": "Offers"
    },
    "profileNavItemOrderHistory": {
      "id": "profile.nav_item_order_history",
      "defaultMessage": "Order History"
    },
    "profileNavItemPayment": {
      "id": "profile.nav_item_payment",
      "defaultMessage": "Payment"
    },
    "profileOffersCheckFloatingButton": {
      "id": "profile.offers_check_floating_button",
      "defaultMessage": "Looking for Offers?"
    },
    "profileV2ViewProfileButton": {
      "id": "profile.profile_v2_view_profile_button",
      "defaultMessage": "View My Profile"
    },
    "profileNoGiftCards": {
      "id": "profile.profile_no_gift_cards",
      "defaultMessage": "No Gift Cards Yet"
    },
    "profileGiftCardCode": {
      "id": "profile.profile_gift_card_code",
      "defaultMessage": "**** **** **** ****"
    },
    "profileGiftCardValue": {
      "id": "profile.profile_gift_card_value",
      "defaultMessage": "Curent Value: {value}"
    },
    "profileUnavailable": {
      "id": "profile.profile_unavailable",
      "defaultMessage": "Profile is Unavailable"
    },
    "profileOrderHistorySubtotal": {
      "id": "profile.order_history_subtotal",
      "defaultMessage": "Subtotal: {subtotal}"
    },
    "profileOrderHistoryTaxes": {
      "id": "profile.order_history_taxes",
      "defaultMessage": "Taxes: {taxes}"
    },
    "profileOrderHistoryFees": {
      "id": "profile.order_history_fees",
      "defaultMessage": "Fees: {fees}"
    },
    "profileOrderHistoryTip": {
      "id": "profile.order_history_tip",
      "defaultMessage": "Tip: {tip}"
    },
    "profileOrderHistoryTotal": {
      "id": "profile.order_history_total",
      "defaultMessage": "Total: {total}"
    },
    "profileOrderHistoryEmpty": {
      "id": "profile.order_history_empty",
      "defaultMessage": "No Orders Yet"
    },
    "profileOrderHistoryOrderNow": {
      "id": "profile.order_history_order_now",
      "defaultMessage": "Order Now"
    },
    "profileFavoritesPoppedDishesTab": {
      "id": "profile.favorites.popped_dishes_tab",
      "defaultMessage": "POPPED"
    },
    "profileFavoritesReviewedDishesTab": {
      "id": "profile.favorites.reviewed_dishes_tab",
      "defaultMessage": "REVIEWED"
    },
    "profileFavoritesRemindTryTab": {
      "id": "profile.favorites.remind_try_tab",
      "defaultMessage": "REMIND ME"
    },
    "profileFavoritesBookmarksTab": {
      "id": "profile.favorites.bookmarks_tab",
      "defaultMessage": "BOOKMARKS"
    },
  }
);
export default defaultMessages;
