import React from 'react';
import { Grid as MuiGrid, makeStyles } from '@material-ui/core';

import type { GridProps as CommonGridProps } from '@popmenu/common-ui';
import { Paper } from '@popmenu/common-ui';
import { classNames } from '../utils/withStyles';
import gridStyles from '../assets/jss/shared/gridStyles';

import { AH, AHLevelProvider } from '../consumer/shared/AccessibleHeading';

export interface GridProps extends CommonGridProps {
  className?: string,
  inline?: boolean,
  justify?: 'center' | 'flex-end' | 'flex-start' | 'space-around' | 'space-between',
  paper?: boolean,
  shadow?: boolean,
  title?: string,
}

const useStyles = makeStyles(gridStyles);

const Grid = ({
  children,
  className = undefined,
  container,
  inline = false,
  item,
  justify = 'flex-start',
  paper = false,
  shadow = false,
  spacing,
  title = undefined,
  ...restProps
}: GridProps) => {
  const classes = useStyles();
  if (container) {
    if (inline) {
      return (
        <MuiGrid
          container
          classes={{
            container: classNames(
              className,
              classes.containerInline,
              restProps.wrap === 'nowrap' ? classes.containerInlineNoWrap : null,
            ),
          }}
          justifyContent={justify}
          spacing={typeof spacing === 'undefined' ? 2 : spacing}
          {...restProps}
        >
          {children}
        </MuiGrid>
      );
    }
    return (
      <MuiGrid
        container
        classes={{
          container: classNames(classes.container, className),
        }}
        justifyContent={justify}
        spacing={typeof spacing === 'undefined' ? 4 : spacing}
        {...restProps}
      >
        {children}
      </MuiGrid>
    );
  } else if (item) {
    let gridChildren = children;
    if (title) {
      gridChildren = (
        <React.Fragment>
          <AH typography gutterBottom variant="h6">
            {title}
          </AH>
          <AHLevelProvider>
            {gridChildren}
          </AHLevelProvider>
        </React.Fragment>
      );
    }
    if (paper) {
      gridChildren = (
        <Paper legacyStyles elevation={shadow ? 1 : 0}>
          {gridChildren}
        </Paper>
      );
    }
    return (
      <MuiGrid
        item
        classes={{
          item: className,
        }}
        {...restProps}
      >
        {gridChildren}
      </MuiGrid>
    );
  }
  throw new Error('[POPMENU] Invalid Grid (`container` or `item` prop required');
};

export default Grid;
