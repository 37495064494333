import {
  hexToRgb,
  rgbToHex,
  hslToRgb,
  decomposeColor,
  recomposeColor,
  darken,
  lighten,
  getContrastRatio,
} from '@material-ui/core/styles';

export { darken, lighten };

// colors used for curbside vehicle options
export const colorOptions = {
  '#000000': 'Black',
  '#0092d0': 'Blue',
  '#009102': 'Green',
  '#2d2d2e': 'Other',
  '#575757': 'Grey',
  '#792502': 'Brown',
  '#ACACAD': 'Silver',
  '#d7a55e': 'Beige',
  '#fdbe43': 'Gold',
  '#FF0000': 'Red',
  '#ff7500': 'Orange',
  '#FFFFFF': 'White',
} as const;

// Util functions converted from tinycolor2 using Material UI tools

const alphaToHex = (alpha: number) => {
  if (alpha > 100 || alpha < 0 || Number.isNaN(alpha)) {
    throw new Error('The argument must be a number between 0 and 100 inclusive');
  }
  return Math.ceil(255 * alpha / 100).toString(16).toUpperCase();
};

// @returns {number} Luminance in range from 0 to 255
const getRec601Luminance = (color: string): number => {
  const colorData = decomposeColor(color);
  switch (colorData.type) {
    case 'rgb':
    case 'rgba':
      return (colorData.values[0] * 299 + colorData.values[1] * 587 + colorData.values[2] * 114) / 1000;
    case 'hsl':
    case 'hsla':
      return getRec601Luminance(hslToRgb(color));
    default:
      return 0;
  }
};

interface WCAG2Unvalidated {
  level?: string;
  size?: string;
}

interface WCAG2 {
  level: 'AA' | 'AAA';
  size: 'small' | 'large';
}

const validateWCAG2 = ({ level = 'AA', size = 'small' }: WCAG2Unvalidated = {}): WCAG2 => {
  const level0 = level.toUpperCase();
  const size0 = size.toLowerCase();
  const level1: WCAG2['level'] = level0 === 'AA' || level0 === 'AAA' ? level0 : 'AA';
  const size1: WCAG2['size'] = size0 === 'small' || size0 === 'large' ? size0 : 'small';
  return { level: level1, size: size1 };
};

export const getReadability = (color1: string, color2: string) => getContrastRatio(color1 || '#000000', color2 || '#000000');

// TODO: it looks like validateWCAG2 is not needed, we can just pass WCAG2, since JS callers don't pass wcag2 currently
// But let's wait until full conversion to TypeScript to remove it, if then.
export const isReadable = (color1: string, color2: string, wcag2: WCAG2Unvalidated = {}) => {
  const readability = getReadability(color1, color2);
  const wcag2Data = validateWCAG2(wcag2);
  switch (`${wcag2Data.level}${wcag2Data.size}`) {
    case 'AAsmall':
    case 'AAAlarge':
      return readability >= 4.5;
    case 'AAlarge':
      return readability >= 3;
    case 'AAAsmall':
      return readability >= 7;
  }
  return false;
};

const getMostReadable = (baseColor: string, colorList: string[], args: WCAG2Unvalidated & { includeFallbackColors?: boolean } = {}): string => {
  const { level, size } = args;

  const best = colorList.reduce((result, current) => {
    const readability = getReadability(baseColor, current);
    return readability > result.bestScore ? { bestColor: current, bestScore: readability } : result;
  }, { bestColor: '', bestScore: 0 });

  if (isReadable(baseColor, best.bestColor, { level, size }) || !args.includeFallbackColors) {
    return best.bestColor;
  } else {
    return getMostReadable(baseColor, ['#fff', '#000'], { ...args, includeFallbackColors: false });
  }
};

// Exported util color functions
export const hexToRgba = (hex: string, alpha = 100) => {
  if (hex) {
    if (hex === 'transparent') {
      return 'rgba(0, 0, 0, 0)';
    } else {
      return hexToRgb(`${hex}${alphaToHex(alpha)}`);
    }
  }
  return null;
};

export const isDarkColor = (color: string) => getRec601Luminance(color) < 128;

export const isTransparentColor = (color: string) => {
  if (color) {
    const colorData = decomposeColor(color);
    // If there is no alpha data it shouldn't be transparent
    if (colorData.values.length !== 4) {
      return false;
    }
    const alpha = colorData.values[3] * 100;
    return alpha < 100;
  }
  return true;
};

export const readableFontColor = (backgroundColor: string, color?: string, customBgColors?: string[]) => {
  let colors = ['#000', '#FFF'];
  if (customBgColors) {
    colors = customBgColors;
  }
  if (color) {
    if (isReadable(color, backgroundColor)) {
      return color;
    }
    colors.push(color);
  }
  const mostReadable = getMostReadable(backgroundColor, colors, { includeFallbackColors: !customBgColors });
  return rgbToHex(mostReadable);
};

export const darkenToReadableColor = (backgroundColor: string, color: string) => {
  let readableColor = color;
  for (let attempt = 1; attempt < 10; attempt += 1) {
    if (isReadable(readableColor, backgroundColor)) {
      return readableColor;
    }
    readableColor = darken(readableColor, attempt * 0.1);
  }
  return readableFontColor(backgroundColor, color);
};

// Returns { color: "hex string", alpha: 0-100 }
export const rgbaToHex = (rgba: string) => {
  if (!rgba) {
    return { alpha: 100, color: null };
  }

  const colorData = decomposeColor(rgba);
  // If there is no alpha data
  if (colorData.values.length !== 4) {
    return { alpha: 100, color: rgbToHex(recomposeColor(colorData)) };
  }

  return { alpha: colorData.values[3] * 100, color: rgbToHex(recomposeColor(colorData)) };
};

export const stringToColor = (str: string) => {
  let hash = 0;
  for (let i = 0; i < str.length; i += 1) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  let color = '#';
  for (let i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xFF;
    color += (`00${value.toString(16)}`).substr(-2);
  }
  return color;
};
