import { useContext, useEffect } from 'react';
import type React from 'react';
import { useLazyQuery, useQuery } from '~/lazy_apollo/client';
import type {
  DocumentNode,
  TypedDocumentNode,
  OperationVariables,
  LazyQueryHookOptions,
  QueryHookOptions,
  QueryResult,
  NoInfer,
} from '~/lazy_apollo/client';

import { ServerSideMemoContext, DraftModeContext } from './initContext';
import { isSSR } from './dom';
import { toQueryName } from './apollo';

import { EmptyDocument } from '../libs/gql/queries/emptyQuery.generated';

// Unfortunately |skip| prop doesn't work: https://github.com/apollographql/apollo-client/issues/6190
// React doesn't support conditional hooks, so useQuery should be called every render unconditionally.
// Given all that the only way to skip the query is to execute empty query.
// TODO: Make a PR apollo-client and get rid of this workaround.
const useSkippableQuery = <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  { skip, ...options }: { skip: boolean } & QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): QueryResult<TData, TVariables> | EmptyObject => {
  const result = useQuery(
    skip ? EmptyDocument : query,
    {
      ...(skip ? {} : options),
      fetchPolicy: skip ? 'cache-only' : options.fetchPolicy,
    });

  if (skip) {
    // emptyQuery isn't actually empty, so result.data is present even when skip==true.
    return {};
  }
  return result;
};

const validateCache = (data: unknown, serverSideData: unknown) => {
  const serializedData = JSON.stringify(data, null, 2);
  const serializedServerSizeData = JSON.stringify(serverSideData, null, 2);
  if (serializedData !== serializedServerSizeData) {
    console.error('Data should not change. Copy-paste the following strings to https://text-compare.com/');
    console.error('Cached data', serializedServerSizeData);
    console.error('Updated data', serializedData);
    // TODO: move popularMenuItems to a separate query and uncomment the following line.
    // throw new Error("Data shouldn't change");
  }
};

// SUBTLE: Shouldn't be used for data that can update.
// This hook executes query server-side and reuses the results client-side.
// Will have no effect if query isn't preloaded in app/helpers/consumer_react_helper.rb
// Performance optimization: Reduces the size of hydration task substantially.
export const useServerSideQuerySubtle = <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options: QueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
): Partial<QueryResult<TData, TVariables>> => {
  const memoKey = `${toQueryName(query)}Query:${JSON.stringify(options)}`;
  const serverSideMemo = useContext(ServerSideMemoContext) || {};

  // Draft mode updates the query based on the messages from outside the iframe.
  // useServerSideQuerySubtle introduces 10 seconds delay, so it must immediately fallback to apollo query in the draftmode.
  const { draftMode } = useContext(DraftModeContext);

  const serverSideData = serverSideMemo[memoKey] as TData | undefined;
  const useServerSideCache = !draftMode && !isSSR && !!serverSideData;
  const shouldValidateCacheForTesting = useServerSideCache && !!window.Cypress;

  const result = useSkippableQuery(query, {
    skip: useServerSideCache && !shouldValidateCacheForTesting,
    ...options,
  });

  if (isSSR && result.data) {
    serverSideMemo[memoKey] = result.data;
  }

  if (result.data && shouldValidateCacheForTesting) {
    validateCache(result.data, serverSideData);
  }

  if (useServerSideCache) {
    return { data: serverSideData };
  }
  // if we got here, skip was false and result isn't {}
  return result as QueryResult<TData, TVariables>;
};

// Performance tweak: use this query to reduce the size of the hydration task.
// The query will be executed client-side only.
export const useSeparateJSTaskQuery = <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<NoInfer<TData>, NoInfer<TVariables>>,
) => {
  const [loadQuery, result] = useLazyQuery(query, options);

  useEffect(() => {
    setTimeout(loadQuery as () => void, 0);
  }, [loadQuery]);

  return result;
};

export type QueryChildren<TData = unknown, TVariables extends OperationVariables = OperationVariables> =
  (result: Partial<QueryResult<TData, TVariables>>) => React.JSX.Element | null;

interface ServerSideQuerySubtleProps<TData = unknown, TVariables extends OperationVariables = OperationVariables> extends
  QueryHookOptions<TData, TVariables> {
  children: QueryChildren<TData, TVariables>;
  query: DocumentNode | TypedDocumentNode<TData, TVariables>;
}

// Boilerplate to support legacy react components.
// See useServerSideQuerySubtle.
export const ServerSideQuerySubtle = <TData = unknown, TVariables extends OperationVariables = OperationVariables>(
  props: ServerSideQuerySubtleProps<TData, TVariables>,
) => {
  const { children, query, ...options } = props;
  const result = useServerSideQuerySubtle(query, options);
  return children(result);
};
