
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "restaurantsSocialHandlesSocialTypeActionsFacebook": {
      "id": "restaurants.social_handles.social_type_actions.facebook",
      "defaultMessage": "Like on Facebook"
    },
    "restaurantsSocialHandlesSocialTypeActionsInstagram": {
      "id": "restaurants.social_handles.social_type_actions.instagram",
      "defaultMessage": "Find on Instagram"
    },
    "restaurantsSocialHandlesSocialTypeActionsLinkedin": {
      "id": "restaurants.social_handles.social_type_actions.linkedin",
      "defaultMessage": "Find on Linkedin"
    },
    "restaurantsSocialHandlesSocialTypeActionsPinterest": {
      "id": "restaurants.social_handles.social_type_actions.pinterest",
      "defaultMessage": "Pin on Pinterest"
    },
    "restaurantsSocialHandlesSocialTypeActionsSnapchat": {
      "id": "restaurants.social_handles.social_type_actions.snapchat",
      "defaultMessage": "Follow on Snapchat"
    },
    "restaurantsSocialHandleSocialTypeActionsTikTok": {
      "id": "restaurants.social_handles.social_type_actions.tiktok",
      "defaultMessage": "Follow on TikTok"
    },
    "restaurantsSocialHandlesSocialTypeActionsTwitter": {
      "id": "restaurants.social_handles.social_type_actions.twitter",
      "defaultMessage": "Follow on Twitter"
    },
    "restaurantsSocialHandlesSocialTypeActionsYoutube": {
      "id": "restaurants.social_handles.social_type_actions.youtube",
      "defaultMessage": "Susbcribe on Youtube"
    }
  }
);
export default defaultMessages;

