import {
  MENU_FEATURED_ITEM_CHOSEN,
} from './FeaturedSectionActions';

const initialState = {
  itemSource: null,
  menuItemIdChosen: 0,
  quantity: 0,
};

const FeaturedSectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case MENU_FEATURED_ITEM_CHOSEN:
      return {
        ...state,
        menuItemIdChosen: action.menuItemId,
      };

    default:
      return state;
  }
};

export default FeaturedSectionReducer;
