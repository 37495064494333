// This file contains a set of workarounds for a bug in react-virtuoso:
// Sometimes virtuoso scrolls to a wrong position and we need to programmatically retry the scrolling.
// This activity should be interrupted by user scrolls.

import { isSSR } from '~/utils/dom';

const RETRIES_COUNT = 8;
const RETRIES_INTERVAL_MS = 200;
// Time to wait before resetting isProgrammaticScroll flag (in milliseconds)
//
// One would think that this timeout would cause the page to cancel user scrolling attempts
// with a probability of "PROGRAMMATIC_SCROLL_TIMEOUT_MS/RETRIES_INTERVAL_MS = 50/200 = 0.25"
// This would suggest that every 4th scroll attempt after clicking menu tabs could result in an annoying bug.
// However, this is not the case. The actual probability is much closer to zero
// because Virtuoso is optimized to call `window.scrollTo` only when the
// current scroll position differs from the desired one.
//
// User scrolling may only be affected when Virtuoso has scrolled to an incorrect location.
// In such cases, it might appear as if "the page is still scrolling after clicking a menu tab"
// rather than being perceived as a bug.
const PROGRAMMATIC_SCROLL_TIMEOUT_MS = 50;

let isProgrammaticScroll = false;
let retriesSetTimeoutId;
let resetProgrammaticScrollTimeoutId;
const userScrollListeners = new Set();

export const addUserScrollListener = (listener) => {
  userScrollListeners.add(listener);
};

export const removeUserScrollListener = (listener) => {
  userScrollListeners.delete(listener);
};

if (!isSSR) {
  window.addEventListener('scroll', () => {
    if (!isProgrammaticScroll) {
      clearTimeout(retriesSetTimeoutId);
      userScrollListeners.forEach(listener => listener());
    }

    clearTimeout(resetProgrammaticScrollTimeoutId);
    resetProgrammaticScrollTimeoutId = setTimeout(() => {
      isProgrammaticScroll = false;
      clearTimeout(retriesSetTimeoutId);
    }, PROGRAMMATIC_SCROLL_TIMEOUT_MS);
  }, { passive: true });
}

function onStartProgrammaticScroll() {
  clearTimeout(retriesSetTimeoutId);
  clearTimeout(resetProgrammaticScrollTimeoutId);
  isProgrammaticScroll = true;
}

export const repeatUntilUserScroll = (callback) => {
  onStartProgrammaticScroll();

  let retryCount = 0;

  const retry = () => {
    onStartProgrammaticScroll();
    callback();
    retryCount += 1;

    if (retryCount < RETRIES_COUNT) {
      retriesSetTimeoutId = setTimeout(retry, RETRIES_INTERVAL_MS);
    }
  };

  // Postponing virtuoso scrolls to a separate task from the curent React rendering iteration
  // makse it less flaky.
  setTimeout(() => {
    retry();
  }, 0);
};
