import {
  CLOSE_UPGRADE_REQUEST_MODAL,
  OPEN_UPGRADE_REQUEST_MODAL,
} from './UpgradeRequestActions';

const initialState = {
  showUpgradeRequestModal: '',
};

const modals = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_UPGRADE_REQUEST_MODAL:
      return {
        ...initialState,
        showUpgradeRequestModal: '',
      };

    case OPEN_UPGRADE_REQUEST_MODAL:
      return {
        ...initialState,
        showUpgradeRequestModal: action.requestType,
      };

    default:
      return state;
  }
};

export default modals;
