export const OPEN_ANNOUNCEMENT_BANNER = 'OPEN_ANNOUNCEMENT_BANNER';
export const CLOSE_ANNOUNCEMENT_BANNER = 'CLOSE_ANNOUNCEMENT_BANNER';
export const CLOSE_ANNOUNCEMENT_MODAL = 'CLOSE_ANNOUNCEMENT_MODAL';

export function openAnnouncementBanner(announcementLocationId,
  announcementMenuId) {
  return (dispatch) => {
    dispatch({
      announcementLocationId,
      announcementMenuId,
      type: OPEN_ANNOUNCEMENT_BANNER,
    });
  };
}

export function closeAnnouncementBanner() {
  return (dispatch) => {
    dispatch({ type: CLOSE_ANNOUNCEMENT_BANNER });
  };
}

export function closeAnnouncementModal() {
  return (dispatch) => {
    dispatch({ type: CLOSE_ANNOUNCEMENT_MODAL });
  };
}
