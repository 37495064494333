import type { Property } from 'csstype';
import type { CSSProperties } from 'react';
import type { GridSize } from '@material-ui/core';

export const toBackgroundStyle = ({ backgroundAttachment, backgroundColor, backgroundEffect, backgroundImageUrl }: {
  backgroundAttachment?: Property.BackgroundAttachment;
  backgroundColor?: Property.BackgroundColor;
  backgroundEffect?: 'bg_repeat' | 'bg_repeat_y' | 'bg_static' | 'bg_cover';
  backgroundImageUrl?: string;
}) => {
  const style: CSSProperties = {};
  if (backgroundAttachment) {
    switch (backgroundAttachment) {
      case 'fixed':
        style.backgroundAttachment = 'fixed';
        break;
      default:
        style.backgroundAttachment = 'scroll';
        break;
    }
  }
  if (backgroundColor) {
    style.backgroundColor = backgroundColor;
  }
  if (backgroundImageUrl) {
    style.backgroundImage = `url("${backgroundImageUrl}")`;
    switch (backgroundEffect) {
      case 'bg_repeat':
        style.backgroundPosition = 'center top';
        style.backgroundRepeat = 'repeat';
        style.backgroundSize = 'auto auto';
        break;
      case 'bg_repeat_y':
        style.backgroundPosition = 'center top';
        style.backgroundRepeat = 'repeat-y';
        style.backgroundSize = '100% auto';
        break;
      case 'bg_static':
        style.backgroundPosition = 'center center';
        style.backgroundRepeat = 'no-repeat';
        style.backgroundSize = 'auto auto';
        break;
      default: // bg_cover
        style.backgroundPosition = 'center center';
        style.backgroundRepeat = 'no-repeat';
        style.backgroundSize = 'cover';
        break;
    }
  }
  return style;
};

/**
 * The `toImportant` function is a TypeScript generic function that appends `!important` to a CSS property value.
 * In TypeScript, appending `!important` directly to a CSS property value can cause a type error because the TypeScript
 * type definitions for CSS properties do not include `!important`. This is where the `toImportant` function comes in.
 * See https://github.com/frenic/csstype/issues/160.
 *
 * It takes a CSS property value as a string, appends `!important` to it, and returns the new string.
 * The function casts the new string to the original type of the passed value, so that the type of the returned value
 * matches the type of the passed value.
 */
export const toImportant = <T extends string | number>(value: T) => `${value} !important` as T;

export const isGridSize = (value: string | number): value is GridSize => (
  (typeof value === 'number' && Number.isInteger(value) && value >= 1 && value <= 12) || value === 'auto'
);

export const toGridSizeOrUndefined = (value: string | number): GridSize | undefined => (
  isGridSize(value) ? value : undefined
);
