export default theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100% !important',
      padding: `${theme.spacing(2)}px !important`,
    },
    margin: '0 auto',
  },
  'container-max-width-acmx_50': {
    maxWidth: '50%',
  },
  'container-max-width-acmx_75': {
    maxWidth: '75%',
  },
  'container-max-width-acmx_100': {
    maxWidth: '100%',
  },
  content: {
    color: theme.consumer.textBoxSection.fontColor,
    display: 'flex',
  },
  'content-img-position-ap_bottom': {
    flexDirection: 'column-reverse',
  },
  'content-img-position-ap_left': {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    flexDirection: 'row',
  },
  'content-img-position-ap_right': {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    flexDirection: 'row',
  },
  'content-img-position-ap_top': {
    flexDirection: 'column',
  },
  header: {
    color: theme.consumer.textBoxSection.fontColor,
  },
  img: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100% !important',
    },
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: 'none !important',
    padding: theme.spacing(2),
    width: '480px',
  },
  imgWrapper: {
    '& picture': {
      margin: 'auto',
    },
    alignItems: 'center',
    display: 'flex',
    flex: '1 1 auto',
    order: 0,
    textAlign: 'center',
  },
  'imgWrapper-img-position-ap_right': {
    justifyContent: 'flex-end',
    order: 1,
  },
  paragraph: {
    flex: '1 1 auto',
  },
});
