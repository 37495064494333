import React from 'react';
import { SnackbarProvider as SnackbarProviderLib } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import type { RequiredChildren } from '~/utils/react';

const useStyles = makeStyles(theme => ({
  containerRoot: {
    zIndex: 999999999,
  },
  info: {
    '& .MuiSnackbarContent-action': {
      '& div': {
        '& .pm-curbside-text': {
          maxWidth: '150px',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        },
        '& .pm-curbside-vehicle': {
          height: '50px',
          marginRight: theme.spacing(1),
          width: '50px',
        },
        '& .pm-curbside-vehicle-container': {
          alignItems: 'center',
          backgroundColor: '#f5f5f5',
          borderRadius: '5px',
          display: 'flex',
          justifyContent: 'center',
          margin: theme.spacing(2, 0, 1),
        },
        '& .pm-snackbar-buttons': {
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: theme.spacing(2),
        },
      },
      margin: '24px auto 16px',
      paddingLeft: '0px',
    },
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    backgroundColor: '#FFF',
    color: '#000',
    flexGrow: 0,
  },
  success: {
    backgroundColor: theme.palette.green.dark,
  },
}));

const SnackbarProvider = ({ children }: RequiredChildren) => {
  const classes = useStyles();
  return (
    <SnackbarProviderLib
      maxSnack={5}
      classes={{
        containerRoot: classes.containerRoot,
        variantInfo: classes.info,
        variantSuccess: classes.success,
      }}
    >
      {children}
    </SnackbarProviderLib>
  );
};

export default SnackbarProvider;
