declare global {
  interface Window {
    // Defined in app/components/late_prefetch.rb
    popmenuLatePrefetchingBundles?: string[];
  }
}

const workerScript = `
  /*latePrefetch.js*/
  self.addEventListener('message', async function(e) {
    for (const bundle of e.data.bundles) {
      await new Promise((resolve) => { setTimeout(resolve, 20); });
      fetch(e.data.baseURI + bundle, { priority: 'low' }).then((resp) => { resp.text(); });
    }
  }, false);
`;

const worker =
  typeof window === 'object' &&
  window.popmenuIsModernBrowser?.() &&
  new Worker(URL.createObjectURL(new Blob([workerScript], {
    type: 'text/javascript',
  })));

export const prefetchBundles = () => {
  if (typeof window.popmenuLatePrefetchingBundles === 'object' && worker) {
    worker.postMessage({
      baseURI: document.location.origin,
      bundles: window.popmenuLatePrefetchingBundles,
    });
  }
};
