
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "reviewsSubmit": {
      "id": "reviews.submit",
      "defaultMessage": "Submit Review"
    },
    "reviewsResubmit": {
      "id": "reviews.resubmit",
      "defaultMessage": "Update Review"
    }
  }
);
export default defaultMessages;

