
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "followPromptHeading": {
      "id": "follow.prompt.heading",
      "defaultMessage": "Become a VIP"
    },
    "followPromptDescription": {
      "id": "follow.prompt.description",
      "defaultMessage": "Be the first to receive updates on exclusive events, secret menus, special offers/discounts, loyalty rewards & more!"
    },
    "followPromptCollapseDescription": {
      "id": "follow.prompt.collapse_description",
      "defaultMessage": "Receive updates on discounts, loyalty, rewards & more!"
    },
    "followPromptDividerText": {
      "id": "follow.prompt.divider_text",
      "defaultMessage": "or join with"
    }
  }
);
export default defaultMessages;

