// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';
import loadable from '@loadable/component';
import retry from '../../../utils/retry';
import Loading from '../../../shared/Loading';

export const AdvancedFormsSection = loadable(() => retry(() => import('../AdvancedFormsSection')));
export const ArticleSection = loadable(() => retry(() => import('../ArticleSection')));
export const CalendarEventsView = loadable(() => retry(() => import('../../calendar_event/CalendarEventsView')), { fallback: <Loading size="fullscreen" /> });
export const CustomForm = loadable(() => retry(() => import('../CustomForm')));
export const EcwidShopSection = loadable(() => retry(() => import('../EcwidShopSection')));
export const FacebookFeedSection = loadable(() => retry(() => import('../FacebookFeedSection')));
export const FeaturedItemsSection = loadable(() => retry(() => import('../FeaturedItemsSection')));
export const FoodtecGiftCardSection = loadable(() => retry(() => import('../FoodtecGiftCardSection')));
export const FormsiteSection = loadable(() => retry(() => import('../FormsiteSection')));
export const GallerySection = loadable(() => retry(() => import('../GallerySection')));
export const GiftCardSection = loadable(() => retry(() => import('../gift_cards/GiftCardSection')));
export const InstagramFeedSection = loadable(() => retry(() => import('../InstagramFeedSection')));
export const PopmenuInstagramFeedSection = loadable(() => retry(() => import('../PopmenuInstagramFeedSection')));
export const LocationSearchSection = loadable(() => retry(() => import('../LocationSearchSection')), { fallback: <Loading size="fullscreen" /> });
export const LocationSection = loadable(() => retry(() => import('../LocationSection')));
export const MapSection = loadable(() => retry(() => import('../MapSection')));
export const MenuItemCartContainerV2 = loadable(() => retry(() => import('../../menus/cart/MenuItemCartContainerV2')));
export const NextMenuGroup = loadable(() => retry(() => import('../../menus/NextMenuGroup')));
export const ReviewSection = loadable(() => retry(() => import('../ReviewSection')));
export const TripleseatSection = loadable(() => retry(() => import('../TripleseatSection')));
export const VideoSection = loadable(() => retry(() => import('../VideoSection')));
export const YoutubeSection = loadable(() => retry(() => import('../YoutubeSection')));
