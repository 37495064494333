import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "consumerDistanceAway": {
      "id": "consumer.distance.away",
      "defaultMessage": "away"
    },
    "consumerDistanceMile": {
      "id": "consumer.distance.mile",
      "defaultMessage": "mile"
    },
    "consumerDistanceMiles": {
      "id": "consumer.distance.miles",
      "defaultMessage": "miles",
    },
    "consumerDistanceMeasureUnitsAway": {
      "id": "consumer.distance.measure_units_away",
      "defaultMessage": "{measure} {units} away",
    },
    "consumerGiftCardCheckBalance": {
      "id": "consumer.gift_cards.check_balance",
      "defaultMessage": "Check Balance"
    },
    "consumerGiftCardCheckBalanceCode": {
      "id": "consumer.gift_cards.check_balance_code",
      "defaultMessage": "Enter Digital Gift Card 16 Digit Code"
    },
    "consumerGiftCard": {
      "id": "consumer.gift_cards.gift_card",
      "defaultMessage": "Gift Card"
    },
    "consumerGiftCardCode": {
      "id": "consumer.gift_cards.gift_card_code",
      "defaultMessage": "Enter Digital Gift Card Code"
    },
    "consumerGiftCardForMe": {
      "id": "consumer.gift_cards.gift_card_item.for_me",
      "defaultMessage": "This gift card is for me"
    },
    "consumerGiftCardAmountCantFullyCoverTip": {
      "id": "consumer.gift_cards.gift_card_cant_cover_tip",
      "defaultMessage": "Gift Card amount can't fully cover tip"
    },
    "consumerGiftCardPlaceOrder": {
      "id": "consumer.gift_cards.place_order",
      "defaultMessage": "Place Order"
    },
    "consumerGiftCardRemainingBalance": {
      "id": "consumer.gift_cards.remaining_balance",
      "defaultMessage": "Remaining Balance"
    },
    "consumerGiftCardRemove": {
      "id": "consumer.gift_cards.remove",
      "defaultMessage": "Remove Gift Card"
    },
    "consumerGiftCardRemoveConfirm": {
      "id": "consumer.gift_cards.remove.confirm",
      "defaultMessage": "Are you sure you want to remove this gift card?"
    },
    "consumerLocationSelectionFindLocationPlaceholder": {
      "id": "consumer.location_selection.find_location_placeholder",
      "defaultMessage": "Search address, city, zipcode"
    },
    "consumerLocationSelectionCurbsidePickupOption": {
      "id": "consumer.location_selection.curbside_pickup_option",
      "defaultMessage": "This location offers a curbside pickup option"
    },
    "consumerOrderingAddToOrder": {
      "id": "consumer.ordering.add_to_order",
      "defaultMessage": "Add to Order"
    },
    "consumerOrderingApplied": {
      "id": "consumer.ordering.applied",
      "defaultMessage": "applied"
    },
    "consumerOrderingAppliedAtCheckout": {
      "id": "consumer.ordering.applied_at_checkout",
      "defaultMessage": "applied at checkout"
    },
    "consumerOrderingAppliedBelow": {
      "id": "consumer.ordering.applied_below",
      "defaultMessage": "applied below"
    },
    "consumerOrderingApply": {
      "id": "consumer.ordering.apply",
      "defaultMessage": "Apply"
    },
    "consumerOrderingAutoTipDisclaimer": {
      "id": "consumer.ordering.auto_tip_disclaimer",
      "defaultMessage": "A {tipAmount}% tip will be auto applied to your tab if you forget to close your tab"
    },
    "consumerOrderingBackInstructions": {
      "id": "consumer.ordering.back_instructions",
      "defaultMessage": "Back"
    },
    "consumerOrderingTotal": {
      "id": "consumer.ordering.total",
      "defaultMessage": "Total"
    },
    "consumerOrderingCateringCartTypeCartSummaryHeading": {
      "id": "consumer.ordering.catering_cart_type.cart_summary_heading",
      "defaultMessage": "My Bag"
    },
    "consumerOrderingCartEmpty": {
      "id": "consumer.ordering.cart_empty",
      "defaultMessage": "Your bag is empty."
    },
    "consumerOrderingCartEmptyAddItems": {
      "id": "consumer.ordering.cart_empty_add_items",
      "defaultMessage": "Please add items on the menu."
    },
    "consumerOrderingCode": {
      "id": "consumer.ordering.code",
      "defaultMessage": "Code"
    },
    "consumerOrderingCurrentBalance": {
      "id": "consumer.ordering.current_balance",
      "defaultMessage": "Current Balance"
    },
    "consumerOrderingCustomerDescription": {
      "id": "consumer.ordering.customer_description",
      "defaultMessage": "Tell us about yourself and where you're seated"
    },
    "consumerOrderingTabOpenDisclaimer": {
      "id": "consumer.ordering.tab_open_disclaimer",
      "defaultMessage": "Your tab has been opened!"
    },
    "consumerOrderingCloseTabDisclaimer": {
      "id": "consumer.ordering.close_tab_disclaimer",
      "defaultMessage": "Close your tab faster"
    },
    "consumerOrderingCloseTabSummary": {
      "id": "consumer.ordering.close_tab_summary",
      "defaultMessage": "Order Summary"
    },
    "consumerOrderingDineInCheckout": {
      "id": "consumer.ordering.dine_in_checkout",
      "defaultMessage": "Dine-in Checkout"
    },
    "consumerOrderingDefaultCartTypeCartSummaryHeading": {
      "id": "consumer.ordering.default_cart_type.cart_summary_heading",
      "defaultMessage": "My Bag"
    },
    "consumerOrderingDestroy": {
      "id": "consumer.ordering.destroy",
      "defaultMessage": "Remove Item"
    },
    "consumerOrderingDestroyConfirm": {
      "id": "consumer.ordering.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this item from your bag?"
    },
    "consumerOrderingDestroyConfirmTitle": {
      "id": "consumer.ordering.destroy_confirm_title",
      "defaultMessage": "Confirm Remove Item"
    },
    "consumerOrderingDetails": {
      "id": "consumer.ordering.details",
      "defaultMessage": "Details"
    },
    "consumerOrderingTab": {
      "id": "consumer.ordering.tab",
      "defaultMessage": "Tab"
    },
    "consumerOrderingDineInCartTypeCartSummaryHeading": {
      "id": "consumer.ordering.dine_in_cart_type.cart_summary_heading",
      "defaultMessage": "My Tab"
    },
    "consumerOrderingDineInCartTypePendingCartSummaryHeading": {
      "id": "consumer.ordering.dine_in_cart_type.pending_cart_summary_heading",
      "defaultMessage": "New Order"
    },
    "consumerOrderingEditItem": {
      "id": "consumer.ordering.edit_item",
      "defaultMessage": "Edit"
    },
    "consumerOrderingEditItemTooltip": {
      "id": "consumer.ordering.edit_item_tooltip",
      "defaultMessage": "Edit This Item"
    },
    "consumerOrderingOnlineOrderingFee": {
      "id": "consumer.ordering.online_ordering_fee",
      "defaultMessage": "Online Ordering Fee"
    },
    "consumerOrderingFeeDisclaimer": {
      "id": "consumer.ordering.fee_disclaimer",
      "defaultMessage": "An online ordering fee may be applied to this order."
    },
    "consumerOrderingGiftCardInput": {
      "id": "consumer.ordering.gift_card_input",
      "defaultMessage": "Enter Gift Card Code"
    },
    "consumerOrderingGiftCardAndOfferInput": {
      "id": "consumer.ordering.gift_card_offer_code_input",
      "defaultMessage": "Enter Offer/Gift Card Code"
    },
    "consumerOrderingGuestInfo": {
      "id": "consumer.ordering.guest_info",
      "defaultMessage": "Guest Info"
    },
    "consumerOrderingMyTab": {
      "id": "consumer.ordering.my_tab",
      "defaultMessage": "My Tab"
    },
    "consumerOrderingPaymentInfo": {
      "id": "consumer.ordering.payment_info",
      "defaultMessage": "Payment Info"
    },
    "consumerOrderingOfferInput": {
      "id": "consumer.ordering.offer_code_input",
      "defaultMessage": "Enter Offer Code"
    },
    "consumerOrderingOriginalBalance": {
      "id": "consumer.ordering.original_balance",
      "defaultMessage": "Original Balance"
    },
    "consumerOrderingPlaceOrder": {
      "id": "consumer.ordering.place_order",
      "defaultMessage": "Checkout"
    },
    "consumerOrderingKeepOrdering": {
      "id": "consumer.ordering.keep_ordering",
      "defaultMessage": "Keep Ordering"
    },
    "consumerOrderingPlaceDineInOrder": {
      "id": "consumer.ordering.place_dine_in_order",
      "defaultMessage": "Secured Checkout"
    },
    "consumerOrderingCustomTip": {
      "id": "consumer.ordering.custom_tip",
      "defaultMessage": "Custom Tip"
    },
    "consumerOrderingRemove": {
      "id": "consumer.ordering.remove",
      "defaultMessage": "Remove"
    },
    "consumerOrderingRemoveItem": {
      "id": "consumer.ordering.remove_item",
      "defaultMessage": "Remove"
    },
    "consumerOrderingChangeLocation": {
      "id": "consumer.ordering.change_location",
      "defaultMessage": "Change Location"
    },
    "consumerOrderingConfirmChangeLocation": {
      "id": "consumer.ordering.confirm_change_location",
      "defaultMessage": "Confirm Change Location"
    },
    "consumerOrderingConfirmChangeLocationMessage": {
      "id": "consumer.ordering.confirm_change_location_message",
      "defaultMessage": "Changing locations will clear out your current bag."
    },
    "consumerOrderingEditMyOrderInfo": {
      "id": "consumer.ordering.edit_my_order_info",
      "defaultMessage": "Edit My Order Info"
    },
    "consumerOrderingGiftCard": {
      "id": "consumer.ordering.gift_card",
      "defaultMessage": "Gift Card"
    },
    "consumerOrderingOrderingOffer": {
      "id": "consumer.ordering.ordering_offer",
      "defaultMessage": "Offer"
    },
    "consumerOrderingGiftCardBalance": {
      "id": "consumer.ordering.gift_card_balance",
      "defaultMessage": "Gift Card - {balance}"
    },
    "consumerOrderingSeeCodeDetails": {
      "id": "consumer.ordering.see_code_details",
      "defaultMessage": "details"
    },
    "consumerOrderingOfferMinimumRequired": {
      "id": "consumer.ordering_offer.minimum_required",
      "defaultMessage": "Minimum Required"
    },
    "consumerOrderingOfferAddQualifyingItems": {
      "id": "consumer.ordering_offer.add_qualifying_items",
      "defaultMessage": "Add qualifying items to your cart to redeem this offer."
    },
    "consumerOrderingOfferSeeQualifyingItems": {
      "id": "consumer.ordering_offer.see_qualifying_items",
      "defaultMessage": "See qualifying items."
    },
    "consumerOrderingOfferRemove": {
      "id": "consumer.ordering_offer.remove",
      "defaultMessage": "Remove Offer Code"
    },
    "consumerOrderingOfferRemoveConfirm": {
      "id": "consumer.ordering_offer.remove.confirm",
      "defaultMessage": "Are you sure you want to remove this offer code?"
    },
    "consumerOrderingOrderType": {
      "id": "consumer.ordering.order_type",
      "defaultMessage": "Order Type"
    },
    "consumerOrderingOrderTime": {
      "id": "consumer.ordering.order_time",
      "defaultMessage": "Order Time"
    },
    "consumerAsapNotAvailable": {
      "id": "consumer.ordering.asap_not_available",
      "defaultMessage": "*ASAP ordering is currently unavailable"
    },
    "consumerScheduledNotAvailable": {
      "id": "consumer.ordering.scheduled_not_available",
      "defaultMessage": "*Later ordering is currently unavailable"
    },
    "consumerOrderingScheduleOrder": {
      "id": "consumer.ordering.schedule_order",
      "defaultMessage": "Schedule Order"
    },
    "consumerOrderingBecomeVip": {
      "id": "consumer.ordering.become_vip",
      "defaultMessage": "Become a VIP - Receive updates on special events, new menu items, menu reviews, and more!"
    },
    "consumerOrderingSave": {
      "id": "consumer.ordering.save",
      "defaultMessage": "Save"
    },
    "consumerOrderingContinue": {
      "id": "consumer.ordering.continue",
      "defaultMessage": "Continue"
    },
    "consumerOrderingEditOrder": {
      "id": "consumer.ordering.edit_order",
      "defaultMessage": "Edit Order"
    },
    "consumerOrderingAsapOrderUnavailable": {
      "id": "consumer.ordering.asap_order_unavailable",
      "defaultMessage": "Sorry, this location is closed for ASAP orders right now"
    },
    "consumerOrderingLocations": {
      "id": "consumer.ordering.locations",
      "defaultMessage": "Locations"
    },
    "consumerOrderingScheduleOrderUnavailable": {
      "id": "consumer.ordering.schedule_order_unavailable",
      "defaultMessage": "Sorry, this location is closed for scheduled pickup orders right now"
    },
    "consumerOrderingScheduleTimeLocationUnavailable": {
      "id": "consumer.ordering.schedule_time_location_unavailable",
      "defaultMessage": "Sorry, but the original time you selected is not available for this location"
    },
    "consumerOrderingScheduleTimeUnavailable": {
      "id": "consumer.ordering.schedule_time_unavailable",
      "defaultMessage": "Sorry, but that time is not available for this location. Please select a different time"
    },
    "consumerOrderingNcrPaymentError": {
      "id": "consumer.ordering.ncr_payment_error",
      "defaultMessage": "Sorry, there's an error with NCR Payment"
    },
    "consumerOrderingPleaseRefresh": {
      "id": "consumer.ordering.please_refresh",
      "defaultMessage": "There's an error, Please refresh and try again."
    },
    "consumerOrderingPickup": {
      "id": "consumer.ordering.pickup",
      "defaultMessage": "Pickup"
    },
    "consumerOrderingPickupCurbsideVehicleColor": {
      "id": "consumer.ordering.pickup_curbside.vehicle_color",
      "defaultMessage": "Vehicle Color"
    },
    "consumerOrderingPickupCurbsideVehicleMakeModel": {
      "id": "consumer.ordering.pickup_curbside.vehicle_make_model",
      "defaultMessage": "Make & Model"
    },
    "consumerOrderingPickupCurbsideVehicleTooltip": {
      "id": "consumer.ordering.pickup_curbside.vehicle_tooltip",
      "defaultMessage": "Your vehicle info helps us to identify your vehicle and deliver your order."
    },
    "consumerOrderingContactInfo": {
      "id": "consumer.ordering.contact_info",
      "defaultMessage": "Contact Info"
    },
    "consumerOrderingSpecialInstructions": {
      "id": "consumer.ordering.special_instructions",
      "defaultMessage": "Special Instructions"
    },
    "consumerOrderingDelivery": {
      "id": "consumer.ordering.delivery",
      "defaultMessage": "Delivery"
    },
    "consumerOrderingPickupModalTitle": {
      "id": "consumer.ordering.pickup_modal_title",
      "defaultMessage": "Pickup Order"
    },
    "consumerOrderingOffer": {
      "id": "consumer.odering.offer",
      "defaultMessage": "Offer"
    },
    "consumerOrderingOrderingEvent": {
      "id": "consumer.ordering.ordering_event",
      "defaultMessage": "Ordering Event - "
    },
    "consumerOrderingDeliveryAddressInput": {
      "id": "consumer.ordering.delivery_address_input",
      "defaultMessage": "Address"
    },
    "consumerOrderingDeliveryAddressExtraPlaceholder": {
      "id": "consumer.ordering.delivery_address_extra_placeholder",
      "defaultMessage": "Building/Apt/Unit (e.g. 100A)"
    },
    "consumerOrderingDeliveryInstructions": {
      "id": "consumer.ordering.delivery.delivery_instructions",
      "defaultMessage": "Delivery Instructions"
    },
    "consumerOrderingDeliveryAddressInputCustomStreetNumber": {
      "id": "consumer.ordering.delivery_address_input.custom.street_number",
      "defaultMessage": "Street Number"
    },
    "consumerOrderingDeliveryAddressInputCustomAddress1": {
      "id": "consumer.ordering.delivery_address_input.custom.address1",
      "defaultMessage": "Address Line 1"
    },
    "consumerOrderingDeliveryAddressInputCustomAddress2": {
      "id": "consumer.ordering.delivery_address_input.custom.address2",
      "defaultMessage": "Address Line 2 (Optional)"
    },
    "consumerOrderingDeliveryAddressInputCustomTownCity": {
      "id": "consumer.ordering.delivery_address_input.custom.town_city",
      "defaultMessage": "Town/City"
    },
    "consumerOrderingDeliveryAddressInputCustomPostalCode": {
      "id": "consumer.ordering.delivery_address_input.custom.postal_code",
      "defaultMessage": "Postal Code"
    },
    "consumerOrderingDineIn": {
      "id": "consumer.ordering.dine_in",
      "defaultMessage": "Contactless Dine-In"
    },
    "consumerOrderingTable": {
      "id": "consumer.ordering.table",
      "defaultMessage": "Table"
    },
    "consumerOrderingDineInTableNumber": {
      "id": "consumer.ordering.dine_in_table_number",
      "defaultMessage": "Table Number"
    },
    "consumerOrderingDineInTableNumberDescription": {
      "id": "consumer.ordering.dine_in_table_number_description",
      "defaultMessage": "Please enter your table number found on your table"
    },
    "consumerOrderingExploreLocations": {
      "id": "consumer.ordering.explore_locations",
      "defaultMessage": "Explore our locations and pickup hours"
    },
    "consumerOrderingSubmitOrder": {
      "id": "consumer.ordering.submit_order",
      "defaultMessage": "Submit Order"
    },
    "consumerOrderingOk": {
      "id": "consumer.ordering.ok",
      "defaultMessage": "Ok"
    },
    "consumerOrderingOrderingUnavailable": {
      "id": "consumer.ordering.ordering_unavailable",
      "defaultMessage": "Sorry, our online ordering is closed right now"
    },
    "consumerOrderingNotificationsEmail": {
      "id": "consumer.ordering.notifications.email",
      "defaultMessage": "Email to "
    },
    "consumerOrderingNotificationsHeading": {
      "id": "consumer.ordering.notifications.heading",
      "defaultMessage": "Order Update Notifications"
    },
    "consumerOrderingNotificationsTextMessage": {
      "id": "consumer.ordering.notifications.text_message",
      "defaultMessage": "Text to "
    },
    "consumerOrderingPickupHours": {
      "id": "consumer.ordering.pickup_hours",
      "defaultMessage": "Pickup hours"
    },
    "consumerOrderingEditCart": {
      "id": "consumer.ordering.edit_cart",
      "defaultMessage": "Edit"
    },
    "consumerOrderingEditCartTooltip": {
      "id": "consumer.ordering.edit_cart_tooltip",
      "defaultMessage": "Edit Bag Details"
    },
    "consumerOrderingLaterDelivery":{
      defaultMessage: 'Later Delivery',
      id: 'consumer.ordering.later_delivery',
    },
    "consumerOrderingLaterPickup":{
      defaultMessage: 'Later Pickup',
      id: 'consumer.ordering.later_pickup',
    },
    "consumerOrderingASAPDelivery":{
      defaultMessage: 'ASAP Delivery',
      id: 'consumer.ordering.asap_delivery',
    },
    "consumerOrderingASAPPickup":{
      defaultMessage: 'ASAP Pickup',
      id: 'consumer.ordering.asap_pickup',
    },
    "consumerOrderingDineInIntroText": {
      "id": "consumer.ordering.dine_in_intro_text",
      "defaultMessage": "Select a menu to start an order"
    },
    "consumerOrderingMyOrderInfo": {
      "id": "consumer.ordering.my_order_info",
      "defaultMessage": "My Order Info"
    },
    "consumerOrderingScheduleForLater": {
      "id": "consumer.ordering.schedule_for_later",
      "defaultMessage": "Schedule for Later"
    },
    "consumerOrderingScheduleOrderOnly": {
      "id": "consumer.ordering.schedule_order_only",
      "defaultMessage": "NOTE: We're currently closed for pickup, but an order can be scheduled for later."
    },
    "consumerOrderingUpdateOrder": {
      "id": "consumer.ordering.update_order",
      "defaultMessage": "Update Order"
    },
    "consumerOrderingUpdatedOrder": {
      "id": "consumer.ordering.updated_order",
      "defaultMessage": "🎉 Updated Order!"
    },
    "consumerOrderingOfferAdded": {
      "id": "consumer.ordering.offer_added",
      "defaultMessage": "Offer Added"
    },
    "consumerOrderingGiftCardAdded": {
      "id": "consumer.ordering.gift_card_added",
      "defaultMessage": "Gift Card Added"
    },
    "consumerOrderingAvailabilityDiscrepancy": {
      "id": "consumer.ordering.availability_discrepancy",
      "defaultMessage": "Certain item(s) from your previous order are currently unavailable. Please make new selections below.",
    },
    "consumerOrderingSelectedItemAvailabilityDiscrepancy": {
      "id": "consumer.ordering.selected_item_availability_discrepancy",
      "defaultMessage": "Sorry, the selected item is not available for this location.",
    },
    "consumerOrderingSelectedItemMaxReached": {
      "id": "consumer.ordering.max_quantity_reached",
      "defaultMessage": "Max quantity reached",
    },
    "consumerOrderingButton": {
      "id": "consumer.ordering.order_button",
      "defaultMessage": "Order Now",
    },
    "consumerOrderingDetailsModalTitlePickup": {
      "id": "consumer.ordering.details_modal_title_pickup",
      "defaultMessage": "Pickup Order",
    },
    "consumerOrderingDetailsModalTitleDelivery": {
      "id": "consumer.ordering.details_modal_title_delivery",
      "defaultMessage": "Delivery Order",
    },
    "consumerOrderingDetailsModalTitle": {
      "id": "consumer.ordering.details_modal_title",
      "defaultMessage": "My Order Info"
    },
    "consumerOrderingDetailsModalSubmitBtnSave": {
      "id": "consumer.ordering.details_modal_submit_title_save",
      "defaultMessage": "Save Order Info",
    },
    "consumerOrderingDetailsModalSubmitBtnEnterAddress": {
      "id": "consumer.ordering.details_modal_submit_title_please_enter",
      "defaultMessage": "Enter Order Info",
    },
    "consumerPreviewControlsAddsection": {
      "id": "consumer.preview_add_section",
      "defaultMessage": "Add Section"
    },
    "consumerPreviewControlsModalConfirm": {
      "id": "consumer.preview_controls_modal_confirm",
      "defaultMessage": "Confirm"
    },
    "consumerPreviewControlsModalMessage": {
      "id": "consumer.preview_controls_modal_message",
      "defaultMessage": "Are you sure you want to delete this section?"
    },
    "consumerPreviewControlsModalCancel": {
      "id": "consumer.preview_controls_modal_cancel",
      "defaultMessage": "Cancel"
    },
    "consumerOrderingOutOfStock": {
      "id": "consumer.ordering.out_of_stock",
      "defaultMessage": "Out of Stock"
    },
    "consumerOrderingItemUnavailable": {
      "id": "consumer.ordering.item_unavailable",
      "defaultMessage": "The {menuItemName} is not available at {locationName}. Please select another dish or switch locations."
    },
    "consumerOrderingBackToMenu": {
      "id": "consumer.ordering.back_to_menu",
      "defaultMessage": "Back to Menu"
    },
    "consumerOrderingSeeAllLocations": {
      "id": "consumer.ordering.see_all_locations",
      "defaultMessage": "See All Locations"
    },
    "consumerOfflineHeading": {
      "id": "consumer.offline.heading",
      "defaultMessage": "No internet."
    },
    "consumerOfflineBody": {
      "id": "consumer.offline.body",
      "defaultMessage": "Looks like there is a problem with the network connection."
    },
    "consumerErrorHeading": {
      "id": "consumer.error.heading",
      "defaultMessage": "Sorry."
    },
    "consumerErrorBody": {
      "id": "consumer.error.body",
      "defaultMessage": "Looks like there was an error loading the page."
    },
    "consumerErrorLink": {
      "id": "consumer.error.link",
      "defaultMessage": "Return to home"
    },
    "consumerNotFoundHeading": {
      "id": "consumer.not_found.heading",
      "defaultMessage": "Sorry."
    },
    "consumerNotFoundBody": {
      "id": "consumer.not_found.body",
      "defaultMessage": "Looks like we couldn't find the page you were looking for."
    },
    "consumerNotFoundLink": {
      "id": "consumer.not_found.link",
      "defaultMessage": "Return to home"
    },
    "consumerReservationRequest": {
      "id": "consumer.reservation.request",
      "defaultMessage": "Request a Reservation"
    },
    "consumerWaitlistJoin": {
      "id": "consumer.waitlist.join",
      "defaultMessage": "Join our waitlist"
    },
    "consumerVipPopPathTitle": {
      "id": "consumer.vip_pop_path.title",
      "defaultMessage": "Sign up to Engage"
    },
    "consumerVipReviewPathTitle": {
      "id": "consumer.vip_review_path.title",
      "defaultMessage": "Sign up to Leave a Review"
    },
    "consumerVipPopPathSubtitle": {
      "id": "consumer.vip_pop_path.subtitle",
      "defaultMessage": "It only takes 5 seconds"
    },
    "consumerVipPopPathBody": {
      "id": "consumer.vip_pop_path.body",
      "defaultMessage": "The more items you engage with the closer you get to loyalty rewards, personalized recommendations and exclusive offers."
    },
    "consumerVipEmail": {
      "id": "consumer.vip.email",
      "defaultMessage": "Email Address"
    },
    "consumerVipEnterYourEmail": {
      "id": "consumer.vip.enter_your_email",
      "defaultMessage": "Enter your email address"
    },
    "consumerVipReceiveTextMessage": {
      "id": "consumer.vip.receive_text_message",
      "defaultMessage": "Receive and redeem offers, discounts, loyalty rewards and more, directly from your phone"
    },
    "consumerInstagramEmptySectionTitle": {
      "id": "consumer.instagram.empty_section_title",
      "defaultMessage": "There are no photos in your Instagram Account."
    }
  }
);
export default defaultMessages;
