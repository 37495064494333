// See docs/loadable-components.md for details regarding **.imports-X.** file extension & duplicate file structure.
import React from 'react';
import loadable from '@loadable/component';
import retry from '../../../../utils/retry';
import Loading from '../../../../shared/Loading';

const CodeApplierV2 = loadable(() => retry(() => import('./CodeApplierV2')), {
  fallback: <Loading size="lg" />,
});

export default CodeApplierV2;
