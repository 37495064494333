import React, { useCallback, useRef, memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from '@shakacode/recompose';
import { IconButton } from '@material-ui/core';
import { Icon, Typography } from '@popmenu/common-ui';
import { useSSRComputation } from '@shakacode/use-ssr-computation.macro';

import { X as XIcon } from '@popmenu/web-icons';
import { readableFontColor } from '../../utils/colors';
import { classNames, withStyles } from '../../utils/withStyles';
import navStyles from '../shared/Nav/styles';
import { closeAnnouncementBanner as closeAnnouncementBannerAction, closeAnnouncementModal as closeAnnouncementModalAction } from '../../shared/AnnouncementsActions';
import { setOrderingOfferCode as setOrderingOfferCodeAction } from '../../shared/ConsumerActions';

import AnnouncementContent from './AnnouncementContent';
import RootAnnouncement from '../shared/RootAnnouncement/RootAnnouncement';
import BasicModal from '../../admin/shared/BasicModal';
import CustomLink from '../shared/CustomLink';
import { addUniqueId } from '../../lazy_apollo/ssrComputationUtils';

const renderAnnouncementContent = (announcement) => {
  if (announcement.content && announcement.heading) {
    return `${announcement.heading} - ${announcement.content}`;
  }
  return announcement.content || announcement.heading;
};

const AnnouncementBanner = ({ closeAnnouncementBanner, closeAnnouncementModal, dismissedModalAnnouncement, setOrderingOfferCode, isPreview, restaurant, dismissedBannerAnnouncement, classes }) => {
  const announcementRef = useRef();
  const hideAnnouncement = useCallback((announcement) => {
    if (announcement.displayType === 'display_banner') {
      closeAnnouncementBanner();
    } else if (announcement.displayType === 'display_modal') {
      closeAnnouncementModal();
    }
  }, [closeAnnouncementBanner, closeAnnouncementModal]);

  const announcementsOptions = useMemo(() => addUniqueId({ variables: { restaurantId: restaurant.id } }), [restaurant.id]);
  const data = useSSRComputation('../../lazy_apollo/RestaurantAllFeaturedAnnouncementsQuery.ssr-computation', [announcementsOptions], {});

  if (isPreview) return null;
  if (!data || !data.restaurantAllFeaturedAnnouncements) {
    return null;
  }

  const isMarketingBanner = data.restaurantAllFeaturedAnnouncements.find(announcement => announcement.displayType === 'display_banner');

  const showRootAnnouncement = () => {
    if (isMarketingBanner) {
      return null;
    } else {
      return <RootAnnouncement restaurant={restaurant} />;
    }
  };

  return data.restaurantAllFeaturedAnnouncements.map((announcement) => {
    if (announcement.displayType === 'display_banner' && !dismissedBannerAnnouncement) {
      return (
        <div
          key={announcement.id}
          className={classNames('pm-root-announcement', classes.bannerAnnouncementContainer, 'pm-announcement-banner')}
          ref={announcementRef}
          data-cy={'announcement_banner'}
        >
          <CustomLink
            className={classes.rootAnnouncementLink}
            onClick={() => {
              hideAnnouncement(announcement);
              if (announcement.marketingOffer?.orderingOffer?.code) {
                setOrderingOfferCode(announcement.marketingOffer.orderingOffer.code);
              }
            }}
            url={announcement.linkUrl}
          >
            {renderAnnouncementContent(announcement)}
          </CustomLink>
          {!announcement.linkUrl && (
            <Typography className={classes.rootAnnouncementText}>
              {renderAnnouncementContent(announcement)}
            </Typography>
          )}
          <IconButton
            aria-label="Dismiss Announcement"
            className={classes.bannerAnnouncementDismissButton}
            onClick={() => hideAnnouncement(announcement)}
          >
            <Icon icon={XIcon} />
          </IconButton>
        </div>
      );
    }
    if (announcement.displayType === 'display_modal') {
      const style = {
        color: restaurant.theme.announcementBgColor ? readableFontColor(restaurant.theme.announcementBgColor) : null,
      };
      const titleId = announcement.heading ? 'announcement-dialog-title' : undefined;
      return (
        <React.Fragment key={announcement.id}>
          <BasicModal
            backgroundColor={restaurant.theme.announcementBgColor}
            className={classNames('pm-announcement-modal')}
            closeModal={() => hideAnnouncement(announcement)}
            DialogProps={{ 'aria-labelledby': titleId }}
            disableBackdropClick={false}
            disableTransition
            show={!dismissedModalAnnouncement}
            size="md"
          >
            <div className="pm-featured-announcement fr-view pm-announcement-modal-body" style={style}>
              <AnnouncementContent
                announcement={announcement}
                onLinkClick={() => hideAnnouncement(announcement)}
                titleId={titleId}
              />
            </div>
          </BasicModal>
          {showRootAnnouncement()}
        </React.Fragment>
      );
    }
    if (announcement.displayType === 'display_above_menu' || announcement.displayType === 'display_page_section') {
      return (
        showRootAnnouncement()
      );
    }
    return null;
  });
};

AnnouncementBanner.defaultProps = {
  locationId: null,
};

AnnouncementBanner.propTypes = {
  classes: PropTypes.object.isRequired,
  closeAnnouncementBanner: PropTypes.func.isRequired,
  closeAnnouncementModal: PropTypes.func.isRequired,
  dismissedBannerAnnouncement: PropTypes.bool.isRequired,
  dismissedModalAnnouncement: PropTypes.bool.isRequired,
  locationId: PropTypes.number,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
    theme: PropTypes.shape({
      announcementBgColor: PropTypes.string,
      announcementBorderColor: PropTypes.string,
    }),
  }).isRequired,
};

export default memo(compose(
  withStyles(navStyles),
  connect(state => ({
    dismissedBannerAnnouncement: state.announcements.dismissedBannerAnnouncement,
    dismissedModalAnnouncement: state.announcements.dismissedModalAnnouncement,
    isPreview: state.consumer.isPreview,
    locationId: state.announcements.announcementLocationId,
  }),
  {
    closeAnnouncementBanner: closeAnnouncementBannerAction,
    closeAnnouncementModal: closeAnnouncementModalAction,
    setOrderingOfferCode: setOrderingOfferCodeAction,
  }),
)(AnnouncementBanner));
