// https://gist.github.com/briancavalier/842626

const consoleDebug = (fn: () => string) => {
  if (typeof console.debug !== 'undefined') {
    console.debug(fn());
  }
};

const retry = <T>(fn: () => Promise<T>, retryMessage = '', retriesLeft = 3, interval = 500) => new Promise<T>((resolve, reject) => {
  fn()
    .then(resolve)
    .catch(() => {
      setTimeout(() => {
        if (retriesLeft === 1) {
          console.warn(`Maximum retries exceeded, retryMessage: ${retryMessage}. Reloading page...`);
          window.location.reload();
          return;
        }
        // Passing on "reject" is the important part
        consoleDebug(() => `Trying request, retryMessage: ${retryMessage}, retriesLeft: ${retriesLeft - 1}`);
        retry(fn, retryMessage, retriesLeft - 1, interval).then(resolve, reject);
      }, interval);
    });
});

export default retry;
