import { combineReducers } from 'redux';
import { menuPageReducer } from '../menuPageReducer';
import { checkoutPageReducer } from '../checkoutPageReducer';
import { dialogReducer } from '../dialogReducer';

export const rootReducer = combineReducers({
  checkoutPage: checkoutPageReducer,
  dialog: dialogReducer,
  menuPage: menuPageReducer,
});
