import React, { createContext, useContext, useMemo } from 'react';
import type { Restaurant, RestaurantFeatureSetting } from '~/libs/gql/types';

export type FeatureName = HintedString<Exclude<keyof RestaurantFeatureSetting, '__typename' | 'id'>>;

export interface FeatureFlags {
  isFeatureActive: (flag: FeatureName) => boolean;
}

export const FeatureFlagsContext = createContext<FeatureFlags | undefined>(undefined);

type FeatureFlagsContextProviderProps = {
  restaurant: Restaurant;
  children: React.ReactNode;
};

export type PartialRestaurantFeatures = {
  featureSetting?: Optional<Partial<RestaurantFeatureSetting>>,
  featureFlags?: Optional<Record<string, boolean>>
};

export const getFeatureFlags = (restaurant: PartialRestaurantFeatures) => {
  const combinedFeatures = { ...restaurant.featureSetting, ...restaurant.featureFlags };
  const { id, __typename, ...featureFlags } = combinedFeatures;

  return featureFlags;
};

export const makeIsFeatureActive = (featureFlags: Record<string, unknown>) => (featureName: FeatureName) => {
  const featureValue = featureFlags[featureName];
  return typeof featureValue === 'string' ?
    featureValue === 'enabled' || featureValue === 'true' :
    !!featureValue;
};

const FeatureFlagsContextProvider = ({
  restaurant,
  ...props
}: FeatureFlagsContextProviderProps) => {
  const featureFlags = getFeatureFlags(restaurant);

  const value = useMemo(() => {
    const isFeatureActive = makeIsFeatureActive(featureFlags);

    return { isFeatureActive };
  }, [featureFlags]);

  return <FeatureFlagsContext.Provider value={value} {...props} />;
};

export const useFeatureFlags = (): FeatureFlags => {
  const context = useContext(FeatureFlagsContext);

  if (!context) {
    throw new Error(
      'The useFeatureFlags hook must be used inside a FeatureFlagsContextProvider.',
    );
  }

  return context;
};

export default FeatureFlagsContextProvider;
