import React, { useContext } from 'react';

import type { Restaurant } from '~/libs/gql/types';

export type WithRestaurantProps = { restaurant: Restaurant };

const RestaurantContext = React.createContext<Restaurant | null>(null);

// For use only in analytics
export let restaurantGlobal: Restaurant | null = null;

interface RestaurantContextProviderProps {
  children: React.ReactNode;
  value: Restaurant;
}

export const RestaurantContextProvider = ({ value, children }: RestaurantContextProviderProps) => {
  restaurantGlobal = value;
  if (process.env.LOG_TEST_DATA_TO_BROWSER_CONSOLE) {
    console.log('LOG_TEST_DATA_TO_BROWSER_CONSOLE Restaurant:\n', JSON.stringify(value, null, 2));
  }
  return <RestaurantContext.Provider value={value}>{children}</RestaurantContext.Provider>;
};

/**
 * Not currently deprecated because we aren't ready to get rid of it everywhere and don't want to just add TODOs.
 * But still try to avoid using this if possible.
 * More information and approach to fix calls in
 * https://www.notion.so/popmenu/What-the-Fragment-324c55dbe7a44e2baefbb80a734750da#7f8f520565ff4901a3e2fff8d194c763
 */
export function withRestaurant<TProps extends WithRestaurantProps>(Component: React.ComponentType<TProps>) {
  // eslint-disable-next-line react/function-component-definition
  return function ComponentWithRestaurantContext(props: Omit<TProps, keyof WithRestaurantProps>) {
    return (
      // https://github.com/Microsoft/TypeScript/issues/28938#issuecomment-450636046
      <RestaurantContext.Consumer>
        {restaurant => <Component {...props as TProps} restaurant={restaurant} />}
      </RestaurantContext.Consumer>
    );
  };
}

/**
 * Not currently deprecated because we aren't ready to get rid of it everywhere and don't want to just add TODOs.
 * But still try to avoid using this if possible.
 * More information and approach to fix calls in
 * https://www.notion.so/popmenu/What-the-Fragment-324c55dbe7a44e2baefbb80a734750da#7f8f520565ff4901a3e2fff8d194c763
 */
export const useRestaurant = () => useContext(RestaurantContext);
