
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "errorsSignInGoogleInvalidToken": {
      "id": "errors.sign_in.google.invalid_token",
      "defaultMessage": "Sorry, there was an error signing in via Google"
    },
    "errorsSignInFacebookInvalidToken": {
      "id": "errors.sign_in.facebook.invalid_token",
      "defaultMessage": "Sorry, there was an error signing in via Facebook"
    },
    "errorLocationAccess": {
      "id": "errors.location_access",
      "defaultMessage": "Failed to get the current location."
    },
    "errorLocationPermissionDenied": {
      "id": "errors.location_permission_denied",
      "defaultMessage": "Location settings have been disabled, please re-enable them in your browser to use this feature."
    },
    "errorGettingUserAddress": {
      "id": "errors.getting_user_address",
      "defaultMessage": "Failed to get the current address."
    },
  }
);
export default defaultMessages;

