import { lighten, readableFontColor } from '../../../../../../utils/colors';

export default theme => ({
  editButtons: {
    '& span': {
      textTransform: 'none !important',
    },
    '&:last-child': {
      marginRight: 0,
    },
    color: theme.palette.info.dark,
    fontSize: '0.85rem',
    marginRight: theme.spacing(1),
  },
  grayText: {
    color: theme.consumer.general.mutedText.color,
  },
  menuSummaryEditButtons: {
    opacity: 0.7,
  },
  offerAppliedChip: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: '100px',
    color: readableFontColor(theme.palette.primary.main),
    height: '100%',
  },
  selectedMenuItemDish: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  selectedMenuItemInfo: {
    overflow: 'hidden',
    width: '100%',
  },
  selectedMenuItemName: {
    margin: theme.spacing(1, 0),
  },
  selectedMenuItemPrice: {
    float: 'right',
    marginLeft: theme.spacing(1),
  },
  selectedMenuItemRow: {
    display: 'flex',
    padding: theme.spacing(0, 2),
    position: 'relative',
  },
  selectedMenuItemRowImg: {
    borderRadius: theme.shape.borderRadius,
    height: '100%',
    objectFit: 'cover',
    width: '100%',
  },
  selectedMenuItemRowImgContainer: {
    [theme.breakpoints.up('sm')]: {
      minWidth: '87px',
    },
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(1),
    minWidth: '40px',
    overflow: 'hidden',
    position: 'relative',
  },
  selectedMenuItemRowImgWrapper: {
    [theme.breakpoints.up('sm')]: {
      height: '87px',
      width: '87px',
    },
    height: '40px',
    width: '40px',
  },
  selectedMenuItemRowWithOffer: {
    backgroundColor: lighten(theme.consumer.general.successMessage.color, 0.9),
    display: 'flex',
    margin: theme.spacing(-2, 0),
    padding: theme.spacing(2, 2),
    position: 'relative',
  },
});
