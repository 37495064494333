
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "usersPassword": {
      "id": "users.password",
      "defaultMessage": "Password"
    },
    "usersPhone": {
      "id": "users.phone",
      "defaultMessage": "Phone"
    },
    "usersPhoneOptional": {
      "id": "users.phone_optional",
      "defaultMessage": "Mobile phone (optional)"
    },
    "usersUpdatePassword": {
      "id": "users.update_password",
      "defaultMessage": "Update Password"
    },
    "usersUpdateProfile": {
      "id": "users.update_profile",
      "defaultMessage": "Update Profile"
    },
    "usersProfile": {
      "id": "users.profile",
      "defaultMessage": "Profile"
    },
    "usersPasswordNew": {
      "id": "users.password_new",
      "defaultMessage": "New password"
    },
    "usersPasswordNewConfirm": {
      "id": "users.password_new_confirm",
      "defaultMessage": "New password confirmation"
    },
    "usersEmail": {
      "id": "users.email",
      "defaultMessage": "Email"
    },
    "usersEmailAddress": {
      "id": "users.email_address",
      "defaultMessage": "Email Address"
    },
    "usersAnniversary": {
      "id": "users.anniversary",
      "defaultMessage": "Wedding Anniversary"
    },
    "usersAnniversaryOptional": {
      "id": "users.anniversary_optional",
      "defaultMessage": "Wedding Anniversary (optional)"
    },
    "usersBirthday": {
      "id": "users.birthday",
      "defaultMessage": "Birthday"
    },
    "usersBirthdayOptional": {
      "id": "users.birthday_optional",
      "defaultMessage": "Birthday (optional)"
    },
    "userResetPassword": {
      "id": "users.reset_password",
      "defaultMessage": "Reset my password"
    },
    "userSignInHelp": {
      "id": "users.sign_in_help",
      "defaultMessage": "Need help signing in?"
    },
    "userSendMagicLink": {
      "id": "users.send_magic_link",
      "defaultMessage": "Send Magic Link"
    },
    "usersPhoneTooltip": {
      "id": "users.phone.tooltip",
      "defaultMessage": "Receive and redeem offers, discounts, loyalty rewards and more, directly from your phone"
    },
    "usersLastName": {
      "id": "users.last_name",
      "defaultMessage": "Last Name"
    },
    "usersFirstName": {
      "id": "users.first_name",
      "defaultMessage": "First Name"
    },
  }
);
export default defaultMessages;

