
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "dishesDetailsReserveBody": {
      "id": "dishes.details.reserve_body",
      "defaultMessage": "Call us to make a reservation now."
    },
    "dishesDetailsReserveBodyForm": {
      "id": "dishes.details.reserve_body_form",
      "defaultMessage": "Call us or use the form below to make a reservation now."
    },
    "dishesDetailsReserveHeading": {
      "id": "dishes.details.reserve_heading",
      "defaultMessage": "Ready to join us? Great!"
    },
    "dishesDetailsBookButton": {
      "id": 'dishes.details.book_button',
      "defaultMessage": 'Book Now'
    },
    "dishesDetailsReviewButton": {
      "id": 'dishes.details.review_button',
      "defaultMessage": 'Leave a Review'
    },
    "dishesDetailsSeeReviewsButton": {
      "id": 'dishes.details.see_reviews_button',
      "defaultMessage": 'see reviews'
    },
    "dishesDetailsJoinWaitList": {
      "id": 'dishes.details.join_the_waitlist',
      "defaultMessage": 'Join the Waitlist'
    },
    "dishesDetailsBookATable": {
      "id": 'dishes.details.book_a_table',
      "defaultMessage": 'Ready to try this? Book a table.'
    }
  }
);
export default defaultMessages;

