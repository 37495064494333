export default theme => ({
  body: {
    '& a': {
      color: 'white',
      textDecoration: 'underline',
    },
    color: 'white',
  },
  container: {
    backgroundColor: '#222',
    marginBottom: '-90px',
    padding: `${theme.spacing(30)}px ${theme.spacing(6)}px`,
  },
  heading: {
    color: 'white',
  },
});
