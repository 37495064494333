import type { StyleRules, Theme } from '@material-ui/core/styles';
import { toImportant } from '~/utils/styles';

const moreHoverFocusStyles = {
  borderRadius: '8px 0 8px 8px',
  clip: 'auto',
  display: 'block',
  height: 'auto',
  left: 'auto',
  overflow: 'visible',
  right: '100%',
  top: '5px !important',
  width: 'auto',
};

export default (theme: Theme) => ({
  inVisible: {
    order: 101,
    pointerEvents: 'none',
    visibility: 'hidden',
  },
  leftNav: {
    justifyContent: 'flex-end',
    left: 0,
  },
  moreLink: {
    '& svg': {
      fontSize: '20px',
    },
    alignItems: 'center',
    display: 'flex',
  },
  nav: {
    display: 'flex',
    position: toImportant('relative'),
  },
  navLinkImg: {
    height: '30px', // 30px + 10px top padding + 10x bottom padding = 50px nav item height
    marginRight: theme.spacing(1),
    width: 'auto',
  },
  navLinkImgContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    top: '-5px',
  },
  navList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-end',
  },
  navModal: {
    justifyContent: 'center',
    whiteSpace: 'normal',
  },
  navMoreDropdown: {
    // Extra selector to increase specificity over base CSS
    '&.nav-more-dropdown-links': {
      // Hide submenus until user hovers over
      '& .nav-dropdown > .nav-dropdown-links': {
        // Prevent using display: none to hide dropdown links as it prevents them from becoming focusable by tabbing
        clip: 'rect(0 0 0 0)',
        height: '1px',
        left: '-9999px',
        overflow: 'hidden',
        position: 'absolute',
        width: '1px',
      },
      '& .nav-dropdown:focus > .nav-dropdown-links': {
        ...moreHoverFocusStyles,
      },
      '& .nav-dropdown:focus-within > .nav-dropdown-links': {
        // Copy/paste to match focus + hover rules, Edge ignores any blocks that include focus-within
        ...moreHoverFocusStyles,
      },
      '& .nav-dropdown:hover > .nav-dropdown-links': {
        ...moreHoverFocusStyles,
      },
    },
  },
  navWrapper: {
    display: 'flex',
    marginTop: '0 !important',
    whiteSpace: 'nowrap',
  },
  overflowStyle: {
    order: 100,
    position: toImportant('sticky'),
    right: '0',
  },
  overflowWrapper: {
    display: 'flex',
  },
  rightNav: {
    justifyContent: 'flex-start',
    right: 0,
  },
  rightOverflowWrapper: {
    display: 'flex',
    position: toImportant('relative'),
  },
  toolbarWrapperLogoCenter: {
    minWidth: 0,
    paddingLeft: '0 !important',
    paddingRight: '0 !important',
    position: toImportant('relative'),
    whiteSpace: 'nowrap',
  },
  visible: {
    opacity: 1,
    order: 0,
    visibility: 'visible',
  },
} as const satisfies StyleRules);
