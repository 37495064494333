import advancedFormsShared from '../messages/shared/advanced_forms'
import consumer from '../messages/consumer/consumer'
import dishes from '../messages/consumer/dishes'
import generic from './shared/generic';
import models from '../messages/consumer/models'
import orderingDineIn from '../messages/consumer/ordering_dine_in'
import pops from '../messages/consumer/pops'
import restaurants from '../messages/consumer/restaurants'
import consumerShared from '../messages/shared/consumer'
import customFormEntriesShared from '../messages/shared/custom_form_entries'
import errorsShared from '../messages/shared/errors'
import followShared from '../messages/shared/follow'
import modelsShared from '../messages/shared/models'
import navShared from '../messages/shared/nav'
import popsShared from '../messages/shared/pops'
import profile from '../messages/consumer/profile'
import reviewsShared from '../messages/shared/reviews'
import sessionsShared from '../messages/shared/sessions'
import socialAccounts from '../messages/shared/social_accounts'
import usersShared from '../messages/shared/users'
import reservations from '../messages/consumer/reservations'

const consumerMessages = {
  ...generic,
  ...consumer,
  ...dishes,
  ...models,
  ...orderingDineIn,
  ...pops,
  ...profile,
  ...restaurants,
  ...consumerShared,
  ...customFormEntriesShared,
  ...errorsShared,
  ...followShared,
  ...modelsShared,
  ...navShared,
  ...popsShared,
  ...reviewsShared,
  ...sessionsShared,
  ...socialAccounts,
  ...usersShared,
  ...reservations,
  ...advancedFormsShared,
}

export default consumerMessages;
