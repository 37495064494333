
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "modelsCalendarEvent::DayOfWeekIsSunday": {
      "id": "models.calendar_event/day_of_week.is_sunday",
      "defaultMessage": "Sun"
    },
    "modelsCalendarEvent::DayOfWeekIsMonday": {
      "id": "models.calendar_event/day_of_week.is_monday",
      "defaultMessage": "Mon"
    },
    "modelsCalendarEvent::DayOfWeekIsTuesday": {
      "id": "models.calendar_event/day_of_week.is_tuesday",
      "defaultMessage": "Tue"
    },
    "modelsCalendarEvent::DayOfWeekIsWednesday": {
      "id": "models.calendar_event/day_of_week.is_wednesday",
      "defaultMessage": "Wed"
    },
    "modelsCalendarEvent::DayOfWeekIsThursday": {
      "id": "models.calendar_event/day_of_week.is_thursday",
      "defaultMessage": "Thu"
    },
    "modelsCalendarEvent::DayOfWeekIsFriday": {
      "id": "models.calendar_event/day_of_week.is_friday",
      "defaultMessage": "Fri"
    },
    "modelsCalendarEvent::DayOfWeekIsSaturday": {
      "id": "models.calendar_event/day_of_week.is_saturday",
      "defaultMessage": "Sat"
    },
    "modelsFollowerFavoriteLocationSubmit": {
      "id": "models.follower.favorite_location.submit",
      "defaultMessage": "Update Favorite Location"
    },
    "modelsMenu::MenuHoursBannerButtonText": {
      "id": "models.menu/menu_hours.menu_banner_button_text",
      "defaultMessage": "See Menu Hours"
    },
    "modelsMenu::MenuHoursModalTitle": {
      "id": "models.menu/menu_hours.modal_title",
      "defaultMessage": "Menu Hours"
    },
    "modelsMenu::MenuHoursModalAllDay": {
      "id": "models.menu/menu_hours.modal_all_day",
      "defaultMessage": "All Day"
    },
    "modelsMenu::MenuHoursModalEveryDay": {
      "id": "models.menu/menu_hours.modal_every_day",
      "defaultMessage": "Every Day"
    },
    "modelsMenu::MenuBannerTextUnavailable": {
      "id": "models.menu/menu_hours.menu_banner_text.unavailable",
      "defaultMessage": "This Menu is Not Available"
    },
    "modelsMenu::MenuBannerTextUnavailableDaysFromUntil": {
      "id": "models.menu/menu_hours.menu_banner_text.available_days_from_until",
      "defaultMessage": "You can order from this menu {days} {from} until {until}"
    },
    "modelsMenu::MenuBannerTextUnavailableEveryDayFromUntil": {
      "id": "models.menu/menu_hours.menu_banner_text.available_every_day_from_until",
      "defaultMessage": "You can order from this menu every day {from} until {until}"
    },
    "modelsMenu::MenuBannerTextUnavailableAllDayDays": {
      "id": "models.menu/menu_hours.menu_banner_text.available_all_day_days",
      "defaultMessage": "You can order from this menu all day {days}"
    },
    "modelsMenu::MenuBannerTextUnavailableAllDayEveryDay": {
      "id": "models.menu/menu_hours.menu_banner_text.available_all_day_every_day",
      "defaultMessage": "You can order from this menu all day every day"
    },
    "modelsMenuItemCart::InvalidSelectedItemsModalTitle": {
      "id": "models.menu_item_cart/invalid_selected_items.modal.title",
      "defaultMessage": "Oops! There is a problem with your order"
    },
    "modelsMenuItemCart::InvalidSelectedItemsModalButton": {
      "id": "models.menu_item_cart/invalid_selected_items.modal.button",
      "defaultMessage": "OK"
    },
  }
);
export default defaultMessages;

