import type { DeliveryLocation } from '~/libs/gql/types';
import type { CartType, FulfillmentType } from '~/utils/types';
import {
  RESET_MENU_ITEM_CART_DATA,
  SET_MENU_ITEM_CART_ADDRESS,
  SET_MENU_ITEM_CART_BYPASS_PROMPT,
  SET_MENU_ITEM_CART_CHECKOUT_PENDING,
  SET_MENU_ITEM_CART_IS_SCHEDULED,
  SET_MENU_ITEM_CART_LOCATION_ID,
  SET_MENU_ITEM_CART_SCHEDULED_AT,
  SET_MENU_ITEM_CART_SPECIAL_INSTRUCTIONS_MAX_LENGTH,
  SET_MENU_ITEM_CART_TYPE,
  SET_EDIT_MENU_ITEM_CART_LOCATION_MODAL_ENABLED,
  SET_MENU_ITEM_CART_FULFILLMENT_TYPE,
  SET_MENU_ITEM_CART_DELIVERY_ADDRESS,
  SET_MENU_ITEM_CART_DELIVERY_ADDRESS_EXTRA,
  SET_MENU_ITEM_CART_DELIVERY_ADDRESS_ERROR,
  SET_MENU_ITEM_CART_DELIVERY_VERIFIED_ADDRESS,
  SET_MENU_ITEM_CART_DELIVERY_LOCATIONS,
  SET_MENU_ITEM_CART_DELIVERY_PENDING_VERIFIED_ADDRESS,
  SET_MENU_ITEM_CART_DELIVERY_PENDING_LOCATIONS,
  SET_MENU_ITEM_CART_ID,
  SET_MENU_ITEM_CART_NEEDS_UPDATE,
  SET_SELECTED_MENU_ITEM,
  SET_SELECTED_SECTION_INDEX,
  SET_SELECTED_SECTION_OFFSET,
  SET_SELECTED_SECTION_SLUG,
  SET_SHOULD_PRELOAD_SECTIONS,
  OPEN_INVALID_CART_MODAL,
  CLOSE_INVALID_CART_MODAL,
  SET_DINE_IN_GUEST_INPUT_VERIFIED,
  SET_FOCUS_FIRST_SECTION_ITEM_EFFECT,
  type MenuItemCartAction,
  type VerifiedAddress,
} from './MenuItemCartActions';

export interface MenuItemCartState {
  bypassPrompt: boolean;
  dineInGuestInputVerified: boolean;
  editMenuItemCartLocationModalEnabled: boolean;
  focusFirstSectionItemEffect: 'active' | 'standby'
  menuItemCartAddress?: Optional<string>;
  menuItemCartCheckoutPending: boolean;
  menuItemCartDeliveryAddress: Optional<string>;
  menuItemCartDeliveryAddressError: Optional<string>;
  menuItemCartDeliveryAddressExtra: Optional<string>;
  menuItemCartDeliveryLocations: DeliveryLocation[];
  menuItemCartDeliveryPendingLocations: DeliveryLocation[];
  menuItemCartDeliveryPendingVerifiedAddress: Optional<VerifiedAddress>;
  menuItemCartDeliveryVerifiedAddress: Optional<VerifiedAddress>;
  menuItemCartFulfillmentType: Optional<FulfillmentType>;
  menuItemCartId: Optional<number>;
  menuItemCartIsScheduled: boolean | null;
  menuItemCartLocationId: Optional<number>;
  menuItemCartNeedsUpdate: boolean;
  menuItemCartScheduledAt: unknown;
  menuItemCartSpecialInstructionsMaxLength: Optional<number>;
  menuItemCartType: Optional<CartType>;
  selectedMenuItem: Optional<{
    menuId: Optional<number>;
    menuSectionId: Optional<number>;
  }>;
  selectedSectionIndex: number;
  selectedSectionOffset: number;
  selectedSectionSlug: Optional<string>;
  shouldPreloadSections: boolean;
  showInvalidCartModal: boolean;
}

const initialState: MenuItemCartState = {
  bypassPrompt: false,
  dineInGuestInputVerified: false,
  editMenuItemCartLocationModalEnabled: true,
  focusFirstSectionItemEffect: 'standby',
  menuItemCartAddress: null,
  menuItemCartCheckoutPending: false,
  menuItemCartDeliveryAddress: null,
  menuItemCartDeliveryAddressError: null,
  menuItemCartDeliveryAddressExtra: null,
  menuItemCartDeliveryLocations: [],
  menuItemCartDeliveryPendingLocations: [],
  menuItemCartDeliveryPendingVerifiedAddress: {
    city: null,
    formattedAddress: null,
    postalCode: null,
    state: null,
    streetAddress: null,
  },
  menuItemCartDeliveryVerifiedAddress: {
    city: null,
    formattedAddress: null,
    postalCode: null,
    state: null,
    streetAddress: null,
  },
  menuItemCartFulfillmentType: null,
  menuItemCartId: null,
  menuItemCartIsScheduled: null,
  menuItemCartLocationId: null,
  menuItemCartNeedsUpdate: false,
  menuItemCartScheduledAt: null,
  menuItemCartSpecialInstructionsMaxLength: null,
  menuItemCartType: 'default_cart_type',
  selectedMenuItem: {
    menuId: null,
    menuSectionId: null,
  },
  selectedSectionIndex: -1,
  selectedSectionOffset: 0,
  selectedSectionSlug: null,
  shouldPreloadSections: false,
  showInvalidCartModal: false,
};

const MenuItemCartReducer = (state: MenuItemCartState = initialState, action: MenuItemCartAction): MenuItemCartState => {
  switch (action.type) {
    case SET_MENU_ITEM_CART_ADDRESS:
      return {
        ...state,
        menuItemCartAddress: action.menuItemCartAddress,
      };
    case SET_MENU_ITEM_CART_BYPASS_PROMPT:
      return {
        ...state,
        bypassPrompt: action.bypassPrompt,
      };
    case SET_MENU_ITEM_CART_CHECKOUT_PENDING:
      return {
        ...state,
        menuItemCartCheckoutPending: action.checkoutPending,
      };
    case SET_MENU_ITEM_CART_IS_SCHEDULED:
      return {
        ...state,
        menuItemCartIsScheduled: action.menuItemCartIsScheduled,
      };
    case SET_MENU_ITEM_CART_LOCATION_ID:
      return {
        ...state,
        menuItemCartLocationId: action.menuItemCartLocationId,
      };
    case SET_MENU_ITEM_CART_SCHEDULED_AT:
      return {
        ...state,
        menuItemCartScheduledAt: action.menuItemCartScheduledAt,
      };
    case SET_MENU_ITEM_CART_TYPE:
      return {
        ...state,
        menuItemCartType: action.menuItemCartType,
      };
    case SET_EDIT_MENU_ITEM_CART_LOCATION_MODAL_ENABLED:
      return {
        ...state,
        editMenuItemCartLocationModalEnabled: action.editMenuItemCartLocationModalEnabled,
      };
    case SET_MENU_ITEM_CART_FULFILLMENT_TYPE:
      return {
        ...state,
        menuItemCartFulfillmentType: action.menuItemCartFulfillmentType,
      };
    case SET_MENU_ITEM_CART_DELIVERY_ADDRESS:
      return {
        ...state,
        menuItemCartDeliveryAddress: action.menuItemCartDeliveryAddress,
      };
    case SET_MENU_ITEM_CART_DELIVERY_ADDRESS_EXTRA:
      return {
        ...state,
        menuItemCartDeliveryAddressExtra: action.menuItemCartDeliveryAddressExtra,
      };
    case SET_MENU_ITEM_CART_DELIVERY_ADDRESS_ERROR:
      return {
        ...state,
        menuItemCartDeliveryAddressError: action.menuItemCartDeliveryAddressError,
      };
    case SET_MENU_ITEM_CART_DELIVERY_VERIFIED_ADDRESS:
      return {
        ...state,
        menuItemCartDeliveryVerifiedAddress: action.menuItemCartDeliveryVerifiedAddress,
      };
    case SET_MENU_ITEM_CART_DELIVERY_LOCATIONS:
      return {
        ...state,
        menuItemCartDeliveryLocations: action.menuItemCartDeliveryLocations,
      };
    case SET_MENU_ITEM_CART_DELIVERY_PENDING_LOCATIONS:
      return {
        ...state,
        menuItemCartDeliveryPendingLocations: action.menuItemCartDeliveryPendingLocations,
      };
    case SET_MENU_ITEM_CART_DELIVERY_PENDING_VERIFIED_ADDRESS:
      return {
        ...state,
        menuItemCartDeliveryPendingVerifiedAddress: action.menuItemCartDeliveryPendingVerifiedAddress,
      };
    case SET_MENU_ITEM_CART_ID:
      return {
        ...state,
        menuItemCartId: action.menuItemCartId,
      };
    case SET_MENU_ITEM_CART_SPECIAL_INSTRUCTIONS_MAX_LENGTH:
      return {
        ...state,
        menuItemCartSpecialInstructionsMaxLength: action.menuItemCartSpecialInstructionsMaxLength,
      };
    case RESET_MENU_ITEM_CART_DATA:
      return {
        ...initialState,
      };
    case SET_MENU_ITEM_CART_NEEDS_UPDATE:
      return {
        ...state,
        menuItemCartNeedsUpdate: action.updateNeeded,
      };
    case SET_SELECTED_MENU_ITEM:
      return {
        ...state,
        selectedMenuItem: action.selectedMenuItem,
      };
    case SET_SELECTED_SECTION_INDEX:
      return {
        ...state,
        selectedSectionIndex: action.selectedSectionIndex,
      };
    case SET_SELECTED_SECTION_OFFSET:
      return {
        ...state,
        selectedSectionOffset: action.selectedSectionOffset,
      };
    case SET_SELECTED_SECTION_SLUG:
      return {
        ...state,
        selectedSectionSlug: action.selectedSectionSlug,
      };
    case OPEN_INVALID_CART_MODAL:
      return {
        ...state,
        showInvalidCartModal: true,
      };
    case CLOSE_INVALID_CART_MODAL:
      return {
        ...state,
        showInvalidCartModal: false,
      };
    case SET_DINE_IN_GUEST_INPUT_VERIFIED:
      return {
        ...state,
        dineInGuestInputVerified: action.isVerified,
      };
    case SET_SHOULD_PRELOAD_SECTIONS:
      return {
        ...state,
        shouldPreloadSections: action.shouldPreloadSections,
      };
    case SET_FOCUS_FIRST_SECTION_ITEM_EFFECT:
      return {
        ...state,
        focusFirstSectionItemEffect: action.status,
      };
    default:
      return state;
  }
};

export default MenuItemCartReducer;
