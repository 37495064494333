import gql from 'graphql-tag';
import type { DocumentNode, OperationVariables, TypedDocumentNode } from '~/lazy_apollo/client';

export type CachedQuery<TData, TVariables extends OperationVariables = OperationVariables> = {
  query: string | readonly string[];
  data: TData;
  variables: TVariables;
}

// Extract the query name from given GQL object
export const toQueryName = <TData, TVariables extends OperationVariables = OperationVariables>(query: DocumentNode | TypedDocumentNode<TData, TVariables>): string | null => {
  let queryName: string | null = null;
  query.definitions.forEach((definition) => {
    if (!queryName && 'name' in definition && definition.name?.value) {
      queryName = definition.name.value;
    }
  });
  if (!queryName) {
    console.warn('[POPMENU] Query name not found!');
  }
  return queryName;
};

// Manually write query to the Apollo cache for prerendering
export const writeQueries = <TData, TVariables extends OperationVariables = OperationVariables>(queries: CachedQuery<TData, TVariables>[] | null, client: PopmenuApolloClient) => {
  (queries || []).forEach((query) => {
    if (query.data) {
      client.writeQuery({
        data: query.data,
        query: gql(query.query),
        variables: query.variables,
      });
    }
  });
};
