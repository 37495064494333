
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "socialAccountsSocialTypesGoogleAdminTypeName": {
      "id": "social_accounts.social_types.google_admin_type.name",
      "defaultMessage": "Google Admin"
    },
    "socialAccountsSocialTypesGoogleTypeName": {
      "id": "social_accounts.social_types.google_type.name",
      "defaultMessage": "Google"
    },
    "socialAccountsSocialTypesGoogleTypeAccessType": {
      "id": "social_accounts.social_types.google_type.access_type",
      "defaultMessage": "offline"
    },
    "socialAccountsSocialTypesGoogleTypePrompt": {
      "id": "social_accounts.social_types.google_type.prompt",
      "defaultMessage": "consent"
    },
    "socialAccountsSocialTypesTwitterTypeName": {
      "id": "social_accounts.social_types.twitter_type.name",
      "defaultMessage": "Twitter"
    },
    "socialAccountsSocialTypesFacebookTypeName": {
      "id": "social_accounts.social_types.facebook_type.name",
      "defaultMessage": "Facebook"
    },
    "socialAccountsSocialTypesFacebookTypeAccessType": {
      "id": "social_accounts.social_types.facebook_type.access_type",
      "defaultMessage": " "
    },
    "socialAccountsSocialTypesFacebookTypePrompt": {
      "id": "social_accounts.social_types.facebook_type.prompt",
      "defaultMessage": " "
    },
    "socialAccountsSocialTypesGoogleAdminTypeConnect": {
      "id": "social_accounts.social_types.google_admin_type.connect",
      "defaultMessage": "Connect Google Admin Account"
    },
    "socialAccountsSocialTypesGoogleAdminTypeConnectMessage": {
      "id": "social_accounts.social_types.google_admin_type.connect_message",
      "defaultMessage": "- Domain verification and sitemap management\n- Additional visitor statistics and analytics"
    },
    "socialAccountsSocialTypesGoogleAdminTypeScope": {
      "id": "social_accounts.social_types.google_admin_type.scope",
      "defaultMessage": "profile email https://www.googleapis.com/auth/analytics https://www.googleapis.com/auth/analytics.edit https://www.googleapis.com/auth/analytics.provision https://www.googleapis.com/auth/siteverification https://www.googleapis.com/auth/webmasters"
    },
    "socialAccountsSocialTypesGoogleAdminTypeSignInPath": {
      "id": "social_accounts.social_types.google_admin_type.sign_in_path",
      "defaultMessage": "/sign-in/google"
    },
    "socialAccountsSocialTypesGoogleAdminTypeSignInVariable": {
      "id": "social_accounts.social_types.google_admin_type.sign_in_variable",
      "defaultMessage": "googleCode"
    },
    "socialAccountsSocialTypesGoogleTypeConnect": {
      "id": "social_accounts.social_types.google_type.connect",
      "defaultMessage": "Connect Google Accounts"
    },
    "socialAccountsSocialTypesGoogleTypeConnectMessage": {
      "id": "social_accounts.social_types.google_type.connect_message",
      "defaultMessage": "- Connect or claim and verify a Google Business Profile account\n- Schedule and publish local posts and offers directly to Google\n- Automatically sync business and menu data"
    },
    "socialAccountsSocialTypesGoogleTypeScope": {
      "id": "social_accounts.social_types.google_type.scope",
      "defaultMessage": "profile email https://www.googleapis.com/auth/business.manage"
    },
    "socialAccountsSocialTypesGoogleTypeSignInPath": {
      "id": "social_accounts.social_types.google_type.sign_in_path",
      "defaultMessage": "/sign-in/google"
    },
    "socialAccountsSocialTypesGoogleTypeSignInVariable": {
      "id": "social_accounts.social_types.google_type.sign_in_variable",
      "defaultMessage": "googleCode"
    },
    "socialAccountsSocialTypesTwitterTypeAccessType": {
      "id": "social_accounts.social_types.twitter_type.access_type",
      "defaultMessage": " "
    },
    "socialAccountsSocialTypesTwitterTypeConnect": {
      "id": "social_accounts.social_types.twitter_type.connect",
      "defaultMessage": "Connect Twitter Accounts"
    },
    "socialAccountsSocialTypesTwitterTypeConnectMessage": {
      "id": "social_accounts.social_types.twitter_type.connect_message",
      "defaultMessage": "- Schedule and publish Tweets\n- Track Tweet favorites and other analytics"
    },
    "socialAccountsSocialTypesTwitterTypePrompt": {
      "id": "social_accounts.social_types.twitter_type.prompt",
      "defaultMessage": " "
    },
    "socialAccountsSocialTypesTwitterTypeScope": {
      "id": "social_accounts.social_types.twitter_type.scope",
      "defaultMessage": " "
    },
    "socialAccountsSocialTypesTwitterTypeSignInPath": {
      "id": "social_accounts.social_types.twitter_type.sign_in_path",
      "defaultMessage": "/sign-in/twitter"
    },
    "socialAccountsSocialTypesTwitterTypeSignInVariable": {
      "id": "social_accounts.social_types.twitter_type.sign_in_variable",
      "defaultMessage": "twitterCode"
    },
    "socialAccountsSocialTypesFacebookTypeConnect": {
      "id": "social_accounts.social_types.facebook_type.connect",
      "defaultMessage": "Connect Meta Accounts"
    },
    "socialAccountsSocialTypesFacebookTypeConnectMessage": {
      "id": "social_accounts.social_types.facebook_type.connect_message",
      "defaultMessage": "- Schedule and publish Facebook and Instagram posts\n- Track post reactions and other analytics"
    },
    "socialAccountsSocialTypesFacebookTypeScope": {
      "id": "social_accounts.social_types.facebook_type.scope",
      "defaultMessage": "public_profile email pages_read_engagement pages_read_user_content pages_manage_posts read_insights business_management"
    },
    "socialAccountsSocialTypesInstagramTypeScope": {
      "id": "social_accounts.social_types.instagram_type.scope",
      "defaultMessage": "instagram_basic instagram_content_publish instagram_manage_insights"
    },
    "socialAccountsSocialTypesFacebookTypeSignInPath": {
      "id": "social_accounts.social_types.facebook_type.sign_in_path",
      "defaultMessage": "/sign-in/facebook"
    },
    "socialAccountsSocialTypesFacebookTypeSignInVariable": {
      "id": "social_accounts.social_types.facebook_type.sign_in_variable",
      "defaultMessage": "facebookCode"
    },
    "socialAccountsSocialTypesInstagramTypeConnected": {
      "id": "social_accounts.social_types.instagram_type.connected",
      "defaultMessage": "Connected to Instagram"
    },
    "socialAccountsSocialTypesInstagramTypeConnectedByUser": {
      "id": "social_accounts.social_types.instagram_type.connected_by_user",
      "defaultMessage": "Connected {date} by {userDisplayName}"
    },
    "socialAccountsSocialTypesInstagramTypeHowToConnect": {
      "id": "social_accounts.social_types.instagram_type.how_to_connect",
      "defaultMessage": "How to Connect to Instagram"
    },
    "socialAccountsSocialTypesInstagramTypeBackToIntegrations": {
      "id": "social_accounts.social_types.instagram_type.back_to_integrations",
      "defaultMessage": "Go Back to the Integrations Page of Popmenu."
    },
    "socialAccountsSocialTypesInstagramTypeStepsTitle": {
      "id": "social_accounts.social_types.instagram_type.steps_title",
      "defaultMessage": "Steps:"
    },
    "socialAccountsSocialTypesInstagramTypeSteps": {
      "id": "social_accounts.social_types.instagram_type.steps",
      "defaultMessage": "Log into your page and navigate to Pages\n Click on the appropriate Facebook Business Page\n Click on Page Settings\n Click on Instagram\n Click the Connect Account Button"
    },
    "socialAccountsSocialTypesInstagramTypeClickOnSettingsIcon": {
      "id": "social_accounts.social_types.instagram_type.click_on_settings_icon",
      "defaultMessage": "You will need to click on the settings icon and reauthorize Facebook"
    },
    "socialAccountsSocialTypesInstagramTypeCloseButton": {
      "id": "social_accounts.social_types.instagram_type.close_button",
      "defaultMessage": "DONE"
    },
    "socialAccountsSocialTypesInstagramTypeConnect": {
      "id": "social_accounts.social_types.instagram_type.connect",
      "defaultMessage": "Connect to Instagram"
    },
    "socialAccountsSocialTypesInstagramNotConnected": {
      "id": "social_accounts.social_types.instagram_type.not_connected",
      "defaultMessage": "Instagram not connected"
    },
    "socialAccountsSocialTypesInstagramTypeRequiresReauthentication": {
      "id": "social_accounts.social_types.instagram_type.requires_reauthentication",
      "defaultMessage": "Requires re-authentication"
    },
    "socialAccountsSocialTypesConnected": {
      "id": "social_accounts.connected",
      "defaultMessage": "Connected"
    },
    "socialAccountsNewDescription": {
      "id": "social_accounts.new_description",
      "defaultMessage": "Connect a platform to begin using social posts and other integrated services. You can connect multiple accounts to manage all of your locations through Popmenu."
    },
    "socialAccountsIndex": {
      "id": "social_accounts.index",
      "defaultMessage": "Integrations"
    },
    "socialAccountsNew": {
      "id": "social_accounts.new",
      "defaultMessage": "Connect"
    },
    "socialAccountsSocialTypesFacebookTypeCloseModalWarningTitle": {
      "id": "social_accounts.social_types.facebook_type.close_modal_warning_title",
      "defaultMessage": "Select a Facebook Page"
    },
    "socialAccountsSocialTypesFacebookTypeCloseModalWarningMessage": {
      "id": "social_accounts.social_types.facebook_type.close_modal_warning_message",
      "defaultMessage": "Are you sure you want to exit without selecting a Facebook Page?"
    },
    "socialAccountsSocialTypesTwitterTypeHeader": {
      "id": "social_accounts.social_types.twitter_type.header",
      "defaultMessage": "Twitter/X Accounts"
    },
    "socialAccountsSocialTypesFacebookTypeHeader": {
      "id": "social_accounts.social_types.facebook_type.header",
      "defaultMessage": "Meta Accounts"
    },
    "socialAccountsSocialTypesGoogleTypeHeader": {
      "id": "social_accounts.social_types.google_type.header",
      "defaultMessage": "Google Accounts"
    },
    "socialAccountsEdit": {
      "id": "social_accounts.edit",
      "defaultMessage": "Manage Integration"
    },
    "socialAccountsDestroy": {
      "id": "social_accounts.destroy",
      "defaultMessage": "Remove Integration"
    },
    "socialAccountsDestroyConfirm": {
      "id": "social_accounts.destroy_confirm",
      "defaultMessage": "Are you sure you want to remove this integration?"
    },
    "socialAccountsDestroyConfirmTitle": {
      "id": "social_accounts.destroy_confirm_title",
      "defaultMessage": "Confirm Removal"
    },
    "socialAccountsEmptyFacebookPagesError": {
      "id": "social_accounts.empty_facebook_pages_error",
      "defaultMessage": "We're attempting to load pages, please check back later.\nPlease check Popmenu's permissions or reauthenticate the account, if pages do not load for a prolonged period of time."
    },
    "socialAccountsGoogleMyBusinessDescription": {
      "id": "social_accounts.google_my_business_description",
      "defaultMessage": "Connect your Google Account to get started."
    },
    "socialAccountsExpiredError": {
      "id": "social_accounts.expired_error",
      "defaultMessage": "Refresh credentials in the Integrations settings to continue posting"
    },
    "socialAccountsTabsSocialPostTab": {
      "id": "social_accounts.tabs.social_post_tab",
      "defaultMessage": "Social Posts"
    },
    "socialAccountsTabsGmbPostTab": {
      "id": "social_accounts.tabs.gmb_post_tab",
      "defaultMessage": "Google Business Profile Posts"
    },
    "socialAccountsDisconnect": {
      "id": "social_accounts.menu.disconnect",
      "defaultMessage": "Disconnect"
    },
    "socialAccountsReverify": {
      "id": "social_accounts.menu.reverify",
      "defaultMessage": "Re-verify"
    },
    "socialAccountsMenuEdit": {
      "id": "social_accounts.menu.edit",
      "defaultMessage": "Edit"
    },
    "socialAccountsViewPage": {
      "id": "social_accounts.menu.view_page",
      "defaultMessage": "View page"
    }
  }
);
export default defaultMessages;

