import React from 'react';
import PropTypes from 'prop-types';
import { compose, mapProps } from '@shakacode/recompose';

import { isTransparentColor } from '../../utils/colors';
import { themeShape, withTheme } from '../../utils/withTheme';
import { withWindowContext } from '../../shared/WindowProvider';

import Loading from '../../shared/Loading';

const LoadingPage = ({ maxNavbarHeight, theme }) => {
  let marginTop = 0;
  let paddingTop = 0;
  if (isTransparentColor(theme.navbarBgColor) && maxNavbarHeight) {
    marginTop = `${maxNavbarHeight * -1}px`;
    paddingTop = `${maxNavbarHeight}px`;
  }
  return (
    <div
      className="pm-page-loading-wrap"
      id="pm-page-loading-wrap"
      style={{ marginTop, paddingTop }}
    >
      <Loading size="lg" />
    </div>
  );
};

LoadingPage.propTypes = {
  maxNavbarHeight: PropTypes.number.isRequired,
  theme: themeShape.isRequired,
};

export default compose(
  withTheme,
  withWindowContext,
  mapProps(({ window, ...props }) => ({
    ...props,
    maxNavbarHeight: window.maxNavbarHeight,
  })),
)(LoadingPage);
