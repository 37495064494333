import { useEffect } from 'react';

const AriaHiddenFixDialog = () => {
  // Temporary fix for the Material UI dialog regarding accessibility
  // Issue still open https://github.com/mui/material-ui/issues/19450
  useEffect(() => {
    const consumerApp = document.getElementById('consumer-app');
    const observer = new MutationObserver(() => {
      if (consumerApp?.getAttribute('aria-hidden')) {
        consumerApp.removeAttribute('aria-hidden');
      }
    });

    if (consumerApp) {
      observer.observe(consumerApp, {
        attributeFilter: ['aria-hidden'],
      });
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return null;
};

export default AriaHiddenFixDialog;
