import { createEvent } from '~/utils/eventable';
import { removeHostName } from '../utils/urls';

export const processOutboundLinkClick = (event: MouseEvent) => {
  try {
    const a = (event.target as Element).closest('a');
    if (a && (a.protocol === 'https:' || a.protocol === 'http:')) {
      const host = new URL(a.href).hostname;
      const currentHost = window.location.hostname;
      if (removeHostName(host) !== removeHostName(currentHost)) {
        let eventType = a.innerText;

        if (!eventType) {
          const img = a.querySelector('img');
          eventType = img && img.alt && img.alt.trim() !== '' ? img.alt : 'unknown';
        }

        createEvent({
          eventableType: 'OutboundLinkClick',
          eventLabel: a.href,
          eventType,
        });
      }
    }
  } catch (err) {
    console.warn(err);
  }
};
