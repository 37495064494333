import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "orderingDineInViewOrder": {
      "id": "ordering.dine_in.view_order",
      "defaultMessage": "View Order"
    },
    "orderingDineInViewMyTab": {
      "id": "ordering.dine_in.view_my_tab",
      "defaultMessage": "View My Tab"
    },
    "orderingDineInPlaceOrder": {
      "id": "ordering.dine_in.place_order",
      "defaultMessage": "Place Order"
    },
    "orderingDineInAreYouSure": {
      "id": "ordering.dine_in.are_you_sure",
      "defaultMessage": "Are you sure you want to place this order?"
    },
    "orderingDineInCloseMyTab": {
      "id": "ordering.dine_in.close_my_tab",
      "defaultMessage": "Close My Tab"
    },
    "orderingDineInCloseTabForX": {
      "id": "ordering.dine_in.close_tab_for_x",
      "defaultMessage": "Are you sure you want to close your tab for"
    }
  }
);
export default defaultMessages;
