import { findElements } from './dom';

const FRAME_TITLES: Record<string, string> = {
  'calendar.time.ly': 'Event Calendar via Timely',
  'chat.chekkit.io': 'Message us via Chekkit',
  'chownow.com': 'Order Online via ChowNow',
  'events.time.ly': 'Event Calendar via Timely',
  'formsite.com': 'Contact Us via Formsite',
  'google.com/maps/embed': 'Google Map',
  'iframeButton/orderNow': 'Order with Grubhub',
  'opentable.com': 'Make a Reservation on OpenTable',
  'powr.io/instagram-feed': 'Instagram Feed',
  'powr.io/plugins/instagram-feed': 'Instagram Feed',
  'powr.io/social-feed': 'Social Feed',
  'resy.com': 'Make a Reservation on Resy',
  'revlocal.com': 'Customer Reviews by RevLocal',
  'vimeo.com': 'Video on Vimeo',
  'youtube.com': 'Video on YouTube',
};

const IMG_ALTS: Record<string, string> = {
  'gathercdn.com': 'Book Your Event on Gather',
  'groupraise-pro': 'Fundraise Here',
};

export const fixEmbedAccessibility = (context: Optional<HTMLElement>) => {
  if (!context) {
    return;
  }
  console.log('[POPMENU] Fixing third-party accessibility...');

  // Fix blank title on third-party iframes
  findElements('iframe', context).forEach((frame) => {
    if (frame.src && !frame.title) {
      Object.entries(FRAME_TITLES).forEach(([srcPart, frameTitle]) => {
        if (frame.src.includes(srcPart)) {
          frame.title = frameTitle; // eslint-disable-line no-param-reassign
          console.log(`[POPMENU] Fixed frame title for ${frame.src}`);
        }
      });
    }
  });

  // Fix empty alt attribute on third-party imgs
  findElements('img', context).forEach((img) => {
    if (img.src && !img.alt) {
      Object.entries(IMG_ALTS).forEach(([srcPart, imgAlt]) => {
        if (img.src.includes(srcPart)) {
          img.alt = imgAlt; // eslint-disable-line no-param-reassign
          console.log(`[POPMENU] Fixed img alt for ${img.src}`);
        }
      });
    }
  });

  // Ecwid fixes
  findElements('.ecwid-fieldLabel', context).forEach((label) => {
    if (label.getAttribute('role') === 'text') {
      label.removeAttribute('role');
    }
  });
  findElements('.gwt-TextBox', context).forEach((input) => {
    if (input.getAttribute('name') === 'qty') {
      input.setAttribute('aria-label', 'Quantity');
    }
  });

  // Fix Tripleseat lighthouse errors
  findElements('#referral_label').forEach((label) => {
    if (label.getAttribute('id') === 'referral_label') {
      label.setAttribute('for', 'lead_referral_source_id');
    }
  });

  // BeerMenu meta viewport tag removal
  findElements('#menu_widget').forEach((tag) => {
    tag.querySelector('meta')?.remove();
  });
};
