import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@popmenu/admin-ui';
import IconButton from '@material-ui/core/IconButton';
import { Tooltip, Icon } from '@popmenu/common-ui';

import { Trash } from '@popmenu/web-icons';
import { withIntl } from '../../utils/withIntl';

import Confirm from './Confirm';
import Mutation from '../../shared/Mutation';

const DestroyControl = props => (
  <Mutation
    mutation={props.mutation}
    refetchQueries={props.refetchQueries}
    onCompleted={props.onRemoveComplete}
    onError={(err) => {
      if (typeof props.onError === 'function') {
        props.onError(err);
      }
    }}
  >
    {(destroyMutation, { loading }) => (
      <Confirm
        loading={loading}
        message={props.i18nKey ? props.t(`${props.i18nKey}.destroy_confirm`) : props.confirmMessage}
        onConfirm={() => {
          destroyMutation({
            update: () => {
              if (typeof props.onCompleted === 'function') {
                props.onCompleted();
              }
            },
            variables: props.variables,
          });
        }}
        title={props.i18nKey ? props.t(`${props.i18nKey}.destroy_confirm_title`) : props.confirmTitle}
      >
        {({ openModal }) => (
          <Tooltip title={props.i18nKey ? props.t(`${props.i18nKey}.destroy`) : props.tooltip || ''}>
            {props.text ? (
              <Button
                aria-label={props.i18nKey ? props.t(`${props.i18nKey}.destroy`) : props.tooltip}
                data-cy="destroy_control"
                data-tour-id={props['data-tour-id']}
                onClick={(evt) => {
                  if (props.onClick) {
                    props.onClick(evt);
                  }
                  openModal();
                }}
                startIcon={<Icon icon={Trash} />}
                size={props.size || 'medium'}
                variant="secondary"
                {...props.ButtonProps}
              >
                {props.text}
              </Button>
            ) : (
              <IconButton
                aria-label={props.i18nKey ? props.t(`${props.i18nKey}.destroy`) : props.tooltip}
                data-cy="destroy_control"
                data-tour-id={props['data-tour-id']}
                onClick={(evt) => {
                  if (props.onClick) {
                    props.onClick(evt);
                  }
                  openModal();
                }}
                size="small"
                color="inherit"
                {...props.ButtonProps}
              >
                <Icon icon={Trash} />
              </IconButton>
            )}
          </Tooltip>
        )}
      </Confirm>
    )}
  </Mutation>
);

DestroyControl.defaultProps = {
  ButtonProps: {},
  confirmMessage: null,
  confirmTitle: null,
  'data-tour-id': null,
  i18nKey: null,
  icon: null,
  onClick: null,
  onCompleted: null,
  onError: null,
  refetchQueries: [],
  size: undefined,
  text: null,
  tooltip: null,
  variant: undefined,
};

DestroyControl.propTypes = {
  ButtonProps: PropTypes.object,
  confirmMessage: PropTypes.string,
  confirmTitle: PropTypes.string,
  'data-tour-id': PropTypes.string,
  i18nKey: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  mutation: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  onCompleted: PropTypes.func,
  onError: PropTypes.func,
  refetchQueries: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(PropTypes.object)]),
  size: PropTypes.oneOf(['lg', 'md', 'sm', 'xs']),
  t: PropTypes.func.isRequired,
  text: PropTypes.string,
  tooltip: PropTypes.string,
  variables: PropTypes.object.isRequired,
  variant: PropTypes.oneOf(['contained', 'outlined', 'text']),
};

export default withIntl(DestroyControl);
