export const SET_OFFER_DETAILS = 'SET_OFFER_DETAILS';
export const CLEAR_OFFER_DETAILS = 'CLEAR_OFFER_DETAILS';
export const SET_MARKETING_OFFER_DETAILS = 'SET_MARKETING_OFFER_DETAILS';
export const CLEAR_MARKETING_OFFER_DETAILS = 'CLEAR_MARKETING_OFFER_DETAILS';
export const SET_WRONG_LOCATION_OFFER = 'SET_WRONG_LOCATION_OFFER';
export const CLEAR_WRONG_LOCATION_OFFER = 'CLEAR_WRONG_LOCATION_OFFER';

export function setOfferDetails(offerValues) {
  return (dispatch) => {
    dispatch({
      offerValues,
      type: SET_OFFER_DETAILS,
    });
  };
}

export function clearOfferDetails() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_OFFER_DETAILS,
    });
  };
}

export function setMarketingOfferDetails(offerValues) {
  return (dispatch) => {
    dispatch({
      offerValues,
      type: SET_MARKETING_OFFER_DETAILS,
    });
  };
}

export function clearMarketingOfferDetails() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_MARKETING_OFFER_DETAILS,
    });
  };
}

export function setShowWrongLocationOffer(wrongLocationOfferDetails) {
  return (dispatch) => {
    dispatch({
      type: SET_WRONG_LOCATION_OFFER,
      wrongLocationOfferDetails,
    });
  };
}

export function clearShowWrongLocationOffer() {
  return (dispatch) => {
    dispatch({
      type: CLEAR_WRONG_LOCATION_OFFER,
    });
  };
}
