import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Icon } from '@popmenu/common-ui';
import { ShoppingBag } from '@popmenu/web-icons';

import { setShowMobileCartModal } from '../../../shared/ConsumerActions';
import { useCurrentSession } from '../../../shared/CurrentSessionProvider';

const CartButtonDisplay = ({ classes }) => {
  const { refetch, loading, pendingMenuItemCart: menuItemCart } = useCurrentSession();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!loading && (menuItemCart.isSubmitted || menuItemCart.isTabClosedConsumer)) {
      refetch();
    }
  }, [refetch, loading, menuItemCart.isSubmitted, menuItemCart.isTabClosedConsumer]);
  let quantity = 0;
  menuItemCart.selectedMenuItems.forEach((selectedMenuItem) => {
    quantity += (selectedMenuItem.quantity || 0);
  });

  return (
    <div className={classes.buttonContainer}>
      <Button
        aria-label="View My Order"
        className={classes.button}
        startIcon={<Icon icon={ShoppingBag} />}
        onClick={() => dispatch(setShowMobileCartModal(true))}
        size="large"
        variant="text"
        data-cy="cart_button"
      />
      {quantity !== 0 && (
        <div aria-hidden className={classes.buttonQuantity}>
          {quantity}
        </div>
      )}
    </div>
  );
};

export default CartButtonDisplay;
