import type { DishesAction } from './DishActions';
import {
  CLOSE_ADD_TO_CART_MODAL,
  CLOSE_MENU_ITEM_MODAL,
  CLOSE_EDIT_SELECTED_ITEM_MODAL,
  CLOSE_EDIT_MENU_ITEM_CART_MODAL,
  CLOSE_EDIT_MENU_ITEM_CART_LOCATION_MODAL,
  OPEN_ADD_TO_CART_MODAL,
  OPEN_MENU_ITEM_MODAL,
  OPEN_EDIT_SELECTED_ITEM_MODAL,
  OPEN_EDIT_MENU_ITEM_CART_MODAL,
  OPEN_EDIT_MENU_ITEM_CART_LOCATION_MODAL,
  OPEN_EDIT_MENU_ITEM_CART_MODAL_FROM_CUSTOM_PAGE,
  PREVENT_EDIT_MENU_ITEM_CART_MODAL_ON_CLOSE_CALLBACK,
  SET_ALLOW_SUGGESTED_ITEMS,
  SET_DISCREPANCY_MENU_ITEM_IDS,
  SET_MENU_ID,
  SET_MENU_ITEM_ID,
  SET_SUGGESTED_ITEMS_WITH_MODIFIERS,
} from './DishActions';
import type { DishesState } from './DishesState';

const initialState: DishesState = {
  allowSuggestedItems: true,
  discrepancyMenuItemIds: [],
  editing: false,
  editMenuItemCartModalOnCloseCallback: null,
  locationId: null,
  menuEnabled: true,
  menuId: null,
  menuItemCart: null,
  menuItemId: null,
  menuOrderingUrl: null,
  onCloseCallback: null,
  onSelectedMenuItemEditComplete: null,
  preserveAddToCartModal: false,
  remindToTry: null,
  scheduledAt: null,
  selectedMenuItemId: null,
  showAddToCartButton: false,
  showAddToCartModal: false,
  showEditMenuItemCartLocationModal: false,
  showEditMenuItemCartModal: false,
  showEditMenuItemCartModalFromMenu: false,
  showMenuItemModal: false,
  showSelectedItemModal: false,
  suggestedItemsWithModifiers: [],
};

const dishes = (state: DishesState = initialState, action: DishesAction) => {
  switch (action.type) {
    case CLOSE_ADD_TO_CART_MODAL:
      return {
        ...state,
        remindToTry: null,
        showAddToCartButton: false,
        showAddToCartModal: false,
        showMenuItemModal: false,
      };

    case CLOSE_MENU_ITEM_MODAL:
      return {
        ...state,
        menuOrderingUrl: null,
        scheduledAt: null,
        showAddToCartButton: false,
        showMenuItemModal: false,
      };

    case CLOSE_EDIT_SELECTED_ITEM_MODAL:
      return {
        ...state,
        selectedMenuItemId: null,
        showSelectedItemModal: false,
      };

    case CLOSE_EDIT_MENU_ITEM_CART_LOCATION_MODAL:
      return {
        ...state,
        locationId: null,
        showEditMenuItemCartLocationModal: false,
      };

    case CLOSE_EDIT_MENU_ITEM_CART_MODAL:
      return {
        ...state,
        editing: false,
        menuItemCart: null,
        showEditMenuItemCartModal: false,
      };

    case OPEN_ADD_TO_CART_MODAL:
      return {
        ...state,
        menuId: action.menuId || state.menuId,
        menuItemId: action.menuItemId,
        showAddToCartButton: false,
        showAddToCartModal: true,
        showEditMenuItemCartModalFromMenu: false,
        showMenuItemModal: false,
      };

    case OPEN_MENU_ITEM_MODAL:
      return {
        ...state,
        menuEnabled: action.menuEnabled,
        menuItemId: action.menuItemId,
        menuOrderingUrl: action.menuOrderingUrl,
        scheduledAt: action.scheduledAt,
        showAddToCartButton: action.showAddToCartButton !== null ? action.showAddToCartButton : state.showAddToCartButton,
        showMenuItemModal: true,
      };

    case OPEN_EDIT_SELECTED_ITEM_MODAL:
      return {
        ...state,
        onSelectedMenuItemEditComplete: action.onSelectedMenuItemEditComplete,
        selectedMenuItemId: action.selectedMenuItemId,
        showAddToCartModal: false,
        showMenuItemModal: false,
        showSelectedItemModal: true,
      };

    case OPEN_EDIT_MENU_ITEM_CART_MODAL:
      return {
        ...state,
        editing: action.editing,
        editMenuItemCartModalOnCloseCallback: action.onCloseCallback,
        menuItemCart: action.menuItemCart,
        showEditMenuItemCartModal: true,
        showEditMenuItemCartModalFromMenu: false,
        showMenuItemModal: action.preserveAddToCartModal || false,
        showSelectedItemModal: false,
      };

    case OPEN_EDIT_MENU_ITEM_CART_LOCATION_MODAL:

      return {
        ...state,
        locationId: action.locationId,
        showEditMenuItemCartLocationModal: true,
      };

    case OPEN_EDIT_MENU_ITEM_CART_MODAL_FROM_CUSTOM_PAGE:

      return {
        ...state,
        menuId: action.menuId,
        menuItemId: action.menuItemId,
        remindToTry: action.remindToTry,
        showAddToCartModal: true,
        showEditMenuItemCartModalFromMenu: true,
      };

    case PREVENT_EDIT_MENU_ITEM_CART_MODAL_ON_CLOSE_CALLBACK:
      return {
        ...state,
        editMenuItemCartModalOnCloseCallback: null,
        menuItemCart: null,
        showEditMenuItemCartModal: false,
      };

    case SET_MENU_ITEM_ID:
      return {
        ...state,
        menuItemId: action.menuItemId,
      };

    case SET_MENU_ID:
      return {
        ...state,
        menuId: action.menuId,
      };

    case SET_DISCREPANCY_MENU_ITEM_IDS:
      return {
        ...state,
        discrepancyMenuItemIds: action.discrepancyMenuItemIds,
      };

    case SET_ALLOW_SUGGESTED_ITEMS:
      return {
        ...state,
        allowSuggestedItems: action.allowSuggestedItems,
      };

    case SET_SUGGESTED_ITEMS_WITH_MODIFIERS:
      return {
        ...state,
        suggestedItemsWithModifiers: action.suggestedItemsWithModifiers,
      };
    default:
      return state;
  }
};

export default dishes;
