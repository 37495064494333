// Application static props
// These are set by the server and are kept constant for the lifetime of the webpage
// the isMobileUserAgent prop here is set based on the UserAgent string only and does not change after the page is loaded.
// The isMobile prop in WindowsSizeProvider can change after the page is loaded if the UserAgent string indicates a mobile while the screen size is large enough to be a desktop.
type AppStaticProps = {
  isMobileUserAgent: boolean;
};

// Applications props provided to ConsumerApp, AdminApp, or WidgetApp when rendered on the server
type AppSSRProps = {
  mobile: boolean;
  tablet: boolean;
}

let properties: AppStaticProps | undefined;
export function setAppStaticProps(props: AppSSRProps) {
  properties = {
    isMobileUserAgent: props.mobile || props.tablet,
  };
}

export function getAppStaticProps(): AppStaticProps {
  if (!properties) {
    throw new Error('Application properties not set before access.');
  }
  return properties;
}
