export default theme => ({
  draftBannerContainer: {
    backgroundColor: 'white',
    display: 'flex',
    overflow: 'hidden',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    position: 'relative',
    width: '100%',
    zIndex: 100,
  },
  draftBannerDismissButton: {
    alignSelf: 'center',
    color: 'black',
  },
  draftBannerText: {
    color: 'black',
    display: 'block',
    marginRight: theme.spacing(4),
    minHeight: theme.spacing(6),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    textAlign: 'center',
    width: '100%',
  },
});
