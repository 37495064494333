import type { RequestHandler } from '~/lazy_apollo/client/link/core';
// this file generates dynamically
// eslint-disable-next-line import/no-unresolved
import _aliases from 'OperationStoreAliases';
/**
 * The client who synced these operations with the server
 * @return {String}
 * @private
 */
const _client = 'PopmenuClient';

type OperationStoreClient = {
  apolloLink: RequestHandler,
  getOperationId: (operationName: string) => string,
  getPersistedQueryAlias: (operationName: string) => string,
};

const operationStoreClient: OperationStoreClient = {
  /**
   * Satisfy the Apollo Link API.
   * This link checks for an operation name, and if it's present,
   * sets the HTTP context to _not_ include the query,
   * and instead, include `extensions.operationId`.
   * (This is inspired by apollo-link-persisted-queries.)
   */
  /* eslint-disable no-param-reassign */
  apolloLink(operation, forward) {
    if (operation.operationName) {
      const operationId = operationStoreClient.getOperationId(operation.operationName);
      operation.setContext({
        http: {
          includeExtensions: true,
          includeQuery: false,
        },
      });
      operation.extensions.operationId = operationId;
    }
    return forward(operation);
  },
  /* eslint-enable no-param-reassign */

  // Build a string for `params[:operationId]`
  getOperationId(operationName) {
    return `${_client}/${operationStoreClient.getPersistedQueryAlias(operationName)}`;
  },

  // Fetch a persisted alias from a local operation name
  getPersistedQueryAlias(operationName) {
    const persistedAlias = _aliases?.[operationName];
    if (!persistedAlias) {
      throw new Error(`Failed to find persisted alias for operation name: ${String(operationName)}`);
    } else {
      return persistedAlias;
    }
  },
};

export default (process.env.OPERATION_STORE_ENABLED ? operationStoreClient : null);
