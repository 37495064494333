export interface VisibilityOptions {
  // rootMargin is a string representing the margin around the viewport.
  // "200px" means that the callback will be executed when the element is 200px below the viewport.
  rootMargin?: string;
  // threshold is a number between 0 and 1 representing the proportion of the
  // element that needs to be in the viewport to trigger scrolledTo update.
  threshold?: number;
  // If true components above the viewport will be considered visible.
  componentsAboveViewportAreVisible?: boolean;
}

export function isVisible(observedNode?: Element, options?: VisibilityOptions) {
  if (!observedNode) return false;
  const { rootMargin, threshold = 0, componentsAboveViewportAreVisible = false } = options ?? {};
  const rootMarginInt = parseInt(rootMargin ?? '', 10) || 0;

  const rect = observedNode.getBoundingClientRect();
  const windowHeight = (window.innerHeight || document.documentElement.clientHeight);
  const windowWidth = (window.innerWidth || document.documentElement.clientWidth);

  const componentBottomIsBelowViewTop = rect.top + rect.height * (1 - threshold) + rootMarginInt > 0;
  const componentTopIsAboveViewBottom = rect.bottom - rect.height * (1 - threshold) - rootMarginInt < windowHeight;

  const componentRightIsAfterViewLeft = rect.left + rect.width * (1 - threshold) + rootMarginInt > 0;
  const componentLeftIsBeforeViewRight = rect.right - rect.width * (1 - threshold) - rootMarginInt < windowWidth;

  return (componentBottomIsBelowViewTop || !!componentsAboveViewportAreVisible) && componentTopIsAboveViewBottom &&
         componentRightIsAfterViewLeft && componentLeftIsBeforeViewRight;
}

export const countElementsInViewport = (selector: string, visibilityOptions: VisibilityOptions = {}) => {
  const elements = document.querySelectorAll(selector);
  let count = 0;
  elements.forEach((element) => {
    // offsetParent is null for elements with display: none
    if (!(element instanceof HTMLElement) || element.offsetParent === null) return;
    if (isVisible(element, visibilityOptions)) count += 1;
  });
  return count;
};
