// Import type only, values should be imported dynamically
import type { EventVariables, InitAnalyticsOptions, SnowplowContext } from '~/utils/eventableCallbacks';

/** @internal Currently used only for tests, but fine to use otherwise as well */
export type { EventVariables } from '~/utils/eventableCallbacks';

export const initAnalytics = (options: InitAnalyticsOptions) => {
  void import(/* webpackChunkName: "eventable" */ './eventableCallbacks').then(({ initAnalyticsCallback }) => {
    initAnalyticsCallback(options);
  });
};

export const createEvent = (variables: EventVariables) => {
  void import(/* webpackChunkName: "eventable" */ './eventableCallbacks').then(({ createEventCallback }) => {
    createEventCallback(variables);
  });
};

export const createPageView = (pageSnowplowContexts: SnowplowContext[] = []) => {
  void import(/* webpackChunkName: "eventable" */ './eventableCallbacks').then(({ createPageViewCallback }) => {
    createPageViewCallback(pageSnowplowContexts);
  });
};
