export function asError(err: unknown): Error {
  if (err instanceof Error) {
    return err;
  }
  if (err == null || typeof err !== 'object') {
    const result = {
      message: String(err),
      name: typeof err,
      toString() {
        return `${this.name}: ${this.message}`;
      },
    };
    return result;
  }
  const _err = err as Record<string, unknown>;
  const { name: _name, stack, cause, ...rest } = _err;
  const name = typeof _name === 'string' ? _name : _err.constructor.name;
  const errHasOwnToString = _err.toString !== Object.prototype.toString;
  let message: string;
  if (typeof _err.message === 'string') {
    message = _err.message;
  } else {
    try {
      message = JSON.stringify(rest);
    } catch {
      // eslint-disable-next-line @typescript-eslint/no-base-to-string
      message = errHasOwnToString ? _err.toString() : 'No message found';
    }
  }
  const result: Error & { toString(): string; cause?: unknown } = {
    message,
    name,
    toString() {
      return `${this.name}: ${this.message}`;
    },
  };
  if (errHasOwnToString) {
    result.toString = _err.toString.bind(_err);
  }
  if (typeof stack === 'string') {
    result.stack = stack;
  }
  if ('cause' in _err) {
    result.cause = cause;
  }
  return result;
}

export const asErrorString = (err: unknown) => asError(err).toString();
