import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Icon } from '@popmenu/common-ui';
import { ChevronDown } from '@popmenu/web-icons';

import { useTheme } from '../../../utils/withTheme';
import { readableFontColor } from '../../../utils/colors';
import { classNames, makeStyles } from '../../../utils/withStyles';
import styles from './styles';

type MoreOverflowLinkProps = {
  className?: string,
  isSplit: boolean,
  noBackground?: boolean,
  moreDropdownLinks?: (React.ReactElement | null)[][],
  navItemStyles: () => React.CSSProperties,
};

const useStyles = makeStyles(styles);

const MoreOverflowLink = ({
  moreDropdownLinks = [],
  noBackground = false,
  navItemStyles,
}: MoreOverflowLinkProps) => {
  const classes = useStyles();
  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);

  const shouldShowMenu = useMemo(
    () => moreDropdownLinks.some(links => links.some(link => link !== null)), [moreDropdownLinks],
  );

  const backgroundColor = noBackground ? null : theme.navbarBgColor; // more not featurable for now

  return (
    <React.Fragment>
      {shouldShowMenu && (
      <li>
        <div
          className="a nav-dropdown nav-more-dropdown"
          style={navItemStyles()}
          tabIndex={-1}
          role="button"
          aria-expanded={isExpanded}
          onFocus={() => setIsExpanded(true)}
          onBlur={() => setIsExpanded(false)}
          data-testid="more-overflow-link"
        >
          <span className={classes.moreLink}><FormattedMessage id="general.more" defaultMessage="More" /> <Icon aria-hidden icon={ChevronDown} /></span>
          <div
            className={classNames('nav-dropdown-links nav-more-dropdown-links', classes.navMoreDropdown)}
            style={{
              backgroundColor: backgroundColor ?? undefined,
              color: backgroundColor ? readableFontColor(backgroundColor) : undefined,
            }}
          >
            {moreDropdownLinks}
          </div>
        </div>
      </li>
      )}
    </React.Fragment>
  );
};

export default MoreOverflowLink;
