import React from 'react';
import { withRouter, type RouteComponentProps } from 'react-router-dom';
import { branch, compose, mapProps } from '@shakacode/recompose';
import { connect, type ConnectedProps } from 'react-redux';

import { asErrorString } from '~/utils/asError';
import { CustomHTML } from './CustomHTML';
import { closestElement, dangerouslyExecuteScripts, findElements } from '../../utils/dom';
import { fixEmbedAccessibility } from '../../utils/embeds';

import type { PopmenuConfig } from '../../utils/withPopmenuConfig';
import { withPopmenuConfig } from '../../utils/withPopmenuConfig';
import { closeMenuItemModal } from '../../shared/DishActions';

const connector = connect(() => ({}), { closeModal: closeMenuItemModal });
type PropsFromRedux = ConnectedProps<typeof connector>

type OuterProps = {
  id : string;
  html?: string;
}

type InnerProps = OuterProps & PropsFromRedux & {
  history?: RouteComponentProps['history'];
  fixFrameHeight?: boolean;
  cloudflareBaseUrl: string;
  includeRouter: boolean;
}

class CustomContent extends React.PureComponent<InnerProps> {
  fixAccessibilityTimeout: NodeJS.Timeout | null;

  contentRef: React.RefObject<HTMLDivElement>;

  fixFrameTimeouts: NodeJS.Timeout[];

  executed: boolean;

  static defaultProps = {
    fixFrameHeight: false,
    history: undefined,
    html: undefined,
  };

  constructor(props: InnerProps) {
    super(props);
    this.fixAccessibility = this.fixAccessibility.bind(this);
    this.contentRef = React.createRef();
    this.fixAccessibilityTimeout = null;
    this.fixFrameTimeouts = [];
    this.executed = false;
  }

  componentDidMount() {
    this.executeScripts();
    this.fixAccessibility();
    this.fixFrames();
  }

  componentDidUpdate() {
    this.executeScripts();
    this.fixAccessibility();
    this.fixFrames();
  }

  componentWillUnmount() {
    if (this.fixAccessibilityTimeout) clearTimeout(this.fixAccessibilityTimeout);
    this.fixFrameTimeouts.forEach((timeout) => {
      clearTimeout(timeout);
    });
  }

  fixAccessibility() {
    if (this.fixAccessibilityTimeout) clearTimeout(this.fixAccessibilityTimeout);
    fixEmbedAccessibility(this.contentRef.current);
    this.fixAccessibilityTimeout = setTimeout(() => {
      fixEmbedAccessibility(this.contentRef.current);
    }, 750);
  }

  /* eslint-disable no-param-reassign */
  fixFrames() {
    if (this.props.fixFrameHeight) {
      if (this.contentRef.current) {
        findElements('iframe', this.contentRef.current).forEach((iframe) => {
          try {
            if (iframe?.contentWindow?.document?.body) {
              window.addEventListener('message', ({ data: message } : {data: { height: number; width: number; } }) => {
                iframe.style.height = `${message.height}px`;
                iframe.style.width = `${message.width}px`;
              }, false);
            }
          } catch (err) {
            console.log(`Frame error: ${asErrorString(err)}`);
          }
        });
      }
    }
  }
  /* eslint-enable no-param-reassign */

  executeScripts() {
    if (this.contentRef.current && !this.executed) {
      this.executed = true;
      findElements('iframe', this.contentRef.current).forEach((frame) => {
        frame.addEventListener('load', this.fixAccessibility.bind(this), { once: true });
        frame.addEventListener('load', () => {
          const message = { height: document.body.scrollHeight, width: document.body.scrollWidth };
          window.top?.postMessage(message, '*');
        });
        this.fixFrames();
        this.fixFrameTimeouts.push(setTimeout(() => { this.fixFrames(); }, 750));
        this.fixFrameTimeouts.push(setTimeout(() => { this.fixFrames(); }, 1500));
      });
      findElements('script', this.contentRef.current).forEach((script) => {
        script.addEventListener('load', this.fixAccessibility.bind(this), { once: true });
        this.fixFrames();
      });
      dangerouslyExecuteScripts(this.contentRef.current, this.props.id);
    }
  }

  /* eslint-disable jsx-a11y/click-events-have-key-events */
  /* eslint-disable jsx-a11y/no-static-element-interactions */
  render() {
    const { closeModal, cloudflareBaseUrl, fixFrameHeight, history, html, id, includeRouter, ...divProps } = this.props;
    if (!html) {
      return null;
    }
    return (
      <CustomHTML html={html} cloudflareBaseUrl={cloudflareBaseUrl} id={id} contentRef={this.contentRef}>
        {convertedHTML => (
          <div
            {...divProps}
            dangerouslySetInnerHTML={{ __html: convertedHTML }}
            data-disable-hydration-bar
            id={id}
            onClick={(e) => {
            // Intercept clicks on links and pass through react-router to prevent page reload
              if (history) {
                try {
                  const link = closestElement(e.currentTarget, 'a') || closestElement(e.target, 'a');
                  if (link && link.href && link.target !== '_blank') {
                    const url = new URL(link.href);
                    const pathname = `${url.pathname || ''}${url.hash || ''}`;
                    const useReactRouter = url.hostname === window.location.hostname && // Link to same domain
                    url.pathname !== window.location.pathname && // Not current page
                    !pathname.includes('/files/'); // Always download custom files
                    if (useReactRouter) {
                      e.preventDefault();
                      closeModal();
                      history.push(pathname);
                      return false;
                    }
                  }
                } catch (err) {
                  console.warn(`[POPMENU] Error handling link: ${asErrorString(err)}`);
                }
              }
              return true;
            }}
            ref={this.contentRef}
          />
        )}
      </CustomHTML>
    );
    /* eslint-enable jsx-a11y/no-static-element-interactions */
    /* eslint-enable jsx-a11y/click-events-have-key-events */
  }
}

export default compose<InnerProps, OuterProps>(
  withPopmenuConfig,
  mapProps(({ popmenuConfig, ...props } : {popmenuConfig: PopmenuConfig;} & OuterProps) => ({
    ...props,
    cloudflareBaseUrl: popmenuConfig.cloudflareUrl,
    includeRouter: !popmenuConfig.widget,
  })),
  // Conditionally include react-router HOC to support embeds
  branch<InnerProps>(
    props => props.includeRouter,
    withRouter,
  ),
  mapProps<InnerProps, InnerProps & RouteComponentProps>(({ location, match, staticContext, ...props }) => ({
    ...props,
  })),
  connect(() => ({}), { closeModal: closeMenuItemModal }),
)(CustomContent);
