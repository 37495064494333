
import { defineMessages } from 'react-intl';
const defaultMessages = defineMessages(
  {
    "popsPopTypesLovedItPopped": {
      "id": "pops.pop_types.loved_it.popped",
      "defaultMessage": "Loved It!"
    },
    "popsPopTypesWannaTryPopped": {
      "id": "pops.pop_types.wanna_try.popped",
      "defaultMessage": "Remind Me To Try This"
    },
    "popsPopTypesLikedItPopped": {
      "id": "pops.pop_types.liked_it.popped",
      "defaultMessage": "Liked It"
    },
    "popsPopTypesDislikedItPopped": {
      "id": "pops.pop_types.disliked_it.popped",
      "defaultMessage": "Disliked It"
    }
  }
);
export default defaultMessages;

