import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { createEvent } from '~/utils/eventable';

const dispatchEvent = ({ restaurant, name, target }) => {
  createEvent({
    eventableType: 'ActionBar',
    eventLabel: name,
    eventTarget: target,
    eventType: 'action_bar_link_button',
    restaurantId: restaurant.id,
  });
};

const disableClick = (event, isPreview) => {
  if (isPreview) {
    event.stopPropagation();
    event.preventDefault();
    return true;
  }

  return false;
};

const ActionBarLink = ({ classes, link, isUser, restaurant, modalEvents, isPreview }) => {
  const linkTypeChecks = {
    showProfile: isUser && link.linkType === 'sign_up_type',
    showSignInType: (!isUser || isPreview) && link.linkType === 'sign_in_type',
    showSignUp: !isUser && link.linkType === 'sign_up_type',
  };

  if (linkTypeChecks.showSignInType) {
    return (
      <Link
        key={`${link.id}_link`}
        aria-label={link.name}
        className={classes.actionBarLink}
        to={link.url}
        onClick={(e) => {
          if (disableClick(e, isPreview)) return;

          dispatchEvent({
            name: link.name,
            restaurant,
            target: link.linkType,
          });
          modalEvents.setVipData({ showSignInForm: true });
          modalEvents.openVipV2Modal();
        }}
      >
        {link.name}
      </Link>
    );
  }

  if (linkTypeChecks.showProfile) {
    const openProfile = () => {
      if (restaurant.featureSetting.isProfileV2Enabled) {
        modalEvents.openProfileV2Modal('my_profile');
      } else {
        modalEvents.openProfileModal();
      }
    };
    let linkName = link.name;
    if (!isPreview) {
      linkName = <FormattedMessage id="profile.nav_item_my_profile" defaultMessage="My Profile" />;
    }

    return (
      <Link
        key={`${link.id}_link`}
        aria-label={link.name}
        className={classes.actionBarLink}
        to={link.url}
        onClick={(e) => {
          if (disableClick(e, isPreview)) return;

          dispatchEvent({
            name: link.name,
            restaurant,
            target: 'profile_type',
          });
          openProfile();
        }}
      >
        {linkName}
      </Link>
    );
  }

  if (linkTypeChecks.showSignUp) {
    const openSignUp = () => {
      modalEvents.setVipData();
      modalEvents.openVipV2Modal();
    };
    return (
      <Link
        key={`${link.id}_link`}
        aria-label={link.name}
        className={classes.actionBarLink}
        to={link.url}
        onClick={(e) => {
          if (disableClick(e, isPreview)) return;

          dispatchEvent({
            name: link.name,
            restaurant,
            target: `become_vip${'2'}_${link.linkType}`,
          });
          openSignUp();
        }}
      >
        {link.name}
      </Link>
    );
  }

  const externalUrl = link.linkType === 'custom_type' && link.isExternal;
  const localUrl = link.url?.indexOf('/') === 0 && link.url?.indexOf('/files/') !== 0;
  const isOrderingPage = link.url?.includes('order') || link.url?.includes('getting-started');

  if (!link.disableAjaxLoad && !externalUrl && localUrl) {
    return (
      <Link
        key={`${link.id}_link`}
        aria-label={link.name}
        className={classes.actionBarLink}
        data-event-tracking={link.url?.includes('popmenu-order') && 'order-online-link'}
        to={link.url}
        onClick={(e) => {
          if (disableClick(e, isPreview)) return;

          dispatchEvent({
            name: link.name,
            restaurant,
            target: (isOrderingPage ? `ordering_${link.linkType}` : link.linkType),
          });
        }}
      >
        {link.name}
      </Link>
    );
  } else {
    return (
      <a
        key={`${link.id}_link`}
        aria-label={link.name}
        href={link.url}
        className={classes.actionBarLink}
        rel={link.isExternal ? 'noopener' : null}
        target={link.isExternal ? '_blank' : null}
      >
        {link.name}
      </a>
    );
  }
};

ActionBarLink.propTypes = {
  classes: PropTypes.object.isRequired,
  isUser: PropTypes.bool.isRequired,
  link: PropTypes.object.isRequired,
  modalEvents: PropTypes.object.isRequired,
  restaurant: PropTypes.object.isRequired,
};

export default memo(ActionBarLink);
