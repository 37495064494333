import React from 'react';
import { connect } from 'react-redux';
import { compose, mapProps } from '@shakacode/recompose';
import PropTypes from 'prop-types';
import { Button } from '@popmenu/common-ui';
import { createEvent } from '~/utils/eventable';
import CustomLink from '../../shared/CustomLink';
import { withCurrentSession } from '../../../shared/CurrentSessionProvider';
import {
  openProfileModal,
  openProfileV2Modal,
  openVipV2Modal,
  setVipData,
} from '../../../shared/ModalActions';
import { setMenuItemCartIsScheduled, setMenuItemCartLocationId } from '../../../shared/MenuItemCartActions';
import { withIntl } from '../../../utils/withIntl';
import { withStyles } from '../../../utils/withStyles';
import textBoxSectionStyles from './styles';

const SectionAboutLink = (props) => {
  const { aboutLinkText, aboutLinkUrl, aboutFontAlignment, disableAjaxLoad, linkType } = props;

  const signInLink = (
    <Button
      className="pm-about-section-button page-button"
      color="primary"
      variant="contained"
      onClick={() => {
        createEvent({
          eventableType: 'BecomeVipV2',
          eventType: 'v2_sign_in_nav_link',
        });
        props.setVipData({ showSignInForm: true });
        props.openVipV2Modal();
      }}
    >
      {aboutLinkText || props.t('sessions.sign_in')}
    </Button>
  );

  const showNavSignUp = () => {
    const onOpenProfile = () => {
      if (props.restaurant.featureSetting.isProfileV2Enabled) {
        props.openProfileV2Modal();
      } else {
        props.openProfileModal();
      }
    };
    const click = props.isUser ?
      () => {
        onOpenProfile();
      } : () => {
        try {
          createEvent({
            eventableType: 'BecomeVipV2',
            eventType: 'v2_nav_link',
          });
          props.setVipData();
          props.openVipV2Modal();
        } catch (err) {
          console.warn(err);
        }
      };

    return (
      <Button
        className="pm-about-section-button page-button"
        color="primary"
        variant="contained"
        onClick={click}
      >
        {props.isUser ? props.t('profile.nav_item_my_profile') : aboutLinkText}
      </Button>
    );
  };

  return (
    linkType === 'sign_in_type' ? signInLink :
      linkType === 'sign_up_type' ? showNavSignUp() :
        aboutLinkText && aboutLinkUrl && (
          <div style={{ textAlign: aboutFontAlignment }}>
            <CustomLink
              className="pm-about-section-button page-button"
              disableAjaxLoad={disableAjaxLoad}
              type="button"
              url={aboutLinkUrl}
            >
              {aboutLinkText}
            </CustomLink>
          </div>
        )
  );
};

SectionAboutLink.defaultProps = {
  aboutFontColor: null,
  aboutLinkText: null,
  aboutLinkUrl: null,
  disableAjaxLoad: false,
};

SectionAboutLink.propTypes = {
  aboutFontAlignment: PropTypes.string.isRequired,
  aboutFontColor: PropTypes.string,
  aboutLinkText: PropTypes.string,
  aboutLinkUrl: PropTypes.string,
  classes: PropTypes.object.isRequired,
  disableAjaxLoad: PropTypes.bool,
  restaurant: PropTypes.shape({
    featureSetting: PropTypes.shape({
      isProfileV2Enabled: PropTypes.bool,
    }),
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default compose(
  withStyles(textBoxSectionStyles),
  withCurrentSession,
  connect(() => ({}), {
    openProfileModal,
    openProfileV2Modal,
    openVipV2Modal,
    setMenuItemCartIsScheduled,
    setMenuItemCartLocationId,
    setVipData,
  }),
  withIntl,
  mapProps(({ currentSession, ...props }) => ({
    ...props,
    isUser: !!currentSession.user,
  })),
)(SectionAboutLink);
