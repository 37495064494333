import React from 'react';
import PropTypes from 'prop-types';

import { createPageView } from '~/utils/eventable';
import { formatPhone } from '../../utils/strings';

import CustomPage from '../pages/CustomPage';
import Header from '../shared/Header';
import Meta from '../shared/Meta';

class CustomHomePage extends React.PureComponent {
  componentDidMount() {
    if (this.props.homePage?.slug) {
      createPageView([{
        data: {
          restaurantId: this.props.homePage.restaurantId,
          slug: this.props.homePage.slug,
        },
        schema: 'iglu:com.popmenu/custom_page/jsonschema/1-0-1',

      }]);
    }
  }

  renderMeta() {
    let location = '';
    let phone = '';
    if (this.props.location) {
      location = ` in ${this.props.location.city}, ${this.props.location.state}`;
      if (this.props.location.displayPhone) {
        phone = ` Call us at ${formatPhone(this.props.location.displayPhone)}.`;
      }
    }
    let description = `${this.props.restaurant.name}${location}.${phone} Check out our location and hours, and latest menu with reviews and ratings.`;
    let keywords = '';
    let title;
    let imageUrl;
    if (this.props.homePage) {
      if (this.props.homePage.metaDescription) {
        description = this.props.homePage.metaDescription;
      }
      if (this.props.homePage.metaKeywords) {
        keywords = this.props.homePage.metaKeywords;
      }
      if (this.props.homePage.customMetaTitle && this.props.homePage.customMetaTitle !== this.props.restaurant.name && this.props.homePage.customMetaTitle.toLowerCase() !== 'home') {
        title = this.props.homePage.customMetaTitle;
      }
      if (this.props.homePage.metaImageUrl) {
        imageUrl = this.props.homePage.metaImageUrl;
      }
    }

    return (
      <Meta
        canonicalUrl="/"
        defaultTitle={this.props.restaurant.siteTitle}
        description={description}
        imageUrl={imageUrl}
        keywords={keywords}
        title={title}
        titleTemplate={`%s - ${this.props.restaurant.siteTitle}`}
      />
    );
  }

  render() {
    if (this.props.homePage) {
      return (
        <React.Fragment>
          {this.renderMeta()}
          <CustomPage
            restaurantName={this.props.restaurant.name}
            {...this.props.homePage}
          />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {this.renderMeta()}
        <Header
          heading={this.props.restaurant.name}
          photoUrl={this.props.restaurant.photoUrl}
        />
      </React.Fragment>
    );
  }
}

CustomHomePage.defaultProps = {
  homePage: null,
};

CustomHomePage.propTypes = {
  homePage: PropTypes.object,
  location: PropTypes.shape({
    city: PropTypes.string,
    displayPhone: PropTypes.string,
    enabledMenus: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      photoUrl: PropTypes.string,
      slug: PropTypes.string,
      thumbnailUrl: PropTypes.string,
    })),
    name: PropTypes.string,
    phone: PropTypes.string,
    photoUrl: PropTypes.string,
    slug: PropTypes.string,
    state: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  }).isRequired,
  restaurant: PropTypes.shape({
    id: PropTypes.number,
    logoUrl: PropTypes.string,
    name: PropTypes.string,
    photoUrl: PropTypes.string,
    siteTitle: PropTypes.string,
  }).isRequired,
};

export default CustomHomePage;
