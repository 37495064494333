import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { Typography } from '@popmenu/common-ui';

import { withIntl } from '../../../utils/withIntl';
import { withStyles } from '../../../utils/withStyles';
import errorContainerStyles from './styles';

import { AH } from '../AccessibleHeading';

const ErrorContainer = props => (
  <div className={props.classes.container}>
    <AH typography align="center" className={props.classes.heading} gutterBottom variant="h3">
      <FormattedMessage id="consumer.error.heading" defaultMessage="Sorry." />
    </AH>
    <Typography align="center" className={props.classes.body} gutterBottom>
      <FormattedMessage
        id="consumer.error.body"
        defaultMessage="Looks like there was an error loading the page."
      />
    </Typography>
    <Typography align="center" className={props.classes.body}>
      <a href="/" aria-labelledby="consumer.error.link">
        <FormattedMessage id="consumer.error.link" defaultMessage="Return to home" />
      </a>
    </Typography>
  </div>
);

ErrorContainer.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default withStyles(errorContainerStyles)(withIntl(ErrorContainer));
