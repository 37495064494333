import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '@shakacode/recompose';
import { Link } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { Icon } from '@popmenu/common-ui';
import { X as XIcon } from '@popmenu/web-icons';

import { classNames, withStyles } from '../../../utils/withStyles';
import navStyles from '../Nav/styles';

class RootAnnouncement extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      showRootAnnouncement: true,
    };
    this.hideRootAnnouncement = this.hideRootAnnouncement.bind(this);
  }

  hideRootAnnouncement() {
    this.setState({ showRootAnnouncement: false });
  }

  render() {
    const { showRootAnnouncement } = this.state;
    const { rootAnnouncement } = this.props.restaurant;
    if (showRootAnnouncement && rootAnnouncement) {
      return (
        <div className={classNames('pm-root-announcement', 'pm-root-announcement-root', this.props.classes.rootAnnouncementContainer)}>
          {rootAnnouncement.linkUrl && rootAnnouncement.linkUrl.indexOf('/') === 0 && (
            <Link className={this.props.classes.rootAnnouncementLink} onClick={this.hideRootAnnouncement} to={rootAnnouncement.linkUrl}>
              {rootAnnouncement.content}
            </Link>
          )}
          {rootAnnouncement.linkUrl && rootAnnouncement.linkUrl.indexOf('/') !== 0 && (
            <a className={this.props.classes.rootAnnouncementLink} onClick={this.hideRootAnnouncement} href={rootAnnouncement.linkUrl}>
              {rootAnnouncement.content}
            </a>
          )}
          <IconButton
            aria-label="Dismiss Announcement"
            className={this.props.classes.rootAnnouncementDismissButton}
            onClick={this.hideRootAnnouncement}
          >
            <Icon icon={XIcon} />
          </IconButton>
        </div>
      );
    }
    return null;
  }
}

RootAnnouncement.propTypes = {
  classes: PropTypes.object.isRequired,
  restaurant: PropTypes.shape({
    rootAnnouncement: PropTypes.shape({
      content: PropTypes.string,
      country: PropTypes.string,
      id: PropTypes.number,
      linkUrl: PropTypes.string,
    }),
  }).isRequired,
};

export default compose(
  withStyles(navStyles),
)(RootAnnouncement);
