/* eslint-disable no-param-reassign */
import 'core-js/stable';
import ResizeObserver from 'resize-observer-polyfill';
import matchAll from 'string.prototype.matchall';

// # console polyfill via https://github.com/paulmillr/console-polyfill/blob/master/index.js
// # matchMedia polyfill via https://github.com/akiran/react-slick/issues/742#issuecomment-298992238
// # scrollTo patch catches unsupported scrollTo args in legacy browsers
// # ArrayBuffer.isView polyfill via https://github.com/jvilk/BrowserFS/issues/193#issuecomment-319771804
((global) => {
  matchAll.shim();

  if (!global.console) {
    global.console = {};
  }
  const con = global.console;
  const dummy = () => { };
  const properties = ['memory'];
  const methods = ('assert,clear,count,debug,dir,dirxml,error,exception,group,' +
    'groupCollapsed,groupEnd,info,log,markTimeline,profile,profiles,profileEnd,' +
    'show,table,time,timeEnd,timeline,timelineEnd,timeStamp,trace,warn').split(',');
  properties.forEach((prop) => {
    if (!con[prop]) {
      con[prop] = {};
    }
  });

  methods.forEach((method) => {
    if (!con[method]) {
      con[method] = dummy;
    }
  });

  if (!global.ResizeObserver) {
    global.ResizeObserver = ResizeObserver;
  }
  if (!global.matchMedia) {
    global.matchMedia = (() => ({
      matches: false,
      addListener() { },
      removeListener() { },
    }));
  }
  global.scrollToOriginal = global.scrollTo;
  global.scrollTo = (...args) => {
    try {
      global.scrollToOriginal.apply(window, args);
    } catch (err) {
      console.warn(`[POPMENU] Window.scrollTo failed: ${err.toString()}`);
    }
  };
  if (!ArrayBuffer.isView) {
    ArrayBuffer.isView = a => a !== null && typeof (a) === 'object' && a.buffer instanceof ArrayBuffer;
  }
})(typeof window === 'undefined' ? this : window);
console.log('[POPMENU] Polyfill Initialized (console, matchMedia, scrollTo, isView)');
